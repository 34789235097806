import axios, { AxiosResponse } from "axios";
import { Role } from "../models/role";

async function getallRoles(token: any): Promise<Role[]> {
	try {
		const response: AxiosResponse<Role[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/roles`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching roles data:", error);
		throw error;
	}
}

export { getallRoles };
