import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	Layout,
	Card,
	Select,
	Button,
	message,
	// ColorPicker,
	// ColorPickerProps,
	// GetProp,
} from "antd";
//import "./AddRole.css";
import { useNavigate, useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
// import usePlatformStore from "../stores/platformStore";
import GithubConnectionDetails from "./connections/GithubConnectionDetails";
import JiraConnectionDetails from "./connections/JiraConnectionDetails";
import { getConnectionById, updateConnection } from "../api/ConnectionAPI";
import { useRequest } from "ahooks";

// type Color = GetProp<ColorPickerProps, "value">;

const EditConnectionModal: React.FC = () => {
	// const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
	// 	setUnauthorizedModel: state.setUnauthorizedModel,
	// }));
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { id } = useParams();
	// const { Text } = Typography;
	// const formItemLayout = { labelCol: { span: 2 }, wrapperCol: { span: 14 } };
	// const [tagName, setTagName] = useState<string>("");
	// const [category, setCategory] = useState<string>("");
	// const [description, setDescription] = useState<string>("");
	// const [color, setColor] = useState<Color>("#1677ff");
	const [connectionName, setConnectionName] = useState<string>("");
	const [connectionType, setConnectionType] = useState<string>("");
	const [connectionData, setConnectionData] = useState<any>({});
	const [responseData, setResponseData] = useState<any>({});

	// const [tagData, setTagData] = useState<any>({});

	const { data: connectionsDataById, run: fetchConnectionsBuId } = useRequest(
		(id) => getConnectionById(id, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "Connections data");
				// message.info("Conection Setting");
			},
			onError: (error) => {
				message.error(`Failed to fetch Connections : ${error.message}`);
				console.error("Error fetching connections data:", error);
			},
		}
	);

	useEffect(() => {
		if (connectionsDataById) {
			setResponseData(connectionsDataById);
			setConnectionName(connectionsDataById.name);
			setConnectionType(connectionsDataById.type);
			// setAccessToken(responseData.accessToken);
			console.log(connectionsDataById.data);
			setConnectionData(connectionsDataById.data);

			if (connectionsDataById.type === "Github") {
				form.setFieldsValue({
					connectionName: connectionsDataById.name,
					connectionType: connectionsDataById.type,

					//accessToken: responseData.accessToken,
				});
			} else if (connectionsDataById.type === "Jira") {
				form.setFieldsValue({
					connectionName: connectionsDataById.name,
					connectionType: connectionsDataById.type,

					//accessToken: responseData.accessToken,
				});
			}
		}
	}, [connectionsDataById]);
	useEffect(() => {
		// const fetchData = async () => {
		// 	try {
		// 		const response = await fetch(
		// 			`${process.env.REACT_APP_API_URL}/connection/${id}`,
		// 			{
		// 				headers: {
		// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
		// 				},
		// 			}
		// 		);
		// 		if (response.ok) {
		// 			const resData = await response.json();
		// 			setResponseData(resData);
		// 			setConnectionName(resData.name);
		// 			setConnectionType(resData.type);
		// 			// setAccessToken(responseData.accessToken);
		// 			console.log(resData.data);
		// 			setConnectionData(resData.data);

		// 			if (resData.type === "Github") {
		// 				form.setFieldsValue({
		// 					connectionName: resData.name,
		// 					connectionType: resData.type,

		// 					//accessToken: responseData.accessToken,
		// 				});
		// 			} else if (resData.type === "Jira") {
		// 				form.setFieldsValue({
		// 					connectionName: resData.name,
		// 					connectionType: resData.type,

		// 					//accessToken: responseData.accessToken,
		// 				});
		// 			}

		// 			// form.setFieldsValue({
		// 			// 	connectionName: resData.name,
		// 			// 	connectionType: resData.type,
		// 			// 	connectionData: resData.data,
		// 			// 	//accessToken: responseData.accessToken,

		// 			// 	// category: responseData.category,
		// 			// 	// description: responseData.description,
		// 			// 	// color: responseData.color,
		// 			// });
		// 		} else if (response.status === 401) {
		// 			setUnauthorizedModel(true);
		// 		} else {
		// 			console.error("Error fetching connection data:", response.statusText);
		// 		}
		// 	} catch (error) {
		// 		console.error("Error fetching connection data:", error);
		// 	}
		// };
		if (id) {
			fetchConnectionsBuId(id);
		}
	}, [form, id]);

	useEffect(() => {
		console.log("Connection Data:", connectionData);
	}, [connectionData]);

	const { run: updateConnections } = useRequest(
		(id, data) => updateConnection(id, data, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				navigate("/fd/workspace/settings/connections");
				console.log(result, "updateConnections");
			},
			onError: (error) => {
				message.error(`Failed to update Connections : ${error.message}`);
				console.log(error, "updateConnections");
			},
		}
	);

	const handleButtonClick = async () => {
		const formData = form.getFieldsValue();
		const createdBy = connectionData.createdBy || "defaultCreatedBy";
		const updatedBy = "defaultUpdatedBy";

		const postData = {
			// name: formData.tagName || tagData.name,
			// category: formData.category || tagData.category,
			// description: formData.description || tagData.description,
			// color: formData.color || color,
			name: formData.connectionName || connectionData.name,
			type: formData.connectionType || connectionData.type,
			data: connectionData,
			createdBy,
			updatedBy,
			account_Id: userProfileStoreInstance.profile.account_Id,
		};

		console.log("Sending data to the backend:", postData);

		updateConnections(id, postData);
	};

	// const handleCancelClick = async () => {
	// 	navigate("/fd/workspace/settings/connections");
	// };

	return (
		<Layout style={{ background: "white" }}>
			<h2
				style={{
					color: "black",
					fontFamily: "Poppins",
					fontSize: "24px",
					marginLeft: "2.5rem",
				}}
			>
				Edit Connection
			</h2>
			<Card
				className="fixedCard-settings"
				style={{
					overflow: "auto",
					width: "80vw",
					maxHeight: "68vh",
					marginLeft: "1rem",
				}}
			>
				<Form
					layout="horizontal"
					initialValues={responseData}
					onFinish={handleButtonClick}
				>
					<Form.Item
						label="Type"
						required
						style={{ width: "100%" }}
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 18 }}
					>
						{/* <Input
								value={connectionType}
								onChange={(e) => setConnectionType(e.target.value)}
							/> */}

						<Select
							defaultValue=""
							style={{ width: 120 }}
							value={connectionType}
							onChange={(value) => setConnectionType(value)}
							options={[
								{ value: "Jira", label: "Jira" },
								{ value: "Github", label: "Github" },
							]}
						/>
					</Form.Item>
					<Form.Item
						label="Name"
						required
						style={{ width: "100%" }}
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 18 }}
					>
						<Input
							value={connectionName}
							onChange={(e) => setConnectionName(e.target.value)}
						/>
					</Form.Item>
					{connectionType === "Github" && (
						<GithubConnectionDetails
							connectionData={connectionData}
							setConnectionData={setConnectionData}
						/>
					)}
					{connectionType === "Jira" && (
						<JiraConnectionDetails
							connectionData={connectionData}
							setConnectionData={setConnectionData}
						/>
					)}

					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form>
			</Card>
		</Layout>
	);
};

export default EditConnectionModal;
