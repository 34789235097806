import { Form, Input } from "antd";
import React, { useEffect } from "react";

interface JiraConnectionDetailsProps {
	// connectionBaseUrl: string;
	// setConnectionBaseUrl: (connectionAccessToken: string) => void;
	// connectionUserName: string;
	// setConnectionUserName: (connectionAccessToken: string) => void;
	// connectionAPIToken: string;
	// setConnectionAPIToken: (connectionAccessToken: string) => void;
	connectionData: any;
	setConnectionData: (connectionData: any) => void;
}

const JiraConnectionDetails: React.FC<JiraConnectionDetailsProps> = (props) => {
	// Implement your component logic here

	const [baseUrl, setBaseUrl] = React.useState<string>("");

	useEffect(() => {
		console.log("JiraConnectionDetailsProps:", props);
		setBaseUrl(props.connectionData.baseUrl);
	}, []);

	return (
		// JSX code for your component's UI
		<>
			<Form.Item
				label="BaseUrl"
				name={["connectionData", "baseUrl"]}
				required
				style={{ width: "100%", marginBottom: 10 }}
				// labelCol={{ span: 6.8 }}
				// wrapperCol={{ span: 16 }}
			>
				<Input
					// style={{ marginLeft: "22px" }}
					style={{ width: "100%", fontSize: "14px", height: "32px" }}
					placeholder="BaseUrl"
					defaultValue={props.connectionData.baseUrl}
					value={baseUrl}
					onChange={(e) =>
						props.setConnectionData({
							...props.connectionData,
							baseUrl: e.target.value
								? e.target.value
								: props.connectionData.baseUrl,
						})
					}
				/>
			</Form.Item>
			<Form.Item
				label="UserName"
				required
				style={{ width: "100%", marginBottom: 10 }}
				// labelCol={{ span: 6.9 }}
				// wrapperCol={{ span: 16 }}
			>
				<Input
					//style={{ marginLeft: "-1px" }}
					style={{ width: "100%", fontSize: "14px", height: "32px" }}
					placeholder="UserName"
					defaultValue={props.connectionData.userName}
					value={props.connectionData.userName}
					onChange={(e) =>
						props.setConnectionData({
							...props.connectionData,
							userName: e.target.value,
						})
					}
				/>
			</Form.Item>

			<Form.Item
				label="APIToken"
				name={["connectionData", "apiToken"]}
				required
				style={{ width: "100%", marginBottom: 10 }}
				// labelCol={{ span: 6 }}
				// wrapperCol={{ span: 16 }}
			>
				<Input
					//	style={{ marginLeft: "3px" }}
					style={{ width: "100%", fontSize: "14px", height: "32px" }}
					placeholder="APIToken"
					defaultValue={props.connectionData.apiToken}
					value={props.connectionData.apiToken}
					onChange={(e) =>
						props.setConnectionData({
							...props.connectionData,
							apiToken: e.target.value,
						})
					}
				/>
			</Form.Item>
		</>
	);
};

export default JiraConnectionDetails;
