/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FolderOpenTwoTone } from "@ant-design/icons";

// import EditWorkspaceModal from "./EditWorkspace";
import {
	DeploymentUnitOutlined,
	//CrownFilled,
	BulbOutlined,
	ShareAltOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import {
	Button,
	Card,
	Empty,
	Flex,
	Layout,
	Modal,
	Typography,
	//Radio,
	Segmented,
	Skeleton,
	Tooltip,
	message,
	Space,
} from "antd";
import { useNavigate } from "react-router-dom";
import ShareWorkSpaceModal from "./ShareWorkspace";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
// import AccountStore, { AccountStoreType } from "../stores/AccountStore";
import usePlatformStore from "../stores/platformStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import operation_failed from "../assets/operation_failed.svg";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
import ArchivedWorkspaceConfirmationModal from "./ArchivedWorkspaceConfirmationModal";
import { update } from "lodash";
import moment from "moment";
import breadcrumbConfig from "../util/BreadCrumbsUtil";
import BreadCrumb from "../pages/Editors/BreadCrumb";
export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

const { Paragraph } = Typography;

function WorkspaceList() {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const navigate = useNavigate();
	const { Content } = Layout;

	const [Workspace, setWorkspace] = useState([]);
	const [sharedWorkspaces, setSharedWorkspaces] = useState([]);
	const [inCompleteIdeaConversation, setInCompleteIdeaConversation] = useState(
		[]
	);
	const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(null);
	const [isShareModalVisible, setIsShareModalVisible] = useState(false);
	const [tasks, setTasks] = useState<string>("completed");
	const [isTaskCompleted, setIsTaskCompleted] = useState(false);
	const [selectedTab, setSelectedTab] = useState("All Workspaces");
	const [error, setError] = useState(null);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [workspaceToDelete, setWorkspaceToDelete] = useState(null);
	const [abilities, setAbilities] = useState(ability);

	const { setCurrentWorkspace } = useWorkspaceStore((state: any) => ({
		setCurrentWorkspace: state.setCurrentWorkspace,
	}));
	const { setBackNavigateUrl, setNextNavigateUrl } = usePlatformStore(
		(state: any) => ({
			setBackNavigateUrl: state.setBackNavigateUrl,
			setNextNavigateUrl: state.setNextNavigateUrl,
		})
	);
	useEffect(() => {
		setNextNavigateUrl("/");
		setBackNavigateUrl("/");
	}, []);

	const handleTabChange = (e: any) => {
		console.log(e);
		setSelectedTab(e);
	};

	const fetchTasksData = async () => {
		if (userProfileStoreInstance.idToken) {
			try {
				const response = await fetch(
					`${ServerLink}/tasks/activity?activity_type=FIRST_TIME_USER_FEEDBACK`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				const tasks = await response.json();
				console.log("tasksand", tasks);
				// userProfileStoreInstance.setProfile(tasks[0].user_profile_id);
				if (tasks.length > 0) {
					setTasks(tasks[0].status);
				}
			} catch (error) {
				console.error(error);
			}
		}
	};
	useEffect(() => {
		fetchTasksData();
	}, [userProfileStoreInstance.idToken]);

	const ServerLink = process.env.REACT_APP_API_URL;

	const fetchData = async () => {
		if (userProfileStoreInstance.idToken) {
			try {
				const response = await fetch(`${ServerLink}/workspace`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const json = await response.json();
				setWorkspace(json);
				console.log("json", json);
				setError(null);

				// const sharedWorkspaces = await fetch(
				// 	`${ServerLink}/workspace-permissions/sharedworkspaces`,
				// 	{
				// 		headers: {
				// 			Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				// 		},
				// 	}
				// );
				// const sharedWorkspacesJson = await sharedWorkspaces.json();

				// setSharedWorkspaces(sharedWorkspacesJson);
			} catch (error: any) {
				console.error(error);
				console.error(error);
				setError(error);
				message.error(`An error occurred: ${error.message}`);
			}
		}
	};

	const fetchConversationData = async () => {
		if (userProfileStoreInstance.idToken) {
			try {
				const response = await fetch(
					`${ServerLink}/ai-conversation-thread/me/idea-refinement?status=IN_PROGRESS`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const json = await response.json();
				console.log("json", json);

				setInCompleteIdeaConversation(json);

				// setWorkspace(json);
			} catch (error: any) {
				console.error(error);
				console.error(error);
				setError(error);
				message.error(`An error occurred: ${error.message}`);
			}
		}
	};

	useEffect(() => {
		if (userProfileStoreInstance.idToken) {
			fetchData();
			fetchConversationData();
		}
	}, [userProfileStoreInstance.idToken]);

	console.log("workspace", Workspace.length);

	const formatDateDifference = (updatedAt: any) => {
		// const updatedAtDate = new Date(updatedAt);
		// const currentDate: Date = new Date();

		// const timeDifference = currentDate.getTime() - updatedAtDate.getTime();
		// const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
		// const monthsDifference =
		// 	currentDate.getMonth() -
		// 	updatedAtDate.getMonth() +
		// 	12 * (currentDate.getFullYear() - updatedAtDate.getFullYear());

		const currentDate = moment().startOf("day");
		const updatedAtDate = moment(updatedAt).startOf("day");
		const daysDifference = currentDate.diff(updatedAtDate, "days");
		const monthsDifference = currentDate.diff(updatedAtDate, "months");

		if (daysDifference < 1) {
			return "Today";
		} else if (daysDifference === 1) {
			return "1 day ago";
		} else if (daysDifference < 30) {
			return `${daysDifference} days ago`;
		} else if (monthsDifference === 1) {
			return "1 month ago";
		} else if (monthsDifference > 1) {
			return `${monthsDifference} months ago`;
		} else {
			return "Today";
		}
	};

	// const handleSettingsClick = () => {
	// 	navigate("/settings/role");
	// };

	useEffect(() => {
		setIsTaskCompleted(tasks === "open");
	}, [tasks]);

	useEffect(() => {
		if (isTaskCompleted === true) {
			navigate("/load");
		}
	}, []);

	// const showEditModal = () => {
	// 	setIsEditModalVisible(true);
	// };

	// const handleEditClick = (workspaceId: any) => {
	// 	setSelectedWorkspaceId(workspaceId);
	// 	console.log(selectedWorkspaceId);
	// 	showEditModal();
	// };

	// const handleEditModalCancel = () => {
	// 	setIsEditModalVisible(false);
	// 	fetchData();
	// };

	const showShareModal = () => {
		setIsShareModalVisible(true);
	};

	const handleShareClick = (id: any) => {
		setSelectedWorkspaceId(id);
		console.log(selectedWorkspaceId);
		showShareModal();
	};

	// Function to close the edit modal
	const handleShareModalCancel = () => {
		setIsShareModalVisible(false);
	};
	const { setAddWorkspaceDetails } = useWorkspaceStore((state: any) => ({
		setAddWorkspaceDetails: state.setAddWorkspaceDetails,
	}));

	const [modal, contextHolder] = Modal.useModal();

	const countDown = (message: any, status: any) => {
		let secondsToGo = 5;
		let instance: any;

		if (status === "fail") {
			instance = modal.error({
				title: "Error!",
				content: (
					<div>
						<Typography.Text>{message}</Typography.Text>
						<img
							src={operation_failed}
							alt="Error Image"
							style={{ maxWidth: "100%" }}
						/>
					</div>
				),
			});
		}

		const timer = setInterval(() => {
			secondsToGo -= 1;
			//   instance.update({
			// 	content: (
			// 	  <div>
			// 		<p>{message}</p>
			// 		<img src={imageUrl} alt="Image" style={{ maxWidth: '100%' }} />
			// 	  </div>
			// 	),
			//   });
		}, 1000);

		setTimeout(() => {
			clearInterval(timer);
			instance.destroy();
		}, secondsToGo * 1000);
	};
	const handleNewWorkspace = () => {
		setAddWorkspaceDetails(null);
		//navigate(`/workspace/add`);
		setBackNavigateUrl("/fd/workspace/list");
		navigate(`/fd/workspace/create`);
	};
	const handleStartIdeaClick = () => {
		setAddWorkspaceDetails(null);
		//navigate(`/workspace/add`);
		setBackNavigateUrl("/fd/workspace/list");
		//navigate(`/fd/chat/idea-refinement`);
		navigate(`/fd/preprocess/chat/idea-refinement`);
	};

	const handleOk = async () => {
		try {
			// Fetch the existing workspace data
			const response = await fetch(
				`${ServerLink}/workspace/${workspaceToDelete}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const existingWorkspaceData = await response.json();

			// Update only the status field
			const updatedWorkspaceData = {
				...existingWorkspaceData,
				status: "ARCHIVED", // Set the new status value
			};

			// Perform the PUT request to update the workspace
			const updateResponse = await fetch(
				`${ServerLink}/workspace/${workspaceToDelete}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(updatedWorkspaceData),
				}
			);

			if (!updateResponse.ok) {
				throw new Error(`HTTP error! Status: ${updateResponse.status}`);
			}

			fetchData();
		} catch (error: any) {
			// Handle errors from the GET or PUT request
			console.error("Error updating workspace status:", error);
			message.error(`An error occurred: ${error.message}`);
		}

		// Close the modal after handling the update
		setIsDeleteModalVisible(false);
	};

	const handleCancel = () => {
		// Close the modal without performing any action
		setIsDeleteModalVisible(false);
	};
	const updateConversationToDelete = async (conversationThread: any) => {
		if (userProfileStoreInstance.idToken) {
			try {
				const response = await fetch(
					`${ServerLink}/ai-conversation-thread/${conversationThread._id}/delete`,
					{
						method: "PUT",
						body: JSON.stringify({
							_id: conversationThread._id,
							status: "ARCHIVED",
						}),
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const json = await response.json();

				// setWorkspace(json);
			} catch (error: any) {
				console.error(error);
				console.error(error);
				setError(error);
				message.error(`An error occurred: ${error.message}`);
			}
		}
	};
	const showDeleteConversationModal = async (conversationThread: any) => {
		await updateConversationToDelete(conversationThread);
		//fetchData();
		await fetchConversationData();
	};
	const showDeleteModal = (workspaceId: any) => {
		setWorkspaceToDelete(workspaceId);
		setIsDeleteModalVisible(true);
	};

	return (
		<AbilityContext.Provider value={abilities}>
			<Layout style={{ backgroundColor: "#F5F8FE" }}>
				{contextHolder}
				<Content>
					{/* <div className="LandingPageText">
						<div data-testid="Greeting" className="LandingPageText1">
							Transform Ideas To Possiblities
						</div>
						<div data-testid="elaboration" className="LandingPageText2">
							Empowering Ideas into Achievable Realities, with a simplicity
							centered and AI assistance.
						</div>
					</div> */}

					{/* <div>
						<motion.div whileHover={{ scale: 1.2 }}>
							<Can
								I="WORKSPACE_CAN_CREATE"
								a="WORKSPACE_CAN_CREATE"
								passThrough
							>
								{(allowed) =>
									allowed ? (
										<Button
											data-testid="create-workspace-button"
											onClick={handleStartIdeaClick}
											size="large"
											type="primary"
											style={{ marginTop: 20 }}
										>
											Start with your Idea <BulbOutlined />
										</Button>
									) : (
										<Button
											data-testid="create-workspace-button"
											onClick={() =>
												countDown(
													"You don't have permission to create workspace",
													"fail"
												)
											}
											size="large"
											type="primary"
											style={{ marginTop: 20 }}
										>
											Start with your Idea <BulbOutlined />
										</Button>
									)
								}
							</Can>
						</motion.div>
						
					</div> */}

					<div
						className="workspaceLayoutContent"
						style={{ marginTop: "-2rem" }}
					>
						<div data-testid="YourWorkspaces" className="LandingPageWorkspaces">
							{/* <h3
								color="#87d068"
								style={{
									fontSize: "1.3rem",
									marginLeft: "42px",
									marginBottom: "-40px",
									fontFamily: "Poppins",
								}}
							>
								All Workspaces
							</h3> */}
							<div
								style={{
									fontSize: "1.3rem",
									marginLeft: "42px",
									marginBottom: "-40px",
								}}
							>
								<BreadCrumb config={breadcrumbConfig.workspace} />
							</div>
							{/* <Radio.Group
								onChange={handleTabChange}
								defaultValue="Workspace"
								style={{ width: "60rem" }}
								className="RadioGroup"
							>
								<Radio.Button value="Workspace">All Workspaces</Radio.Button>
								<Radio.Button value="Share Workspace">
									Shared Workspaces
								</Radio.Button>
							</Radio.Group> */}
							{/* <Segmented
								options={["All Workspaces", "Shared Workspaces"]}
								onChange={handleTabChange}
							/> */}

							<Flex justify="flex-end" gap="small">
								{/* <Button
									onClick={handleNewWorkspace}
									type="default"
									style={{ float: "right" }}
								>
									{" "}
									New workspace
								</Button> */}
								<Button
									onClick={handleStartIdeaClick}
									type="primary"
									style={{ float: "right" }}
								>
									{" "}
									Start with your Idea <BulbOutlined />
								</Button>
							</Flex>
						</div>
						<Card
							className="WorkspaceDiv"
							style={{
								height: "450px",
								overflowY: "auto",
								marginTop: "-1rem",
							}}
						>
							{error ? (
								<div className="Empty">
									<Empty />
								</div>
							) : (
								<div
									style={{
										marginTop: "-1rem",
										display: "grid",
										gridTemplateColumns:
											"repeat(auto-fill, minmax(330px, 1fr))",
									}}
								>
									{inCompleteIdeaConversation?.length > 0 ? (
										inCompleteIdeaConversation.map(
											(conversation: any, index: number) => {
												return (
													<motion.div
														key={conversation._id}
														data-testid={`conversation${index}`}
														className="conversation"
														initial={{ scale: 0.8, opacity: 0 }}
														animate={{ scale: 1, opacity: 1 }}
														transition={{
															duration: 1,
															delay: index * 0.2,
														}}
														style={{ borderBottom: "none" }}
													>
														<Card style={{ width: "100%", padding: "1rem" }}>
															<div className="workspaceNameContent">
																<div className="RecentWorkspaceCardDiv">
																	<BulbOutlined
																		className="DeploymentUnitIcon"
																		// style={{ marginLeft: "28px" }}
																	/>
																</div>
																<div className="workspaceContent">
																	<div
																		data-testid={`RecentWorkspaceCardTitleText${index}`}
																		className="RecentWorkspaceCardTitleText"
																		style={{
																			marginLeft: "-10px",
																			fontFamily: "Poppins",
																		}}
																	>
																		{conversation.goal}
																		{`-`}
																		{index + 1}
																	</div>
																	<div
																		data-testid={`EditIcon${index}`}
																		className="EditIcon"
																		style={{
																			marginLeft: "-38px",
																			fontFamily: "Poppins",
																		}}
																	>
																		.
																	</div>
																</div>
															</div>
															<div
																className="RecentWorkspaceCardButton"
																// style={{ marginLeft: "28px" }}
															>
																<div>
																	<div className="ProgressInfo">
																		<div data-testid={`EditDays${index}`}>
																			{formatDateDifference(
																				conversation.updatedAt
																			)}
																		</div>
																	</div>
																</div>

																<Tooltip
																	placement="bottom"
																	title="Archive workspace"
																>
																	<FolderOpenTwoTone
																		onClick={() =>
																			showDeleteConversationModal(conversation)
																		}
																		className="RecentWorkspaceCardArchived"
																	/>
																</Tooltip>
																<Button
																	data-testid={`RecentWorkspaceCardButtonExplore${index}`}
																	className="RecentWorkspaceCardButtonExplore"
																	style={{
																		overflow: "hidden",
																		backgroundColor: "#0444bf",
																		fontFamily: "Poppins",
																	}}
																	type="primary"
																	onClick={() => {
																		//setCurrentWorkspace(workspace);
																		navigate(
																			`/fd/chatv3/resume/${conversation.goal}/${conversation._id}`
																		);
																	}}
																>
																	Resume
																</Button>
															</div>
														</Card>
													</motion.div>
												);
											}
										)
									) : (
										<></>
										// <div className="Empty">
										// 	{/* <Skeleton active avatar>
										// 		<List.Item.Meta title="dfgbdfsgsdf" />
										// 	</Skeleton> */}
										// 	<Flex align="center">
										// 		<Skeleton
										// 			paragraph={{ rows: 1, width: 500 }}
										// 			title={{ width: 100 }}
										// 			active
										// 		/>
										// 		<Skeleton.Button size="large" active />
										// 	</Flex>
										// 	<Flex align="center">
										// 		<Skeleton
										// 			paragraph={{ rows: 1, width: 500 }}
										// 			title={{ width: 100 }}
										// 			active
										// 		/>
										// 		<Skeleton.Button size="large" active />
										// 	</Flex>
										// </div>
									)}

									{Workspace?.length > 0 ? (
										Workspace.map((workspace: any, index: number) => {
											return (
												<motion.div
													key={workspace._id}
													data-testid={`RecentWorkspaceCard${index}`}
													className="RecentWorkspaceCard"
													initial={{ scale: 0.8, opacity: 0 }}
													animate={{ scale: 1, opacity: 1 }}
													transition={{
														duration: 1,
														delay: index * 0.2,
													}}
													style={{ borderBottom: "none" }}
												>
													<Card style={{ width: "100%", padding: "1rem" }}>
														<div className="workspaceNameContent">
															<div className="RecentWorkspaceCardDiv">
																<DeploymentUnitOutlined
																	className="DeploymentUnitIcon"
																	// style={{ marginLeft: "28px" }}
																/>
															</div>
															<div className="workspaceContent">
																<div
																	data-testid={`RecentWorkspaceCardTitleText${index}`}
																	className="RecentWorkspaceCardTitleText"
																	style={{
																		marginLeft: "-10px",
																		fontFamily: "Poppins",
																	}}
																>
																	{workspace.name}
																</div>
																<div
																	data-testid={`EditIcon${index}`}
																	className="EditIcon"
																	style={{
																		marginLeft: "-38px",
																		fontFamily: "Poppins",
																	}}
																>
																	A brief description of the application giving
																	a basic idea of the project.
																</div>
															</div>
														</div>
														<div
															className="RecentWorkspaceCardButton"
															// style={{ marginLeft: "28px" }}
														>
															<div>
																<div className="ProgressInfo">
																	<div data-testid={`EditDays${index}`}>
																		{formatDateDifference(workspace.updatedAt)}
																	</div>
																</div>
															</div>
															{workspace.createdBy ===
															userProfileStoreInstance.profile?._id ? (
																<Tooltip
																	placement="bottom"
																	title="Share workspace"
																>
																	<ShareAltOutlined
																		data-testid={`RecentWorkspaceCardButtonShare${index}`}
																		className="RecentWorkspaceCardButtonShare"
																		onClick={() =>
																			handleShareClick(workspace._id)
																		}
																	/>
																</Tooltip>
															) : (
																<></>
															)}

															<Tooltip
																placement="bottom"
																title="Archive workspace"
															>
																<FolderOpenTwoTone
																	onClick={() => showDeleteModal(workspace._id)}
																	className="RecentWorkspaceCardArchived"
																/>
															</Tooltip>
															<Button
																data-testid={`RecentWorkspaceCardButtonExplore${index}`}
																className="RecentWorkspaceCardButtonExplore"
																style={{
																	overflow: "hidden",
																	backgroundColor: "#0444bf",
																	fontFamily: "Poppins",
																	fontSize: "16px",
																	borderRadius: "6px",
																	height: "35px",
																}}
																type="primary"
																size="small"
																onClick={() => {
																	setCurrentWorkspace(workspace);
																	navigate(
																		//`/workspace/fe/featurelist/${workspace._id}`
																		`/space/${workspace._id}`
																	);
																}}
															>
																Explore
															</Button>
														</div>
													</Card>
												</motion.div>
											);
										})
									) : (
										<div className="Empty">
											{/* <Skeleton active avatar>
												<List.Item.Meta title="dfgbdfsgsdf" />
											</Skeleton> */}
											<Flex align="center">
												<Skeleton
													paragraph={{ rows: 1, width: 1200 }}
													title={{ width: 100 }}
													active
												/>
												<Skeleton.Button size="large" active />
											</Flex>
											<Flex align="center">
												<Skeleton
													paragraph={{ rows: 1, width: 1200 }}
													title={{ width: 100 }}
													active
												/>
												<Skeleton.Button size="large" active />
											</Flex>
										</div>
									)}
								</div>
								// ) : (
								// 	<div
								// 		style={{
								// 			marginTop: "-1rem",
								// 			display: "flex",
								// 			flexWrap: "wrap",
								// 		}}
								// 	>
								// 		{sharedWorkspaces?.length > 0 ? (
								// 			sharedWorkspaces.map((workspace: any, index: number) => {
								// 				return (
								// 					<div
								// 						key={workspace._id}
								// 						data-testid={`RecentWorkspaceCard${index}`}
								// 						className="RecentWorkspaceCard"
								// 						// initial={{ y: 40, opacity: 0 }}
								// 						// animate={{ y: 0, opacity: 1 }}
								// 						// transition={{
								// 						// 	duration: 1,
								// 						// 	delay: index * 0.2,
								// 						// }}
								// 						style={{ width: "33.33%", borderBottom: "none" }}
								// 					>
								// 						<Card style={{ width: "100%" }}>
								// 							<div className="workspaceNameContent">
								// 								<div className="RecentWorkspaceCardDiv">
								// 									<DeploymentUnitOutlined
								// 										className="DeploymentUnitIcon"
								// 										style={{ marginLeft: "28px" }}
								// 									/>
								// 								</div>
								// 								<div className="workspaceContent">
								// 									<div
								// 										data-testid={`RecentWorkspaceCardTitleText${index}`}
								// 										className="RecentWorkspaceCardTitleText"
								// 										style={{ marginLeft: "-10px" }}
								// 									>
								// 										{workspace.name}
								// 									</div>
								// 									<div
								// 										data-testid={`EditIcon${index}`}
								// 										className="EditIcon"
								// 										style={{ marginLeft: "-38px" }}
								// 									>
								// 										A brief description of the application giving
								// 										a basic idea of the project.
								// 									</div>
								// 								</div>
								// 							</div>
								// 							<div
								// 								className="RecentWorkspaceCardButton"
								// 								style={{ marginLeft: "28px" }}
								// 							>
								// 								<div>
								// 									<div className="ProgressInfo">
								// 										<div data-testid={`EditDays${index}`}>
								// 											{formatDateDifference(workspace.updatedAt)}
								// 										</div>
								// 									</div>
								// 								</div>
								// 								{workspace.createdBy ===
								// 								userProfileStoreInstance.profile?._id ? (
								// 									<Tooltip
								// 										placement="bottom"
								// 										title="Share workspace"
								// 									>
								// 										<ShareAltOutlined
								// 											data-testid={`RecentWorkspaceCardButtonShare${index}`}
								// 											className="RecentWorkspaceCardButtonShare"
								// 											onClick={() =>
								// 												handleShareClick(workspace._id)
								// 											}
								// 										/>
								// 									</Tooltip>
								// 								) : (
								// 									<></>
								// 								)}

								// 								<Tooltip
								// 									placement="bottom"
								// 									title="Archive workspace"
								// 								>
								// 									<DeleteOutlined
								// 										onClick={() => showDeleteModal(workspace._id)}
								// 										className="RecentWorkspaceCardArchived"
								// 									/>
								// 								</Tooltip>
								// 								<Button
								// 									data-testid={`RecentWorkspaceCardButtonExplore${index}`}
								// 									className="RecentWorkspaceCardButtonExplore"
								// 									style={{
								// 										overflow: "hidden",
								// 									}}
								// 									type="primary"
								// 									onClick={() => {
								// 										setCurrentWorkspace(workspace);
								// 										navigate(
								// 											//`/workspace/fe/featurelist/${workspace._id}`
								// 											`/space`
								// 										);
								// 									}}
								// 								>
								// 									Explore
								// 								</Button>
								// 							</div>
								// 						</Card>
								// 					</div>
								// 				);
								// 			})
								// 		) : (
								// 			<div className="Empty">
								// 				{/* <Skeleton active avatar>
								// 					<List.Item.Meta title="dfgbdfsgsdf" />
								// 				</Skeleton> */}
								// 				<Flex align="center">
								// 					<Skeleton
								// 						paragraph={{ rows: 1, width: 500 }}
								// 						title={{ width: 100 }}
								// 						active
								// 					/>
								// 					<Skeleton.Button size="large" active />
								// 				</Flex>
								// 				<Flex align="center">
								// 					<Skeleton
								// 						paragraph={{ rows: 1, width: 500 }}
								// 						title={{ width: 100 }}
								// 						active
								// 					/>
								// 					<Skeleton.Button size="large" active />
								// 				</Flex>
								// 			</div>
								// 		)}
								// 	</div>
							)}
						</Card>
					</div>

					{/* <div className="bulb-container">
						<Button
							data-testid="LandingPageOutlineBulb"
							className="LandingPageOutlineBulb"
						>
							<BulbTwoTone />
						</Button>
					</div> */}

					{/* <EditWorkspaceModal
						isModalVisible={isEditModalVisible}
						handleModalCancel={handleEditModalCancel}
						workspaceId={selectedWorkspaceId}
					/> */}

					<ShareWorkSpaceModal
						id={selectedWorkspaceId}
						visible={isShareModalVisible}
						onCancel={handleShareModalCancel}
					/>
				</Content>
				<Flex
					className="footer"
					justify="center"
					style={{
						width: "100%",
						position: "absolute",
						bottom: "0",
						margin: "1rem 0 0px 0",
					}}
				>
					<p style={{ fontSize: 12, fontWeight: 500, fontFamily: "Poppins" }}>
						© 2024 Zentience. All rights reserved.
					</p>
				</Flex>
				<ArchivedWorkspaceConfirmationModal
					visible={isDeleteModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
				/>
			</Layout>
		</AbilityContext.Provider>
	);
}

export default WorkspaceList;
