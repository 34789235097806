/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
	Layout,
	Collapse,
	Table,
	Tag,
	Flex,
	Card,
	Space,
	Button,
	Result,
} from "antd";
import { usePlanStore } from "../../../stores/PlanStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";

import { EyeOutlined, SmileOutlined } from "@ant-design/icons";
import "./Sprint.css";
import SprintNav from "./SprintNav";
import CreateSprintPlanModel from "./CreateSprintPlanModel";
import useWorkspaceStore from "../../../stores/WorkspaceState";

const { Content } = Layout;
const { Panel } = Collapse;

const Release: React.FC = () => {
	const [todos, setTodos] = useState<any[]>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const {
		isCardView,
		open,
		input,
		setOpen,
		ReleasePlanValue,
		setReleasePlan,
		setFetchPlan,
		selectedGroupBy,
		FetchPlan,
		planvalue,
		filter,
		setplanvalue,
		urlData,
		sortBy,
		changeUrl,
	} = usePlanStore();
	const [modalVisible, setModalVisible] = useState(false);
	const [Nodata, setNodata] = useState(false);
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const fetchTodos = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/${currentWorkspace._id}?type=Sprintplan`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const todosData: any[] = await response.json();
				if (todosData.length === 0) {
					setNodata(true);
				} else {
					setNodata(false);
				}
				setTodos(todosData);
				const planname = todosData
					.map((todo) => {
						return todo.name;
					})
					.flat();
				setplanvalue(planname);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	const handleModalCancel = () => {
		setModalVisible(false);
	};

	const handleAddWorkItemClick = () => {
		setModalVisible(true);
	};

	useEffect(() => {
		fetchTodos();
	}, [FetchPlan]);
	// const filteredDataSource:any[] = todos.filter(item =>
	// 	item.title.replace(/\s/g, "").toLowerCase().includes(input.replace(/\s/g, "").toLowerCase()) ||
	// 	item.description.replace(/\s/g, "").toLowerCase().includes(input.replace(/\s/g, "").toLowerCase())
	// );

	console.log("firstdata", todos);

	// Filter function
	const sortedAndFilteredData = (data: any) => {
		const filteredData = data.filter((item: any) => {
			let matchesFilter = true;
			const inputLower = input.replace(/\s/g, "").toLowerCase();
			const typeFilters = filter
				.filter((filterItem) => filterItem.startsWith("Type"))
				.map((filterItem) => {
					const typeSplit = filterItem.split("_");
					const type = typeSplit.shift();
					const rest = typeSplit.join("_");
					return rest.toLowerCase();
				});
			const statusFilters = filter
				.filter((filterItem) => filterItem.startsWith("Status"))
				.map((filterItem) => filterItem.split("_")[1].toLowerCase());
			const priorityFilters = filter
				.filter((filterItem) => filterItem.startsWith("Priority"))
				.map((filterItem) => filterItem.split("_")[1].toLowerCase());

			matchesFilter =
				matchesFilter &&
				(item.title.replace(/\s/g, "").toLowerCase().includes(inputLower) ||
					item.description
						.replace(/\s/g, "")
						.toLowerCase()
						.includes(inputLower));

			if (typeFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					typeFilters.some(
						(filterValue) => item?.type?.toLowerCase() === filterValue
					);
			}

			if (statusFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					statusFilters.some(
						(filterValue) => item?.status?.toLowerCase() === filterValue
					);
			}

			if (priorityFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					priorityFilters.some(
						(filterValue) => item?.priority?.toLowerCase() === filterValue
					);
			}

			return matchesFilter;
		});

		const test = [...filteredData].sort((a, b) => {
			console.log("sortBy", sortBy);
			if (sortBy === "Created date (Asc)") {
				return (
					new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
				);
			} else if (sortBy === "Created date (Desc)") {
				return (
					new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				);
			} else {
				return 0;
			}
		});

		return test;
	};

	function groupBy(array: any[], key: string) {
		return array.reduce((result: any, currentItem: any) => {
			const groupKey = currentItem[key];
			if (!result[groupKey]) {
				result[groupKey] = [];
			}
			result[groupKey].push(currentItem);
			return result;
		}, {});
	}

	useEffect(() => {
		changeUrl(
			`/space/${currentWorkspace._id}/planner/sprintplans`,
			`/space/${currentWorkspace._id}/planner/sprintplanner`,
			"Sprint Planner"
		);
	}, []);

	useEffect(() => {
		setFetchPlan(!FetchPlan);
	}, [urlData]);

	const setGroupKey = (selectedGroupBy: string) => {
		switch (selectedGroupBy) {
			case "Type":
				return "type";
			case "Priority":
				return "priority";
			case "Status":
				return "status";
			case "release":
				return "name";
			default:
				return "name";
		}
	};

	const data = todos.flatMap(
		(todo) =>
			todo?.items?.map((item: any) => ({
				planName: todo.name,
				key: item._id,
				title: item.title,
				description: item.description,
				priority: item.priority,
				type: item.type,
				status: item.status,
				story: item.story,
				createdAt: item.createdAt,
			})) || []
	);

	const data2 = sortedAndFilteredData(data);
	console.log("data2", data2);

	const groupKey = setGroupKey(selectedGroupBy);
	let groupedTasks = groupBy(data, groupKey);
	if (groupKey === "name") {
		groupedTasks = groupBy(todos, groupKey);
	} else {
		groupedTasks = groupBy(data, groupKey);
	}

	console.log("groupedTasks", groupedTasks);

	const handleDrawerCancel = () => {
		setOpen(false);
	};

	const truncateDescription = (description: string, wordCount: number) => {
		const words = description.split(" ");
		if (words.length > wordCount) {
			return words.slice(0, wordCount).join(" ") + "...";
		}
		return description;
	};

	const columns = [
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			render: (text: any, record: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<EyeOutlined style={{ fontSize: "1.1rem", marginRight: "1vw" }} />
					<a style={{ color: "black", fontWeight: "bold" }}>{text}</a>
				</div>
			),
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			render: (text: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<a
						style={{ color: "black", fontWeight: "bold" }}
						dangerouslySetInnerHTML={{ __html: text }}
					></a>
				</div>
			),
		},
		{
			title: "Priority",
			dataIndex: "priority",
			key: "priority",
			render: (priority: any) => (
				<Tag color="blue" style={{ color: "black" }}>
					{priority}
				</Tag>
			),
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: (type: any) => (
				<Tag color="yellow" style={{ color: "black" }}>
					{type}
				</Tag>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
	];

	return (
		<Layout style={{ background: "#FAFBFF" }}>
			<SprintNav />
			{isCardView ? (
				<Flex
					style={{
						height: "70vh",
						justifyContent: !Nodata ? "left" : "center",
					}}
				>
					{planvalue && !Nodata ? (
						planvalue.map((col: any, index: number) => (
							<Flex vertical style={{ width: "25vw" }} key={index + 1}>
								<Flex
									style={{
										height: "5vh",
										display: "flex",
										alignItems: "center",
									}}
									justify="center"
									className="ColumnTitle"
								>
									{col}
								</Flex>
								<Flex
									vertical
									style={{
										overflow: "scroll",
										height: "70vh",
									}}
								>
									{todos
										.filter((item: any) => item.name === col)
										.map((tasks: any) =>
											tasks.items.map((task: any) => (
												<Card className="KanoCard" key={task.id}>
													<Flex align="start">
														{/* <div
                                                                style={{
                                                                    color: col.color,
                                                                    fontSize: "1.2rem",
                                                                }}
                                                            >
                                                                {task.icon}
                                                            </div> */}
														<Space direction="vertical">
															<Flex justify="space-between">
																<div
																	style={{
																		fontWeight: "bold",
																	}}
																>
																	{task.title}
																</div>
																{/* <div style={{ fontWeight: "bold" }}>
                                                                        {task.story}
                                                                    </div> */}
															</Flex>
															<Flex className="kanoCardDescription">
																{truncateDescription(task.description, 10)}
																{/* {task.description.split(' ').slice(0, 20).join(' ')}
                                                                            {task.description.split(' ').length == 20 && '...'} */}
															</Flex>
														</Space>
													</Flex>
													<Flex
														justify="space-between"
														style={{
															marginTop: "1.5rem",
														}}
													>
														<div
															style={{
																fontSize: "0.8rem",
																fontWeight: "bold",
																color: col.color,
																float: "left",
															}}
														>
															Status: {task.status}
														</div>
														<Tag
															color={"yellow"}
															style={{
																color: "black",
																fontSize: "0.8rem",
																// width: "fit-content",
																textAlign: "center",
															}}
														>
															{task.type}
														</Tag>
													</Flex>
												</Card>
											))
										)}
								</Flex>
							</Flex>
						))
					) : (
						<Result
							icon={<SmileOutlined />}
							title="There are no existing plans right now, Create one!"
							extra={
								<Button type="primary" onClick={handleAddWorkItemClick}>
									Next
								</Button>
							}
							style={{ marginTop: "10vh" }}
						/>
					)}
				</Flex>
			) : (
				<Layout style={{ background: "#FAFBFF", height: "70vh" }}>
					{!Nodata ? (
						<Content
							style={{
								background: "#FAFBFF",
								padding: "24px",
								height: "70vh",
								overflow: "auto",
							}}
						>
							<Collapse>
								{Object.entries(groupedTasks).map(
									([plan, tasks]: [string, any]) => (
										<Panel
											style={{ textAlign: "left" }}
											header={plan}
											key={plan}
										>
											<Table
												columns={columns}
												dataSource={
													groupKey === "name"
														? data2?.filter(
																(item: any) => item.planName === plan
															)
														: Object.values(tasks)
																.flatMap((group: any) => group)
																.filter(
																	(item: any) =>
																		sortedAndFilteredData([item]).length > 0
																)
												}
												expandable={{
													expandedRowRender: (record: any) => (
														<p style={{ margin: 0 }}>{record.description}</p>
													),
												}}
											/>
										</Panel>
									)
								)}
							</Collapse>
						</Content>
					) : (
						<Result
							icon={<SmileOutlined />}
							title="There are no existing plans right now, Create one!"
							extra={
								<Button type="primary" onClick={handleAddWorkItemClick}>
									Next
								</Button>
							}
							style={{ marginTop: "10vh" }}
						/>
					)}
				</Layout>
			)}
			<CreateSprintPlanModel
				visible={modalVisible}
				onCancel={handleModalCancel}
			/>
		</Layout>
	);
};

export default Release;
