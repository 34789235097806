// import React, { useEffect, useState } from "react";
import React, { useEffect, useState } from "react";
import "./AppLayout.css";
//import EditWorkspaceModal from "../Alchemi/EditWorkspace";
import //PlusCircleOutlined,
//DeploymentUnitOutlined,
//EditOutlined,
//UserOutlined,
//BulbTwoTone,
//SettingOutlined,
"@ant-design/icons";
// import { FloatButton, Image, Layout, Spin, message } from "antd";
import { Flex, Layout, message } from "antd";
import { Outlet, useParams } from "react-router-dom";
//import ShareWorkSpaceModal from "../component/ShareWorkspace";
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "../authConfig";
import AppHeader from "./AppHeader";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../stores/UserProfileStore";
// import { QuestionCircleOutlined, QuestionOutlined } from "@ant-design/icons";
// import Help from "../pages/Help";
//import PlanerMenu from "../pages/Planer/PlanerMenu";
//import PlanerMenuV2 from "../pages/Planner/PlanerMenuV2";
import userProfileStore from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
//import PlanerMenuV3 from "../pages/Planner/PlanerMenuV3";
import PlanerMenuV4 from "../pages/Planner/PlanerMenuV4";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
import { createContext } from "react";
export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);
import { defineAbility } from "@casl/ability";
import ChatSider from "../pages/ChatSider";
import ValidateAuthentication from "../component/auth/ValidateAuthentication";
//import usePlatformStore from "../stores/platformStore";

function AppLayout() {
	const { Content } = Layout;

	const userProfileStoreInstance = userProfileStore() as any;
	const ServerLink = process.env.REACT_APP_API_URL;

	const [abilities, setAbilities] = useState(ability);

	console.log("ability", abilities);

	const currentWorkspace = useWorkspaceStore() as any;
	const workspaceId = useParams().workspaceId;
	// const { menuMode } = userProfileStore((state: any) => ({
	// 	menuMode: state.menuMode,
	// }));

	const fetchWorkspacePermissions = async () => {
		if (userProfileStoreInstance.idToken !== null && workspaceId) {
			try {
				const response = await fetch(
					`${ServerLink}/workspace-permissions/userid-workspaceid?work-space-id=${workspaceId}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const json = await response.json();
				console.log("json.length", json.length);
				if (json.length > 0) {
					currentWorkspace.setworkspacePermissions(json[0]);
					// setWorkspaceCount(json.length);
					const initialAbilities = defineAbility((can) => {
						// const permissions = json[0].roles.permissions;
						// Object.entries(permissions).forEach(([subject, actions]) => {
						// 	can(actions, subject);
						// });
						const permissions = json[0]?.roles?.permissions; // Assuming json is your data containing roles with permissions
						permissions?.forEach((permission: any) => {
							const { subject, action } = permission;
							can(action, subject);
						});
					});

					setAbilities(initialAbilities);
					console.log("applayoutpermissions", json[0]);
				} else {
					// setWorkspaceCount(0);
				}

				console.log("json", json);
				// setError(null);
			} catch (error: any) {
				console.error(error);
				// console.error(error);
				// setError(error);
				message.error(`An error occurred: ${error.message}`);
			}
		}
	};

	useEffect(() => {
		if (workspaceId) {
			fetchWorkspacePermissions();
		}
	}, [workspaceId, userProfileStoreInstance.idToken]);

	// const [open, setOpen] = useState(false);

	// const showDrawer = () => {
	// 	setOpen(true);
	// };
	// const { aiChatWidget, setAiChatWidget } = usePlatformStore((state: any) => ({
	// 	aiChatWidget: state.aiChatWidget,
	// 	setAiChatWidget: state.setAiChatWidget,
	// }));
	// const [drawerVisible, setDrawerVisible] = useState(false);
	// const toggleDrawer = () => {
	// 	//setDrawerVisible(!drawerVisible);
	// 	setAiChatWidget(!aiChatWidget);
	// };

	return (
		<Layout
			className="LandingPage"
			style={{ height: "100vh", background: "#F5F8FE" }}
		>
			{/* {authenticated ? ( */}
			<>
				<ValidateAuthentication />
				<AbilityContext.Provider value={abilities}>
					<Layout style={{ background: "#F5F8FE" }}>
						{/* <PlanerMenuV3 /> */}
						{/* <PlanerMenuV4 /> */}
						<Layout style={{ background: "#F5F8FE" }}>
							<AppHeader />
							<Content
								style={{
									height: "100%",
									overflow: "hidden",
									paddingTop: ".2rem",
									// marginRight: aiChatWidget ? "26.2vw" : 0,
									marginLeft: "32.2vw",
									transition: "margin-left 0.3s",
									background: "#F5F8FE",
								}}
							>
								<Layout style={{ height: "100%", background: "#F5F8FE" }}>
									<Outlet />
									{/* <Avatar
										shape="circle"
										style={{
											position: "fixed",
											margin: "20px",
											padding: "0px 8px 8px 0px",
											width: "50px",
											height: "50px",
											// right: 0,
											left: 0,
											bottom: 0,
											backgroundColor: "white",
										}}
										onClick={toggleDrawer}
										size={{ xs: 24, sm: 32, md: 40, lg: 40, xl: 40, xxl: 40 }}
										src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_ai_logo_v2.svg"
									/> */}
									<ChatSider />
								</Layout>
							</Content>
							<Flex
								className="footer"
								justify="center"
								style={{
									width: "100%",
									position: "absolute",
									bottom: "0",
									margin: "1rem 0 0px 0",
									background: "#F5F8FE",
								}}
							>
								<p
									style={{
										// marginLeft: menuMode === "horizontal" ? 0 : "-33vw",
										marginLeft: 0,
										fontSize: 12,
										fontWeight: 500,
										fontFamily: "Poppins",
									}}
								>
									© 2024 Zentience. All rights reserved.
								</p>
							</Flex>
						</Layout>
						<PlanerMenuV4 />
					</Layout>
				</AbilityContext.Provider>
			</>
			{/* ) : (
				<div className="loader-container">
					<Spin size="large" />
					<Image
						style={{ marginTop: "10px" }}
						width={150}
						preview={false}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.svg"
					/>
					<Spin size="large" />
				</div>
			)} */}
		</Layout>
	);
}

export default AppLayout;
