/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import "../layout/AppLayout.css";
//import EditWorkspaceModal from "../Alchemi/EditWorkspace";
import //PlusCircleOutlined,
//DeploymentUnitOutlined,
//EditOutlined,
//UserOutlined,
//BulbTwoTone,
//SettingOutlined,
"@ant-design/icons";
import { Image, Layout, Spin, message } from "antd";

//import ShareWorkSpaceModal from "../component/ShareWorkspace";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { useNavigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
// import WorkspaceList from "./WorkspaceList";
import AppHeader from "../layout/AppHeader";
import useWorkspaceStore from "../stores/WorkspaceState";
import usePlatformStore, { PlatformStoreType } from "../stores/platformStore";
import { getWorkspacebyId } from "../api/Workspace";
import { useRequest } from "ahooks";

function AuthenticateUser() {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const platformStore = usePlatformStore() as PlatformStoreType;
	const [loader, setloader] = useState(true);
	const [workspaceCount, setWorkspaceCount] = useState(-1);
	const navigate = useNavigate();
	const { instance, accounts, inProgress } = useMsal();
	// const isAuthenticated = useIsAuthenticated();
	const { setAddWorkspaceDetails, setCurrentWorkspace } = useWorkspaceStore(
		(state: any) => ({
			setCurrentWorkspace: state.setCurrentWorkspace,
			setAddWorkspaceDetails: state.setAddWorkspaceDetails,
		})
	);
	const [authenticated, setAuthenticated] = useState(false);
	const [userdata, setUserData] = useState<any>();
	const [calldefaultworkspace, setCallDefaultWorkspace] = useState(false);

	const fetchDefaultWorkspace = async (userdataRes: any) => {
		if (
			userdataRes &&
			userdataRes.preferences &&
			userdataRes.preferences.defaultWorkspaceId &&
			calldefaultworkspace
		) {
			const workspaceDetails = await fetch(
				`${ServerLink}/workspace/${userdataRes.preferences.defaultWorkspaceId}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const workspaceData = await workspaceDetails.json();
			// console.log("workspaceData", workspaceData);
			setAddWorkspaceDetails(workspaceData);
			setCurrentWorkspace(workspaceData);
			setloader(false);
			navigate(`/space/${workspaceData._id}`);
		} else {
			//navigate("/space");
			if (calldefaultworkspace) {
				setloader(false);
				navigate(`/fd/workspace/list`);
			}
		}
	};

	// useEffect(() => {
	// 	//if (userdata) {
	// 		fetchDefaultWorkspace();
	// 	//}
	// },[userdata]);

	console.log("defaultwork", calldefaultworkspace);

	const fetchData = async () => {
		if (userProfileStoreInstance.idToken !== null) {
			try {
				const response = await fetch(`${ServerLink}/workspace`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const json = await response.json();
				console.log("json.length", json.length);
				if (json.length > 0) {
					setWorkspaceCount(json.length);
				} else {
					setWorkspaceCount(0);
				}

				console.log("json", json);
				// setError(null);
			} catch (error: any) {
				console.error(error);
				// console.error(error);
				// setError(error);
				message.error(`An error occurred: ${error.message}`);
			}
		}
	};
	useEffect(() => {
		if (authenticated == true && userProfileStoreInstance.idToken !== null) {
			fetchData();
		}
	}, [userProfileStoreInstance, authenticated]);

	const setDefaultworkspace = async (preferenceWorkspace: string) => {
		try {
			const response = await fetch(
				`${ServerLink}/workspace/${preferenceWorkspace}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			const userdataRes = await response.json();

			setAddWorkspaceDetails(userdataRes);
			setCurrentWorkspace(userdataRes);
		} catch (error: any) {
			message.error(`Error fetching user profile: ${error.message}`);
		}
	};

	useEffect(() => {
		const fetchTaskData = async () => {
			try {
				const response = await fetch(`${ServerLink}/tasks/user`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				const userdataRes = await response.json();
				console.log("userdataRes", userdataRes);
				console.log("workspaceCount: ", workspaceCount);
				if (userdataRes.length > 0) {
					setCallDefaultWorkspace(false);
					if (userdataRes[0].status === "OPEN") {
						console.log("condition", workspaceCount);
						if (workspaceCount === 0) {
							console.log("Redirect to /new");
							setloader(false);
							navigate("/feed");
							platformStore.setafterfeedUrl("/fd/workspace/new");
						}
						if (workspaceCount > 0) {
							await setDefaultworkspace(
								userdataRes[0].user_profile_id.preferences.defaultWorkspaceId
							);
							console.log("Redirect to /default");
							setloader(false);
							navigate("/feed");
							platformStore.setafterfeedUrl(
								`/space/${userdataRes[0].workspace._id}`
							);
						}
					}
				} else {
					setCallDefaultWorkspace(true);
				}
			} catch (error: any) {
				message.error(`Error fetching user profile: ${error.message}`);
			}
		};
		if (authenticated == true && userProfileStoreInstance.idToken !== null) {
			fetchTaskData();
		}
		// fetchTaskData();
	}, [authenticated, userProfileStoreInstance.idToken, workspaceCount]);

	const match = platformStore.redirectUrl.match(/\/space\/([^\/]+)/);

	let workspaceId: string = " ";
	if (match && match[1]) {
		workspaceId = match[1];
	} else {
		console.log("No ID found after 'space/'");
	}

	const { data: workspaceData, run: getWorkspaceData } = useRequest(
		() => getWorkspacebyId(workspaceId, userProfileStoreInstance.idToken),
		{
			manual: true,
		}
	);

	useEffect(() => {
		if (workspaceData) {
			setCurrentWorkspace(workspaceData);
			navigate(platformStore.redirectUrl);
		}
	}, [workspaceData]);

	const fetchUserData = async () => {
		setUserData({});
		try {
			const response = await fetch(`${ServerLink}/user-profiles/user/azureid`, {
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			});
			const userdataRes = await response.json();
			userProfileStoreInstance.setProfile(userdataRes);
			setUserData(userdata);
			if (!platformStore.redirectUrl) {
				fetchDefaultWorkspace(userdataRes);
			} else {
				// getWorkspaceData();
				navigate(platformStore.redirectUrl);
			}
		} catch (error: any) {
			message.error(`Error fetching user profile: ${error.message}`);
		}
	};
	useEffect(() => {
		if (authenticated == true && userProfileStoreInstance.idToken !== null) {
			fetchUserData();
		}
	}, [authenticated, calldefaultworkspace, userProfileStoreInstance.idToken]);

	const scope_user_read: string = process.env.REACT_APP_SCOPE_USER_READ ?? "";

	const checkAuthentication = async () => {
		if (accounts.length === 0) {
			try {
				await new Promise((resolve) => {
					setTimeout(resolve, 100);
					message.success("Redirecting to login page...");
				});
				await instance.loginRedirect(loginRequest);

				console.log("Access token:", loginRequest);

				setAuthenticated(true);
			} catch (error: any) {
				message.error(`Error authenticating: ${error.message}`);
			}
		} else {
			setTimeout(() => {
				setAuthenticated(true);
			}, 1000);

			const silentRequest = {
				account: accounts[0],
				scopes: ["openid", scope_user_read],
				forceRefresh: true,
				refreshTokenExpirationOffsetSeconds: 60000,
			};
			try {
				await new Promise((resolve) => {
					setTimeout(resolve, 500);
				});
				const response = await instance.acquireTokenSilent(silentRequest);
				console.log("response for token specially id token", response);
				const idToken = response.accessToken;
				// console.log("idtoken", idToken);
				userProfileStoreInstance.setIdToken(idToken);
			} catch (error: any) {
				message.error(`Error authenticating: ${error.message}`);
			}
			// console.log("Access token:", loginRequest);
		}
	};
	useEffect(() => {
		if (inProgress === InteractionStatus.None && accounts) {
			checkAuthentication();
		}
	}, [instance, accounts.length, inProgress]);

	//console.log("authenticated", authenticated);

	const ServerLink = process.env.REACT_APP_API_URL;
	useEffect(() => {
		if (authenticated) {
			//message.success("Welcome! You are now logged in.");
		}
	}, [authenticated]);

	// useEffect(() => {
	// 	if (authenticated && workspaceCount !== -1) {
	// 		setloader(false); // Set loading to false when authentication is complete
	// 	}
	// }, [authenticated,workspaceCount]);

	return (
		<Layout className="LandingPage" style={{ height: "100vh" }}>
			{authenticated && !loader ? (
				<>
					<AppHeader />
					{/* <WorkspaceList /> */}
				</>
			) : (
				<div className="loader-container">
					<Spin size="large" />
					<Image
						style={{ marginTop: "10px" }}
						width={150}
						preview={false}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.svg"
					/>
					<Spin size="large" />
				</div>
			)}
		</Layout>
	);
}

export default AuthenticateUser;
