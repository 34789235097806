/* eslint-disable prefer-const */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
	Badge,
	Button,
	Flex,
	Menu,
	Popover,
	Input,
	Checkbox,
	Tag,
	Tooltip,
	Space,
} from "antd";
import {
	CheckCircleOutlined,
	FilterOutlined,
	SearchOutlined,
	WarningOutlined,
	CloseOutlined,
	SwapOutlined,
	ArrowLeftOutlined,
	UserOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import "./Filter.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { Modal } from "antd";
import useWorkspaceStore from "../../../stores/WorkspaceState";
import { set } from "lodash";

// const modeConfig = {
//
//       filters: {
//         status: {
//           label: "Status",
//           options: [
//             { key: Constants.STATUS + "_" + Constants.IN_PROGRESS, label: Constants.IN_PROGRESS },
//             { key: Constants.STATUS + "_" + Constants.OPEN, label: Constants.OPEN },
//             { key: Constants.STATUS + "_" + Constants.COMPLETED, label: Constants.COMPLETED },
//           ],
//           icon: <CheckCircleOutlined />,
//         },
//         priority: {
//           label: "Priority",
//           options: [
//             { key: Constants.PRIORITY + "_" + Constants.URGENT, label: Constants.URGENT },
//             { key: Constants.PRIORITY + "_" + Constants.IMPORTANT, label: Constants.IMPORTANT },
//             { key: Constants.PRIORITY + "_" + Constants.MEDIUM, label: Constants.MEDIUM },
//             { key: Constants.PRIORITY + "_" + Constants.LOW, label: Constants.LOW },
//           ],
//           icon: <WarningOutlined />,
//         },
//         type: {
//           label: "Type",
//           options: [
//             { key: "Type_AllItems", label: "All Items" },
//             { key: "Type_FEATURE", label: "Features" },
//             { key: "Type_USER_STORY", label: "User Stories" },
//             { key: "Type_BUG", label: "Bugs" },
//             { key: "Type_EPIC", label: "Epic" },
//           ],
//           icon: <SwapOutlined />,
//         },
//       },
//   };

//  the above might be structure of props passed to this component

type FilterProps = {
	config: any;
	filterValue: any;
	setFilterValue: (value: any) => void;
	setSelectedFilter: (values: any) => void;
	ViewFilter: boolean;
};

type MenuItem = Required<MenuProps>["items"][number];

const UniversalComponent: React.FC<any> = ({
	config,
	filterValue,
	setFilterValue,
	setSelectedFilter,
	ViewFilter,
}: FilterProps) => {
	const items: MenuItem[] = Object.entries<any>(config.filters).map(
		([key, { label, options, icon }]) => ({
			key: `sub_${key}`,
			icon,
			label,
			children: options,
		})
	);

	console.log("filterValue", filterValue);

	const [menuitems, setmenuitems] = useState<any>(items);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	// const [filtermenuitems, setfiltermenuitems] = useState<any>([]);
	const filtermenuitems: any[] = items;
	const [copymenuitems, setcopymenuitems] = useState<any>(items);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [filterval, setfilterval] = useState(
		Object.entries(config.filters).map(([key]) => ({
			key,
			value: [],
		}))
	);
	const [filterName, setFilterName] = useState("");
	const [filterDescription, setFilterDescription] = useState("");
	// console.log("filterValuefromstore",filterValue);
	const onSaveButtonClick = () => {
		setIsModalVisible(true);
	};

	const handleOk = async () => {
		setIsModalVisible(false);
		await createViewFilter();
	};
	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const createViewFilter = async () => {
		try {
			console.log("selectedValues", selectedValues);

			// const transformedFilter = selectedValues.map(
			// 	({
			// 		field,
			// 		condition,
			// 		value,
			// 	}: {
			// 		field: any;
			// 		condition: string;
			// 		value: any;
			// 	}) => ({
			// 		field: selectedValues[0].split("_")[0],

			// 		condition: "is",

			// 		value: selectedValues.map((val: any) =>
			// 			val.split("_").slice(1).join("_")
			// 		),
			// 	})
			// );
			const transformedFilter = selectedValues.reduce((acc: any, val: any) => {
				const field = val.split("_")[0];
				//const value = val.split("_")[1];
				const value = val.split("_").slice(1).join("_");

				const existingFilter = acc.find(
					(filter: any) => filter.field === field
				);

				if (existingFilter) {
					existingFilter.value.push(value);
					existingFilter.condition = "is any of";
				} else {
					acc.push({
						field,
						condition: "is",
						value: [value],
					});
				}

				return acc;
			}, []);

			const response = await fetch(`${process.env.REACT_APP_API_URL}/view`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify({
					name: filterName,
					description: filterDescription,
					//filter: selectedValues,
					filter: transformedFilter,
					workspaceId: currentWorkspace._id,
				}),
			});

			const data = await response.json();
			console.log(data);
		} catch (error) {
			console.error(error);
		}
	};

	const onClick: MenuProps["onClick"] = (e: any) => {
		if (e.key.includes("sub")) {
			const clickedItem: any = items.find((item: any) => item.key === e.key);
			let itemms = [];
			if (clickedItem && clickedItem.children) {
				itemms = clickedItem.children.map((child: any) => ({
					key: child.key,
					label: child.label,
				}));
			}
			setmenuitems(itemms);
			setcopymenuitems(itemms);
		}
	};

	const onClickPopoverButton = () => {
		setmenuitems(items);
		setcopymenuitems(items);
		//createViewFilter();
	};

	const onchangevalue = (value: any) => {
		const test = copymenuitems;
		const newitems = test?.filter((item: any) =>
			item?.label?.toLowerCase().includes(value.target.value.toLowerCase())
		);
		setmenuitems(newitems);
	};

	// console.log("filterval",filterval);

	const selectedValues = filterValue.flatMap(
		({ key, value }: { key: string; value: string[] }) => {
			const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
			return value.map((val) => `${capitalizedKey}_${val}`);
		}
	);
	console.log("test", filterValue);

	const regex = /^([^_]+)_(.*)$/;

	const onChangeCheckboxGroup = (values: any) => {
		// console.log("values for checkbox", values);
		const updatedFilters: any = { ...filterValue };
		const testingarray: any = [
			{ key: "Status", value: [] },
			{ key: "Priority", value: [] },
			{ key: "Type", value: [] },
			{ key: "Assignee", value: [] },
		];
		let filterstatus = false;
		let filterpriority = false;
		let filtertype = false;
		let filterassignee = false;
		if (values.length > 0) {
			values.forEach((value: any) => {
				const [, filterCategory, filterValue] = value.match(regex) || [];
				if (filterCategory === "Status") {
					filterstatus = true;
					// setfilterval({key:'Status',value:filterValue});
					testingarray[0].value.push(filterValue);
				}
				if (filterCategory === "Priority") {
					filterpriority = true;
					testingarray[1].value.push(filterValue);
				}
				if (filterCategory === "Type") {
					filtertype = true;
					testingarray[2].value.push(filterValue);
				}
				if (filterCategory === "Assignee") {
					filterassignee = true;
					testingarray[3].value.push(filterValue);
				}
			});
			if (filterstatus) {
				filterval[0].value = testingarray[0].value;
			}
			if (filterpriority) {
				filterval[1].value = testingarray[1].value;
			}
			if (filtertype) {
				filterval[2].value = testingarray[2].value;
			}
			if (filterassignee) {
				filterval[3].value = testingarray[3].value;
			}
			if (filterstatus && filterpriority && filtertype && filterassignee) {
				setfilterval(testingarray);
			}
			setFilterValue(filterval);
		} else if (values.length === 0 && menuitems[0]?.key.includes("Status")) {
			filterval[0].value = [];
		} else if (values.length === 0 && menuitems[0]?.key.includes("Priority")) {
			filterval[1].value = [];
		} else {
			setfilterval(testingarray);
			setFilterValue(testingarray);
		}
		setSelectedFilter(values);
		// Update BacklogFilterValue

		// Update selected values
	};

	const HandleRemoveFilter = (value: any) => {
		if (value === "Status") {
			filterval[0].value = [];
			setmenuitems(items);
			setcopymenuitems(items);
			setfilterval(filterval);
			const newdata = (filterValue[0].value = []);
			setSelectedFilter(newdata);
		}
		if (value === "Priority") {
			filterval[1].value = [];
			setmenuitems(items);
			setcopymenuitems(items);
			setfilterval(filterval);
			const newdata = (filterValue[1].value = []);
			setSelectedFilter(newdata);
		}
		if (value === "Type") {
			filterval[2].value = [];
			setmenuitems(items);
			setcopymenuitems(items);
			setfilterval(filterval);
			const newdata = (filterValue[2].value = []);
			setSelectedFilter(newdata);
		}
		if (value === "Assignee") {
			filterval[3].value = [];
			setmenuitems(items);
			setcopymenuitems(items);
			setfilterval(filterval);
			const newdata = (filterValue[3].value = []);
			setSelectedFilter(newdata);
		}
	};

	const renderIcon = (filterCategory: string) => {
		if (filterCategory === "Status") {
			return <CheckCircleOutlined />;
		} else if (filterCategory === "Priority") {
			return <WarningOutlined />;
		} else if (filterCategory === "Type") {
			return <SwapOutlined />;
		} else if (filterCategory === "Assignee") {
			return <UserOutlined />;
		} else {
			return null; // Return null or a default icon if none of the conditions match
		}
	};

	const seprateValuesInFilter = (values: any) => {
		// Step 1: Count occurrences of each filterCategory
		const categoryCount = values.reduce((acc: any, value: any) => {
			const [, filterCategory] = value.match(regex) || [];
			if (filterCategory) {
				acc[filterCategory] = (acc[filterCategory] || 0) + 1;
			}
			return acc;
		}, {});

		// Step 2: Aggregate filterValues for categories with multiple occurrences
		const aggregatedValues = values.reduce((acc: any, value: any) => {
			const [, filterCategory, filterValue] = value.match(regex) || [];
			if (filterCategory) {
				if (!acc[filterCategory]) {
					acc[filterCategory] = [];
				}
				acc[filterCategory].push(filterValue);
			}
			return acc;
		}, {});

		// Step 3: Render components
		return Object.entries(aggregatedValues).map(
			([filterCategory, filterValues]: [string, any], index: number) => {
				const isMultiple = filterValues.length > 1;
				const displayText = isMultiple
					? `${filterValues.length} ${
							filterCategory === "Status"
								? "Statuses"
								: filterCategory === "Priority"
									? "Priorities"
									: filterCategory === "Type"
										? "Types"
										: filterCategory === "Assignee"
											? "Assignees"
											: ""
						}`
					: filterValues[0];
				const menu = (
					<Menu style={{ width: "20vw" }}>
						<Checkbox.Group
							onChange={(e) => {
								onChangeCheckboxGroup(e);
							}}
							defaultValue={selectedValues}
						>
							{filterCategory === "Status" &&
								filtermenuitems[0].children.map((item: any, index: number) => (
									<Menu.Item
										key={item.key + index}
										className="innermenu"
										style={{ alignContent: "center" }}
									>
										<Checkbox key={item.key} value={item.key}>
											{item.label}
										</Checkbox>
									</Menu.Item>
								))}
							{filterCategory === "Priority" &&
								filtermenuitems[1].children.map((item: any, index: number) => (
									<Menu.Item
										key={item.key + index}
										className="innermenu"
										style={{ alignContent: "center" }}
									>
										<Checkbox key={item.key} value={item.key}>
											{item.label}
										</Checkbox>
									</Menu.Item>
								))}
							{filterCategory === "Type" &&
								filtermenuitems[2].children.map((item: any, index: number) => (
									<Menu.Item
										key={item.key + index}
										className="innermenu"
										style={{ alignContent: "center" }}
									>
										<Checkbox key={item.key} value={item.key}>
											{item.label}
										</Checkbox>
									</Menu.Item>
								))}
							{filterCategory === "Assignee" &&
								filtermenuitems[3].children.map((item: any, index: number) => (
									<Menu.Item
										key={item.key + index}
										className="innermenu"
										style={{ alignContent: "center" }}
									>
										<Checkbox key={item.key} value={item.key}>
											{item.label}
										</Checkbox>
									</Menu.Item>
								))}
						</Checkbox.Group>
					</Menu>
				);

				return (
					<Flex key={index} style={{ alignItems: "center", gap: "0.05rem" }}>
						<Tag
							icon={renderIcon(filterCategory)}
							style={{
								alignContent: "center",
								margin: 0,
								borderRadius: "0.5rem 0 0 0.5rem",
							}}
						>
							{filterCategory}
						</Tag>
						<Tag
							style={{
								alignContent: "center",
								margin: 0,
								borderRadius: "0 0 0 0",
							}}
						>
							{isMultiple ? "is any of" : "is"}
						</Tag>
						<Popover content={menu} trigger="click">
							<Tag
								style={{
									alignContent: "center",
									margin: 0,
									borderRadius: "0 0rem 0rem 0",
									cursor: "pointer",
								}}
							>
								{displayText}
							</Tag>
						</Popover>
						<Tag
							onClick={() => HandleRemoveFilter(filterCategory)}
							style={{
								alignContent: "center",
								margin: 0,
								borderRadius: "0 0.5rem 0.5rem 0",
								cursor: "pointer",
							}}
							icon={<CloseOutlined />}
						></Tag>
					</Flex>
				);
			}
		);
	};

	const Backbutton = () => {
		setmenuitems(items);
		setcopymenuitems(items);
	};

	return (
		<>
			<Flex>
				<Popover
					title={<></>}
					placement="bottomLeft"
					content={
						<>
							<Flex>
								<Space>
									{/* {!menuitems[0]?.key.includes("sub") && (
										<Tooltip placement="bottom" title="Back">
											<Button
												shape="circle"
												icon={<ArrowLeftOutlined />}
												size="middle"
												onClick={Backbutton}
												style={{
													color: "#0444BF",
													borderColor: "#0444BF",
													padding: "0.2rem 0 0 0rem",
												}}
											/>
										</Tooltip>
									)} */}
									<Input
										placeholder="Filter..."
										style={{ width: 250, border: "none" }}
										size="small"
										onChange={onchangevalue}
										allowClear
										suffix={<SearchOutlined />}
										key={"search"}
										addonBefore={
											!menuitems[0]?.key.includes("sub") && (
												<ArrowLeftOutlined onClick={Backbutton} />
											)
										}
									/>
								</Space>
							</Flex>
							{menuitems[0]?.key.includes("sub") ? (
								<Menu onClick={onClick} style={{ width: 250 }} mode="vertical">
									{menuitems.map(
										(item: any) =>
											item.label !== undefined && (
												<Menu.Item
													key={item.key}
													icon={item.icon}
													style={{
														height: 35,
														lineHeight: "35px",
														padding: "0px 4px",
														borderRadius: "6px",
													}}
												>
													{item.label}
												</Menu.Item>
											)
									)}
								</Menu>
							) : (
								<Menu style={{ width: 250 }}>
									<Checkbox.Group
										onChange={(e) => {
											onChangeCheckboxGroup(e);
										}}
										defaultValue={selectedValues}
										// options={menuitems.map((item: any) => item.key)}
										// value={selectedValues}
									>
										{menuitems.map((item: any, index: number) => (
											<Menu.Item
												key={item.key + index}
												className="innermenu"
												style={{
													alignContent: "center",
													height: 30,
													lineHeight: "30px",
													padding: "0px 4px",
													borderRadius: "6px",
													width: 250,
												}}
											>
												<Checkbox key={item.key} value={item.key}>
													{item.label}
												</Checkbox>
											</Menu.Item>
										))}
									</Checkbox.Group>
								</Menu>
							)}
						</>
					}
					trigger={"click"}
					className="ant-popover"
					style={{ textAlign: "center", border: "2px solid red" }}
				>
					{" "}
					<Flex justify="center" align="center">
						<Badge color="blue" offset={[-40, 0]}>
							<Button
								// type="primary"
								style={{ borderRadius: "6px", fontSize: "14px" }}
								size="small"
								icon={<FilterOutlined />}
								onClick={onClickPopoverButton}
							>
								Filter
							</Button>
						</Badge>
					</Flex>
				</Popover>
				{ViewFilter &&
					selectedValues.length > 0 &&
					seprateValuesInFilter(selectedValues)}
				{ViewFilter && selectedValues.length > 0 && (
					<Button
						// type="primary"
						style={{
							borderRadius: "6px",
							marginLeft: "10px",
							fontSize: "14px",
						}}
						size="small"
						onClick={onSaveButtonClick}
					>
						Save
					</Button>
				)}
				<Modal
					title="Save Filter"
					visible={isModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<Input
						placeholder="Filter Name"
						onChange={(e) => setFilterName(e.target.value)}
					/>
					<Input
						placeholder="Filter Description"
						style={{ marginTop: "10px" }}
						onChange={(e) => setFilterDescription(e.target.value)}
					/>
				</Modal>
			</Flex>
		</>
	);
};

export default UniversalComponent;
