import axios, { AxiosResponse } from "axios";
import { Dashboard } from "../models/Dashboard";

async function getDashboardById(id: any, token: any): Promise<Dashboard> {
	try {
		const response: AxiosResponse<Dashboard> = await axios.get(
			`${process.env.REACT_APP_API_URL}/dashboard/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching dashboard:", error);
		throw error;
	}
}

async function getDashboardLists(token: any): Promise<Dashboard[]> {
	try {
		const response: AxiosResponse<Dashboard[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/dashboard`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching dashboard:", error);
		throw error;
	}
}

async function updatedbyid(id: any, data: any, token: any): Promise<Dashboard> {
	try {
		const response: AxiosResponse<Dashboard> = await axios.put(
			`${process.env.REACT_APP_API_URL}/dashboard/${id}`,
			data,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching dashboard:", error);
		throw error;
	}
}

async function Createdashboard(body: any, token: any): Promise<Dashboard> {
	try {
		const response: AxiosResponse<Dashboard> = await axios.post(
			`${process.env.REACT_APP_API_URL}/dashboard`,
			body,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error creating dashboard:", error);
		throw error;
	}
}

async function getWidgetById(
	id: any,
	widgetid: any,
	token: any
): Promise<Dashboard> {
	try {
		const response: AxiosResponse<Dashboard> = await axios.get(
			`${process.env.REACT_APP_API_URL}/dashboard/${id}/widgets/${widgetid}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching dashboard:", error);
		throw error;
	}
}

async function updateWidgetById(
	id: any,
	widgetid: any,
	data: any,
	token: any
): Promise<Dashboard> {
	try {
		const response: AxiosResponse<Dashboard> = await axios.put(
			`${process.env.REACT_APP_API_URL}/dashboard/${id}/widgets/${widgetid}`,
			data,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching dashboard:", error);
		throw error;
	}
}
export {
	getDashboardById,
	getDashboardLists,
	updatedbyid,
	Createdashboard,
	getWidgetById,
	updateWidgetById,
};
