import React, { useState, useEffect } from "react";
import { XFlow, XFlowGraph, Grid, Minimap } from "@antv/xflow";
import { InitNode } from "../informationArchitecturev2";
import { useParams } from "react-router-dom";
import {
	//Breadcrumb,
	Flex,
	//Space,
	Spin,
} from "antd";
import BreadCrumb from "../../pages/Editors/BreadCrumb";
import usePageStore from "../../stores/PageStore";

const FlowPage = () => {
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoading(false); // 1 sec
		}, 1000);

		return () => clearTimeout(timeout); // Cleanup function
	}, []);

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
		breadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
		breadcrumbConfig: state.breadcrumbConfig,
	}));

	useEffect(() => {
		setPageSlug("information-architecture-xflow");
		setPageTitle("Information Architecture XFlow");
		setPageDescription("");
		setItemType(`${id}`);
		setItemId("");
		setIsList(true);
		setItemDetails({});
		setBreadcrumbConfig({
			page: `/space/${id}/planner/de/info/${id}`,
			config: [
				{
					label: "Site Map",
					url: `/space/${id}/planner/de/info/${id}`,
				},
			],
		});
	}, []);

	return (
		<div
			className={"container"}
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				height: "100vh",
				marginTop: "-5vh",
			}}
		>
			{loading ? ( // Show loading indicator while loading
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "100vh",
					}}
				>
					<Spin size="large" />
					<img
						style={{ marginTop: "10px", width: "150px" }}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
						alt="Loading"
					/>
					<Spin size="large" />
				</div>
			) : (
				<>
					<div style={{ width: "89vw", height: "87vh", position: "relative" }}>
						<Flex style={{ width: "100%" }}>
							{" "}
							<Flex
								justify="space-between"
								style={{ alignItems: "center", width: "100%" }}
							>
								{/* <Space>
									<Flex vertical>
										<Breadcrumb>
											<Breadcrumb.Item>
												<h2
													color="#87d068"
													style={{ fontSize: "1.1rem", marginLeft: "3vw" }}
												>
													SiteMap
												</h2>
											</Breadcrumb.Item>
										</Breadcrumb>
									</Flex>
								</Space> */}
								<div style={{ fontSize: "1.1rem", marginLeft: "3vw" }}>
									<BreadCrumb config={breadcrumbConfig} />
								</div>
							</Flex>
						</Flex>
						<div
							style={{ width: "100%", height: "100%", position: "relative" }}
						>
							<XFlow>
								<XFlowGraph
									centerView
									zoomable
									zoomOptions={{
										minScale: 0.5,
										maxScale: 3,
									}}
									pannable
									fitView
									magnetAdsorbedHighlightOptions={{
										name: "stroke",
										args: {
											attrs: {
												fill: "#5F95FF",
												stroke: "#5F95FF",
											},
										},
									}}
									connectionOptions={{
										snap: true,
										allowBlank: false,
										allowLoop: false,
										highlight: true,
										anchor: "center",
										router: "orth",
										connector: "rounded",
									}}
									connectionEdgeOptions={{
										attrs: {
											line: {
												stroke: "rgb(72, 203, 164)",
												strokeWidth: 2,
												targetMarker: {
													name: "block",
													width: 14,
													height: 10,
												},
											},
										},
										zIndex: 0,
									}}
									selectOptions={{
										multiple: true,
										rubberband: true,
										strict: true,
										modifiers: "shift",
									}}
								/>
								<Grid
									type="doubleMesh"
									size={10}
									options={[
										{
											color: "#E7E8EA",
											thickness: 1,
										},
										{
											color: "#CBCED3",
											thickness: 1,
											factor: 4,
										},
									]}
								/>
								<Minimap
									width={200} // Adjust the width of the minimap
									height={150} // Adjust the height of the minimap
									className="custom-minimap" // Add a custom class for styling
									style={{
										position: "absolute",
										top: 10, // Adjust the position of the minimap
										right: 10, // Adjust the position of the minimap
										border: "1px solid #ccc", // Add border for visibility
										borderRadius: 5, // Add border radius for style
										boxShadow: "0 0 5px rgba(0,0,0,0.3)", // Add box shadow for style
									}}
								/>
								<InitNode id={id} />
							</XFlow>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default FlowPage;
