/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useState, useEffect } from "react";
import { Badge, Button, Menu, Popover, Input, message, Avatar } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";

const TypeFilterComponent = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const [Type, setType] = useState<any[]>([]);
	const { selectedItemDetails, setSelectedItemDetails } = planerViewStore();
	const [filteredtype, setfilteredtype] = useState<any[]>([]);
	const [popoverVisible, setPopoverVisible] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchtypes = async () => {
			const requestOptions = {
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					slug: "workitem-types",
				},
			};

			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/propertiesconfig/slug/${selectedItemDetails?.workspaceId}`,
					requestOptions
				);
				if (!response.ok) {
					message.error("Failed to fetch workspace permissions");
					setLoading(false);
					return;
				}

				const data = await response.json();
				if (Array.isArray(data)) {
					const extracTypes: any = data[0]?.values?.map(
						(type: any) => type.value
					);
					setType(extracTypes);
					setfilteredtype(extracTypes);
					setLoading(false);
				} else {
					console.error("Fetched data is not an array");
				}
			} catch (error) {
				console.error("Error fetching user profiles:", error);
				message.error("An error occurred while fetching user profiles");
				setLoading(false);
			}
		};
		if (userProfileStoreInstance.idToken && selectedItemDetails?.workspaceId) {
			fetchtypes();
		}
	}, [userProfileStoreInstance.idToken, selectedItemDetails.workspaceId]);

	const onChangeValue = (event: any) => {
		const value = event.target.value.toLowerCase();
		const filteredItems = Type.filter((perm) =>
			perm.toLowerCase().includes(value)
		);
		setfilteredtype(filteredItems);
	};

	const handleMenuClick = async (type: any) => {
		try {
			const requestOptions = {
				method: "PUT",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify({ type: type }),
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				requestOptions
			);

			if (response.ok) {
				// Fetch the updated selectedItemDetails from the server
				const updatedResponse = await fetch(
					`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
					requestOptions
				);

				if (updatedResponse.ok) {
					const updatedItemDetails = await updatedResponse.json();
					setSelectedItemDetails(updatedItemDetails);
					// setSelectedUserProfile(profile.userProfileId);
					message.success("Type updated successfully");
				} else {
					message.error("Failed to fetch updated item details");
				}
			} else {
				message.error("Failed to update assignee");
			}
		} catch (error) {
			console.error("Error updating assignee:", error);
			message.error("An error occurred while updating type");
		}

		setPopoverVisible(false);
	};

	return (
		<Popover
			title={null}
			// placement="rightTop"
			content={
				loading ? (
					<div>Loading...</div>
				) : (
					<>
						<Input
							placeholder="Search"
							onChange={onChangeValue}
							allowClear
							suffix={<SearchOutlined />}
							key={"search"}
						/>
						<Menu
							style={{ width: 256, maxHeight: 300, overflowY: "auto" }}
							mode="vertical"
						>
							{filteredtype ? (
								filteredtype?.map(
									(perm) =>
										perm && (
											<Menu.Item
												key={perm}
												onClick={() => handleMenuClick(perm)}
											>
												{perm}
											</Menu.Item>
										)
								)
							) : (
								<Menu.Item disabled>No type</Menu.Item>
							)}
						</Menu>
					</>
				)
			}
			trigger="click"
			visible={popoverVisible}
			onVisibleChange={setPopoverVisible}
		>
			<div style={{ textAlign: "center" }}>
				{selectedItemDetails.type ? (
					<div style={{ display: "flex", alignItems: "center" }}>
						<span>Type : </span>
						<span style={{ marginLeft: "8px" }}>
							{selectedItemDetails.type}
						</span>
					</div>
				) : (
					"Type: NONE"
				)}
			</div>
		</Popover>
	);
};

export default TypeFilterComponent;
