import axios, { AxiosResponse } from "axios";
import { WorkspacePermission } from "../models/WorkspacePermission";

async function getUsers(id: any, token: any): Promise<WorkspacePermission[]> {
	try {
		const response: AxiosResponse<WorkspacePermission[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/workspace-permissions/workspace?work-space-id=${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}

export { getUsers };
