interface PageConfig {
	[key: string]: {
		page: string;
		config: {
			label: string;
			url: string;
			dynamicId?: boolean;
		}[];
	};
}

const breadcrumbConfig: PageConfig = {
	// Planner Pages
	Mytask: {
		page: "/space/planner/mytasks",
		config: [
			{
				label: "My Tasks",
				url: "/space/planner/mytasks",
			},
		],
	},
	backlog: {
		page: "/space/planner/backlogs",
		config: [
			{
				label: "Backlogs",
				url: "/space/planner/backlogs",
			},
		],
	},
	plans: {
		page: "/space/planner/plans",
		config: [
			{
				label: "Plans",
				url: "/space/planner/plans",
			},
		],
	},
	savedfilterview: {
		page: "/space/planner/filterview",
		config: [
			{
				label: "Views",
				url: "/space/planner/filterview",
			},
		],
	},
	Editor: {
		page: "/space/planner/editor",
		config: [
			{
				label: "Backlogs",
				url: "/space/planner/backlogs",
			},
			{
				label: "Details",
				url: "/space/planner/editor/:id",
				dynamicId: true,
			},
		],
	},
	plan: {
		page: "/space/planner/plans",
		config: [
			{
				label: "Plans",
				url: "/space/planner/plans",
			},
		],
	},
	capacity: {
		page: "/space/planner/capacityplan",
		config: [
			{
				label: "Plans",
				url: "/space/planner/plans",
			},
			{
				label: "Capacity Plan",
				url: "/space/planner/capacityplan/:id",
				dynamicId: true,
			},
		],
	},
	task: {
		page: "/space/planner/taskboard",
		config: [
			{
				label: "Plans",
				url: "/space/planner/plans",
			},
			{
				label: "Task Boarding",
				url: "/space/planner/taskboard/:id",
				dynamicId: true,
			},
		],
	},
	view: {
		page: "/space/planner/workitemView",
		config: [
			{
				label: "Plans",
				url: "/space/planner/plans",
			},
			{
				label: "Details",
				url: "/space/planner/workitemView/:id",
				dynamicId: true,
			},
		],
	},
	Edit: {
		page: "/space",
		config: [
			{
				label: "Plans",
				url: "/space/planner/plans",
			},
			{
				label: "Details",
				url: "space/planner/planner?planId=`${id}",
				dynamicId: true,
			},
		],
	},
	rituals: {
		page: "/space/planner/rituals",
		config: [
			{
				label: "Ritual Sessions",
				url: "/space/planner/rituals",
			},
		],
	},
	ritualsView: {
		page: `/space/planner/rituals`,
		config: [
			{
				label: "Ritual Sessions",
				url: "/space/planner/rituals",
			},
			{
				label: "Details",
				url: "planner/rituals/:id",
				dynamicId: true,
			},
		],
	},
	team: {
		page: "/space/planner/teammember",
		config: [
			{
				label: "Team Member",
				url: "/space/planner/teammember",
			},
		],
	},
	persona: {
		page: "/space/planner/de/uxprocess",
		config: [
			{
				label: "User Persona",
				url: "/space/planner/de/uxprocess/:id",
				dynamicId: true,
			},
		],
	},
	sitemap: {
		page: "/space/planner/de/info",
		config: [
			{
				label: "SiteMap",
				url: "/space/planner/de/info/:id",
				dynamicId: true,
			},
		],
	},
	architecture: {
		page: "/space/planner/de/ddd",
		config: [
			{
				label: "Architecture",
				url: "/space/planner/de/ddd/core/:id",
				dynamicId: true,
			},
		],
	},
	Apidesign: {
		page: "/space/planner/de/apiDesign",
		config: [
			{
				label: "Api Defination",
				url: "/space/planner/de/apiDesign/:id",
				dynamicId: true,
			},
		],
	},
	dataschema: {
		page: "/space/planner/de/data",
		config: [
			{
				label: "Database Schema",
				url: "/space/planner/de/data/:id",
				dynamicId: true,
			},
		],
	},
	// Workspace & Settings Pages
	workspace: {
		page: "/fd/workspace/list",
		config: [
			{
				label: "Home",
				url: "/space",
			},
			{
				label: "Workspaces",
				url: "/fd/workspace/list",
			},
		],
	},
	tagmanagement: {
		page: "/fd/workspace/settings/tag",
		config: [
			{
				label: "Tag Management",
				url: "/fd/workspace/settings/tag",
			},
		],
	},
	workitemmanagement: {
		page: "/fd/workspace/settings/workitem",
		config: [
			{
				label: "Workitem Management",
				url: "/fd/workspace/settings/workitem",
			},
		],
	},
	planmanagement: {
		page: "/fd/workspace/settings/plan",
		config: [
			{
				label: "Plan Management",
				url: "/fd/workspace/settings/plan",
			},
		],
	},
	propertiesmanagement: {
		page: "/fd/workspace/settings/properties",
		config: [
			{
				label: "Properties Management",
				url: "/fd/workspace/settings/properties",
			},
		],
	},
	connections: {
		page: "/fd/workspace/settings/connections",
		config: [
			{
				label: "Connection Management",
				url: "/fd/workspace/settings/connections",
			},
		],
	},
	taskRuns: {
		page: "/fd/workspace/settings/taskRuns",
		config: [
			{
				label: "TaskRuns",
				url: "/fd/workspace/settings/taskRuns",
			},
		],
	},
	rolemanagement: {
		page: "/settings/role",
		config: [
			{
				label: "Role Management",
				url: "/settings/role",
			},
		],
	},
	usermanagement: {
		page: "/settings/user",
		config: [
			{
				label: "User Management",
				url: "/settings/user",
			},
		],
	},
	workspacemanagement: {
		page: "/settings/workspace",
		config: [
			{
				label: "Workspace Management",
				url: "/settings/workspace",
			},
		],
	},
	accountmanagement: {
		page: "/settings/account",
		config: [
			{
				label: "Account Management",
				url: "/settings/account",
			},
		],
	},
	editrolemanagement: {
		page: "/settings/role",
		config: [
			{
				label: "Role Management",
				url: "/settings/role/edit/:id",
				dynamicId: true,
			},
		],
	},
	editusermanagement: {
		page: "/settings/role",
		config: [
			{
				label: "User Management",
				url: "/settings/user/edit/:id",
				dynamicId: true,
			},
		],
	},
	dashboard: {
		page: "/space/dashboard",
		config: [
			{
				label: "Home",
				url: "/space",
			},
			{
				label: "Details",
				url: "/space/dashboard/:id",
				dynamicId: true,
			},
		],
	},
	profilemanagement: {
		page: "/profile/details",
		config: [
			{
				label: "Profile Management",
				url: "/profile/details",
			},
		],
	},
	preferencemanagement: {
		page: "/profile/perferences",
		config: [
			{
				label: "Preference",
				url: "/profile/perferences",
			},
		],
	},
};

export default breadcrumbConfig;
