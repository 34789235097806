import React, { useEffect, useState } from "react";
import {
	Modal,
	Form,
	Input as AntInput,
	Button,
	DatePicker,
	message,
	// Radio,
	Select,
} from "antd";
import "react-quill/dist/quill.snow.css";
import "./CreatePlanModel.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { usePlanStore } from "../../stores/PlanStore";

import { useNavigate, useParams } from "react-router-dom";

interface CreatePlanModelprops {
	visible: boolean;
	onCancel: () => void;
}

const { RangePicker } = DatePicker;
const { Option } = Select;
const messageApi = message;
const success = () => {
	messageApi.success("New Plan added successfully");
};

const CreatePlanModel: React.FC<CreatePlanModelprops> = ({
	visible,
	onCancel,
}) => {
	const navigate = useNavigate();

	const [form] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { setFetchPlan, FetchPlan, setnewplan } = usePlanStore();
	const workspaceId = useParams().workspaceId;
	const [PlanTypes, setPlanTypes] = useState<string[]>([]);

	const [todos, setTodos] = useState<{ _id: string; name: string }[]>([]);

	const handleFormSubmit = async (values: any) => {
		const [startDate, endDate] = values.dateRange.map((date: Date) => {
			return date.toISOString().split("T")[0];
		});

		await fetch(`${process.env.REACT_APP_API_URL}/plan`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify({
				name: values.planName,
				gantType: values.gantType,
				type: values.planType,
				startdate: startDate,
				enddate: endDate,
				items: [],
				workspaceId: workspaceId,
				dependencies: values.dependencies,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data);
				onCancel();
				setFetchPlan(!FetchPlan);
				success();
				setnewplan(data._id, true, values.planName);
				navigate(`/space/${workspaceId}/planner/planner?planId=${data._id}`);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const fetchPlanTypeData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/propertiesconfig/slug/${workspaceId}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						slug: "plan-types",
					},
				}
			);

			if (response.ok) {
				const data = await response.json();
				if (Array.isArray(data)) {
					const extracTypes: any = data[0].values.map(
						(type: any) => type.value
					);
					setPlanTypes(extracTypes);
					console.log("Fetched data for workitem Type:", extracTypes);
				} else {
					console.error("Fetched data is not an array");
				}
			} else {
				console.error("Error fetching prefixes data");
			}
		} catch (error) {
			console.error("Error fetching prefixes data:", error);
		}
	};

	const fetchTodos = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/${workspaceId}?type=All`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const todosData = await response.json();
				setTodos(todosData);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	useEffect(() => {
		if (userProfileStoreInstance.idToken !== null && workspaceId) {
			fetchPlanTypeData();
			fetchTodos();
		}
	}, [userProfileStoreInstance.idToken, workspaceId]);

	const handleCreatePlanType = () => {
		navigate("/fd/workspace/settings/properties");
	};

	return (
		<Modal
			title="Create Plan"
			visible={visible}
			onCancel={onCancel}
			width={500}
			footer={[
				<Button key="back" onClick={onCancel}>
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					htmlType="submit"
					onClick={() => form.submit()}
				>
					Save
				</Button>,
			]}
		>
			<Form
				form={form}
				onFinish={handleFormSubmit}
				// style={{ margin: "2rem 0" }}
				// labelCol={{ span: 6 }}
				// wrapperCol={{ span: 18 }}
				layout="vertical"
				labelCol={{
					style: {
						textAlign: "right",
						margin: 0,
					},
				}}
			>
				<Form.Item
					label="Type"
					name="gantType"
					rules={[{ required: true, message: "Please select type!" }]}
					style={{ width: "100%", marginBottom: 10 }}
				>
					{/* <Radio.Group>
						<Radio.Button value="task">Plan</Radio.Button>
						<Radio.Button value="milestone">Milestone</Radio.Button>
					</Radio.Group> */}
					<Select placeholder="Select Type">
						<Select.Option value="task">Plan</Select.Option>
						<Select.Option value="milestone">Milestone</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="Name"
					name="planName"
					rules={[{ required: true, message: "Please input plan name!" }]}
					style={{ width: "100%", marginBottom: 10 }}
				>
					<AntInput
						placeholder="Plan Name"
						style={{
							width: "100%",
							fontSize: "14px",
							height: "32px",
							borderRadius: "6px",
						}}
					/>
				</Form.Item>

				<Form.Item
					label="Plan Type"
					name="planType"
					rules={[{ required: true, message: "Please select plan type!" }]}
					style={{ width: "100%", marginBottom: 10 }}
				>
					{PlanTypes.length === 0 ? (
						<Button
							type="link"
							onClick={handleCreatePlanType}
							style={{ padding: 0, color: "#0004bf", fontSize: "12px" }}
						>
							Create plan type
						</Button>
					) : (
						// <Radio.Group options={PlanTypes} optionType="button" />
						<Select placeholder="Select Plan Type">
							{PlanTypes.map((planType) => (
								<Select.Option key={planType} value={planType}>
									{planType}
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>

				<Form.Item
					label="Duration"
					name="dateRange"
					rules={[{ required: true, message: "Please select date range!" }]}
					style={{ width: "100%", marginBottom: 10 }}
				>
					<RangePicker style={{ width: "100%" }} />
				</Form.Item>

				<Form.Item
					label="Goals"
					name="goals"
					rules={[{ required: true, message: "Please input goals!" }]}
					style={{ width: "100%", marginBottom: 10 }}
				>
					<AntInput.TextArea
						placeholder="Goals"
						rows={3}
						// style={{ width: "30vw" }}
						style={{ width: "100%" }}
					/>
				</Form.Item>

				<Form.Item
					label="Dependencies"
					name="dependencies"
					style={{ width: "100%", marginBottom: 10 }}
				>
					{todos.length === 0 ? (
						<span>Create at least one plan to add dependencies</span>
					) : (
						<Select
							mode="multiple"
							placeholder="Select dependencies"
							style={{ width: "30vw" }}
						>
							{todos.map((todo) => (
								<Option key={todo._id} value={todo._id}>
									{todo.name}
								</Option>
							))}
						</Select>
					)}
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default CreatePlanModel;
