// viewsStore.js
import create from "zustand";

interface ViewsStore {
	ListSelectedGroupBy: string;
	CardOrderning: string;
	radioButtonSelected: string;
	rightClickMenu: boolean;
	sortBy: string;
	isCardView: boolean;
	isEditModalOpen: boolean;
	input: string;
	filter: any[];
	ReleasePlanValue: boolean;
	TaskValue: boolean;
	DrawerTab: string;
	viewData: any;
	setDrawerTab: (DrawerTab: string) => void;
	setListSelectedGroupBy: (value: string) => void;
	setInput: (value: string) => void;
	setfilter: (value: any[]) => void;
	selectedGroupBy: string;
	open: boolean;
	selectedItemDetails: any;
	FetchPlan: boolean;
	planvalue: any[];
	BacklogFilterValue: any[];
	UserSavedFilterValue: any[];
	SavedFilter: any[];
	setSavedFilter: (value: any[]) => void;
	backlogworkitemmodel: boolean;
	mytaskworkitemmodel: boolean;
	Inviteusermodel: boolean;
	BacklogSelectedFilter: any[];
	RowBy: string;
	DocsView: boolean;
	ListView: boolean;
	ViewFilter: boolean;
	setUserSavedFilterValue: (value: any[]) => void;
	setViewFilter: (value: boolean) => void;
	setDocsView: () => void;
	setCardOrderning: (value: string) => void;
	setRowBy: (value: string) => void;
	setBacklogSelectedFilter: (value: any[]) => void;
	setBacklogFilterValue: (value: any[]) => void;
	setRadioButtonSelected: (value: string) => void;
	setInviteusermodel: (isOpen: boolean) => void;
	setRightClickMenu: (isOpen: boolean) => void;
	setmytaskworkitemmodel: (isOpen: boolean) => void;
	setbacklogworkitemmodel: (isOpen: boolean) => void;
	setListView: () => void;
	setCardView: () => void;
	setSortBy: (sortBy: string) => void;
	setEditModalOpen: (isOpen: boolean) => void;
	setSelectedGroupBy: (groupBy: string) => void;
	setOpen: (isOpen: boolean) => void;
	setSelectedItemDetails: (item: any) => void;
	setReleasePlan: (isOpen: boolean) => void;
	setFetchPlan: (FetchPlan: boolean) => void;
	setplanvalue: (value: any[]) => void;
	setTask: (isOpen: boolean) => void;
	setViewData: (viewData: any) => void;
	urlData: {
		prevUrl: string;
		newUrl: string;
		screen: string;
		changeUrl: (prevUrl: string, newUrl: string, screen: string) => void;
	};
}

export const useViewsFilterStores = create<ViewsStore>((set) => ({
	viewData: null,
	ListSelectedGroupBy: "None",
	CardOrderning: "None",
	RowBy: "type",
	BacklogSelectedFilter: [],
	radioButtonSelected: "MyTasks",
	rightClickMenu: false,
	sortBy: "None",
	isCardView: false,
	isEditModalOpen: false,
	input: "",
	filter: [],
	ReleasePlanValue: false,
	backlogworkitemmodel: false,
	mytaskworkitemmodel: false,
	FetchPlan: false,
	TaskValue: false,
	Inviteusermodel: false,
	DrawerTab: "",
	DocsView: false,
	ListView: true,
	ViewFilter: false,
	UserSavedFilterValue: [],
	SavedFilter: [],
	setSavedFilter: (value: any[]) => set({ SavedFilter: value }),
	setUserSavedFilterValue: (value: any[]) =>
		set({ UserSavedFilterValue: value }),
	setViewFilter: (value: boolean) => set({ ViewFilter: value }),
	setDocsView: () =>
		set({ DocsView: true, ListView: false, isCardView: false }),
	setCardOrderning: (value: string) => set({ CardOrderning: value }),
	setRowBy: (value: string) => set({ RowBy: value }),
	setDrawerTab: (value: string) => set({ DrawerTab: value }),
	setRadioButtonSelected: (value: string) =>
		set({ radioButtonSelected: value }),
	setInviteusermodel: (isOpen: boolean) => set({ Inviteusermodel: isOpen }),
	setRightClickMenu: (isOpen: boolean) => set({ rightClickMenu: isOpen }),
	setmytaskworkitemmodel: (isOpen: boolean) =>
		set({ mytaskworkitemmodel: isOpen }),
	setbacklogworkitemmodel: (isOpen: boolean) =>
		set({ backlogworkitemmodel: isOpen }),
	setSortBy: (sortBy: string) => set({ sortBy: sortBy }),
	setListView: () =>
		set({ ListView: true, isCardView: false, DocsView: false }),
	setCardView: () =>
		set({ isCardView: true, ListView: false, DocsView: false }),
	// setEditModalOpen: () => set({isEditModalOpen:true}),
	setEditModalOpen: (isOpen: boolean) => set({ isEditModalOpen: isOpen }),
	setInput: (value: string) => set({ input: value }),
	setfilter: (value: any[]) => set({ filter: value }),
	selectedGroupBy: "None",
	open: false,
	selectedItemDetails: null,
	planvalue: [],
	BacklogFilterValue: [],
	setBacklogSelectedFilter: (value: any[]) =>
		set({ BacklogSelectedFilter: value }),
	setSelectedGroupBy: (groupBy: string) => set({ selectedGroupBy: groupBy }),
	setListSelectedGroupBy: (value: string) =>
		set({ ListSelectedGroupBy: value }),
	setOpen: (isOpen: boolean) => set({ open: isOpen }),
	setSelectedItemDetails: (item: any) => set({ selectedItemDetails: item }),
	setReleasePlan: (isOpen: boolean) => set({ ReleasePlanValue: isOpen }),
	setFetchPlan: (isopen: boolean) => set({ FetchPlan: isopen }),
	setplanvalue: (value: any[]) => set({ planvalue: value }),
	setBacklogFilterValue: (value: any[]) => set({ BacklogFilterValue: value }),
	setTask: (isOpen: boolean) => set({ TaskValue: isOpen }),
	setViewData: (viewData: any) => set({ viewData }),
	urlData: {
		prevUrl: "",
		newUrl: "",
		screen: "",
		changeUrl: (prevUrl: string, newUrl: string, screen: string) => {
			set((state) => ({
				urlData: {
					...state.urlData,
					prevUrl: prevUrl,
					newUrl: newUrl,
					screen: screen,
				},
			}));
		},
	},
}));
