import React, { useEffect, useRef, useState } from "react";
import { Badge, Flex, Layout, Radio } from "antd";
// import { QuestionCircleOutlined } from "@ant-design/icons";

// import ChatSiderUI from "./ChatSiderUI";
import {
	BulbOutlined,
	//CloseOutlined,
	CommentOutlined,
	HomeOutlined,
	NodeExpandOutlined,
} from "@ant-design/icons";
import { useInViewport, useRequest } from "ahooks";
import useAIActivityStore from "../stores/AIActivityStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import AlchemiAIPopup from "../component/AlchemiAIPopup";
// import usePlatformStore from "../stores/platformStore";
import ChatSiderUI from "./ChatSiderUI";
import { useParams } from "react-router-dom";

const { Sider } = Layout;

// interface ChatSiderProps {
// 	visible: boolean;
// 	setVisible: (visible: boolean) => void;
// 	onClose: () => void;
// }

const ChatSider: React.FC = () => {
	// const helpItems = [{ title: "FAQ", icon: <QuestionCircleOutlined /> }];
	const workspaceId = useParams().workspaceId;
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	// const [alchemiBadgeCount, setAlchemiBadgeCount] = useState(0);
	const {
		currentAIActivities,
		setCurrentAIActivities,
		alRecommendations,
		setAlRecommendations,
		isAIConversationActive,
	} = useAIActivityStore((state: any) => ({
		currentAIActivities: state.currentAIActivities,
		setCurrentAIActivities: state.setCurrentAIActivities,
		alRecommendations: state.alRecommendations,
		setAlRecommendations: state.setAlRecommendations,
		isAIConversationActive: state.isAIConversationActive,
	}));
	const [alchemiView, setAlchemiView] = useState("home");

	const getAIRecommendations = async () => {
		// Implement your code here

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/alrecommendations/${workspaceId}?status=ACTIVE`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			}
		);

		const result = await response.json();
		return result;
		//setAIActivityJob(result);
	};
	const {
		data: alRecommendationsData,
		run: alRecommendationsRun,
		cancel: alRecommendationsCancel,
	} = useRequest(() => getAIRecommendations(), {
		pollingInterval: 120000,
		pollingWhenHidden: false,
	});

	const getAIActivty = async () => {
		// Implement your code here

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/describe/${workspaceId}/activity?status=IN_PROGRESS`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			}
		);

		const result = await response.json();
		return result;
		//setAIActivityJob(result);
	};
	const {
		data: aiActivitiesData,
		run: aiActivitiesRun,
		cancel: aiActivitiesCancel,
	} = useRequest(() => getAIActivty(), {
		pollingInterval: 120000,
		pollingWhenHidden: false,
	});

	useEffect(() => {
		return () => {
			aiActivitiesCancel();
			alRecommendationsCancel();
		};
	}, []);

	useEffect(() => {
		if (aiActivitiesData) {
			setCurrentAIActivities(aiActivitiesData);
		}
	}, [aiActivitiesData]);

	useEffect(() => {
		if (alRecommendationsData) {
			setAlRecommendations(alRecommendationsData);
		}
	}, [alRecommendationsData]);

	useEffect(() => {
		let alchemiBadgeCountNumber = 0;
		currentAIActivities &&
			currentAIActivities.length > 0 &&
			currentAIActivities.filter((activity: any) => {
				if (activity.status === "IN_PROGRESS") {
					alchemiBadgeCountNumber = alchemiBadgeCountNumber + 1;
				}
			});

		alRecommendations &&
			alRecommendations.length > 0 &&
			alRecommendations.filter((recommendation: any) => {
				if (recommendation.status === "ACTIVE") {
					alchemiBadgeCountNumber = alchemiBadgeCountNumber + 1;
				}
			});

		// if (alchemiBadgeCountNumber && alchemiBadgeCountNumber > 0) {
		// 	setAlchemiBadgeCount(alchemiBadgeCountNumber);
		// 	setShowAIBadge(false);
		// }
	}, [alRecommendations, currentAIActivities]);

	useEffect(() => {
		if (workspaceId) {
			aiActivitiesRun();
			alRecommendationsRun();
		}
	}, [workspaceId]);

	useEffect(() => {
		if (workspaceId) {
			aiActivitiesRun();
			alRecommendationsRun();
		}
	}, []);

	// useEffect(() => {
	// 	currentAIActivities &&
	// 		currentAIActivities.length > 0 &&
	// 		currentAIActivities.filter((activity: any) => {
	// 			if (activity.status === "IN_PROGRESS") {
	// 				setShowAIBadge(true);
	// 			} else {
	// 				setShowAIBadge(false);
	// 			}
	// 		});
	// 	if (currentAIActivities && currentAIActivities.length === 0) {
	// 		setShowAIBadge(false);
	// 		console.log(showAIBadge);
	// 	}
	// }, [currentAIActivities]);
	const handleAlchemiView = (e: any) => {
		console.log("setAlchemiView", e.target.value);
		setAlchemiView(e.target.value);
	};
	// const { aiChatWidget, setAiChatWidget } = usePlatformStore((state: any) => ({
	// 	aiChatWidget: state.aiChatWidget,
	// 	setAiChatWidget: state.setAiChatWidget,
	// }));
	// console.log("aiChatWidget",aiChatWidget);
	const ref = useRef(null);
	const [inViewport] = useInViewport(ref);
	return (
		<Sider
			width={"32vw"}
			collapsible
			collapsed={false}
			// onCollapse={onClose}
			collapsedWidth={0}
			trigger={null}
			reverseArrow
			style={{
				borderRadius: "10px",
				background: "#fff",
				position: "fixed",
				// marginRight: "4px",
				// right: 0,
				left: 0,
				height: "85vh",
				overflow: "auto",
			}}
		>
			<div style={{ padding: 20 }}>
				{/* <h2>Help Desk</h2>
				<List
					itemLayout="horizontal"
					dataSource={helpItems}
					renderItem={(item) => (
						<List.Item>
							<List.Item.Meta avatar={item.icon} title={item.title} />
						</List.Item>
					)}
				/> */}
				{isAIConversationActive ? (
					<div ref={ref}>
						<ChatSiderUI
							collapsed={false}
							inViewport={inViewport ? true : false}
						/>
					</div>
				) : (
					<>
						<Flex vertical>
							<Flex>
								<Flex
									justify="flex-start"
									align="center"
									style={{ width: "100%" }}
								>
									<b>Alchemi Assist</b>
									{/* <Popover
									placement="bottom"
									trigger={["click"]}
									content={<Flex justify="flex-end" align="center" style={{ width: "100%" }}>
										<Radio.Group defaultValue="home" size="middle">
											<Radio.Button
												value="home"
												defaultChecked
												onClick={handleAlchemiView}
											>
												<HomeOutlined />
											</Radio.Button>
											<Radio.Button
												value="recommendations"
												onClick={handleAlchemiView}
											>
												<Badge
													count={0}
													size="small"
													color="blue"
													offset={[6, 0]}
													showZero={false}
												>
													<BulbOutlined />
												</Badge>
											</Radio.Button>
											<Radio.Button value="chatThreads" onClick={handleAlchemiView}>
												<Badge count={0} size="small" color="blue" offset={[6, 0]}>
													{" "}
													<CommentOutlined />
												</Badge>
											</Radio.Button>
											<Radio.Button value="aiRuns" onClick={handleAlchemiView}>
												<Badge
													count={0}
													size="small"
													color="blue"
													offset={[6, 0]}
													showZero={false}
												>
													{" "}
													<NodeExpandOutlined />
												</Badge>
											</Radio.Button>
										</Radio.Group>
									</Flex>}>
									&nbsp; &nbsp; <SettingFilled />
								</Popover> */}
								</Flex>

								{/* <Flex justify="flex-end" align="center">
									<Button
										icon={<CloseOutlined />}
										size="small"
										onClick={() => setAiChatWidget(false)}
									/>
								</Flex> */}
							</Flex>
							<Flex>
								<Flex justify="center" align="center" style={{ width: "100%" }}>
									<Radio.Group defaultValue="home" size="large">
										<Radio.Button
											value="home"
											defaultChecked
											onClick={handleAlchemiView}
										>
											<HomeOutlined />
										</Radio.Button>
										<Radio.Button
											value="recommendations"
											onClick={handleAlchemiView}
										>
											<Badge
												count={alRecommendations ? alRecommendations.length : 0}
												size="small"
												color="blue"
												offset={[6, 0]}
												showZero={false}
											>
												<BulbOutlined />
											</Badge>
										</Radio.Button>
										{/* <Radio.Button value="chatUI" onClick={handleAlchemiView}>
											<Badge count={0} size="small" color="blue" offset={[6, 0]}>
												{" "}
												<CommentOutlined />
											</Badge>
										</Radio.Button> */}
										<Radio.Button
											value="chatThreads"
											onClick={handleAlchemiView}
										>
											<Badge
												count={0}
												size="small"
												color="blue"
												offset={[6, 0]}
											>
												{" "}
												<CommentOutlined />
											</Badge>
										</Radio.Button>
										<Radio.Button value="aiRuns" onClick={handleAlchemiView}>
											<Badge
												count={0}
												size="small"
												color="blue"
												offset={[6, 0]}
												showZero={false}
											>
												{" "}
												<NodeExpandOutlined />
											</Badge>
										</Radio.Button>
									</Radio.Group>
								</Flex>
							</Flex>
						</Flex>
						<AlchemiAIPopup view={alchemiView} />
					</>
				)}
				{/* <ChatSiderUI collapsed={!visible} /> */}
			</div>
		</Sider>
	);
};

export default ChatSider;
