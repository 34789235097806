import { FilterFilled, ToolOutlined } from "@ant-design/icons";
import {
	Avatar,
	Button,
	Card,
	Dropdown,
	Flex,
	Input,
	Layout,
	List,
	Menu,
	MenuProps,
	Space,
	Tag,
	Tooltip,
} from "antd";
import "./KanoPrioritization.css";

import { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import useWorkspaceStore from "../../../stores/WorkspaceState";

import usePageStore from "../../../stores/PageStore";
import BreadCrumb from "../../Editors/BreadCrumb";

const { Content, Header } = Layout;
const { Search } = Input;

function KanoPrioritization() {
	const [data, setData] = useState<any>();
	const [tags, setTags] = useState<any>();
	const [searchvalue, setsearchvalue] = useState<string>("");

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
		breadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
		breadcrumbConfig: state.breadcrumbConfig,
	}));

	useEffect(() => {
		setPageSlug("prioritization");
		setPageTitle("Kano Prioritization");
		setPageDescription("");
		setItemType("");
		setItemId("");
		setIsList(false);
		setItemDetails({});
		setBreadcrumbConfig({
			page: `/space/${currentWorkspace?._id}/prioritization`,
			config: [
				{
					label: "views",
					url: `/space/${currentWorkspace?._id}/planner/filterview`,
				},

				{
					label: "Kano Prioritization",
					url: `/space/${currentWorkspace?._id}/prioritization`,
					dynamicId: true,
				},
			],
		});
	}, []);
	const fetchTodos = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${currentWorkspace?._id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			const result = await response.json();
			console.log(result, "result");
			if (response.ok) {
				setData(result);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	useEffect(() => {
		fetchTodos();
	}, []);

	useEffect(() => {
		const fetchTagsData = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					setTags(data);
					console.log(data);
				} else {
					console.error("Error fetching tags data");
				}
			} catch (error) {
				console.error("Error fetching tags data:", error);
			}
		};

		fetchTagsData();
	}, []);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	const items: MenuProps["items"] = [
		{
			label: "PERFORMANCE NEEDS",
			key: "1",
		},
		{
			label: "EXCITEMENT NEEDS",
			key: "2",
		},
		{
			label: "BASIC NEEDS",
			key: "3",
		},
	];

	const handleMenuClick = async (e: any, item: any) => {
		const selectedLabel: any | null = items?.find(
			(menuItem) => menuItem?.key === e.key
		);

		console.log(selectedLabel?.label);

		try {
			const updateResponse = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${item._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({ ...item, priority: selectedLabel?.label }),
				}
			);

			const updateResult = await updateResponse.json();

			if (updateResponse.ok) {
				console.log("Data updated successfully:", updateResult);

				fetchTodos();
			} else {
				console.error("Failed to update data");
			}
		} catch (error) {
			console.error("Error updating data:", error);
		}
	};

	const sideTodos = data?.filter((item: any) => item?.priority === undefined);
	console.log(sideTodos, "sideTodos");

	const kanoTags = tags?.filter((tag: any) => tag.category === "Kano");
	console.log(kanoTags, "kanoTags");

	function getFirstWordAndlastword(inputText: string) {
		// Function to generate a random pastel color
		const assigneeNameWords = inputText?.split(" ") || [];
		const firstName = assigneeNameWords[0] || "";
		const lastName =
			assigneeNameWords.length > 1
				? assigneeNameWords[assigneeNameWords.length - 1]
				: "";

		const avatarText = `${firstName.charAt(0)}${lastName.charAt(
			0
		)}`.toUpperCase();

		return avatarText;
	}

	const getRandomPastelColor = () => {
		const hue = Math.floor(Math.random() * 360);
		const pastelColor = `hsl(${hue}, 80%, 80%)`;
		return pastelColor;
	};

	const SearchinputValue = (values: any) => {
		const newvalues = values?.toLowerCase();
		setsearchvalue(newvalues);
	};

	const FilterSearchValudata = (data: any) => {
		console.log("datainsidefun", data, searchvalue);
		const newdata = data?.filter(
			(test: any) =>
				test.title?.toLowerCase().includes(searchvalue) ||
				test.description?.toLowerCase().includes(searchvalue) ||
				test?.WorkitemId?.toLowerCase().includes(searchvalue)
			// 	&&
			// test?.status
			// 	?.toLowerCase()
			// 	.includes(filterstatus)
		);
		return newdata;
	};

	return (
		<>
			<Layout style={{ padding: "1rem", backgroundColor: "#F5F8FE" }}>
				<Header style={{ background: "#F5F8FE" }}>
					<Flex align="center" justify="space-between">
						<Flex gap={20} align="center">
							<BreadCrumb config={breadcrumbConfig} />
						</Flex>
						<Button
							className="save"
							type="primary"
							shape="round"
							style={{ width: "7vw" }}
						>
							Save
						</Button>
					</Flex>
				</Header>
				<Content style={{ backgroundColor: "#F5F8FE" }}>
					<Flex>
						<Flex vertical className="kano" gap={20}>
							<Flex justify="space-between" align="center">
								<Search
									placeholder="input search text"
									allowClear
									style={{ width: 300 }}
									onChange={(e) => SearchinputValue(e.target.value)}
								/>
								<FilterFilled />
							</Flex>
							<Flex>
								<List
									split={false}
									style={{ overflowY: "auto", height: "70vh", width: "100%" }}
									dataSource={FilterSearchValudata(sideTodos)}
									renderItem={(item: any) => (
										<List.Item style={{}}>
											<Dropdown
												overlay={
													<Menu onClick={(e) => handleMenuClick(e, item)}>
														{items.map((menuItem) =>
															menuItem && "label" in menuItem ? (
																<Menu.Item key={menuItem.key}>
																	{menuItem.label}
																</Menu.Item>
															) : null
														)}
													</Menu>
												}
												trigger={["contextMenu"]}
											>
												<Flex>
													<Avatar>
														{" "}
														<ToolOutlined />
													</Avatar>
													<Space
														direction="vertical"
														align="start"
														style={{
															textAlign: "left",
															width: "82%",
															marginLeft: "0.2rem",
															marginRight: "0.2rem",
														}}
													>
														<Space align="start" className="kanoTitle">
															{item.title}
														</Space>
														<Space align="start" className="kanoDescription">
															{item.description}
														</Space>
													</Space>
													<Tooltip
														title={`${item.assignee?.name}`}
														placement="top"
													>
														<Avatar
															style={{
																backgroundColor: getRandomPastelColor(),
															}}
														>
															{getFirstWordAndlastword(item.assignee?.name)}
														</Avatar>
													</Tooltip>
												</Flex>
											</Dropdown>
										</List.Item>
									)}
								/>
							</Flex>
						</Flex>

						<Flex
							style={{
								backgroundColor: "#F5F8FE",

								overflow: "scroll",
								height: "80vh",
								width: "72%",
								// display: "block",
								// position: "relative",
							}}
						>
							{kanoTags &&
								kanoTags.map((col: any) => (
									<Flex vertical style={{ width: "30vw" }} key={col.id}>
										<Flex justify="center" className="ColumnTitle">
											{col.name}
										</Flex>
										<Flex vertical>
											{data &&
												data
													.filter((item: any) => item.priority === col.name)
													.map((task: any) => (
														<Card className="KanoCard" key={task.id}>
															<Flex align="start">
																{/* <div
																style={{
																	color: col.color,
																	fontSize: "1.2rem",
																}}
															>
																{task.icon}
															</div> */}
																<Space direction="vertical">
																	<Flex justify="space-between">
																		<div style={{ fontWeight: "bold" }}>
																			{task.title}
																		</div>
																		{/* <div style={{ fontWeight: "bold" }}>
																		{task.story}
																	</div> */}
																	</Flex>
																	<Flex className="kanoCardDescription">
																		{task.description}
																	</Flex>
																</Space>
															</Flex>
															<Flex
																justify="space-between"
																style={{ marginTop: "0.5rem" }}
															>
																<Tag
																	color={col.color}
																	style={{
																		color: "black",
																		fontSize: "0.8rem",
																		width: "fit-content",
																		textAlign: "center",
																	}}
																>
																	{task.priority}
																</Tag>
																<div style={{ width: "4vw" }}>
																	{/* <Tag
																	style={{
																		color: task.colour,
																		fontSize: "0.8rem",
																		textAlign: "center",
																	}}
																>
																	{task.bug}
																</Tag> */}
																</div>
																<div
																	style={{
																		fontSize: "0.8rem",
																		fontWeight: "bold",
																		color: col.color,
																	}}
																>
																	{task.status}
																</div>
															</Flex>
														</Card>
													))}
										</Flex>
									</Flex>
								))}
						</Flex>
					</Flex>
				</Content>
			</Layout>
		</>
	);
}

export default KanoPrioritization;
