/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	BlockNoteEditor,
	BlockNoteSchema,
	PartialBlock,
	defaultBlockSpecs,
	filterSuggestionItems,
	insertOrUpdateBlock,
} from "@blocknote/core";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import {
	Avatar,
	Button,
	//Button,
	Card,
	Divider,
	Flex,
	Input,
	Tag,
	message,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { planerViewStore } from "../../stores/PlannerStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import "./BlockNote.css";
// import ActivityTab from "../Planner/ActivityTab";
// import ChatInterface from "../Planner/DiscussionTab";
import Activity from "./BlockNoteActivity";
// import RightPannel from "./RightPannel";
import { useDebounceFn, useRequest } from "ahooks";

import BreadCrumb from "./BreadCrumb";
import "./BreadCrumb.css";
// import breadcrumbConfig from "../../util/BreadCrumbsUtil";
import { CodeBlock } from "../../component/editor/CodeBlock";
import {
	SuggestionMenuController,
	getDefaultReactSlashMenuItems,
} from "@blocknote/react";

import ShowParent from "./ShowParent";
import ActionDropdown from "./ActionsDropdown";
import usePageStore from "../../stores/PageStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import MapViewTab from "../Planner/MapViewTab";
import { getWorkItemsById } from "../../api/WorkItemAPI";
import { useParams } from "react-router-dom";

const schema = BlockNoteSchema.create({
	blockSpecs: {
		...defaultBlockSpecs,
		procode: CodeBlock,
	},
});

const insertCode = (editor: typeof schema.BlockNoteEditor) => ({
	title: "Code",
	onItemClick: () => {
		insertOrUpdateBlock(editor, {
			type: "procode",
		});
	},
	aliases: ["code", "monacho", "cd"],
	group: "Other",
});

async function saveToStorage(jsonBlocks: (typeof schema.Block)[]) {
	localStorage.setItem("editorContent", JSON.stringify(jsonBlocks));
	console.log("Data saved to localStorage:", jsonBlocks);
}

export default function BlockNoteEditors() {
	const [messageApi, contextHolder] = message.useMessage();
	const { selectedItemDetails, setSelectedItemDetails } = planerViewStore();
	const [selectedTitle, setSelectedTitle] = useState<any>(
		selectedItemDetails?.title
	);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const workitemId: string = useParams().id ?? "";
	const workspaceId: string = useParams().workspaceId ?? "";

	const { setCurrentWorkspace } = useWorkspaceStore((state: any) => ({
		setCurrentWorkspace: state.setCurrentWorkspace,
	}));

	const getdata = async () => {
		fetch(`${process.env.REACT_APP_API_URL}/workitems/Id/${workitemId}`, {
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("data", data);
				setSelectedItemDetails(data);
				setItemDetails(data);
			})
			.catch((error) => {});
	};

	const getWorkspaceData = async () => {
		fetch(`${process.env.REACT_APP_API_URL}/workspace/${workspaceId}`, {
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setCurrentWorkspace(data);
			})
			.catch((error) => {});
	};

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
		breadcrumbConfig,
		itemDetails,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
		breadcrumbConfig: state.breadcrumbConfig,
		itemDetails: state.itemDetails,
	}));

	useEffect(() => {
		setPageSlug("backlog-item-detail");
		setPageTitle("Backlog Item Detail");
		setItemType(selectedItemDetails?.type);
		setItemId(selectedItemDetails?._id);
		setPageDescription("");
		setIsList(false);
		setItemDetails(selectedItemDetails);
		setBreadcrumbConfig({
			page: `/space/${workspaceId}/planner/editor`,
			config: [
				{
					label: "Backlogs",
					url: `/space/${workspaceId}/planner/backlogs`,
				},
				{
					label: "Details",
					url: `/space/${workspaceId}/planner/editor/:id`,
					dynamicId: true,
				},
			],
		});

		// setPageContext({
		// 	slug: "backlog-item-detail",
		// 	title: "Backlog Item Detail",
		// 	itemType: selectedItemDetails.type,
		// 	itemId: selectedItemDetails._id,
		// 	// ai: {
		// 	// 	goal: "feature-elaboration",
		// 	// 	conversationThreadId: selectedItemDetails.conversationThreadId
		// 	// 		? selectedItemDetails.conversationThreadId
		// 	// 		: "",
		// 	// },
		// 	itemDetails: selectedItemDetails,
		// 	breadCrumbConfig: [
		// 		{
		// 			displayName: selectedItemDetails.title,
		// 			url: `/planner/${selectedItemDetails._id}`,
		// 		},
		// 	],
		// });
	}, []);
	console.log("itemDetails", itemDetails);
	const [initialContent, setInitialContent] = useState<
		PartialBlock[] | undefined | "loading"
	>("loading");
	const [subscriberlist, setsubscriberlist] = useState<any>(
		selectedItemDetails?.subscriberlist
	);

	useEffect(() => {
		if (
			(!selectedItemDetails || !itemDetails) &&
			workitemId &&
			userProfileStoreInstance.idToken
		) {
			getdata();
		}
		console.log("locationurl", window.location.href);
		if (!workspaceId && workitemId && userProfileStoreInstance.idToken) {
			console.log("getting workspace data");
			getWorkspaceData();
		}
	}, [
		itemDetails,
		selectedItemDetails,
		workspaceId,
		workitemId,
		userProfileStoreInstance.idToken,
	]);

	const SaveTitle = async (title: any) => {
		const reqbody = {
			title: title,
		};
		const requestOptions = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(reqbody),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
			requestOptions
		);

		if (!response.ok) {
			throw new Error("Failed to save data to server");
		}
	};

	const { run } = useDebounceFn(
		() => {
			SaveTitle(selectedTitle).catch((error) => {
				console.error(error);
			});
		},
		{
			wait: 1000,
		}
	);

	const account = process.env.REACT_APP_FILE_UPLOAD_STORAGE_ACCOUNT;
	const sasToken = process.env.REACT_APP_FILE_UPLOAD_STORAGE_SAS_TOKEN;
	const containerName = process.env
		.REACT_APP_FILE_UPLOAD_STORAGE_CONTAINER as string;

	if (!account || !sasToken || !containerName) {
		throw new Error("Missing Azure Blob Storage configuration.");
	}

	async function uploadFile(file: File) {
		const blobServiceClient = new BlobServiceClient(
			`https://${account}.blob.core.windows.net/?${sasToken}`
		);
		const containerClient = blobServiceClient.getContainerClient(containerName);

		// Generate a unique blob name and upload the file
		const blobName = `${new Date().getTime()}-${file.name}`;
		const blobClient = containerClient.getBlockBlobClient(blobName);
		await blobClient.uploadData(file, {
			blobHTTPHeaders: { blobContentType: file.type },
		});

		// Get the URL of the uploaded file
		const fileUrl = `https://${account}.blob.core.windows.net/${containerName}/${blobName}`;

		return fileUrl;
	}

	async function fetchDataFromServer(
		selectedItemId: string
	): Promise<PartialBlock[] | undefined> {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/workitems/Id/${selectedItemId}`,
			requestOptions
		);
		if (!response.ok) {
			throw new Error("Failed to fetch data from server");
		}
		const data = await response.json();
		return data.blockData;
	}

	console.log("selectedItemDetails", selectedItemDetails);

	useEffect(() => {
		const loadContent = async () => {
			try {
				const parentData = selectedItemDetails.blockData;

				// console.log(
				// 	parentData && parentData.length > 0
				// 		? parentData.map((parent) => {
				// 				return parent.content && Array.isArray(parent.content)
				// 					? parent.content.map((item) => {
				// 							if (typeof item === "string") {
				// 								return item;
				// 							} else if ("text" in item) {
				// 								return item.text;
				// 							} else {
				// 								return "";
				// 							}
				// 						})
				// 					: "No content available";
				// 			})
				// 		: "No parent data available"
				// );

				const baseContent: PartialBlock[] = [
					// {
					// 	type: "heading",
					// 	content: [
					// 		selectedItemDetails.WorkitemId
					// 			? `${selectedItemDetails.WorkitemId} - ${
					// 					selectedItemDetails.title || ""
					// 				}`
					// 			: selectedItemDetails.title || "",
					// 	],
					// 	props: { level: 1 },
					// },
					// {
					// 	type: "paragraph",
					// 	content: [selectedItemDetails.description || ""],
					// },
				];

				if (
					selectedItemDetails.type === "USER_STORY" &&
					!selectedItemDetails.blockData
				) {
					let acceptanceCriteriaBlocks: PartialBlock[] = [];
					if (selectedItemDetails.data.acceptance_criteria) {
						if (Array.isArray(selectedItemDetails.data.acceptance_criteria)) {
							acceptanceCriteriaBlocks =
								selectedItemDetails.data.acceptance_criteria?.map(
									(criteria: any) => ({
										type: "bulletListItem",
										content: [criteria],
									})
								) || [];
						} else {
							acceptanceCriteriaBlocks = [
								{
									type: "bulletListItem",
									content: [selectedItemDetails.data.acceptance_criteria],
								},
							];
						}
					}

					let useCasesBlocks: PartialBlock[] = [];
					if (selectedItemDetails.data.use_cases) {
						if (Array.isArray(selectedItemDetails.data.use_cases)) {
							useCasesBlocks =
								selectedItemDetails.data.use_cases?.flatMap(
									(useCase: {
										basic_flow: any[];
										alternate_flow: any[];
										pre_conditions: any[];
										post_conditions: any[];
										business_logic: any[];
										system_behavior: any[];
									}) => [
										{
											type: "heading",
											content: ["Basic Flow"],
											props: { level: 3 },
										},
										...useCase.basic_flow.map((flow) => ({
											type: "bulletListItem",
											content: [flow],
										})),
										{
											type: "heading",
											content: ["Alternate Flow"],
											props: { level: 3 },
										},
										...useCase.alternate_flow.map((flow) => ({
											type: "bulletListItem",
											content: [flow],
										})),
										{
											type: "heading",
											content: ["Pre-conditions"],
											props: { level: 3 },
										},
										...useCase.pre_conditions.map((cond) => ({
											type: "bulletListItem",
											content: [cond],
										})),
										{
											type: "heading",
											content: ["Post-conditions"],
											props: { level: 3 },
										},
										...useCase.post_conditions.map((cond) => ({
											type: "bulletListItem",
											content: [cond],
										})),
										{
											type: "heading",
											content: ["Business Logic"],
											props: { level: 3 },
										},
										...useCase.business_logic.map((logic) => ({
											type: "bulletListItem",
											content: [logic],
										})),
										{
											type: "heading",
											content: ["System Behavior"],
											props: { level: 3 },
										},
										...useCase.system_behavior.map((behavior) => ({
											type: "bulletListItem",
											content: [behavior],
										})),
									]
								) || [];
						} else {
							useCasesBlocks = [
								{
									type: "bulletListItem",
									content: [selectedItemDetails.data.use_cases],
								},
							];
						}
					}

					if (acceptanceCriteriaBlocks.length > 0) {
						baseContent.push(
							{
								type: "heading",
								content: ["Acceptance Criteria"],
								props: { level: 2 },
							},
							...acceptanceCriteriaBlocks
						);
					}

					if (useCasesBlocks.length > 0) {
						baseContent.push(
							{
								type: "heading",
								content: ["Use Cases"],
								props: { level: 2 },
							},
							...useCasesBlocks
						);
					}
				}

				if (
					selectedItemDetails.type === "FEATURE" &&
					selectedItemDetails.data &&
					!selectedItemDetails.blockData
				) {
					const complexityScoreBlocks: PartialBlock[] = [
						{
							type: "heading",
							content: ["Complexity Score"],
							props: { level: 2 },
						},
						{
							type: "bulletListItem",
							content: [selectedItemDetails.data.complexity_score],
						},
						{
							type: "bulletListItem",
							content: [selectedItemDetails.data.complexity_score_reason],
						},
					];

					const roleBlocks: PartialBlock[] = [
						{
							type: "heading",
							content: ["Roles"],
							props: { level: 2 },
						},
						...selectedItemDetails.data.role_ids.map((role: string) => ({
							type: "bulletListItem",
							content: [role],
						})),
					];

					const benefitBlocks: PartialBlock[] = [
						{
							type: "heading",
							content: ["Benefits"],
							props: { level: 2 },
						},
						...selectedItemDetails.data.benefits.map((benefit: string) => ({
							type: "bulletListItem",
							content: [benefit],
						})),
					];

					baseContent.push(
						...complexityScoreBlocks,
						...roleBlocks,
						...benefitBlocks
					);
				}

				if (parentData && parentData.length > 0) {
					const mappedContent = parentData.map((parent: any) => {
						return {
							type: parent.type || "numberedListItem",
							props: parent.props || {},
							content:
								parent.content && Array.isArray(parent.content)
									? parent.content.map((item: any) => {
											if (typeof item === "string") {
												return item;
											} else if ("text" in item) {
												return item.text;
											} else {
												return "";
											}
										})
									: [],
						} as PartialBlock;
					});

					setInitialContent([...baseContent, ...mappedContent]);
				} else {
					setInitialContent([
						...baseContent,
						{ type: "paragraph", content: [""] },
					]);
				}
			} catch (error) {
				console.error(error);
				setInitialContent([]);
			}
		};
		if (selectedItemDetails) {
			setSelectedTitle(selectedItemDetails.title);
			setsubscriberlist(selectedItemDetails.subscriberlist);
			loadContent();
		}
	}, [selectedItemDetails]);

	async function saveToServer(jsonBlocks: (typeof schema.Block)[]) {
		const blocksToSave = jsonBlocks;
		const requestOptions = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify({ blockData: blocksToSave }),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
			requestOptions
		);

		if (!response.ok) {
			throw new Error("Failed to save data to server");
		}

		console.log("Data saved to server:", jsonBlocks);
	}

	const editor = useMemo(() => {
		if (initialContent === "loading") {
			return undefined;
		}
		return BlockNoteEditor.create({ schema, initialContent, uploadFile });
	}, [initialContent]);

	// const handleSaveToServer = () => {
	// 	if (editor) {
	// 		saveToServer(editor.document).catch((error) => {
	// 			console.error(error);
	// 		});
	// 	}
	// };

	const debouncedSave = useDebounceFn(
		() => {
			if (editor) {
				saveToServer(editor.document).catch((error) => {
					console.error(error);
				});
			}
		},
		{ wait: 1000 }
	);

	if (initialContent === "loading" || !editor) {
		return <div>Loading content...</div>;
	}

	const HandleSubscriberlist = async () => {
		if (
			selectedItemDetails.assignee._id == userProfileStoreInstance.profile._id
		) {
			messageApi.open({
				type: "error",
				content:
					"You can't unsubscribe to this item as you are the assignee of this item",
			});
			return;
		}
		try {
			const reqbody = {
				subscriberlist: [] as any,
			};
			if (subscriberlist?.length > 0) {
				const subscriberId = userProfileStoreInstance.profile._id.toString();
				const subscriberIds = subscriberlist?.map((subscriber: any) =>
					subscriber._id.toString()
				);

				if (subscriberIds.includes(subscriberId)) {
					// Remove the subscriber ID if it exists
					const testdata = subscriberlist?.filter(
						(subscriber: any) => subscriber._id.toString() !== subscriberId
					);
					reqbody.subscriberlist = testdata.map((data: any) => {
						return data._id.toString();
					});
				} else {
					// Add the subscriber ID if it does not exist
					reqbody.subscriberlist = [
						...subscriberIds,
						userProfileStoreInstance.profile._id,
					];
				}
			} else {
				reqbody.subscriberlist = [userProfileStoreInstance.profile._id];
			}
			const requestOptions = {
				method: "PUT",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(reqbody),
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItemDetails._id}`,
				requestOptions
			);

			if (response.ok) {
				const data = await response.json();
				console.log("datakajdbfj", data);
				// setSelectedItemDetails((prevdata:any)=>{
				// 	return {...prevdata,subscriberlist:data.subscriberlist};

				// });
				setsubscriberlist(data.subscriberlist);
				console.log("Subscriberlist updated successfully");
			} else {
				console.error("Failed to update assignee");
			}
		} catch (error) {
			console.error("Error updating assignee:", error);
		}
	};

	console.log("selectedItemDetails", selectedItemDetails);

	const CheckSubscriber = () => {
		let check = true;
		if (subscriberlist?.length > 0) {
			subscriberlist?.map((subscriber: any) => {
				if (subscriber.email === userProfileStoreInstance.profile.email) {
					check = false;
				}
			});
		}
		return check;
	};

	const CheckSubscribername = () => {
		let name = "";
		if (subscriberlist?.length > 0) {
			subscriberlist?.map((subscriber: any) => {
				if (subscriber.email === userProfileStoreInstance.profile.email) {
					name = subscriber.name.charAt(0);
				}
			});
		}
		return name;
	};

	const HandleItemTitleChange = (e: any) => {
		setSelectedTitle(e.target.value);
		run();
	};

	return (
		<>
			{contextHolder}
			<div
				style={{
					marginLeft: "2.5rem",
					marginTop: "5px",
					marginBottom: "-20px",
				}}
			>
				<BreadCrumb
					config={breadcrumbConfig}
					workItemId={selectedItemDetails.WorkitemId}
				/>
			</div>
			<div
				style={{
					display: "flex",
					height: "100vh",
					marginTop: "5vh",
					backgroundColor: "white",
				}}
			>
				<div style={{ flex: 8, display: "flex", flexDirection: "column" }}>
					<Card
						style={{
							height: "70vh",
							overflowY: "auto",
							display: "flex",
							flexDirection: "column",
							marginTop: "1.5vh",
							border: "none",
							boxShadow: "none",
						}}
					>
						<div
							style={{
								display: "flex",
								textAlign: "left",
								margin: "0vw 0vw 0vw 3vw",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							{/* <Flex style={{width:""}}> */}
							<Tag
								style={{ alignContent: "center", height: "2rem" }}
								color="blue"
							>
								{selectedItemDetails.WorkitemId}
							</Tag>
							<Input
								style={{
									fontSize: "2rem",
									fontWeight: "bolder",
								}}
								value={selectedTitle}
								bordered={false}
								onChange={HandleItemTitleChange}
							/>

							{/* </Flex> */}
							<ActionDropdown />
						</div>
						<div
							style={{
								display: "flex",
								textAlign: "left",
								margin: "0vw 0vw 1vw 3vw",
								alignItems: "center",
							}}
						>
							<ShowParent
								id={selectedItemDetails?._id}
								type={selectedItemDetails?.type}
								priority={selectedItemDetails?.priority}
								assignee={selectedItemDetails?.assignee?.name}
							/>
						</div>
						<BlockNoteView
							editor={editor}
							theme="light"
							onChange={() => {
								saveToStorage(editor.document);
								debouncedSave.run();
							}}
							slashMenu={false}
						>
							<SuggestionMenuController
								triggerCharacter={"/"}
								getItems={async (query) =>
									filterSuggestionItems(
										[
											...getDefaultReactSlashMenuItems(editor),
											insertCode(editor),
										],
										query
									)
								}
							/>
						</BlockNoteView>
						{/* <Button
						style={{
							float: "right",
							marginTop: "31vh",
							marginBottom: "0.5rem",
						}}
						type="primary"
						onClick={handleSaveToServer}
					>
						Save
					</Button> */}
						<div style={{ marginLeft: "1rem" }}>
							<MapViewTab page={"BlockData"} />
						</div>
						<Divider />
						<Flex justify="space-between" align="center">
							<h2 style={{ textAlign: "left", marginLeft: "3vw" }}>Activity</h2>
							<Flex justify="space-between" align="center">
								<Button onClick={HandleSubscriberlist}>
									{CheckSubscriber() ? "Subscribe" : "Unsubscribe"}
								</Button>
								<Avatar
									style={{ backgroundColor: "#e3f0ff", marginLeft: "0.8rem" }}
								>
									{/* {subscriberlist?.name.charAt(0)} */}
									{CheckSubscribername()}
								</Avatar>
							</Flex>
						</Flex>
						<div style={{ textAlign: "left", marginLeft: "3vw" }}>
							<Activity
								subscriberlist={subscriberlist}
								setsubscriberlist={setsubscriberlist}
							/>
						</div>
					</Card>
				</div>
				{/* <div style={{ flex: 2, backgroundColor: "white" }}>
					<RightPannel />
				</div> */}
			</div>
		</>
	);
}
