/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect } from "react";
import {
	Layout,
	Button,
	List,
	Typography,
	Row,
	Popconfirm,
	message,
	Pagination,
} from "antd";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";
import AddRolesModal from "./AddRoleModal";
import RoleManagementNav from "./RoleManagementNav";
import "./RoleManagement.css";
import { useRequest } from "ahooks";
import { getallRoles } from "../../api/RolesAPI";

const { Text } = Typography;

const RoleManagement: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(15);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const [responseData, setResponseData] = useState<any[]>([]);
	const [, setSelectedRoleId] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { data: roleData, run: fetchRolesData } = useRequest(
		() => getallRoles(userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess() {
				// message.success("Role fetched successfully");
			},
			onError() {
				message.error("Error fetching roles data");
			},
		}
	);

	useEffect(() => {
		if (roleData) {
			setResponseData(roleData);
		}
	}, [roleData, isModalVisible]);

	useEffect(() => {
		fetchRolesData();
	}, []);

	const handleAddRoleClick = () => {
		setIsModalVisible(true);
	};

	const handleEditRoleClick = (id: any) => {
		setSelectedRoleId(id);
		navigate(`/settings/role/edit/${id}`);
	};

	const handleDeleteRoleClick = async (id: any) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/roles/${id}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				fetchRolesData();
			} else {
				console.error("Error deleting role");
			}
		} catch (error) {
			console.error("Error deleting role:", error);
		}
	};

	const handleAddRole = (newRole: any) => {
		setResponseData([...responseData, newRole]);
	};

	const renderListItem = (item: any) => (
		<List.Item
			key={item._id}
			style={{ padding: "5px 0" }}
			actions={[
				<a
					key="edit"
					style={{
						color: "#0444BF",
						fontSize: "12px",
						fontFamily: "Open Sans",
					}}
					onClick={() => handleEditRoleClick(item._id)}
				>
					Edit Permissions
				</a>,
				<Popconfirm
					key="delete"
					title="Sure to Delete?"
					onConfirm={() => handleDeleteRoleClick(item._id)}
				>
					<a
						style={{
							color: "#0444BF",
							fontSize: "12px",
							fontFamily: "Open Sans",
						}}
					>
						<DeleteOutlined style={{ fontSize: "12px", color: "red" }} />
					</a>
				</Popconfirm>,
			]}
		>
			<List.Item.Meta
				title={
					<Text
						style={{
							fontSize: "12px",
							fontWeight: "400",
							marginLeft: "1vw",
						}}
					>
						{item.name}
					</Text>
				}
			/>
		</List.Item>
	);

	// Get the current page data
	const currentPageData = responseData.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	return (
		<>
			<RoleManagementNav />
			{/* <Layout
				style={{
					backgroundColor: "white",
					padding: "0 1.5rem ",
					margin: "0 1vw",
					height: "80vh",
					marginTop: "-0.5vh",
				}}
			> */}

			<div
				className="list-view-container"
				style={{
					display: "flex",
					flexDirection: "column",
					height: "76vh",
					marginLeft: "1vw",
				}}
			>
				<div style={{ flex: "1 1 auto" }}>
					<List
						dataSource={currentPageData}
						renderItem={renderListItem}
						className="list-view"
						size="small"
					/>
				</div>
				<div
					style={{
						flex: "0 0 20px",
						textAlign: "end",
						marginTop: "3px",
						marginBottom: "10px",
					}}
				>
					<Pagination
						current={currentPage}
						total={responseData.length}
						pageSize={pageSize}
						onChange={handlePageChange}
						showSizeChanger={false}
					/>
				</div>
				<AddRolesModal
					isVisible={isModalVisible}
					setIsVisible={setIsModalVisible}
					onAddRole={handleAddRole}
				/>
			</div>
			{/* </Layout> */}
		</>
	);
};

export default RoleManagement;
