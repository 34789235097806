// /* eslint-disable @typescript-eslint/no-unused-vars */
// import React, { useEffect, useState } from "react";

// import { Layout, Flex, Spin, Image, Divider, message } from "antd";
// import WorkItemModal from "../Planner/AddWorkitemModel";

// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../../stores/UserProfileStore";

// import { useViewsStores } from "../../stores/BacklogStore";
// import ListViewItem from "../Planner/BacklogListView";

// import KanbanBoard from "../Planner/CardView";
// import DrawerComponent from "../Planner/Drawer";
// import BacklogNav from "../Planner/BacklogNav";
// import useWorkspaceStore from "../../stores/WorkspaceState";
// import BacklogListViewItem from "../Planner/BacklogListView";
// import { planerViewStore } from "../../stores/PlannerStore";
// import { useLocation } from "react-router-dom";
// import { Can } from "../../layout/AppLayout";
// import BacklogDocsView from "../Planner/BacklogDocsView";
// import ViewPageNav from "./ViewPageNavbar";
// import { getWorkitemByFilter } from "../../api/WorkItemAPI";
// import { useRequest } from "ahooks";

// const { Content } = Layout;

// const ViewPage: React.FC = () => {
// 	const [loading, setLoading] = useState(true);
// 	const {
// 		isCardView,
// 		open,
// 		ListView,
// 		FetchPlan,
// 		backlogworkitemmodel,
// 		setbacklogworkitemmodel,
// 		DocsView,
// 		setViewFilter,
// 		selectedGroupBy,
// 		RowBy,
// 		CardOrderning,
// 	} = useViewsStores();

// 	const url = useLocation().pathname;

// 	const { radioButtonSelected, sortBy, rightClickMenu, isEditModalOpen } =
// 		planerViewStore();

// 	// console.log(rightClickMenu, "rightClickMenu");

// 	const [modalVisible, setModalVisible] = useState(false);
// 	const [todos, setTodos] = useState<any[]>([]);
// 	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
// 	const [workItemData, setWorkItemData] = useState<any[]>([]);
// 	const { input, filter } = useViewsStores();
// 	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
// 		currentWorkspace: state.currentWorkspace,
// 	}));
// 	const { UserSavedFilterValue, SavedFilter } = useViewsStores();

// 	const {
// 		data: workItemDatas,
// 		run: fetchworkitembyfilter,
// 		error: viewError,
// 	} = useRequest(
// 		() =>
// 			getWorkitemByFilter(
// 				currentWorkspace._id,
// 				UserSavedFilterValue,
// 				userProfileStoreInstance
// 			),
// 		{
// 			manual: true,

// 			onSuccess: (result) => {
// 				message.success(`Work items fetched successfully`);
// 			},
// 			onError: (error) => {
// 				message.error(`Failed to update work item:", ${error}`);
// 			},
// 		}
// 	);

// 	useEffect(() => {
// 		if (workItemDatas) {
// 			const sortedResult = workItemDatas.sort(
// 				(
// 					a: { createdAt: string | number | Date },
// 					b: { createdAt: string | number | Date }
// 				) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
// 			);
// 			setTodos(sortedResult);
// 			const data = sortedResult.map(
// 				({ title, _id, type, WorkitemId }: any) => ({
// 					name: title,
// 					id: _id,
// 					type: type,
// 					typeId: WorkitemId,
// 				})
// 			);
// 			setWorkItemData(data);
// 			setLoading(false);
// 		}
// 	}, [workItemDatas]);

// 	useEffect(() => {
// 		fetchworkitembyfilter();
// 	}, [
// 		SavedFilter,
// 		open,
// 		FetchPlan,
// 		rightClickMenu,
// 		radioButtonSelected,
// 		backlogworkitemmodel,
// 	]);

// 	useEffect(() => {
// 		setViewFilter(false);
// 	}, []);

// 	// useEffect(() => {
// 	// 	fetchTodos();
// 	// 	// const interval = setInterval(fetchTodos, 2000);
// 	// 	// return () => clearInterval(interval);
// 	// }, [
// 	// 	open,
// 	// 	FetchPlan,
// 	// 	rightClickMenu,
// 	// 	radioButtonSelected,
// 	// 	backlogworkitemmodel,
// 	// ]);

// 	useEffect(() => {
// 		setTimeout(() => {
// 			if (radioButtonSelected != "MyTasks") {
// 				fetchworkitembyfilter();
// 			}
// 		}, 1000);
// 	}, [url]);

// 	useEffect(() => {
// 		setModalVisible(backlogworkitemmodel);
// 	}, [backlogworkitemmodel]);

// 	const handleModalCancel = () => {
// 		setModalVisible(false);
// 		setbacklogworkitemmodel(false);
// 	};

// 	const sortedAndFilteredData = (data: any) => {
// 		const filteredData = data.filter((item: any) => {
// 			let matchesFilter = true;
// 			const inputLower = input?.replace(/\s/g, "").toLowerCase();
// 			const typeFilters = filter
// 				.filter((filterItem) => filterItem.startsWith("Type"))
// 				.map((filterItem) => {
// 					const typeSplit = filterItem.split("_");
// 					const type = typeSplit.shift();
// 					const rest = typeSplit.join("_");
// 					return rest.toLowerCase();
// 				});
// 			const statusFilters = filter
// 				.filter((filterItem) => filterItem.startsWith("Status"))
// 				.map((filterItem) => {
// 					const typeSplit = filterItem.split("_");
// 					const type = typeSplit.shift();
// 					const rest = typeSplit.join("_");
// 					return rest.toLowerCase();
// 				});
// 			const priorityFilters = filter
// 				.filter((filterItem) => filterItem.startsWith("Priority"))
// 				.map((filterItem) => filterItem.split("_")[1].toLowerCase());

// 			matchesFilter =
// 				matchesFilter &&
// 				(item?.title?.replace(/\s/g, "").toLowerCase().includes(inputLower) ||
// 					item?.description
// 						?.replace(/\s/g, "")
// 						.toLowerCase()
// 						.includes(inputLower) ||
// 					item?.WorkitemId?.replace(/\s/g, "")
// 						.toLowerCase()
// 						.includes(inputLower));

// 			if (typeFilters.length > 0) {
// 				matchesFilter =
// 					matchesFilter &&
// 					typeFilters.some(
// 						(filterValue) => item?.type?.toLowerCase() === filterValue
// 					);
// 			}

// 			if (statusFilters.length > 0) {
// 				matchesFilter =
// 					matchesFilter &&
// 					statusFilters.some(
// 						(filterValue) =>
// 							item?.status?.toLowerCase().replace(/_/g, " ") ===
// 							filterValue.replace(/_/g, " ")
// 					);
// 			}

// 			if (priorityFilters.length > 0) {
// 				matchesFilter =
// 					matchesFilter &&
// 					priorityFilters.some(
// 						(filterValue) => item?.priority?.toLowerCase() === filterValue
// 					);
// 			}

// 			return matchesFilter;
// 		});

// 		const sortedAndFilteredData = [...filteredData].sort((a, b) => {
// 			if (sortBy === "Created date (Asc)") {
// 				return (
// 					new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
// 				);
// 			} else if (sortBy === "Created date (Desc)") {
// 				return (
// 					new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
// 				);
// 			} else {
// 				return 0;
// 			}
// 		});

// 		return sortedAndFilteredData;
// 	};

// 	return (
// 		<Can I="view" a="backlog" passThrough>
// 			{(allowed) =>
// 				allowed ? (
// 					<Layout style={{ background: "#f5f8fe" }}>
// 						{loading ? ( // Show loading indicator while loading
// 							<div
// 								style={{
// 									display: "flex",
// 									flexDirection: "row",
// 									alignItems: "center",
// 									justifyContent: "center",
// 									minHeight: "100vh",
// 								}}
// 							>
// 								<Spin size="large" />
// 								<img
// 									style={{ marginTop: "10px", width: "150px" }}
// 									src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
// 									alt="Loading"
// 								/>
// 								<Spin size="large" />
// 							</div>
// 						) : (
// 							<>
// 								<Divider />
// 								<ViewPageNav />
// 								<Layout style={{ background: "#f5f8fe" }}>
// 									{/* <Content
// 										style={{ backgroundColor: "#f5f8fe", overflowY: "auto" }}
// 									>
// 									 <div
// 											style={{
// 												overflow: open ? "hidden" : "scroll",
// 												position: "relative",
// 											}}
// 										> */}
// 									{/* <Flex
// 												justify="center"
// 												style={{
// 													backgroundColor: "#f5f8fe",
// 													// margin: "1rem",
// 													marginRight: "-0.8rem",
// 													// overflowX: "scroll",
// 													//overflowY: "scroll",
// 													// overflow: open ? "hidden" : "scroll",
// 													//height: "68vh",
// 													maxWidth: "100vw",
// 													display: "block",
// 													position: "relative",
// 												}}
// 												className="WorkspaceDiv"
// 											>  */}
// 									<div
// 										style={{
// 											backgroundColor: "#f5f8fe",
// 											marginLeft: "1rem",
// 											marginRight: "-0.4rem",
// 											maxWidth: "100vw",
// 											display: "block",
// 											// position: "relative",
// 										}}
// 									>
// 										{isCardView && (
// 											<KanbanBoard
// 												Data={sortedAndFilteredData(todos)}
// 												selectedGroupBy={selectedGroupBy}
// 												RowBy={RowBy}
// 												CardOrderning={CardOrderning}
// 											></KanbanBoard>
// 										)}
// 										{ListView && (
// 											<BacklogListViewItem
// 												Data={sortedAndFilteredData(todos)}
// 											></BacklogListViewItem>
// 										)}
// 										{DocsView && (
// 											<BacklogDocsView
// 												Data={sortedAndFilteredData(todos)}
// 											></BacklogDocsView>
// 										)}
// 									</div>
// 									{/*</Flex>
// 										 </div>
// 									</Content> */}
// 								</Layout>
// 								<WorkItemModal
// 									visible={modalVisible}
// 									onCancel={handleModalCancel}
// 									data={workItemData}
// 									screen={"backlog"}
// 								/>
// 							</>
// 						)}
// 					</Layout>
// 				) : (
// 					<Flex style={{ marginTop: "15vh" }} justify="center">
// 						<Image
// 							height={"50vh"}
// 							width={"60vw"}
// 							src={
// 								"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_security_access.svg"
// 							}
// 							preview={false}
// 						/>
// 					</Flex>
// 				)
// 			}
// 		</Can>
// 	);
// };

// export default ViewPage;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { Layout, Flex, Spin, Image } from "antd";
import WorkItemModal from "../Planner/AddWorkitemModel";

import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";

import { useViewsStores } from "../../stores/BacklogStore";

import KanbanBoard from "../Planner/CardView";

import BacklogNav from "../Planner/BacklogNav";
import useWorkspaceStore from "../../stores/WorkspaceState";
//import BacklogListViewItem from "./BacklogListView";
import BacklogListViewItemV2 from "../Planner/BacklogListViewV2";
import { planerViewStore } from "../../stores/PlannerStore";
import { useLocation } from "react-router-dom";
import { Can } from "../../layout/AppLayout";
import BacklogDocsView from "../Planner/BacklogDocsView";
import { useRequest } from "ahooks";
import { getWorkitemByFilter } from "../../api/WorkItemAPI";
import ViewPageNav from "./ViewPageNavbar";
import { useViewsFilterStores } from "../../stores/ViewsFilter";
import usePageStore from "../../stores/PageStore";

const { Content } = Layout;

const ViewPage: React.FC = () => {
	// const [loading, setLoading] = useState(true);
	const {
		isCardView,
		open,
		ListView,
		FetchPlan,
		backlogworkitemmodel,
		setbacklogworkitemmodel,
		DocsView,
		setViewFilter,
		ViewFilter,
		selectedGroupBy,
		RowBy,
		CardOrderning,
		UserSavedFilterValue,
		SavedFilter,
		input,
		filter,
		ListSelectedGroupBy,
		setDrawerTab,
	} = useViewsFilterStores();

	const url = useLocation().pathname;

	const { radioButtonSelected, sortBy, rightClickMenu, isEditModalOpen } =
		planerViewStore();
	const [loading, setLoading] = useState(true);

	const [modalVisible, setModalVisible] = useState(false);
	const [todos, setTodos] = useState<any[]>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [workItemData, setWorkItemData] = useState<any[]>([]);
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
	}));

	useEffect(() => {
		setPageSlug("view_filter");
		setPageTitle("View Filter");
		setPageDescription("");
		setItemType();
		setItemId("");
		setIsList(false);
		setItemDetails({});
		setBreadcrumbConfig({
			page: `/space/${currentWorkspace._id}/planner/filterview`,
			config: [
				{
					label: "Views",
					url: `/space/${currentWorkspace._id}/planner/filterview`,
				},
			],
		});
	}, []);

	const { data: backLogData, run: fetchtodos } = useRequest(
		() =>
			getWorkitemByFilter(
				currentWorkspace._id,
				UserSavedFilterValue,
				userProfileStoreInstance
			),
		{
			manual: true,
		}
	);

	useEffect(() => {
		if (backLogData) {
			const sortedResult = backLogData.sort(
				(
					a: { createdAt: string | number | Date },
					b: { createdAt: string | number | Date }
				) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
			);
			setTodos(sortedResult);
			const data = sortedResult.map(
				({ title, _id, type, WorkitemId }: any) => ({
					name: title,
					id: _id,
					type: type,
					typeId: WorkitemId,
				})
			);
			setWorkItemData(data);
			setLoading(false);
		}
	}, [backLogData]);

	useEffect(() => {
		setViewFilter(false);
	}, []);

	useEffect(() => {
		fetchtodos();
	}, [
		SavedFilter,
		open,
		FetchPlan,
		rightClickMenu,
		radioButtonSelected,
		backlogworkitemmodel,
	]);

	useEffect(() => {
		setTimeout(() => {
			if (radioButtonSelected != "MyTasks") {
				fetchtodos();
			}
		}, 1000);
	}, [url]);

	useEffect(() => {
		setModalVisible(backlogworkitemmodel);
	}, [backlogworkitemmodel]);

	const handleModalCancel = () => {
		setModalVisible(false);
		setbacklogworkitemmodel(false);
	};

	const sortedAndFilteredData = (data: any) => {
		const filteredData = data.filter((item: any) => {
			let matchesFilter = true;
			const inputLower = input?.replace(/\s/g, "").toLowerCase();
			const typeFilters = filter
				.filter((filterItem) => filterItem.startsWith("Type"))
				.map((filterItem) => {
					const typeSplit = filterItem.split("_");
					const type = typeSplit.shift();
					const rest = typeSplit.join("_");
					return rest.toLowerCase();
				});
			const statusFilters = filter
				.filter((filterItem) => filterItem.startsWith("Status"))
				.map((filterItem) => {
					const typeSplit = filterItem.split("_");
					const type = typeSplit.shift();
					const rest = typeSplit.join("_");
					return rest.toLowerCase();
				});
			const priorityFilters = filter
				.filter((filterItem) => filterItem.startsWith("Priority"))
				.map((filterItem) => filterItem.split("_")[1].toLowerCase());

			matchesFilter =
				matchesFilter &&
				(item?.title?.replace(/\s/g, "").toLowerCase().includes(inputLower) ||
					item?.description
						?.replace(/\s/g, "")
						.toLowerCase()
						.includes(inputLower) ||
					item?.WorkitemId?.replace(/\s/g, "")
						.toLowerCase()
						.includes(inputLower));

			if (typeFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					typeFilters.some(
						(filterValue) => item?.type?.toLowerCase() === filterValue
					);
			}

			if (statusFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					statusFilters.some(
						(filterValue) =>
							item?.status?.toLowerCase().replace(/_/g, " ") ===
							filterValue.replace(/_/g, " ")
					);
			}

			if (priorityFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					priorityFilters.some(
						(filterValue) => item?.priority?.toLowerCase() === filterValue
					);
			}

			return matchesFilter;
		});

		const sortedAndFilteredData = [...filteredData].sort((a, b) => {
			if (sortBy === "Created date (Asc)") {
				return (
					new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
				);
			} else if (sortBy === "Created date (Desc)") {
				return (
					new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				);
			} else {
				return 0;
			}
		});

		return sortedAndFilteredData;
	};

	return (
		<Can I="view" a="backlog" passThrough>
			{(allowed) =>
				allowed ? (
					<Layout style={{ background: "#f5f8fe" }}>
						{loading ? ( // Show loading indicator while loading
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
									minHeight: "100vh",
								}}
							>
								<Spin size="large" />
								<img
									style={{ marginTop: "10px", width: "150px" }}
									src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
									alt="Loading"
								/>
								<Spin size="large" />
							</div>
						) : (
							<>
								{/* <Divider  style={{marginTop: "0px"}}/> */}
								<ViewPageNav />
								<Layout style={{ background: "#f5f8fe" }}>
									{/* <Content
										style={{ backgroundColor: "#f5f8fe", overflowY: "auto" }}
									> 
									 <div
											style={{
												overflow: open ? "hidden" : "scroll",
												position: "relative",
											}}
										> */}
									{/* <Flex
												justify="center"
												style={{
													backgroundColor: "#f5f8fe",
													// margin: "1rem",
													marginRight: "-0.8rem",
													// overflowX: "scroll",
													//overflowY: "scroll",
													// overflow: open ? "hidden" : "scroll",
													//height: "68vh",
													maxWidth: "100vw",
													display: "block",
													position: "relative",
												}}
												className="WorkspaceDiv"
											>  */}
									<div
										style={{
											backgroundColor: "#f5f8fe",
											marginLeft: "1rem",
											marginRight: "-0.4rem",
											maxWidth: "100vw",
											display: "block",
											// position: "relative",
										}}
									>
										{isCardView && (
											<KanbanBoard
												Data={sortedAndFilteredData(todos)}
												selectedGroupBy={selectedGroupBy}
												RowBy={RowBy}
												CardOrderning={CardOrderning}
											></KanbanBoard>
										)}
										{/* {ListView && (
											<BacklogListViewItem
												Data={sortedAndFilteredData(todos)}
											></BacklogListViewItem>
										)} */}

										{ListView && (
											<BacklogListViewItemV2
												ListSelectedGroupBy={ListSelectedGroupBy}
												setDrawerTab={setDrawerTab}
												Data={sortedAndFilteredData(todos)}
											></BacklogListViewItemV2>
										)}
										{DocsView && (
											<BacklogDocsView
												Data={sortedAndFilteredData(todos)}
											></BacklogDocsView>
										)}
									</div>
									{/*</Flex>
										 </div> 
									</Content> */}
								</Layout>
								<WorkItemModal
									visible={modalVisible}
									onCancel={handleModalCancel}
									data={workItemData}
									screen={"backlog"}
								/>
							</>
						)}
					</Layout>
				) : (
					<Flex style={{ marginTop: "15vh" }} justify="center">
						<Image
							height={"50vh"}
							width={"60vw"}
							src={
								"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_security_access.svg"
							}
							preview={false}
						/>
					</Flex>
				)
			}
		</Can>
	);
};

export default ViewPage;
