import { Form, Input } from "antd";
import React from "react";

interface GithubConnectionDetailsProps {
	connectionData: any;
	setConnectionData: (connectionData: any) => void;
}

const GithubConnectionDetails: React.FC<GithubConnectionDetailsProps> = (
	props
) => {
	return (
		<>
			<Form.Item
				label="AccessToken"
				name={["connectionData", "accessToken"]}
				required
				style={{ width: "100%", marginBottom: 10 }}
				// labelCol={{ span: 8 }}
				// wrapperCol={{ span: 16 }}
			>
				<Input
					// style={{ marginRight: "7px" }}
					style={{ width: "100%", fontSize: "14px", height: "32px" }}
					placeholder="AccessToken"
					value={props.connectionData.accessToken}
					onChange={(e) =>
						props.setConnectionData({
							...props.connectionData,
							accessToken: e.target.value,
						})
					}
				/>
			</Form.Item>
			<Form.Item
				label="RepoName"
				name={["connectionData", "baseUrl"]}
				required
				style={{ width: "100%", marginBottom: 10 }}
				// labelCol={{ span: 8 }}
				// wrapperCol={{ span: 16 }}
			>
				<Input
					// style={{ marginRight: "7px" }}
					style={{ width: "100%", fontSize: "14px", height: "32px" }}
					placeholder="RepoName"
					value={props.connectionData.RepoName}
					onChange={(e) =>
						props.setConnectionData({
							...props.connectionData,
							RepoName: e.target.value,
						})
					}
				/>
			</Form.Item>
			<Form.Item
				label="userName"
				name={["connectionData", "userName"]}
				required
				style={{ width: "100%", marginBottom: 10 }}
				// labelCol={{ span: 8 }}
				// wrapperCol={{ span: 16 }}
			>
				<Input
					// style={{ marginRight: "7px" }}
					style={{ width: "100%", fontSize: "14px", height: "32px" }}
					placeholder="userName"
					value={props.connectionData.userName}
					onChange={(e) =>
						props.setConnectionData({
							...props.connectionData,
							userName: e.target.value,
						})
					}
				/>
			</Form.Item>
		</>
	);
};

export default GithubConnectionDetails;
