import React, { useEffect, useState } from "react";
import userProfileStore from "../stores/UserProfileStore";
import { Form, Input, Row, Upload, message } from "antd";
import "./UserProfileDetails.css";
//import Title from "antd/es/typography/Title";
import usePlatformStore from "../stores/platformStore";
import { PlusOutlined } from "@ant-design/icons";
import { BlobServiceClient } from "@azure/storage-blob";
import ProfileManagementNav from "../pages/Settings/ProfileManagenentNav";

const UserProfileDetails: React.FC = () => {
	const [profileDetails, setProfileDetails] = useState<any>(null);
	//const [file, setFile] = useState<any>(null);

	const { profile, idToken, profileId } = userProfileStore((state: any) => ({
		profile: state.profile,
		idToken: state.idToken,
		profileId: state.profile._id,
	}));
	// const userProfileStoreInstance: any = userProfileStore();

	const account = process.env.REACT_APP_FILE_UPLOAD_STORAGE_ACCOUNT; // get the storage account name from the .env file
	const sasToken = process.env.REACT_APP_FILE_UPLOAD_STORAGE_SAS_TOKEN; // get the SAS token from the .env file
	const containerName: any =
		process.env.REACT_APP_FILE_UPLOAD_STORAGE_CONTAINER; // get the container name from the .env file
	const blobServiceClient = new BlobServiceClient(
		`https://${account}.blob.core.windows.net/?${sasToken}`
	); // create a blobServiceClient
	const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));

	console.log("profiledetail", profileDetails);

	// const handleSubmit = async (e: any) => {
	// 	e.preventDefault();
	// console.log("file-handleSubmit", file);
	// if (!file) {
	// 	// check if the file is selected
	// 	alert("Please select an image to upload");
	// 	return;
	// }
	// console.log(
	// 	"acccount",
	// 	account,
	// 	"sastoken",
	// 	sasToken,
	// 	"containername",
	// 	containerName
	// );
	// if (!account || !sasToken || !containerName) {
	// check if the credentials are set
	// 	alert(
	// 		"Please make sure you have set the Azure Storage credentials in the .env file"
	// 	);
	// 	return;
	// }
	// try {
	//   setLoading(true);
	// const blobName = `${new Date().getTime()}-${profileDetails._id}.jpeg`; // Specify a default blob name if needed
	// const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
	// await blobClient.uploadData(file, {
	// 	blobHTTPHeaders: { blobContentType: file.type },
	// }); // upload the image
	// //   await fetchImages();   // fetch all images again after the upload is completed
	// const newdata = {
	// 	...profileDetails,
	// 	profileImage: `https://${account}.blob.core.windows.net/${containerName}/${blobName}`,
	// };
	// console.log("newdata", newdata);
	// fetch(
	// 	`${process.env.REACT_APP_API_URL}/user-profiles/${profileDetails._id}`,
	// 	{
	// 		method: "PUT",
	// 		headers: {
	// 			"Content-Type": "application/json; charset=UTF-8",
	// 			Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 		},
	// 		body: JSON.stringify(newdata),
	// 	}
	// )
	// 	.then((response) => response.json())
	// 	.then((data) => {
	// 		console.log(data);
	// 	})
	// 	.catch((error) => {
	// 		console.error("Error:", error);
	// 	});
	// 	} catch (error) {
	// 		console.error(error); // Handle error
	// 	}
	// };

	useEffect(() => {
		// Fetch user profile details by profileId
		console.log(profile);
		console.log(profileId);
		console.log("containerClient", containerClient);
		const fetchProfileDetails = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/user-profiles/${profileId}`,
					{
						headers: {
							Authorization: `Bearer ${idToken}`,
						},
					}
				);
				const data = await response.json();
				if (!response.ok) {
					const result = await response.json();
					if (response.status === 401) {
						setUnauthorizedModel(true);
					}
					throw new Error(
						`status: ${response.status} message: ${result.message}`
					);
				}

				setProfileDetails(data);
			} catch (error) {
				message.error(`Eroor: ${error}`);
				console.error("Error fetching profile details:", error);
			}
		};

		fetchProfileDetails();
	}, [profile]);

	const normFile = (e: any) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e?.fileList;
	};

	return (
		<>
			<ProfileManagementNav />
			<Row
				justify="start"
				align="top"
				style={{ backgroundColor: "white", width: "100%", height: "85vh" }}
			>
				{profileDetails && (
					// <Form  style={{ padding: '20px', border: '0.2px solid #d9d9d9', borderRadius: '0.2px' }}>
					<Form className="formStyle">
						{/* <Title style={{ textAlign: "left" }} level={1}>
						Profile Details
					</Title> */}
						<Form.Item
							label="Name"
							style={{ width: "100%", marginLeft: "140px" }}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 18 }}
						>
							<Input
								style={{ marginLeft: "-10px" }}
								className="inputBoxStyle"
								value={profileDetails?.name}
								readOnly
							/>
						</Form.Item>
						<Form.Item
							label="Email"
							style={{ width: "100%", marginLeft: "140px" }}
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 18 }}
						>
							<Input
								style={{ marginLeft: "-10px" }}
								className="inputBoxStyle"
								value={profileDetails?.email}
								readOnly
							/>
						</Form.Item>
						<Form.Item
							label="Upload Image"
							valuePropName="fileList"
							getValueFromEvent={normFile}
							style={{ width: "100%", marginLeft: "69px" }}
							labelCol={{ span: 7 }}
							wrapperCol={{ span: 18 }}
						>
							<Upload
								style={{ marginRight: "50px" }}
								customRequest={async ({ onSuccess, file }) => {
									const blobName = `${new Date().getTime()}-${profileDetails._id}.jpeg`; // Specify a default blob name if needed
									const blobClient =
										containerClient.getBlockBlobClient(blobName); // get the blob client
									await blobClient.uploadData(file as Blob, {
										// blobHTTPHeaders: { blobContentType: "image/jpeg" },
									});
									const newdata = {
										...profileDetails,
										profileImage: `https://${account}.blob.core.windows.net/${containerName}/${blobName}`,
									};
									if (onSuccess) onSuccess("ok");
									console.log("newdata", newdata);
								}}
								listType="picture-card"
								onChange={(e: any) => {
									console.log("e", e);
									if (e.file.status === "done") {
										// setFile(e.file.originFileObj);
									} else if (e.file.status === "error") {
										message.error("Failed to upload image.");
									}
								}}
							>
								<button style={{ border: 0, background: "none" }} type="button">
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</button>
							</Upload>

							{/* <Button type="default" onClick={handleSubmit} >
							Submit
						</Button> */}
						</Form.Item>
					</Form>
				)}
			</Row>
		</>
	);
};

export default UserProfileDetails;
