/* eslint-disable @typescript-eslint/no-unused-vars */
// WorkItemModal.tsx
import React, { useEffect, useState } from "react";
import {
	Modal,
	Form,
	Input as AntInput,
	Button,
	Flex,
	message,
	Radio,
	AutoComplete,
	Input,
	Tag,
	Popover,
	Menu,
	// Checkbox,
} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import { useViewsStores } from "../../stores/BacklogStore";
import Constants from "../../util/ConstantsUtil";
import { BlockNoteView } from "@blocknote/mantine";
import { useCreateBlockNote } from "@blocknote/react";
import {
	ExpandOutlined,
	FormOutlined,
	FullscreenExitOutlined,
	SearchOutlined,
	ShrinkOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";

interface WorkItemModalProps {
	visible: boolean;
	onCancel: () => void;
	data: any[];
	screen: string;
}

{
	/*const PriorityValues = [
	{
		label: "BASIC NEEDS",
		key: "BASIC NEEDS",
	},
	{
		label: "PERFORMANCE NEEDS",
		key: "PERFORMANCE NEEDS",
	},
	{
		label: "EXCITEMENT NEEDS",
		key: "EXCITEMENT NEEDS",
	},
]; */
}

const PriorityValues = [
	{
		label: Constants.URGENT,
		key: Constants.CAPS_URGENT,
	},
	{
		label: Constants.IMPORTANT,
		key: Constants.CAPS_IMPORTANT,
	},
	{
		label: Constants.MEDIUM,
		key: Constants.CAPS_MEDIUM,
	},
	{
		label: Constants.LOW,
		key: Constants.CAPS_LOW,
	},
];

const WorkItemModal: React.FC<WorkItemModalProps> = ({
	visible,
	onCancel,
	data,
	screen,
}) => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [form] = Form.useForm();
	const [optionvalue, setoptionvalue] = useState<any[]>(data);
	const [parent, setparent] = useState<any>({});
	const [IncreaseHeight, setIncreaseHeight] = useState(false);
	const { setFetchPlan, FetchPlan } = planerViewStore();
	const [filterpriority, setfilterpriority] = useState(PriorityValues);
	const messageApi = message;
	const workspaceId = useParams().workspaceId;
	const [html, setHTML] = useState<string>("");
	const [workitemTypes, setWorkitemTypes] = useState([]);
	const [typeSearch, setTypeSearch] = useState("");
	const [prioritySearch, setPrioritySearch] = useState("");
	const [parentSearch, setParentSearch] = useState("");
	const [popoverVisible, setPopoverVisible] = useState({
		type: false,
		priority: false,
		parent: false,
	});
	const [popovername, setPopovername] = useState({
		type: "",
		priority: "",
		parent: "",
	});

	const { backlogworkitemmodel, setbacklogworkitemmodel } = useViewsStores();
	const success = (type: string) => {
		if (!type) {
			messageApi.success(`New Task created successfully`);
		} else {
			messageApi.success(`New ${type} created successfully`);
		}
	};

	const onFinish = async () => {
		try {
			const formData = await form.validateFields();
			const workItemData = {
				type: screen === "backlog" ? formData.type : "TASK",
				title: formData.title,
				blockData: editor.document,
				description: html,
				parentObject: parent,
				status: "OPEN",
				workspaceId: workspaceId,
				priority: formData.Priority,
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems`,
				{
					method: "POST",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(workItemData),
				}
			);

			if (response.ok) {
				console.log("Work item added successfully");
				onCancel();
				setFetchPlan(!FetchPlan);
				success(formData.type);
				setbacklogworkitemmodel(!backlogworkitemmodel);
				form.resetFields();
			} else {
				console.error("Failed to add work item");
			}
		} catch (error) {
			console.error("Error adding work item:", error);
		}
	};

	const formItemLayout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 14 },
	};

	const onSelect = (option: any) => {
		setPopovername((prev) => ({
			...prev,
			parent: ": " + option.name,
		}));
		setparent({ type: option.type, id: option.id });
		form.setFieldsValue({ parent: option.name }); // Set name as the value
	};

	const fetchWorkitemsTypeData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/propertiesconfig/slug/${workspaceId}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						slug: "workitem-types",
					},
				}
			);

			if (response.ok) {
				const data: any = await response.json();
				if (Array.isArray(data)) {
					const extracTypes: any = data[0]?.values?.map(
						(type: any) => type.value
					);
					setWorkitemTypes(extracTypes);
					// console.log("Fetched data for workitem Type:", extracTypes);
					// setResponseData(data);
				} else {
					console.error("Fetched data is not an array");
				}
			} else {
				console.error("Error fetching prefixes data");
			}
		} catch (error) {
			console.error("Error fetching prefixes data:", error);
		}
	};

	useEffect(() => {
		setoptionvalue(data);
	}, [data]);

	useEffect(() => {
		if (workspaceId) {
			fetchWorkitemsTypeData();
		}
	}, [workspaceId]);

	const editor = useCreateBlockNote();

	const onChange = async () => {
		// Converts the editor's contents from Block objects to HTML and store to state.
		const html = await editor.blocksToHTMLLossy(editor.document);
		setHTML(html);
	};

	const selectedTypevalue = (data: any) => {
		const inputLower = typeSearch?.replace(/\s/g, "").toLowerCase();
		const filteredData = data?.filter((item: any) => {
			return item?.replace(/\s/g, "").toLowerCase().includes(inputLower);
		});
		return filteredData;
	};

	const setTypeSearchvalue = (e: any) => {
		const value = e.target.value;
		if (value.trim() !== "") {
			setTypeSearch(value);
		} else {
			setTypeSearch("  ");
		}
	};

	const setPrioritySearchvalue = (e: any) => {
		const value = e.target.value;
		if (value.trim() !== "") {
			setPrioritySearch(value);
		} else {
			setPrioritySearch("  ");
		}
	};

	const selectedPriorityvalue = (data: any) => {
		const inputLower = prioritySearch?.replace(/\s/g, "").toLowerCase();
		const filteredData = data.filter((item: any) => {
			return item.key?.replace(/\s/g, "").toLowerCase().includes(inputLower);
		});
		return filteredData;
	};

	const setParentSearchvalue = (e: any) => {
		const value = e.target.value;
		if (value.trim() !== "") {
			setParentSearch(value);
		} else {
			setParentSearch("  ");
		}
	};

	const selectedParentvalue = (data: any) => {
		const inputLower = parentSearch?.replace(/\s/g, "").toLowerCase();
		const filteredData = data.filter((item: any) => {
			return item.name?.replace(/\s/g, "").toLowerCase().includes(inputLower);
		});
		return filteredData;
	};

	return (
		<Modal
			title={
				<Flex justify="space-between">
					Create Work Item
					{IncreaseHeight ? (
						<FullscreenExitOutlined
							style={{ margin: "-0.5rem 2rem 0rem 0rem" }}
							onClick={() => setIncreaseHeight(false)}
						/>
					) : (
						<ExpandOutlined
							style={{ margin: "-0.5rem 2rem 0rem 0rem" }}
							onClick={() => setIncreaseHeight(true)}
						/>
					)}
				</Flex>
			}
			open={visible}
			onCancel={onCancel}
			footer={[
				<Button key="back" onClick={onCancel}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={onFinish}>
					Save
				</Button>,
			]}
			width={800}
		>
			<Form {...formItemLayout} form={form}>
				<Form.Item
					// label="Title"
					name="title"
					rules={[{ required: true, message: "Please enter a title" }]}
				>
					<AntInput
						style={{ borderRadius: "0.25rem" }}
						placeholder="Work Item title"
						bordered={false}
					/>
				</Form.Item>
				<Form.Item
					style={{ height: IncreaseHeight ? "45vh" : "25vh" }}
					// style={{width:'30vw',height:'50vh'}}
					// label="Description"
					name="description"
				>
					{/* <ReactQuill style={{ borderRadius: "0.25rem", height: "20vh" }} /> */}
					<BlockNoteView editor={editor} theme="light" onChange={onChange} />
				</Form.Item>

				<Flex style={{ flexDirection: "row" }}>
					{screen === "backlog" && (
						<Form.Item
							name="type"
							style={{ width: "auto" }}
							rules={[{ required: true, message: "Please select a type" }]}
						>
							<Flex gap={10} style={{ justifyContent: "flex-start" }}>
								<Popover
									title={<></>}
									placement="topLeft"
									trigger={"click"}
									open={popoverVisible.type}
									onOpenChange={() =>
										setPopoverVisible((prev) => ({
											...prev,
											type: !popoverVisible.type,
										}))
									}
									content={
										<>
											<Input
												placeholder="Search here..."
												onChange={setTypeSearchvalue}
												value={typeSearch}
												allowClear
												suffix={<SearchOutlined />}
												key={"search"}
											/>
											<Menu
												style={{
													// width: "20vw",
													height: "40vh",
													overflow: "scroll",
												}}
											>
												<Radio.Group
													onChange={(e) => {
														setPopoverVisible((prev) => ({
															...prev,
															type: false,
														}));
														setPopovername((prev) => ({
															...prev,
															type: ": " + e.target.value,
														}));
													}}
												>
													{selectedTypevalue(workitemTypes)?.map(
														(item: any, index: number) => (
															<Menu.Item
																key={item.key + index}
																className="innermenu"
																style={{ alignContent: "center" }}
															>
																<Radio key={item} value={item}>
																	{item}
																</Radio>
															</Menu.Item>
														)
													)}
												</Radio.Group>
											</Menu>
										</>
									}
								>
									<Flex justify="center">
										<Tag style={{ cursor: "pointer" }}>
											{" "}
											Type {popovername.type}
										</Tag>
									</Flex>
								</Popover>
							</Flex>
						</Form.Item>
					)}

					<Form.Item
						// label="Priority"
						style={{ width: "auto" }}
						name="Priority"
						rules={[{ required: true, message: "Please enter a priority" }]}
					>
						<Flex gap={10} style={{ justifyContent: "flex-start" }}>
							<Popover
								title={<></>}
								placement="topLeft"
								trigger={"click"}
								open={popoverVisible.priority}
								onOpenChange={() =>
									setPopoverVisible((prev) => ({
										...prev,
										priority: !popoverVisible.priority,
									}))
								}
								content={
									<>
										<Input
											placeholder="Search here..."
											onChange={setPrioritySearchvalue}
											value={prioritySearch}
											allowClear
											suffix={<SearchOutlined />}
											key={"search"}
										/>
										<Menu
											style={{
												width: "20vw",
												height: "40vh",
												overflow: "scroll",
											}}
										>
											<Radio.Group
												onChange={(e) => {
													setPopoverVisible((prev) => ({
														...prev,
														priority: false,
													}));
													setPopovername((prev) => ({
														...prev,
														priority: ": " + e.target.value,
													}));
												}}
											>
												{selectedPriorityvalue(filterpriority).map(
													(item: any, index: number) => (
														<Menu.Item
															key={item.key + index}
															className="innermenu"
															style={{ alignContent: "center" }}
														>
															<Radio key={item} value={item.key}>
																{item.key}
															</Radio>
														</Menu.Item>
													)
												)}
											</Radio.Group>
										</Menu>
									</>
								}
							>
								<Flex justify="center">
									<Tag style={{ cursor: "pointer" }}>
										Priority {popovername.priority}
									</Tag>
								</Flex>
							</Popover>
						</Flex>
					</Form.Item>

					<Form.Item
						// label="Parent"
						style={{ width: "auto" }}
						// rules={[
						// 	{
						// 		required: optionvalue.length > 0,
						// 		message: "Please enter a parent",
						// 	},
						// ]}
						name="parent"
					>
						<Flex gap={10} style={{ justifyContent: "flex-start" }}>
							<Popover
								title={null}
								placement="topLeft"
								trigger={"click"}
								open={popoverVisible.parent}
								onOpenChange={() =>
									setPopoverVisible((prev) => ({
										...prev,
										parent: !popoverVisible.parent,
									}))
								}
								content={
									<>
										<Input
											placeholder="Search here..."
											onChange={setParentSearchvalue}
											value={parentSearch}
											allowClear
											suffix={<SearchOutlined />}
											key={"search"}
										/>
										<Menu
											style={{
												width: "30vw",
												height: "40vh",
												overflow: "scroll",
											}}
										>
											<Radio.Group
												onChange={() => {
													setPopoverVisible((prev) => ({
														...prev,
														parent: false,
													}));
												}}
											>
												{selectedParentvalue(optionvalue)
													?.filter((item: any) => item.type !== "task")
													.map((item: any) => {
														let tagColor = "blue";
														if (item.type === "FEATURE") {
															tagColor = "yellow";
														} else if (item.type === "USER STORY") {
															tagColor = "pink";
														} else if (item.type === "BUG") {
															tagColor = "red";
														}
														return (
															<Menu.Item key={item.key} className="innermenu">
																<Radio
																	key={item.name}
																	value={item.id}
																	onClick={() => onSelect(item)}
																>
																	<div
																		style={{
																			display: "flex",
																			justifyContent: "space-between",
																			width: "25vw",
																		}}
																	>
																		<div>
																			<span>{item.name}</span>
																		</div>
																		<div>
																			<Tag
																				color={tagColor}
																				style={{ fontSize: "0.6rem" }}
																			>
																				{item.type}
																			</Tag>
																		</div>
																	</div>
																</Radio>
															</Menu.Item>
														);
													})}
											</Radio.Group>
										</Menu>
									</>
								}
							>
								<Flex justify="center">
									<Tag style={{ cursor: "pointer" }}>
										Parent {popovername.parent}
									</Tag>
								</Flex>
							</Popover>
						</Flex>
						{/* <AutoComplete
						// popupMatchSelectWidth={252}
						style={{
							//width: 300,
							borderRadius: "0.25rem",
						}}
						options={
							optionvalue
							?.filter((item) => item.type !== "task")
							.map((item) => {
								let tagColor = "blue";
								if (item.type === "FEATURE") {
									tagColor = "yellow";
								} else if (item.type === "USER STORY") {
									tagColor = "pink";
								} else if (item.type === "BUG") {
									tagColor = "red";
								}
								return {
									label: (
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<span>{item.name}</span>
											<Tag color={tagColor} style={{ fontSize: "0.6rem" }}>
												{item.type}
											</Tag>
										</div>
									),
									value: item.id,
									type: item.type,
									id: item.id,
									name: item.name,
								};
							})
						}
						onSelect={onSelect}
						onSearch={handleSearch}
						size="large"
						value={form.getFieldValue("parent")}
					>
						<Input
							size="large"
							placeholder="Parent Work Item"
							style={{ borderRadius: "0.25rem" }}
						/>
					</AutoComplete> */}
					</Form.Item>
				</Flex>
			</Form>
		</Modal>
	);
};

export default WorkItemModal;
