// WorkItemModal.tsx
import React, { useEffect, useState } from "react";
import {
	Modal,
	Form,
	Input as AntInput,
	Button,
	DatePicker,
	Select,
	Space,
	TimePicker,
} from "antd";
//import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { planerViewStore } from "../../../stores/PlannerStore";
import RepeatingMeetingForm from "./RepeatingSession";
import { Moment } from "moment";
import { useRitualStore } from "./RitualStore";
import { useParams } from "react-router-dom";

interface WorkItemModalProps {
	visible: boolean;
	onCancel: () => void;
}
const { Option } = Select;

const RitualCeration: React.FC<WorkItemModalProps> = ({
	visible,
	onCancel,
}) => {
	const [form] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [open, setOpen] = useState(false);
	const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(
		null
	);

	const { setFetchPlan, FetchPlan } = planerViewStore();
	const [tags, setTags] = useState<any[]>([]);
	const [plans, setPlans] = useState<any[]>([]);
	const workspaceId = useParams().workspaceId;

	const {
		startDate,
		endDate,
		timeInterval,
		reoccurringRitual,
		// days,
		repetEvery,
		setReoccurringRitual,
	} = useRitualStore();

	const onFinish = async () => {
		try {
			if (reoccurringRitual === false) {
				const formData = await form.validateFields();
				const startD = formData.startDate.format("YYYY-MM-DD");
				const startT = formData.startTime.format("HH:mm");

				const endD = formData.endDate.format("YYYY-MM-DD");
				const endT = formData.endTime.format("HH:mm");
				const RitualData = {
					type: formData.type,
					title: formData.title,
					description: formData.goals,
					startDate: new Date(`${startD}T${startT}:00.000Z`).toISOString(),
					endDate: new Date(`${endD}T${endT}:00.000Z`).toISOString(),
					createdBy: userProfileStoreInstance.profile._id,
					assignee: userProfileStoreInstance.profile._id,
					teamMember: formData.teamMember,
					notes: "",
					workspaceId: workspaceId,
				};

				console.log(RitualData);

				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/ritual-session-flow-schema/not-repeated`,
					{
						method: "POST",
						headers: {
							"Content-type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify(RitualData),
					}
				);

				if (response.ok) {
					onCancel();
					setFetchPlan(!FetchPlan);
					setReoccurringRitual(false);
					form.resetFields();
				} else {
					console.error("Failed to Create Ritual Session");
				}
			} else if (reoccurringRitual === true) {
				const formData = await form.validateFields();
				const RitualData = {
					ritualSession: {
						type: formData.type,
						title: formData.title,
						description: formData.goals,
						startDate: formData.startTime.toISOString(),
						endDate: formData.endTime.toISOString(),
						createdBy: userProfileStoreInstance.profile._id,
						assignee: userProfileStoreInstance.profile._id,
						workspaceId: workspaceId,
					},
					ritualSessionConfig: {
						startDate: startDate,
						endDate: endDate,
						repeat: repetEvery,
						repetationInterval: timeInterval,
					},
					notes: "",
				};

				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/ritual-session-flow-schema/repeated`,
					{
						method: "POST",
						headers: {
							"Content-type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify(RitualData),
					}
				);

				if (response.ok) {
					onCancel();
					setFetchPlan(!FetchPlan);
					setReoccurringRitual(false);
					form.resetFields();
				} else {
					console.error("Failed to Create Ritual Session");
				}
			}
		} catch (error) {
			console.error("Error in creating ritual session:", error);
		}
	};

	// const formItemLayout = {
	// 	labelCol: { span: 6 },
	// 	wrapperCol: { span: 14 },
	// };

	const fetchPlans = async () => {
		try {
			console.log(workspaceId, "current workspace id");
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/${workspaceId}?type=All`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const todosData: any[] = await response.json();

				setPlans(todosData);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	useEffect(() => {
		if (workspaceId) {
			fetchPlans();
		}
	}, [FetchPlan, workspaceId]);
	const fetchUserProfile = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workspace-permissions/workspace?work-space-id=${workspaceId}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				setTags(data);
				console.log(data);
			} else {
				console.error("Error fetching tags data");
			}
		} catch (error) {
			console.error("Error fetching tags data:", error);
		}
	};
	useEffect(() => {
		if (workspaceId) {
			fetchUserProfile();
		}
	}, [workspaceId]);

	return (
		<Modal
			title="Create Ritual Session"
			centered
			open={visible}
			onCancel={onCancel}
			footer={[
				<Button key="back" onClick={onCancel} style={{ color: "#0444bf" }}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={onFinish}>
					Save
				</Button>,
			]}
			width={500}
			style={{ fontFamily: "Poppins" }}
		>
			{/* <Form {...formItemLayout} form={form} > */}
			<Form
				form={form}
				layout="vertical"
				labelCol={{
					style: {
						textAlign: "right",
						margin: 0,
					},
				}}
			>
				<Form.Item
					label="Session Name"
					name="title"
					rules={[{ required: true, message: "Please select a type" }]}
					style={{ width: "100%", marginBottom: 10 }}
				>
					<AntInput
						style={{ borderRadius: "0.25rem" }}
						placeholder="Enter Session Name"
					/>
				</Form.Item>
				<Form.Item
					label="Type"
					name="type"
					rules={[{ required: true, message: "Please select Ritual Type" }]}
					style={{ width: "100%", marginBottom: 10 }}
				>
					<Select
						style={{ borderRadius: "0.25rem" }}
						placeholder="Select Ritual Type"
					>
						<Option value="RITUAL">RITUAL</Option>
						<Option value="STAND_UP">STAND UP</Option>
					</Select>
				</Form.Item>
				<Form.Item label="Plan" style={{ width: "100%", marginBottom: 10 }}>
					<Select>
						{plans.map((plan) => (
							<Option key={plan._id} value={plan._id}>
								{plan.name}
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					label="Start Time"
					style={{ width: "100%", marginBottom: 10 }}
				>
					<Space>
						<Form.Item
							noStyle
							// label="Start Date"
							name="startDate"
							rules={[{ required: true, message: "Please select start date!" }]}
						>
							<DatePicker
								format="YYYY-MM-DD"
								onChange={(date: Moment | null) => setSelectedStartDate(date)}
							/>
						</Form.Item>
						<Form.Item
							noStyle
							// label="Start Time"
							name="startTime"
							rules={[{ required: true, message: "Please select start time!" }]}
						>
							<TimePicker format="HH:mm" minuteStep={15} />
						</Form.Item>
					</Space>
				</Form.Item>
				<Form.Item label="End Time" style={{ width: "100%", marginBottom: 10 }}>
					<Space>
						<Form.Item
							noStyle
							// label="Start Date"
							name="endDate"
							rules={[{ required: true, message: "Please select start date!" }]}
						>
							<DatePicker
								format="YYYY-MM-DD"
								onChange={(date: Moment | null) => setSelectedStartDate(date)}
							/>
						</Form.Item>
						<Form.Item
							noStyle
							// label="Start Time"
							name="endTime"
							rules={[{ required: true, message: "Please select start time!" }]}
						>
							<TimePicker format="HH:mm" minuteStep={15} />
						</Form.Item>
						<Button onClick={() => setOpen(true)}>Repeat</Button>
					</Space>
				</Form.Item>
				<Form.Item
					label="Team Member"
					name="teamMember"
					style={{ width: "100%", marginBottom: 10 }}
				>
					<Select
						style={{ borderRadius: "0.25rem" }}
						mode="multiple"
						placeholder="Select Team Member"
					>
						{tags.map((user) => (
							<Option key={user._id} value={user.userProfileId?._id}>
								{user.userProfileId?.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				{/* <Form.Item label="End Time">
					<Space>
						<Form.Item
							noStyle
							name="EndTime"
							rules={[{ required: true, message: "Please input!" }]}
						>
							<DatePicker
								showTime={{ format: "HH:mm", minuteStep: 15 }}
								format="YYYY-MM-DD HH:mm"
							/>
						</Form.Item>
						<Button onClick={() => setOpen(true)}>Repeat</Button>
					</Space>
				</Form.Item> */}
				<Form.Item
					style={{ width: "100%", marginBottom: 10, height: "15vh" }}
					label="Agenda"
					name="goals"
				>
					{/* <ReactQuill style={{ borderRadius: "0.25rem", height: "20vh" }} /> */}
					<AntInput.TextArea
						placeholder="Enter Agenda"
						style={{
							height: "10vh",
							width: "100%",
							overflowY: "auto",
						}}
					/>
				</Form.Item>
			</Form>
			<RepeatingMeetingForm
				visible={open}
				onCancel={() => setOpen(false)}
				startDate={selectedStartDate}
			/>
		</Modal>
	);
};

export default RitualCeration;
