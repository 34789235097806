import { useEffect, useState } from "react";
import { Avatar, ConfigProvider, Flex, Menu, Typography } from "antd";
import {
	//Link,
	useNavigate,
} from "react-router-dom";

import usePlatformStore from "../stores/platformStore";
import Constants from "../util/ConstantsUtil";
import Sider from "antd/es/layout/Sider";
import {
	ArrowLeftOutlined,
	LeftOutlined,
	RightOutlined,
	SlidersFilled,
	UserOutlined,
} from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

import { motion, useAnimation } from "framer-motion";
import useWorkspaceStore from "../stores/WorkspaceState";
function ProfileMenu() {
	const navigate = useNavigate();
	const [selectedMenuItem, setSelectedMenuItem] = useState("ProfileManagement");
	const {
		setBackNavigateUrl,
		setNextNavigateUrl,
		setAppBarTitle,
		setCurrentModule,
		setCurrentPage,
	} = usePlatformStore((state: any) => ({
		setBackNavigateUrl: state.setBackNavigateUrl,
		setNextNavigateUrl: state.setNextNavigateUrl,
		setAppBarTitle: state.setAppBarTitle,
		setCurrentModule: state.setCurrentModule,
		setCurrentPage: state.setCurrentPage,
	}));
	useEffect(() => {
		setNextNavigateUrl("");
		setBackNavigateUrl("/");
		setCurrentModule(Constants.PROFILE);
		switch (location.pathname) {
			case "/profile/details":
				setAppBarTitle(Constants.PROFILE_MANAGEMENT);
				setCurrentPage(Constants.PROFILE_MANAGEMENT);
				setSelectedMenuItem("ProfileManagement");
				break;
			case "/profile/perferences":
				setAppBarTitle(Constants.PREFERENCES);
				setCurrentPage(Constants.PREFERENCES);
				setSelectedMenuItem("Preferences");
				break;
			default:
				break;
		}
	}, [location.pathname]);

	// const handleMenuClick = (e: any) => {
	// 	setSelectedMenuItem(e.key);
	// 	if (e.key === "ProfileManagement") {
	// 		setAppBarTitle(Constants.PROFILE_MANAGEMENT);
	// 		setCurrentPage(Constants.PROFILE_MANAGEMENT);
	// 	}
	// };

	// const handleMenuClick = (info: any) => {
	// 	if (info && info.key) {
	// 		setSelectedMenuItem(info.key.toString());
	// 		localStorage.setItem("selectedMenuKey", info.key.toString());
	// 	}
	// };

	const [collapsed, setCollapsed] = useState(false);
	const screens = useBreakpoint();
	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		if (screens.lg) {
			setCollapsed(false);
		} else {
			setCollapsed(true);
		}
	}, [screens]);

	const renderArrowButton = () => {
		if (!screens.xs) {
			return (
				<div
					style={{
						position: "absolute",
						bottom: "20px",
						left: "15px",
						cursor: "pointer",
						backgroundColor: "white",
						color: "#0444BF",
						borderRadius: "5px",
						width: "30px",
						height: "30px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
					}}
					onClick={toggleCollapsed}
				>
					{collapsed ? <RightOutlined /> : <LeftOutlined />}
				</div>
			);
		}
		return null; // Return null if xs screen
	};

	const controls = useAnimation();
	useEffect(() => {
		controls.start({ width: collapsed ? 80 : 250 });
	}, [collapsed, controls]);

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	return (
		<motion.div
			style={{ height: "100%" }}
			animate={controls}
			transition={{ duration: 0.5 }}
		>
			<Sider
				width={250}
				style={{
					background: "#0444BF",
					borderRadius: "0px 20px 20px 0px",
					paddingTop: ".5vh",
					zIndex: "2",
					height: "100vh",
				}}
				collapsed={collapsed}
				breakpoint="lg"
			>
				<Flex
					onClick={() => navigate(`/space/${currentWorkspace?.id}`)}
					className="logo"
				>
					{/* <Avatar
					shape="circle"
					style={{ backgroundColor: "white", margin: "20px 0px 15px 10px" }}
					size={{ xs: 24, sm: 32, md: 32, lg: 32, xl: 32, xxl: 32 }}
					src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.svg"
				/> */}
					<Avatar
						//shape="circle"
						shape="square"
						style={{
							backgroundColor: "#0444BF",
							margin: "22px 0px 15px 3px",
							width: "auto",
							height: "3vh",
						}}
						size={{ xs: 24, sm: 32, md: 32, lg: 32, xl: 32, xxl: 32 }}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi-white-logo.svg"
					/>
					{!collapsed && (
						<Typography.Title
							style={{
								marginTop: "23px",
								marginLeft: "10px",
								color: "white",
								fontSize: "26px",
								fontFamily: "Poppins",
							}}
						>
							Alchemi
						</Typography.Title>
					)}
				</Flex>
				<ConfigProvider
					theme={{
						components: {
							Menu: {
								colorBgContainer: "#0444BF",
								itemBg: "#0444BF",
								subMenuItemBg: "#0444BF",
								popupBg: "#0444BF",

								colorText: "#ffffff",
							},
						},
					}}
				>
					<Menu
						//className="leftmenu"
						style={{
							paddingTop: "2rem",
							fontFamily: "Poppins",
							marginLeft: "12px",
							width: collapsed ? 55 : 228,
						}}
						defaultSelectedKeys={[selectedMenuItem]}
						mode="inline"
						// onClick={handleMenuClick}
					>
						<Menu.ItemGroup
							title={
								<span
									className="ant-menu-light"
									style={{
										marginLeft: collapsed ? "-8px" : "-2px",
										display: "flex",
									}}
								>
									<ArrowLeftOutlined
										onClick={() => navigate(`/space/${currentWorkspace._id}`)}
										style={{
											color: "white",
											fontSize: "14px",
											cursor: "pointer",
											marginRight: collapsed ? "0.2vw" : "0.5vw",
										}}
									/>
									<p
										style={{
											color: "white",
											marginTop: collapsed ? "-3px" : 0,
										}}
									>
										{" "}
										{collapsed ? "Pro.." : "Profile"}
									</p>
								</span>
							}
						>
							<motion.div
								whileTap={{ scale: 0.8 }}
								whileHover={{
									backgroundColor: "#0540B2",
									color: "#fff",
									borderRadius: "6px",
								}}
								style={{
									display: "flex",
									justifyContent: collapsed ? "center" : "flex-start",
								}}
							>
								<Menu.Item
									key="ProfileManagement"
									icon={
										<UserOutlined
											style={{
												marginLeft: collapsed ? "-12px" : "1vw",
												marginRight: collapsed ? "30px" : "auto",
											}}
										/>
									}
									onClick={() => {
										navigate(`/profile/details`);
										setSelectedMenuItem("ProfileManagement");
									}}
									style={{
										background:
											selectedMenuItem === "ProfileManagement"
												? "white"
												: "transparent",
										color:
											selectedMenuItem === "ProfileManagement"
												? "#0444bf"
												: "inherit",
										height: 35,
										borderRadius: "6px",
										width: collapsed ? 48 : 218,
										lineHeight: "35px",
										marginTop: "0.5vh",
									}}
								>
									Profile Management
									{/* <Link to="/profile/details">Profile Management</Link> */}
								</Menu.Item>
							</motion.div>
							<motion.div
								whileTap={{ scale: 0.8 }}
								whileHover={{
									backgroundColor: "#0540B2",
									color: "#fff",
									borderRadius: "6px",
								}}
								style={{
									display: "flex",
									justifyContent: collapsed ? "center" : "flex-start",
								}}
							>
								<Menu.Item
									key="Preferences"
									icon={
										<SlidersFilled
											style={{
												marginLeft: collapsed ? "-12px" : "1vw",
												marginRight: collapsed ? "30px" : "auto",
											}}
										/>
									}
									onClick={() => {
										navigate(`/profile/perferences`);
										setSelectedMenuItem("Preferences");
									}}
									style={{
										background:
											selectedMenuItem === "Preferences"
												? "white"
												: "transparent",
										color:
											selectedMenuItem === "Preferences"
												? "#0444bf"
												: "inherit",
										height: 35,
										borderRadius: "6px",
										width: collapsed ? 48 : 218,
										lineHeight: "35px",
										marginTop: "0.5vh",
									}}
								>
									Preferences
									{/* <Link to="/profile/perferences">Preferences</Link> */}
								</Menu.Item>
							</motion.div>
						</Menu.ItemGroup>
					</Menu>
				</ConfigProvider>
				{renderArrowButton()}
			</Sider>
		</motion.div>
	);
}

export default ProfileMenu;
