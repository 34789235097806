import axios, { AxiosResponse } from "axios";
import { Connection } from "../models/Connection";

async function getConnection(token: any): Promise<Connection[]> {
	try {
		const response: AxiosResponse<Connection[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/connection`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching connections:", error);
		throw error;
	}
}

async function getConnectionById(id: any, token: any): Promise<Connection> {
	try {
		const response: AxiosResponse<Connection> = await axios.get(
			`${process.env.REACT_APP_API_URL}/connection/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching connection:", error);
		throw error;
	}
}

async function updateConnection(id: any, data: any, token: any) {
	try {
		const response: AxiosResponse<Connection> = await axios.put(
			`${process.env.REACT_APP_API_URL}/connection/${id}`,
			data,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error updating connection:", error);
		throw error;
	}
}

async function createConnection(data: any, token: any) {
	try {
		const response: AxiosResponse<Connection> = await axios.post(
			`${process.env.REACT_APP_API_URL}/connection`,
			data,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error creating connection:", error);
		throw error;
	}
}

export { getConnection, getConnectionById, updateConnection, createConnection };
