import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, message } from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { getWidgetById, updateWidgetById } from "../api/DashboardApi";

const { Option } = Select;

interface EditWidgetModalProps {
	visible: boolean;
	onCancel: () => void;
	widgetId: string;
	fetchData: () => void;
}

const EditWidgetModal: React.FC<EditWidgetModalProps> = ({
	visible,
	onCancel,
	widgetId,
	fetchData,
}) => {
	const [form] = Form.useForm();
	const { id } = useParams();
	const [widgetsData, setWidgetsData] = useState<any>();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	console.log(widgetsData, "widgetsData");

	const { data: getWidgetData, run: getWidget } = useRequest(
		(id, widgetId) =>
			getWidgetById(id, widgetId, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				// message.success("Widget data fetched successfully");
				console.log(data);
			},
			onError: (error) => {
				message.error(`Error fetching widget data: ${error.message}`);
				console.error("Error:", error);
			},
		}
	);

	useEffect(() => {
		if (getWidgetData) {
			setWidgetsData(getWidgetData);
			form.setFieldsValue(getWidgetData);
		}
	}, [getWidgetData]);

	useEffect(() => {
		if (visible) {
			getWidget(id, widgetId);
			// getWidgetDetails();
		}
	}, [visible, widgetId, id, form, userProfileStoreInstance.idToken]);

	const { data: updateData, run: updateWidget } = useRequest(
		(id, widgetId, values) =>
			updateWidgetById(id, widgetId, values, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				message.success("Widget data updated successfully");
				console.log(data);
			},
			onError: (error) => {
				message.error(`Error updating widget data: ${error.message}`);
				console.error("Error:", error);
			},
		}
	);

	useEffect(() => {
		if (updateData) {
			fetchData();
			setWidgetsData((prevWidgetsData: any) => {
				if (Array.isArray(prevWidgetsData)) {
					return prevWidgetsData.map((widget) =>
						widget.id === updateData._id ? updateData : widget
					);
				} else {
					console.error("not an array");
					return prevWidgetsData;
				}
			});
			onCancel();
		}
	}, [updateData]);
	const handleOk = async () => {
		try {
			const values = await form.validateFields();
			values.id = widgetId;
			updateWidget(id, widgetId, values);
		} catch (error) {
			console.error("Error updating widget:", error);
		}
	};

	return (
		<Modal
			title="Edit Widget"
			open={visible}
			onOk={handleOk}
			onCancel={onCancel}
		>
			<Form
				form={form}
				layout="horizontal"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
			>
				<Form.Item label="Title" name="title">
					<Input />
				</Form.Item>
				<Form.Item label="Description" name="description">
					<Input.TextArea />
				</Form.Item>
				<Form.Item label="Type" name="type">
					<Select style={{ width: "100%" }}>
						<Option value="text">Text</Option>
						<Option value="number">Number</Option>
						<Option value="date">Date</Option>
					</Select>
				</Form.Item>
				<Form.Item label="Component" name="component">
					<Select style={{ width: "100%" }}>
						<Option value="PieChart">PieChart</Option>
						<Option value="BarChart">BarChart</Option>
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default EditWidgetModal;
