/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
// import {  Image, Layout, Spin, message } from "antd";
import { loginRequest } from "../../authConfig";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { useInterval } from "ahooks";
import { message } from "antd";
import { InteractionStatus } from "@azure/msal-browser";
import usePlatformStore, {
	PlatformStoreType,
} from "../../stores/platformStore";
// import AppHeader from '../../layout/AppHeader';
const ValidateAuthentication: React.FC = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { setRedirectUrl } = usePlatformStore() as PlatformStoreType;
	const [authenticated, setAuthenticated] = useState(false);

	// const [loader, setloader] = useState(true);
	const { instance, accounts, inProgress } = useMsal();
	const scope_user_read: string = process.env.REACT_APP_SCOPE_USER_READ ?? "";

	// useEffect(() => {
	// 	checkAuthentication();
	// }, []);

	useEffect(() => {
		if (authenticated) {
			try {
				getUserProfile();
			} catch (error: any) {
				if (error.message === "Unauthorized") {
					if (inProgress === InteractionStatus.None && accounts) {
						forceRefreshToken();
					}
					getUserProfile();
				} else {
					throw error;
				}
			}
		}
	}, [inProgress, authenticated, accounts.length]);

	const interval = useInterval(() => {
		forceRefreshToken();
	}, 3000000);
	useEffect(() => {
		return () => {
			interval();
		};
	}, []);

	const getUserProfile = async () => {
		try {
			const headers = new Headers();
			const bearer = `Bearer ${userProfileStoreInstance.idToken}`;
			headers.append("Authorization", bearer);
			const options = {
				method: "GET",
				headers: headers,
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/user-profiles/user/azureid`,
				options
			);
			const userData = await response.json();
			userProfileStoreInstance.setProfile(userData);
			console.log("user profile", userData);
		} catch (error: any) {
			if (error.response && error.response.status === 401) {
				throw new Error("Unauthorized");
			} else {
				throw error;
			}
		}
	};

	const forceRefreshToken = async () => {
		if (inProgress === InteractionStatus.None && accounts) {
			const silentRequest = {
				account: accounts[0],
				scopes: ["openid", scope_user_read],
				forceRefresh: true,
			};
			try {
				const response = await instance.acquireTokenSilent(silentRequest);
				console.log("response for token specially id token", response);
				const idToken = response.accessToken;
				userProfileStoreInstance.setIdToken(idToken);
			} catch (error: any) {
				await new Promise((resolve) => {
					setTimeout(resolve, 100);
					message.success("Redirecting to login page...");
				});
				await instance.loginRedirect(loginRequest);
			}
		}
	};

	const checkAuthentication = async () => {
		if (accounts.length === 0) {
			try {
				await new Promise((resolve) => {
					setTimeout(resolve, 100);
					setRedirectUrl(window.location.pathname);
					message.success("Redirecting to login page...");
				});
				await instance.loginRedirect(loginRequest);

				// setAuthenticated(true);
			} catch (error: any) {
				message.error(`Error authenticating: ${error.message}`);
			}
		} else {
			setTimeout(() => {
				setAuthenticated(true);
			}, 1000);

			const silentRequest = {
				account: accounts[0],
				scopes: ["openid", scope_user_read],
				forceRefresh: true,
			};
			try {
				await new Promise((resolve) => {
					setTimeout(resolve, 500);
				});
				const response = await instance.acquireTokenSilent(silentRequest);
				console.log("response for token specially id token", response);
				const idToken = response.accessToken;
				// console.log("idtoken", idToken);
				userProfileStoreInstance.setIdToken(idToken);
			} catch (error: any) {
				message.error(`Error authenticating: ${error.message}`);
			}
			// console.log("Access token:", loginRequest);
		}
	};

	useEffect(() => {
		if (inProgress === InteractionStatus.None) {
			checkAuthentication();
		}
	}, [inProgress]);

	return <></>;
};

export default ValidateAuthentication;
