import { useEffect, useState } from "react";
import GridLayout from "react-grid-layout";

import DynamicContent from "./DynamicContent";
import usePageStore from "../../stores/PageStore";
import useWorkspaceStore from "../../stores/WorkspaceState";

const MyFirstGrid = () => {
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
	}));

	const [layout, setLayout] = useState([
		{ i: "First Grid", x: 0, y: 0, w: 1, h: 2 },
	]);

	const [widgets, setWidgets] = useState([
		{ i: "2", component: "Content1" },
		{ i: "3", component: "Content2" },
	]);
	const [isLayoutDisabled, setIsLayoutDisabled] = useState(false);

	useEffect(() => {
		setWidgets([
			{ i: "2", component: "Content1" },
			{ i: "3", component: "Content2" },
		]);
	}, []);

	const addGrid = () => {
		// Create a new grid object
		const newGrid = {
			i: String(layout.length + 1),
			x: 0,
			y: Infinity,
			w: 1,
			h: 2,
		};

		// Add the new grid to the layout array
		setLayout((prevLayout) => [...prevLayout, newGrid]);
	};

	const toggleLayout = () => {
		setIsLayoutDisabled(!isLayoutDisabled);
	};

	useEffect(() => {
		setPageSlug("reports");
		setPageTitle("Reports");
		setPageDescription("");
		setItemType("");
		setItemId("");
		setIsList(false);
		setItemDetails({});
		setBreadcrumbConfig([
			{
				displayName: "Reports",
				url: `space/${currentWorkspace?._id}/reports`,
			},
		]);
	}, []);

	return (
		<div>
			<button onClick={addGrid}>Add Grid</button>
			<button onClick={toggleLayout}>
				{isLayoutDisabled ? "Enable Layout" : "Disable Layout"}
			</button>
			<GridLayout
				className="layout"
				layout={layout}
				cols={12}
				rowHeight={30}
				width={1200}
				isDraggable={!isLayoutDisabled}
				isResizable={!isLayoutDisabled}
			>
				{layout.map((grid) => (
					<div key={grid.i}>
						{widgets.filter((widget) => widget.i === grid.i).length > 0 && (
							<DynamicContent
								componentName={
									widgets.filter((widget) => widget.i === grid.i)[0].component
								}
							/>
						)}
					</div>
				))}
			</GridLayout>
		</div>
	);
};

export default MyFirstGrid;
