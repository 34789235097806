import { Divider, Flex, Space, Button } from "antd";
import "../pages/Planner/PlanerHeader.css";
import React from "react";
import { EditOutlined, SaveOutlined, PlusOutlined } from "@ant-design/icons";
import BreadCrumb from "../pages/Editors/BreadCrumb";
import usePageStore from "../stores/PageStore";

interface DashboardNavProps {
	isLayoutDisabled: boolean;
	handleEditMode: () => void;
	handleSaveDashboard: () => void;
	addGrid: () => void;
	title: string;
}

const DashboardNav: React.FC<DashboardNavProps> = ({
	isLayoutDisabled,
	handleEditMode,
	handleSaveDashboard,
	addGrid,
	title,
}) => {
	const buttonStyle = {
		marginLeft: "8px",
		borderRadius: "6px",
		fontSize: "14px",
	};

	const { breadcrumbConfig } = usePageStore((state: any) => ({
		breadcrumbConfig: state.breadcrumbConfig,
	}));

	return (
		<div>
			<Divider className="ant-divider-horizontal" />
			<div className="ant-div-container">
				<Flex className="flex-container">
					<Flex style={{ alignItems: "center", marginLeft: "2vw" }}>
						<Space>
							<Flex className="breadcrumb">
								<BreadCrumb config={breadcrumbConfig} workItemId={title} />
							</Flex>
						</Space>
					</Flex>
					<Flex
						style={{
							alignItems: "center",
							marginLeft: "auto",
							marginRight: "2vw",
						}}
					>
						{!isLayoutDisabled && (
							<Button
								type="primary"
								size="small"
								icon={<PlusOutlined />}
								onClick={addGrid}
								style={buttonStyle}
							>
								Add Widget
							</Button>
						)}
						<Button
							type="primary"
							size="small"
							icon={isLayoutDisabled ? <EditOutlined /> : <SaveOutlined />}
							onClick={isLayoutDisabled ? handleEditMode : handleSaveDashboard}
							style={buttonStyle}
						>
							{isLayoutDisabled ? "Edit Dashboard" : "Save Dashboard"}
						</Button>
					</Flex>
				</Flex>
			</div>
		</div>
	);
};

export default DashboardNav;
