import React, { useEffect } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useWorkspaceStore from "../../stores/WorkspaceState";
import { useViewsFilterStores } from "../../stores/ViewsFilter";
import ViewFilterNavbar from "./ViewFilterNavbar";

// import ViewFilterNavbar from "./ViewFilterNavbar";
import usePageStore from "../../stores/PageStore";
//import ViewPageNavbar from "./ViewPageNavbar";

const ViewFilter = () => {
	//const [viewData, setViewData] = useState([]);
	const { setUserSavedFilterValue, setViewFilter } = useViewsFilterStores();

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const navigate = useNavigate();
	const viewDataStoreInstance = useViewsFilterStores();
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const { viewData } = useViewsFilterStores((state: any) => ({
		viewData: state.viewData,
	}));

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
	}));

	useEffect(() => {
		setPageSlug("viewfilter");
		setPageTitle("View Filter");
		setPageDescription("");
		setItemType("");
		setItemId("");
		setIsList(false);
		setItemDetails({});
		setBreadcrumbConfig({
			page: `/space/${currentWorkspace._id}/planner/views`,
			config: [
				{
					label: "Views",
					url: `/space/${currentWorkspace._id}/planner/views`,
				},
			],
		});
	}, []);
	const handleEyeIconClick = async (item: any) => {
		const newdata = item.filter.map((data: any) => {
			return {
				key: data.field,
				value: data.value,
			};
		});
		setViewFilter(false);
		setUserSavedFilterValue(newdata);
		navigate(`/space/${currentWorkspace._id}/planner/filterview`);
	};

	const columns = [
		{
			title: (
				<div style={{ fontFamily: "Poppins", marginLeft: "3rem" }}>Action</div>
			),
			key: "action",
			width: 150,
			render: (text: any, item: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginRight: "1vw",
						}}
					>
						<EyeOutlined
							onClick={() => handleEyeIconClick(item)}
							style={{
								fontSize: "1.1rem",
								//marginRight: "1vw",
								marginLeft: "1vw",
								//color: "#FCA311"
							}}
						/>
					</div>
				</div>
			),
		},
		{
			title: "View Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
		},
		{
			title: "Filter",
			dataIndex: "filter",
			key: "filter",
			render: (filter: any[]) => (
				<>
					{filter?.map((filterItem: any, filterIndex: any) => (
						<div key={filterIndex}>
							{filterItem.field} {filterItem.condition} {filterItem.value}
						</div>
					))}
				</>
			),
		},
	];

	const fetchFilteredViewData = async () => {
		try {
			console.log("inside try");
			const response = await fetch(`${process.env.REACT_APP_API_URL}/view`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			});
			console.log(response);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			if (response.status == 200) {
				// Check if the response is not empty
				console.log("response is 200");

				const data = await response.json();
				viewDataStoreInstance.setViewData(data);
				//setViewData(data);

				//console.log(viewData);
			}
		} catch (error) {
			console.error(error);
		}
	};
	useEffect(() => {
		console.log(viewData);
	}, [viewData]);

	useEffect(() => {
		fetchFilteredViewData();
	}, []);
	return (
		<div>
			{/* <ViewFilterNavbar viewData={viewData} /> */}
			<ViewFilterNavbar />
			{viewData && (
				<Table
					style={{ width: "100%", height: "200%" }}
					columns={columns}
					dataSource={viewData}
					rowKey={(record: any) => record._id}
					pagination={{ pageSize: 10 }}
					//pagination={{ pageSize: viewData.length }}
					scroll={{ y: 300 }}
				/>
			)}
		</div>
	);
};

export default ViewFilter;
