import axios, { AxiosResponse } from "axios";
import { RitualModel } from "../models/RitualModel";
const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
	baseURL,
});

async function createRitualSession(
	createRitualSessionDto: RitualModel,
	token: string
): Promise<RitualModel> {
	try {
		const response: AxiosResponse<RitualModel> = await api.post(
			"${process.env.REACT_APP_API_URL}/ritual-session",
			createRitualSessionDto,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error creating ritual session:", error);
		throw error;
	}
}

async function getRitualSessions(
	workspaceId: string,
	token: string
): Promise<RitualModel[]> {
	try {
		const response: AxiosResponse<RitualModel[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/ritual-session/workspace/${workspaceId}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json; charset=UTF-8",
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching ritual sessions:", error);
		throw error;
	}
}

async function getRitualSession(
	id: string,
	token: string
): Promise<RitualModel> {
	try {
		const response: AxiosResponse<RitualModel> = await api.get(
			`${process.env.REACT_APP_API_URL}/ritual-session/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching ritual session:", error);
		throw error;
	}
}

async function updateRitualSession(
	id: string,
	updateRitualSessionDto: Partial<RitualModel>,
	token: string
): Promise<RitualModel> {
	try {
		const response: AxiosResponse<RitualModel> = await api.put(
			`${process.env.REACT_APP_API_URL}/ritual-session/${id}`,
			updateRitualSessionDto,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error updating ritual session:", error);
		throw error;
	}
}

async function getFilteredItems(
	filterDto: any,
	token: string
): Promise<RitualModel[]> {
	try {
		const response: AxiosResponse<RitualModel[]> = await api.get(
			"${process.env.REACT_APP_API_URL}/ritual-session/filter",
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: filterDto,
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching filtered items:", error);
		throw error;
	}
}

async function getRitualsBYFilterValue(
	filterDto: any,
	workspaceId: string,
	token: string
): Promise<RitualModel[]> {
	try {
		const response: AxiosResponse<RitualModel[]> = await api.post(
			`${process.env.REACT_APP_API_URL}/ritual-session/filter/${workspaceId}`,
			filterDto,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching filtered items:", error);
		throw error;
	}
}

export {
	createRitualSession,
	getRitualsBYFilterValue,
	getRitualSessions,
	getRitualSession,
	updateRitualSession,
	getFilteredItems,
};
