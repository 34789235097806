/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Form,
	Button,
	Layout,
	Card,
	Table,
	Tag,
	Flex,
	Select,
	Popconfirm,
	Typography,
	Input,
	Tooltip,
	Divider,
	message,
} from "antd";
import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import Item from "antd/es/descriptions/Item";
import usePlatformStore from "../../stores/platformStore";

import { Key } from "antd/es/table/interface";
import { EditOutlined } from "@ant-design/icons";
import EditUserNav from "./EditUserNav";
import BreadCrumb from "../Editors/BreadCrumb";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";
import "./EditUser.css";
import { width } from "@antv/x6-common/lib/dom/position";
import { useRequest } from "ahooks";
import { getallRoles } from "../../api/RolesAPI";

const { Option } = Select;

interface Item {
	id: Key;
	key: string;
	Workspace: string;
	Roles: string;
}
const originData: any = [];
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean;
	dataIndex: string;
	title: any;
	//inputType: 'number' | 'text'| 'select';
	record: typeof Item;
	index: number;
	children: React.ReactNode;
	inputNodex: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	//inputType,
	//record,
	//index,
	inputNodex,
	children,
	...restProps
}) => {
	//let inputNode = inputType === 'number' || inputType === 'text' ? <Input /> : <Select><Option value="role1">Role 1</Option></Select>;

	//inputNode = <Select><Option value="role1">role1</Option></Select>;
	// inputNode = (
	// 	<Select>
	// 		{roles.map((role) => (
	// 			<Option key={role} value={role}>
	// 				{role}
	// 			</Option>
	// 		))}
	// 	</Select>
	// );

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{
						margin: 0,
					}}
					rules={[
						{
							required: true,
							message: `Please Input ${title}!`,
						},
					]}
				>
					{inputNodex}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

interface RolesSelectProps {
	roles: any;
	onChange: (value: string) => void;
}

const UserDetail: React.FC = () => {
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { id } = useParams();
	const [userDetails, setUserDetails] = useState<any>(null);
	const [workspacePermissions, setWorkspacePermissions] = useState<any[]>([]);
	const [data, setData] = useState(originData);
	const [editingKey, setEditingKey] = useState("");
	const [selectedRole, setSelectedRole] = useState<string>("");

	const handleRoleChange = (value: string) => {
		setSelectedRole(value); // Update the selected role
	};

	const [roles, setRoles] = useState<any[]>([]);
	const isEditing = (record: any) => record._id === editingKey;

	//query roles from api

	const RolesSelect: React.FC<RolesSelectProps> = ({ roles, onChange }) => {
		const handleChange = (value: string) => {
			onChange(value); // Propagate the selected value to the parent component
		};
		return (
			<Select onChange={handleChange} value={selectedRole}>
				{roles.map((role: any) => (
					<Option key={role._id} value={role._id}>
						{role.name}
					</Option>
				))}
			</Select>
		);
	};

	const { data: roleData, run: fetchRolesData } = useRequest(
		() => getallRoles(userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess() {
				// message.success("Role fetched successfully");
			},
			onError() {
				message.error("Error fetching roles data");
			},
		}
	);

	useEffect(() => {
		if (roleData) {
			setRoles(roleData);
		}
	}, [roleData]);

	useEffect(() => {
		fetchRolesData();
	}, []);

	const edit = (record: any) => {
		console.log("record", record);
		form.setFieldsValue({ Workspace: "", Roles: "", ...record });
		setEditingKey(record._id);
	};
	const cancel = () => {
		setEditingKey("");
	};
	const save = async (record: any & { key: React.Key }) => {
		console.log("record", record);
		console.log("reord_id", record.id);
		try {
			const row = (await form.validateFields()) as typeof Item;

			const newData: any = roles;
			console.log("newData", newData);
			const key = record.key;
			const index = newData.findIndex((item: any) => key == item._id);
			const filterrole: any = roles.filter(
				(role: any) => role._id === selectedRole
			);
			console.log("index", index);
			if (index > -1) {
				const item: any = newData[index];
				newData.splice(index, 1, {
					...item,
					...row,
				});
				setData(newData);
				setEditingKey("");
			} else {
				// Call API to save edited roles
				console.log("row", row);
				console.log("selectedRole", selectedRole);
				console.log("filterrole", filterrole);
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/workspace-permissions/${key}`,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
						body: JSON.stringify({
							roles: filterrole[0]._id,
							permission: filterrole[0].permissions,
						}),
					}
				);

				if (response.ok) {
					console.log("Roles updated successfully");
					fetchUserDetails();
				} else {
					console.error("Error updating roles");
				}
				// const dataa = await response.json();

				// newData.push({
				// 	...row,
				// 	_id: dataa._id,
				// 	key: dataa._id,
				// 	workspaceId: record.workspaceId,
				// 	roles: dataa.roles,
				// });
				// setData(newData);
				setEditingKey("");
				console.log("newData", newData);
			}
		} catch (errInfo) {
			console.log("Validate Failed:", errInfo);
		}
	};

	const fetchUserDetails = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/user-profiles/${id}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const data = await response.json();
				setUserDetails(data);

				const workspaceResponse = await fetch(
					`${process.env.REACT_APP_API_URL}/workspace-permissions/?user-profile-id=${id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);

				if (workspaceResponse.ok) {
					const workspaceData = await workspaceResponse.json();
					setWorkspacePermissions(workspaceData);
					console.log("try", workspaceData);
				} else {
					console.error("Error fetching workspace permissions");
				}
			} else {
				console.error("Error fetching user details");
			}
		} catch (error) {
			console.error("Error fetching user details:", error);
		}
	};

	useEffect(() => {
		fetchUserDetails();
	}, [id]);

	if (!userDetails) {
		return <div>Loading...</div>;
	}

	const handleCancelClick = async () => {
		navigate("/settings/user");
	};

	const { name } = userDetails;

	const columns = [
		{
			title: "Workspace",
			dataIndex: "workspaceId",
			key: "workspaceId",
			editable: false,
			width: 1500,

			render: (workspaceId: any) => (
				<h4 style={{ fontWeight: "normal" }}>{workspaceId?.name}</h4>
			),
		},
		{
			title: "Roles",
			dataIndex: "roles",
			key: "roles",
			editable: true,
			inputType: "select",
			render: (roles: any) => <Tag color="yellow">{roles.name}</Tag>,
		},

		{
			title: "Operation",
			dataIndex: "operation",
			render: (_id: any, record: any) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link
							onClick={() => save({ key: record._id, ...record })}
							style={{
								marginRight: 8,
								fontFamily: "Open Sans",
								color: "#0444bf",
							}}
						>
							Save
						</Typography.Link>
						<Popconfirm title="Sure to cancel?" onConfirm={cancel}>
							<a style={{ color: "#0444bf", fontSize: "16px" }}>Cancel</a>
						</Popconfirm>
					</span>
				) : (
					<Tooltip title="Edit">
						<Typography.Link
							disabled={editingKey !== ""}
							onClick={() => {
								edit(record),
									setSelectedRole(record?.roles?.name),
									console.log("testt", record);
							}}
						>
							<EditOutlined style={{ marginLeft: 25, color: "#0444bf" }} />
						</Typography.Link>
					</Tooltip>
				);
			},
		},
	];

	const mergedColumns = columns.map((col) => {
		console.log("col", col);
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: Item) => ({
				record,
				//inputType: col.dataIndex === 'roles' ? 'select' : 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				inputNodex:
					col.dataIndex === "roles" ? (
						<RolesSelect roles={roles} onChange={handleRoleChange} />
					) : (
						<Input />
					),
				editing: isEditing(record),
			}),
		};
	});

	const onFinish = async () => {
		navigate("/settings/user");
	};

	return (
		<>
			<Divider className="ant-divider-horizontal" />
			<Flex
				justify="space-between"
				align="center"
				style={{
					margin: "0 1vw",
					marginTop: "-0.5vh",
				}}
			>
				<Flex style={{ marginLeft: "1vw" }} className="breadcrumb">
					<BreadCrumb config={breadcrumbConfig.editusermanagement} />
				</Flex>
				{/* <Flex justify={"flex-end"}> */}
				<div>
					<Button
						style={{
							borderRadius: "6px",
							fontSize: "14px",
							marginRight: "0.5vw",
						}}
						onClick={handleCancelClick}
						data-testId="addRoleTest"
						size="small"
					>
						Cancel
					</Button>
					<Button
						style={{ borderRadius: "6px", fontSize: "14px" }}
						type="primary"
						htmlType="submit"
						size="small"
					>
						Save
					</Button>
				</div>
				{/* </Flex> */}
			</Flex>
			<Layout
				style={{
					height: "80vh",
					margin: "0 1vw",
					background: "white",
					padding: "0 1vw",
				}}
			>
				<h2>{name}</h2>
				<Table
					dataSource={workspacePermissions}
					columns={mergedColumns}
					rowKey={(record) => record._id}
					rowClassName={() => "custom-row-padding"}
					//rowClassName="editable-row"
					showHeader={false}
					pagination={false}
					components={{
						body: {
							cell: EditableCell,
						},
					}}
				/>
			</Layout>
		</>
	);
};

export default UserDetail;
