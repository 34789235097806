/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import {
	ArrowLeftOutlined,
	PlusCircleOutlined,
	UserAddOutlined,
} from "@ant-design/icons";
import {
	Form,
	Input as AntInput,
	Button,
	DatePicker,
	Radio,
	Card,
	Flex,
	Checkbox,
	Table,
	Tag,
	Tooltip,
	Breadcrumb,
	Space,
	Input,
	Avatar,
	Dropdown,
	Menu,
	Typography,
	Divider,
	Pagination,
	Tabs,
	message,
} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { useParams } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { CalendarOutlined } from "@ant-design/icons";
import UpdateModal from "./RitualTaskUpdate";
import EditableCell from "./EditableCell";
import { useNavigate } from "react-router-dom";
//import RitualChatInterface from "./RitualDiscussionTab";
import AddChecklistItemModal from "./AddChecklistModel";
import { v4 as uuidv4 } from "uuid";
import useWorkspaceStore from "../../../stores/WorkspaceState";
import RitualActivityTab from "./RitualActivityTab";
//import RitualNotes from "./RitualNotes";
import LinkTabModel from "../LinkTabModel";
import RitualLink from "./RitualLink";
import { getRitualSession, updateRitualSession } from "../../../api/RitualApi";
import { useDebounce, useRequest } from "ahooks";
import RitualViewNav from "./RitualViewNav";
import "./RitualView.css";
import {
	createActivelog,
	getActivelogsbyparentId,
} from "../../../api/ActiveLogAPI";
import RitualNotesV2 from "./RitualNotesV2";
import RitualChatInterface from "./RitualDiscussionTab";

import usePageStore from "../../../stores/PageStore";
import BreadCrumb from "../../Editors/BreadCrumb";
const { RangePicker } = DatePicker;
const { Title } = Typography;
const { TabPane } = Tabs;

const RitualView: React.FC<any> = () => {
	const [form] = Form.useForm();
	const [selectedRadio, setSelectedRadio] = useState<string | null>("radio1");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [updateModalVisible, setUpdateModalVisible] = useState(false);
	const [dataToSend, setDataToSend] = useState<any>(null);
	const [data, setData] = useState<any>();
	const [editedItemTitle, setEditedItemTitle] = useState<string>("");
	const [editMode, setEditMode] = useState<string | null>(null);
	const [openModal, setopenModal] = useState(false);
	const workspaceId = useParams().workspaceId;
	const [tags, setTags] = useState<any[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const pageSize = 6;
	const startIndex = (currentPage - 1) * pageSize;
	const endIndex = startIndex + pageSize;

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
		breadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
		breadcrumbConfig: state.breadcrumbConfig,
	}));

	const navigate = useNavigate();

	const { itemId } = useParams();

	console.log(itemId, "itemId");
	const [visible, setVisible] = useState(false);

	const handleAddChecklistItem = async (value: any) => {
		try {
			const id = uuidv4();

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/ritual-session/${itemId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						...data,
						checklist: [
							...data.checklist,
							{ title: value, status: "pending", _id: id },
						],
					}),
				}
			);

			if (response.ok) {
				// Refresh data after successfully adding checklist item
				fetchTodos();
				console.log("Checklist item added successfully:", value);

				const activityLog = {
					type: "Rituals",
					content: [
						{
							checklist: {
								oldValue: [null],
								newValue: [value],
							},
						},
					],
					parentId: itemId,
				};

				// Send activity log to backend
				await postActivelogs(activityLog);
			} else {
				console.error("Failed to add checklist item");
			}
		} catch (error) {
			console.error("Error adding checklist item:", error);
		} finally {
			handleCancel();
		}
	};

	const handleOk = () => {
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const formItemLayout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	const {
		data: RitualData,
		error: RitualError,
		run,
	} = useRequest(
		() => getRitualSession(itemId!, userProfileStoreInstance.idToken ?? ""),
		{
			manual: true,
			onSuccess: (result) => {
				setData(result);
				const doneChecklistItems = result.checklist.filter(
					(item: any) => item.status === "done"
				);
				const defaultChecklistValues = doneChecklistItems.map(
					(item: any) => item.title
				);
				form.setFieldsValue({
					checklist: defaultChecklistValues,
				});
				console.log(RitualData);
			},
			onError: (error) => {
				console.error("Error fetching todos:", error);
				console.log(RitualError);
			},
		}
	);

	const fetchTodos = () => {
		run();
	};

	useEffect(() => {
		if (userProfileStoreInstance?.idToken && itemId) {
			fetchTodos();
		}
	}, [userProfileStoreInstance, itemId]);

	useEffect(() => {
		setPageSlug(`${RitualData?.title}`);
		setPageTitle(`${RitualData?.title}`);
		setBreadcrumbConfig(
			{
				page: `/space/${workspaceId}/planner/ritual/${itemId}`,
				config: [
					{
						label: "Rituals",
						url: `/space/${workspaceId}/planner/rituals`,
					},
					{
						label: `${RitualData?.title}`,
						url: `/space/${workspaceId}/planner/ritual/${itemId}`,
					},
				],
			},
			[]
		);

		setPageDescription("");
		setItemType(`${RitualData?.type}`);
		setItemDetails(RitualData);
		setIsList(false);
		setItemId(itemId);
	}, [RitualData]);
	const fetchUserProfile = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workspace-permissions/workspace?work-space-id=${workspaceId}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				setTags(data);
				console.log(data);
			} else {
				console.error("Error fetching tags data");
			}
		} catch (error) {
			console.error("Error fetching tags data:", error);
		}
	};
	useEffect(() => {
		if (workspaceId && userProfileStoreInstance?.idToken) {
			fetchUserProfile();
		}
	}, [workspaceId, userProfileStoreInstance]);

	const handleRadioChange = (key: string) => {
		setSelectedRadio(key);
		localStorage.setItem("selectedTab", key);
	};
	const handleDoubleClick = (item: any) => {
		setEditMode(item._id);
		setEditedItemTitle(item.title);
	};

	const {
		run: updateChecklistItem,
		loading: updateLoading,
		error: updateError,
	} = useRequest(
		async (updatedData: any) => {
			if (itemId) {
				await updateRitualSession(
					itemId,
					updatedData,
					userProfileStoreInstance.idToken ?? ""
				);
			} else {
				throw new Error("itemId is undefined");
			}
		},
		{
			manual: true,
			onSuccess: () => {
				fetchTodos();
			},
			onError: (error) => {
				console.error("Error updating checklist item status:", error);
			},
		}
	);

	const handleCheckboxChanges = async (
		title: string,
		checked: boolean,
		id: any
	) => {
		try {
			const updatedChecklist = data.checklist.map((item: any) =>
				item._id === id
					? { ...item, status: checked ? "done" : "pending", _id: id }
					: item
			);

			const updatedData = {
				...data,
				checklist: updatedChecklist,
			};

			updateChecklistItem(updatedData);
		} catch (error) {
			console.error("Error updating checklist item status:", error);
		}
	};
	const handleEnterKeyPress = async (
		e: React.KeyboardEvent<HTMLInputElement>,
		status: string,
		id: any
	) => {
		if (e.key === "Enter") {
			try {
				const updatedChecklist = data.checklist.map((item: any) =>
					item._id === id
						? { ...item, title: editedItemTitle, status: status }
						: item
				);

				const updatedData = {
					...data,
					checklist: updatedChecklist,
				};

				const {
					run: updateChecklistItem,
					loading: updateLoading,
					error: updateError,
				} = useRequest(
					() =>
						updateRitualSession(
							itemId!,
							updatedData,
							userProfileStoreInstance.idToken ?? ""
						),
					{
						manual: true,
						onSuccess: () => {
							fetchTodos(); // Re-fetch todos after successful update
							console.log("Checklist item updated successfully:", itemId);
						},
						onError: (error) => {
							console.error("Failed to update checklist item:", itemId, error);
						},
					}
				);

				updateChecklistItem(); // Trigger the update request
			} catch (error) {
				console.error("Error updating checklist item:", error);
			} finally {
				setEditMode(null);
			}
		}
	};
	useEffect(() => {
		fetchTodos();
	}, [itemId, updateModalVisible, visible]);

	useEffect(() => {
		// Set form values when data changes
		if (data) {
			form.setFieldsValue({
				type: data.type,
				title: data.title,
				TimeSlot: [
					moment(data.startDate, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
					moment(data.endDate, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
				],
				Agenda: data.description,
			});
		}
	}, [data, form]);

	const handleIconClick = (item: any) => {
		setUpdateModalVisible(true);
		setDataToSend(item);
	};

	const handleUpdateModalCancel = () => {
		setUpdateModalVisible(false);
	};

	{
		/*
	function removePTags(text: string) {
		return text.replace(/<p>/g, "").replace(/<\/p>/g, "");
	}
	*/
	}
	const columns = [
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			render: (text: any, item: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<EyeOutlined
						onClick={() => handleIconClick(item)}
						style={{ fontSize: "1.1rem", marginRight: "1vw" }}
					/>
					<a style={{ color: "black", fontWeight: "bold" }}>{text}</a>
				</div>
			),
		},
		// {
		// 	title: "Description",
		// 	dataIndex: "description",
		// 	key: "description",
		// 	render: (text: any) => (
		// 		<div style={{ display: "flex", alignItems: "center" }}>
		// 			<a
		// 				style={{ color: "black", fontWeight: "bold" }}
		// 				// dangerouslySetInnerHTML={{ __html: text }}
		// 			>
		// 				{removePTags(text)}
		// 			</a>
		// 		</div>
		// 	),
		// },

		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: (type: any) => (
				<Tag color="yellow" style={{ color: "black" }}>
					{type}
				</Tag>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
	];
	const [selectedUserName, setSelectedUserName] = useState<string[]>([]);
	const updateTeamMembers = async (selecteduser: object) => {
		try {
			await updateRitualSession(
				itemId!,
				{ teamMember: selecteduser },
				userProfileStoreInstance.idToken ?? ""
			);
		} catch (error) {
			console.error("Error updating team members:", error);
		}
	};
	const handleMenuClick = async (e: any) => {
		const clickedUserName = e.key;
		const clickedUserId = e.item.props["data-userid"];
		const selectedUserNamesCopy = [...selectedUserName];
		const isAlreadySelected = selectedUserNamesCopy.includes(clickedUserName);
		if (!isAlreadySelected) {
			selectedUserNamesCopy.push(clickedUserName);
		} else {
			const index = selectedUserNamesCopy.indexOf(clickedUserName);
			selectedUserNamesCopy.splice(index, 1);
		}
		setSelectedUserName(selectedUserNamesCopy);
		updateTeamMembers(clickedUserId);
	};

	const menu = (
		<Menu onClick={handleMenuClick}>
			{tags.map((member, index) => (
				<Menu.Item
					key={member?.userProfileId?.name || index}
					data-userid={member?.userProfileId?._id}
				>
					{member?.userProfileId?.name}
				</Menu.Item>
			))}
		</Menu>
	);

	// console.log("Menu:", menu);
	// const handleNavigate = () => {
	// 	navigate(`/space/${currentWorkspace._id}/planner/rituals`);
	// };

	const formatDate = (dateString: any) => {
		return moment(dateString).format("D MMMM");
	};

	const currentData = data?.checklist.slice(startIndex, endIndex);

	const handlePageChange = (page: React.SetStateAction<number>) => {
		setCurrentPage(page);
	};

	const getRandomPastelColor = () => {
		const hue = 219;
		const pastelColor = `hsl(${hue}, 80%, 80%)`;
		return pastelColor;
	};

	const { run: postActivelogs } = useRequest(
		(body) => createActivelog(body, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (response) => {
				message.success("Activlog  updated successfully");
				console.log(response);
			},
			onError: (error) => {
				console.log(error);
				message.error(`Active Log Error: ${error.message}`);
			},
		}
	);

	const [visibleDatePicker, setVisibleDatePicker] = useState(false);
	const [selectedDateRange, setSelectedDateRange] = useState<any>(null);

	const handleOpenDatePicker = () => {
		setVisibleDatePicker(true);
	};

	const handleDatePickerChange = async (dates: any) => {
		try {
			await updateRitualSession(
				itemId!,
				{ startDate: dates[0], endDate: dates[1] },
				userProfileStoreInstance.idToken ?? ""
			);

			setSelectedDateRange(dates);
			setVisibleDatePicker(false);
			run();
		} catch (error) {
			console.error("Error updating dates:", error);
		}
	};

	const stripHtml = (html: string) => {
		return html?.replace(/<[^>]*>?/gm, "");
	};
	const [updatedDescription, setUpdatedDescription] = useState<
		string | undefined
	>();
	const debouncedDescription = useDebounce(updatedDescription, { wait: 1000 });

	// useEffect to handle storing data to backend when debouncedDescription changes
	useEffect(() => {
		const updateDescription = async () => {
			try {
				if (data && debouncedDescription !== undefined) {
					const updatedData = { ...data, description: debouncedDescription };
					updateRitualSession(
						itemId!,
						updatedData,
						userProfileStoreInstance.idToken ?? ""
					);
					message.success("Description updated successfully");
				}
			} catch (error) {
				console.error("Error updating description:", error);
				message.error("Failed to update description");
			}
		};

		updateDescription();
	}, [debouncedDescription, data]);
	const handleDescriptionChange = (
		e: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setUpdatedDescription(e.target.value);
	};

	return (
		<>
			<RitualViewNav title={data?.title} />
			<Flex
				justify="center"
				style={{
					backgroundColor: "#f5f8fe",
					//margin: "1rem",
					// padding: "1rem",
					height: "83vh",
					maxWidth: "100vw",
				}}
				//className="WorkspaceDiv"
			>
				<Card
					className="ant-card"
					style={{
						width: "50%",
						marginBottom: "2rem",
						//padding: "0rem 1rem 1rem 1rem",
					}}
				>
					<Flex
						align="center"
						style={{ justifyContent: "space-between", width: "100%" }}
					>
						{/* <div>
							<BreadCrumb config={breadcrumbConfig} />
							<div style={{ display: "flex", alignItems: "center" }}>
								<Button type="text" icon={<CalendarOutlined />} />
								<p
									style={{
										color: "#6B7280",
										marginLeft: "3px",
										marginTop: "0",
									}}
								>
									{formatDate(data?.startDate)} - {formatDate(data?.endDate)}
								</p>
							</div>
						</div> */}
						<Title
							style={{
								fontSize: "20px",
								textAlign: "left",
								marginLeft: "2vw",
								fontWeight: "bolder",
								textTransform: "capitalize",
								marginTop: "3vh",
							}}
						>
							{data?.title}
						</Title>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginTop: "1vh",
								marginRight: "1vw",
							}}
						>
							<Button
								type="text"
								icon={<CalendarOutlined />}
								onClick={handleOpenDatePicker}
								style={{ marginRight: visibleDatePicker ? "22vw" : "0.5vw" }}
							/>
							{visibleDatePicker ? (
								<DatePicker.RangePicker
									picker="date"
									open={visibleDatePicker}
									onOpenChange={(open) => setVisibleDatePicker(open)}
									value={selectedDateRange}
									onChange={(dates) => handleDatePickerChange(dates)}
									style={{
										width: "22vw",
										marginLeft: "2.5vw",
										visibility: "visible",
										position: "absolute",
										zIndex: 1,
									}}
								/>
							) : (
								<p
									style={{
										color: "#6B7280",
										marginLeft: "0.2vw",
										marginTop: "0",
										fontSize: "14px",
									}}
								>
									{formatDate(data?.startDate)} - {formatDate(data?.endDate)}
								</p>
							)}
						</div>
					</Flex>

					<Flex
						vertical
						style={{
							height: "55vh",
							marginTop: "2vh",
							marginLeft: "2rem",
						}}
					>
						<Flex vertical>
							<Flex>
								<Title style={{ color: "#5C92B8" }} level={2}>
									Agenda
								</Title>
							</Flex>
							{/* <div
							style={{
								height: "17vh",
								overflowY: "auto",
							}}
							dangerouslySetInnerHTML={{ __html: data?.description }}
							
						></div> */}
							<AntInput.TextArea
								style={{
									height: "20vh",
									width: "95%",
									marginRight: "1vw",
									overflowY: "auto",
								}}
								value={stripHtml(updatedDescription || data?.description)}
								onChange={handleDescriptionChange}
							/>
						</Flex>
						<Flex vertical style={{ marginTop: "2rem" }}>
							<Flex align="center" justify="space-between" gap={10}>
								<Title style={{ color: "#5C92B8", fontSize: "18px" }}>
									CheckList
								</Title>
								<PlusCircleOutlined
									onClick={handleOk}
									style={{
										fontSize: "20px",
										color: "#5C92B8",
										marginRight: "1vw",
										marginTop: "2vh",
									}}
								/>
							</Flex>
							{data?.checklist && (
								<Flex
									style={{
										height: "25vh",
										width: "38vw",
										overflow: "scroll",
									}}
									vertical
									gap={20}
								>
									<Form
										{...formItemLayout}
										form={form}
										style={{ maxWidth: "100%", width: "100%" }}
									>
										<Form.Item name={"checklist"} noStyle>
											<Checkbox.Group style={{ width: "100%" }}>
												<Flex
													style={{ width: "100%" }}
													vertical
													align="start"
													justify="start"
												>
													{currentData.map((item: any, index: number) => (
														// <React.Fragment key={item._id}>
														<div
															key={item._id}
															style={{ display: "flex", alignItems: "center" }}
														>
															<div
																onDoubleClick={() => handleDoubleClick(item)}
																style={{ marginRight: "8px" }}
															>
																<Checkbox
																	checked
																	value={item.title}
																	type="checkbox"
																	onChange={(e) =>
																		handleCheckboxChanges(
																			item.title,
																			e.target.checked,
																			item._id
																		)
																	}
																/>
															</div>
															<div>
																{editMode === item._id ? (
																	<Input
																		value={editedItemTitle}
																		onChange={(e) =>
																			setEditedItemTitle(e.target.value)
																		}
																		onPressEnter={(e) =>
																			handleEnterKeyPress(
																				e,
																				item.status,
																				item._id
																			)
																		}
																		onBlur={() => setEditMode(null)}
																		autoFocus
																	/>
																) : (
																	<span
																		onDoubleClick={() =>
																			handleDoubleClick(item)
																		}
																		style={{ cursor: "pointer" }}
																	>
																		{item.title}
																	</span>
																)}
															</div>
														</div>
														// {index < currentData.length - 1 && <Divider />}
														// </React.Fragment>
													))}
												</Flex>
											</Checkbox.Group>
										</Form.Item>
									</Form>
								</Flex>
							)}
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									marginTop: "-15px",
								}}
							>
								<Pagination
									current={currentPage}
									pageSize={pageSize}
									total={data?.checklist.length}
									onChange={handlePageChange}
								/>
							</div>
						</Flex>

						<UpdateModal
							visible={updateModalVisible}
							onCancel={handleUpdateModalCancel}
							data={dataToSend}
						/>
					</Flex>
				</Card>
				<Card
					style={{
						width: "50%",
						marginBottom: "2rem",
						padding: "1rem 1rem 1rem 1rem",
						position: "relative",
					}}
				>
					{/* <Flex>
					<Radio.Group onChange={handleRadioChange}>
						<Radio.Button value="radio1">Note</Radio.Button>
						<Radio.Button value="radio2">Discussions</Radio.Button>
						<Radio.Button value="radio3">Activity</Radio.Button>
						<Radio.Button value="radio4">Links</Radio.Button>
					</Radio.Group>
				</Flex> */}
					<Flex style={{ width: "100%", marginRight: "10px", padding: "0" }}>
						<Tabs
							defaultActiveKey="1"
							onChange={handleRadioChange}
							style={{ width: "100%", flex: 1 }}
						>
							<TabPane tab="Note" key="radio1">
								<RitualNotesV2 />
							</TabPane>
							<TabPane tab="Discussions" key="radio2">
								{/* {data?.discussions && <RitualActivity/>} */}
								{data?.discussions && <RitualChatInterface />}
							</TabPane>
							<TabPane tab="Activity" key="radio3">
								{data?.activity && <RitualActivityTab />}
							</TabPane>
							<TabPane tab="Links" key="radio4">
								{itemId && (
									<RitualLink
										itemId={itemId}
										openModal={openModal}
										setopenModal={setopenModal}
									/>
								)}
							</TabPane>
						</Tabs>
					</Flex>
					<Flex
						justify="end"
						style={{
							position: "absolute",
							top: "2.2rem",
							right: "1rem",
							transform: "translateY(-50%)",
						}}
					>
						<div style={{ marginLeft: "10px" }}>
							<Dropdown
								overlay={menu}
								placement="bottomLeft"
								trigger={["click"]}
							>
								<Button shape="circle">
									<UserAddOutlined />
								</Button>
							</Dropdown>
							<Avatar.Group maxCount={3}>
								{selectedUserName.map((name, index) => (
									<Tooltip title={name} key={index}>
										<Avatar
											style={{
												backgroundColor: getRandomPastelColor(),
											}}
										>
											{name
												.split(" ")
												.map((n) => n[0])
												.join("")}
										</Avatar>
									</Tooltip>
								))}
								{Array.isArray(data?.teamMember) &&
									data.teamMember.map((member: any) => (
										<Tooltip title={member.name} key={member._id}>
											<Avatar>
												{member.name
													.split(" ")
													.map(
														(
															namePart: any[],
															index: number,
															array: string | any[]
														) =>
															index === 0 || index === array.length - 1
																? namePart[0]
																: null
													)
													.join("")}
											</Avatar>
										</Tooltip>
									))}
							</Avatar.Group>
						</div>
					</Flex>

					{/* <Flex style={{ height: "50vh", marginTop: "1rem" }}>
					{selectedRadio === "radio1" && (
						// <ReactQuill style={{ width: "100%", height: "100%" }} />
						<RitualNotes />
					)}
					{selectedRadio === "radio2" && data?.discussions && (
						<RitualChatInterface />
					)}
					{selectedRadio === "radio3" && data?.activity && (
						<RitualActivityTab />
					)}
					{selectedRadio === "radio4" && itemId && (
						<RitualLink
							itemId={itemId}
							openModal={openModal}
							setopenModal={setopenModal}
						/>
					)}
				</Flex> */}
				</Card>
				<AddChecklistItemModal
					visible={visible}
					onCreate={handleAddChecklistItem}
					onCancel={handleCancel}
				></AddChecklistItemModal>
			</Flex>
		</>
	);
};

export default RitualView;
