import React from "react";
import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	BarsOutlined,
	FileTextOutlined,
	GroupOutlined,
	UnorderedListOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Badge, Button, Flex, Popover, Select, Space, Typography } from "antd";
// import { useViewsStores } from "../../../stores/BacklogStore";
// import { planerViewStore } from "../../../stores/PlannerStore";

function Display({
	setListView,
	setCardView,
	setSelectedGroupBy,
	setListSelectedGroupBy,
	isCardView,
	setRowBy,
	RowBy,
	selectedGroupBy,
	ListSelectedGroupBy,
	CardOrderning,
	setDocsView,
	setCardOrderning,
	setSortBy,
	DocView,
}: {
	setListView: any;
	setCardView: any;
	setSelectedGroupBy: any;
	isCardView: boolean;
	setRowBy: any;
	RowBy: any;
	selectedGroupBy: any;
	CardOrderning: any;
	setDocsView: any;
	setCardOrderning: any;
	setSortBy: any;
	DocView: boolean;
	ListSelectedGroupBy: any;
	setListSelectedGroupBy: any;
}) {
	// const {
	// 	setListView,
	// 	setCardView,
	// 	setSelectedGroupBy,
	// 	isCardView,
	// 	setRowBy,
	// 	RowBy,
	// 	selectedGroupBy,
	// 	CardOrderning,
	// 	setDocsView,
	// 	setCardOrderning,
	// } = useViewsStores();
	// const { setSortBy } = planerViewStore();

	const { Option } = Select;

	const Gropubyoptions = [
		{
			key: "GroupBy",
			label: "Group By",
			children: [
				{
					label: "Type",
					key: "1",
					icon: <UserOutlined />,
				},
				{
					label: "Priority",
					key: "2",
					icon: <UserOutlined />,
				},
				{
					label: "Status",
					key: "3",
					icon: <UserOutlined />,
				},
				{
					label: "None",
					key: "4",
					icon: <UserOutlined />,
				},
			],
		},
		{
			key: "ShortBy",
			label: "Sort By",
			children: [
				{
					label: "Created date (Asc)",
					key: "created-asc",
				},
				{
					label: "Created date (Desc)",
					key: "created-desc",
				},
				{
					label: "No Sorting",
					key: "",
				},
			],
		},
		{
			key: "RowBy",
			label: "Row By",
			children: [
				{
					label: "type",
					key: "1",
					icon: <UserOutlined />,
				},
				{
					label: "priority",
					key: "2",
					icon: <UserOutlined />,
				},
				{
					label: "status",
					key: "3",
					icon: <UserOutlined />,
				},
				{
					label: "None",
					key: "4",
					icon: <UserOutlined />,
				},
			],
		},
		{
			key: "Ordering",
			label: "Ordering",
			children: [
				{
					label: "assignee",
					key: "assignee",
				},
				{
					label: "priority",
					key: "priority",
				},
				{
					label: "status",
					key: "status",
				},
				{
					label: "No Ordering",
					key: "None",
				},
			],
		},
	];

	const Handlegrouping = (value: any) => {
		setSelectedGroupBy(value);
	};

	const HandleListgrouping = (value: any) => {
		setListSelectedGroupBy(value);
	};

	const HandleSorting = (value: any) => {
		setSortBy(value);
	};

	const HandleRowChange = (value: any) => {
		setRowBy(value);
	};

	const HandleOrderingChange = (value: any) => {
		setCardOrderning(value);
	};

	return (
		<Popover
			title={<></>}
			placement="bottomRight"
			content={
				<>
					<div style={{ margin: "0.9vh 0.5vw" }}>
						<Space direction="vertical" style={{ fontSize: "1rem !important" }}>
							<Flex>
								<Space direction="horizontal">
									<Button
										style={{ borderRadius: "6px", fontSize: "14px" }}
										size="small"
										icon={<UnorderedListOutlined />}
										onClick={setListView}
									>
										List
									</Button>
									<Button
										style={{ borderRadius: "6px", fontSize: "14px" }}
										size="small"
										icon={<GroupOutlined />}
										onClick={setCardView}
									>
										Board
									</Button>
									{DocView && (
										<Button
											style={{ borderRadius: "6px", fontSize: "14px" }}
											size="small"
											icon={<FileTextOutlined />}
											onClick={setDocsView}
										>
											Docs
										</Button>
									)}
								</Space>
							</Flex>

							{!isCardView && (
								<>
									<Flex
										justify="space-between"
										align="center"
										style={{ marginTop: "0.5vh" }}
									>
										<Space direction="horizontal">
											<Typography.Text
												style={{ fontSize: "14px" }}
												type="secondary"
											>
												<UnorderedListOutlined /> Grouping
											</Typography.Text>
										</Space>
										<Select
											value={ListSelectedGroupBy}
											onChange={HandleListgrouping}
											style={{ width: "105px" }}
										>
											{Gropubyoptions[0].children.map((item) => (
												<Option key={item.label} label={item.label}>
													{item.label}
												</Option>
											))}
										</Select>
										{/* </Space> */}
									</Flex>

									<Flex justify="space-between" align="center">
										<Space direction="horizontal">
											<Typography.Text
												style={{ fontSize: "14px" }}
												type="secondary"
											>
												<ArrowUpOutlined />
												<ArrowDownOutlined style={{ marginRight: "4px" }} />
												Ordering
											</Typography.Text>
										</Space>
										<Select
											defaultValue="No Sorting"
											onChange={HandleSorting}
											style={{ width: "105px" }}
										>
											{Gropubyoptions[1].children.map((item) => (
												<Option key={item.label} label={item.label}>
													{item.label}
												</Option>
											))}
										</Select>
										{/* </Space> */}
									</Flex>
								</>
							)}

							{isCardView && (
								<>
									<Flex
										justify="space-between"
										align="center"
										style={{ width: "100%" }}
									>
										<Space direction="horizontal">
											<Typography.Text
												style={{ fontSize: "14px" }}
												type="secondary"
											>
												<BarsOutlined style={{ transform: "rotate(90deg)" }} />
												Columns
											</Typography.Text>
										</Space>
										<Select
											key={"Columns"}
											defaultValue="None"
											value={selectedGroupBy}
											onChange={Handlegrouping}
											style={{ width: "105px" }}
										>
											{Gropubyoptions[0].children.map((item) => (
												<Option key={item.label} label={item.label}>
													{item.label}
												</Option>
											))}
										</Select>
										{/* </Space> */}
									</Flex>

									<Flex
										justify="space-between"
										align="center"
										style={{ width: "100%" }}
									>
										<Space direction="horizontal">
											<Typography.Text
												style={{ fontSize: "14px" }}
												type="secondary"
											>
												<BarsOutlined />
												Rows
											</Typography.Text>
										</Space>
										<Select
											defaultValue="None"
											value={RowBy}
											onChange={HandleRowChange}
											key={"Rows"}
											style={{ width: "105px" }}
										>
											{Gropubyoptions[2].children.map((item) => (
												<Option
													key={item.label}
													label={item.label}
													disabled={
														selectedGroupBy.toLocaleLowerCase() === item.label
													}
												>
													{item.label}
												</Option>
											))}
										</Select>
										{/* </Space> */}
									</Flex>

									<Flex
										justify="space-between"
										align="center"
										style={{ width: "100%" }}
									>
										<Space direction="horizontal">
											<Typography.Text
												style={{ fontSize: "14px" }}
												type="secondary"
											>
												<ArrowUpOutlined />
												<ArrowDownOutlined style={{ marginRight: "4px" }} />
												Ordering
											</Typography.Text>
										</Space>
										<Select
											defaultValue="None"
											value={CardOrderning}
											onChange={HandleOrderingChange}
											key={"Ordering2"}
											style={{ width: "105px" }}
										>
											{Gropubyoptions[3].children.map((item) => (
												<Option key={item.label} label={item.key}>
													{item.label}
												</Option>
											))}
										</Select>
										{/* </Space> */}
									</Flex>
								</>
							)}
						</Space>

						{/* <Typography.Text type="secondary"> List Options</Typography.Text>
						<Typography.Paragraph italic type="secondary">
							{" "}
							Display properties{" "}
						</Typography.Paragraph>
						<Flex
							justify="flex-start"
							style={{
								width: "100%",
								maxWidth: "15rem", // Set maximum width
								margin: "0 auto", // Center the Flex container
								flexWrap: "wrap", // Enable wrapping
                                gap: "0.2rem"
							}}
						>
							<Tag>Priority</Tag>
							<Tag>ID</Tag>
							<Tag>Status</Tag>
							<Tag>Labels</Tag>
							<Tag>Project</Tag>
							<Tag>Cycles</Tag>
							<Tag>Duedate</Tag>
							<Tag>Created</Tag>
							<Tag>Updated</Tag>
							<Tag>Links</Tag>
							<Tag>Assignee</Tag>
						</Flex> */}
					</div>
				</>
			}
			trigger={"click"}
			style={{ textAlign: "center", border: "2px solid red" }}
		>
			{" "}
			<Flex justify="center" align="center">
				<Badge
					// count={alchemiBadgeCount}
					// showZero={showAIBadge}
					color="blue"
					offset={[-40, 0]}
				>
					<Button
						style={{ borderRadius: "6px", fontSize: "14px" }}
						size="small"
						icon={<BarsOutlined />}
					>
						Display
					</Button>
				</Badge>
			</Flex>
		</Popover>
	);
}

export default Display;
