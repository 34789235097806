import React, { useEffect } from "react";
import { Alert, Button, Drawer, Flex, Form, Tabs, Tag } from "antd";
import { planerViewStore } from "../../stores/PlannerStore";

import ChatInterface from "./DiscussionTab";
import QuickView from "./QuickView";
import ActivityTab from "./ActivityTab";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import FeatureQuickView from "./FeatureQuickView";
import UserStoryQuickView from "./UserStoryQuickView";
import WorkflowTaskQuickView from "./WorkflowTaskQuickView";
import { useViewsStores } from "../../stores/BacklogStore";
import MapViewTab from "./MapViewTab";
import useAIActivityStore from "../../stores/AIActivityStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import LinkTab from "./LinkTab";

// import CreateFeaturePlanModel from "./abc";

const { TabPane } = Tabs;

interface DrawerComponentProps {
	visible: boolean;
	onClose: () => void;
	data: any; // Replace 'any' with the actual type of your data
}

const DrawerComponent: React.FC<DrawerComponentProps> = ({
	visible,
	onClose,
	data,
}) => {
	const [form] = Form.useForm();

	const { selectedItemDetails } = planerViewStore();
	const { DrawerTab, setDrawerTab } = useViewsStores();

	useEffect(() => {
		// Set form fields value when data changes
		form.setFieldsValue({
			priority: selectedItemDetails?.priority,
			type: selectedItemDetails?.bug,

			// Add more fields as needed
		});
	}, [data, form]);
	const navigate = useNavigate();
	const { setEditModalOpen, setOpen } = planerViewStore();
	const handleDrawerCancel = () => {
		setOpen(false);
		setEditModalOpen(true);
		navigate(`/space/${currentWorkspace._id}/planner/edit`);
	};

	const handleTabChange = (key: string) => {
		setDrawerTab(key);
	};

	const { setAIConversationContext } = useAIActivityStore((state: any) => ({
		setAIConversationContext: state.setAIConversationContext,
	}));

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const handleElaboration = () => {
		setAIConversationContext({
			type: "feature-elaboration",
			data: {
				workspaceId: currentWorkspace._id,
				workitemId: selectedItemDetails._id,
				userProfile: userProfileStoreInstance.profile,
			},
		});
		navigate(`/fd/preprocess/chat/feature-elaboration`);
	};

	const handleRefinement = () => {
		setAIConversationContext({
			type: "feature-refinement",
			data: {
				workspaceId: currentWorkspace._id,
				workitemId: selectedItemDetails._id,
				userProfile: userProfileStoreInstance.profile,
			},
		});
		navigate(`/fd/preprocess/chat/feature-refinement`);
	};

	return (
		<Drawer
			placement="right"
			closable={true}
			onClose={onClose}
			getContainer={false}
			open={visible}
			drawerStyle={{ border: "none" }}
			width={"55%"}
		>
			<>
				{/* {selectedItemDetails?.type === "FEATURE" && (
					<Alert
						message="Feature is requires for elaboration"
						type="info"
						style={{ borderRadius: "4px", textAlign: "left" }}
						action={
							<Button
								size="small"
								onClick={() => navigate(`/wfitemfd/refinement`)}
							>
								Open
							</Button>
						}
						showIcon
					/>
				)} */}
				{selectedItemDetails?.type === "FEATURE" &&
					selectedItemDetails.workflowStage == "FEATURE_REFINEMENT" &&
					selectedItemDetails.workflowStatus == "OPEN" && (
						<Alert
							message="Feature is requires for refinement"
							type="info"
							style={{ borderRadius: "4px", textAlign: "left" }}
							action={
								<Button size="small" onClick={() => handleRefinement()}>
									Chat
								</Button>
							}
							showIcon
						/>
					)}

				{selectedItemDetails?.type === "FEATURE" &&
					selectedItemDetails.workflowStage == "FEATURE_ELABORATION" &&
					selectedItemDetails.workflowStatus == "OPEN" && (
						<Alert
							message="Feature is requires for elaboration"
							type="info"
							style={{ borderRadius: "4px", textAlign: "left" }}
							action={
								<Button size="small" onClick={() => handleElaboration()}>
									Chat
								</Button>
							}
							showIcon
						/>
					)}

				<Flex style={{ alignItems: "center" }} gap={20}>
					<h3>
						{selectedItemDetails?.WorkitemId
							? selectedItemDetails?.WorkitemId + " : "
							: ""}{" "}
						{selectedItemDetails?.title}
					</h3>{" "}
					{"  "}
					<EditOutlined onClick={handleDrawerCancel} />
					<Tag color="blue">{selectedItemDetails?.type}</Tag>
				</Flex>

				<Tabs
					defaultActiveKey={DrawerTab}
					activeKey={DrawerTab}
					onChange={handleTabChange}
				>
					<TabPane tab="Details" key="details">
						{selectedItemDetails?.type === "FEATURE" && <FeatureQuickView />}
						{(selectedItemDetails?.type === "USER STORY" ||
							selectedItemDetails?.type === "USER_STORY") && (
							<UserStoryQuickView />
						)}
						{selectedItemDetails?.type === "WORKFLOW_TASK" && (
							<WorkflowTaskQuickView />
						)}
						{selectedItemDetails?.type === "TASK" && <QuickView />}
						{selectedItemDetails?.type === "" && <QuickView />}
					</TabPane>
					<TabPane tab="Discussion" key="discussion">
						<ChatInterface />
					</TabPane>
					<TabPane tab="Activity" key="activity">
						<ActivityTab />
					</TabPane>
					<TabPane tab="MapView" key="mapview">
						<MapViewTab page={"Drawer"} />
					</TabPane>
					<TabPane tab="Links" key="link">
						<LinkTab />
					</TabPane>

					{/* <TabPane tab="Links" key="links">
					Links content goes here
				</TabPane> */}
				</Tabs>
			</>
		</Drawer>
	);
};

export default DrawerComponent;
