import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { planerViewStore } from "../../stores/PlannerStore";
import { Layout, Flex, Tabs, Card, Tag, message } from "antd";
import TaskChatInterface from "./TaskDiscussionTab";
import ViewTaskActivity from "./ViewTaskActivity";
import QuickViewTask from "./QuickViewTask";
import MapViewTab from "./MapViewTab";

import ViewPageNavbar from "../../layout/ViewPageNavbar";
import { useRequest } from "ahooks";
import { getWorkItemsById } from "../../api/WorkItemAPI";

const { TabPane } = Tabs;
const { Content } = Layout;

const TaskDetailsPage = () => {
	const { taskId } = useParams();
	const { open } = planerViewStore();
	console.log(open, "open");
	const [taskDetails, setTaskDetails] = useState<any | null>(null);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const [activeTab, setActiveTab] = useState<string>("details");

	const handleTabChange = (key: string) => {
		setActiveTab(key);
	};

	const { data: workitemByIdData, run: getWorkItemById } = useRequest(
		(workItemId) =>
			getWorkItemsById(workItemId, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				message.success(`${result.title} ${result.type} details`);
				console.log(result, "feature data");
			},
			onError: (error) => {
				message.error(`Failed to fetch workitem feature : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (workitemByIdData) {
			setTaskDetails(workitemByIdData);
		}
	}, [workitemByIdData]);

	useEffect(() => {
		getWorkItemById(taskId);
	}, [taskId]);

	const renderTaskDetails = () => {
		if (!taskDetails) {
			return <div>No Data</div>;
		}
		return (
			<Layout style={{ background: "white" }}>
				<Flex style={{ alignItems: "center" }} gap={20}>
					<h3 style={{ marginTop: "3vh", marginLeft: "8vw" }}>
						{taskDetails?.title}
					</h3>
					<Tag color="blue">{taskDetails?.type}</Tag>
				</Flex>
				<Card
					style={{
						width: "100%",
						marginTop: "2vh",
						maxHeight: "80vh",
						overflowY: "auto",
					}}
				>
					<Layout style={{ background: "white" }}>
						<Content style={{ padding: "20px" }}>
							<div style={{ marginLeft: "20px" }}>
								<>
									{/* <Flex style={{ alignItems: "center" }} gap={20}>
										<h3>{taskDetails?.title}</h3>
										<Tag color="blue">{taskDetails?.type}</Tag>
									</Flex> */}
									<Tabs
										defaultActiveKey="details"
										activeKey={activeTab}
										onChange={handleTabChange}
									>
										<TabPane tab="Details" key="details">
											{taskDetails.type === "TASK" && (
												<QuickViewTask taskId={taskId} />
											)}
										</TabPane>
										<TabPane tab="Discussion" key="discussion">
											<TaskChatInterface taskId={taskId} />
										</TabPane>
										<TabPane tab="Activity" key="activity">
											<ViewTaskActivity taskId={taskId} />
										</TabPane>
										<TabPane tab="MapView" key="mapview">
											<MapViewTab page={"viewTask"} />
										</TabPane>
									</Tabs>
								</>
							</div>
						</Content>
					</Layout>
				</Card>
			</Layout>
		);
	};

	return (
		<Layout style={{ backgroundColor: "white" }}>
			<Layout style={{ marginTop: ".5vh", backgroundColor: "#f5f8fe" }}>
				<ViewPageNavbar />
				<div>{renderTaskDetails()}</div>
			</Layout>
		</Layout>
	);
};

export default TaskDetailsPage;
