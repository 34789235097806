import { Button, Collapse, Flex, Form, Layout, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { CaretRightOutlined } from "@ant-design/icons";
import { getDashboardLists } from "../../api/DashboardApi";
import { useRequest } from "ahooks";
import PreferenceNav from "./PreferenceNav";

// type LayoutType = Parameters<typeof Form>[0]["layout"];

const UserProfilePreference: React.FC = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [workspaceList, setWorkspaceList] = useState<any[]>([]);
	const [defaultWorkspace, setDefaultWorkspace] = useState<any>();
	const [defaultDashboard, setDefaultDashboard] = useState<string>();
	const [Dashboardlist, setDashboardList] = useState<any[]>([]);
	const onChangeWorkspace = (value: any) => {
		console.log(`selected ${value}`);
		setDefaultWorkspace(value);
	};
	const onChangeDashboard = (value: string) => {
		setDefaultDashboard(value);
	};

	const [form] = Form.useForm();
	//const [formLayout, setFormLayout] = useState<LayoutType>("vertical");

	// const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
	// 	setFormLayout(layout);
	// };

	// const formItemLayout =
	// 	formLayout === "horizontal"
	// 		? { labelCol: { span: 4 }, wrapperCol: { span: 14 } }
	// 		: null;

	// const buttonItemLayout =
	// 	formLayout === "horizontal"
	// 		? { wrapperCol: { span: 14, offset: 4 } }
	// 		: null;

	const getWorkspaceList = async () => {
		if (userProfileStoreInstance.idToken) {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/workspace`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const json = await response.json();
				//setWorkspace(json);
				console.log("json", json);
				//setError(null);
				setWorkspaceList(json);

				// const sharedWorkspaces = await fetch(
				// 	`${ServerLink}/workspace-permissions/sharedworkspaces`,
				// 	{
				// 		headers: {
				// 			Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				// 		},
				// 	}
				// );
				// const sharedWorkspacesJson = await sharedWorkspaces.json();

				// setSharedWorkspaces(sharedWorkspacesJson);
			} catch (error: any) {
				console.error(error);
				console.error(error);
				//setError(error);
				message.error(`An error occurred: ${error.message}`);
			}
		}
	};

	const getUserProfile = async () => {
		if (userProfileStoreInstance.profile) {
			if (
				userProfileStoreInstance.profile &&
				userProfileStoreInstance.profile.preferences &&
				userProfileStoreInstance.profile.preferences.defaultWorkspaceId
			)
				setDefaultWorkspace(
					userProfileStoreInstance.profile.preferences.defaultWorkspaceId
				);
			if (
				userProfileStoreInstance.profile &&
				userProfileStoreInstance.profile.preferences &&
				userProfileStoreInstance.profile.preferences.defaultDashboardId
			)
				setDefaultDashboard(
					userProfileStoreInstance.profile.preferences.defaultDashboardId
				);
			return;
		} else {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/user/profile`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const json = await response.json();
				userProfileStoreInstance.setProfile(json);
			} catch (error: any) {
				console.error(error);
				message.error(`An error occurred: ${error.message}`);
			}
		}
	};

	const saveUserPreferences = async () => {
		try {
			const userProfile = userProfileStoreInstance.profile;
			userProfile.preferences = {
				defaultWorkspaceId: defaultWorkspace,
				defaultDashboardId: defaultDashboard,
			};
			console.log("userProfile", userProfile);
			const profileId = userProfile._id;

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/user-profiles/${profileId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(userProfile),
				}
			);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const json = await response.json();
			userProfileStoreInstance.setProfile(json);
			message.success("User preferences saved successfully");
		} catch (error: any) {
			console.error(error);
			message.error(`An error occurred: ${error.message}`);
		}
	};

	useEffect(() => {
		getUserProfile();
		getWorkspaceList();
	}, []);

	useEffect(() => {
		if (userProfileStoreInstance.profile) {
			if (
				userProfileStoreInstance.profile &&
				userProfileStoreInstance.profile.preferences &&
				userProfileStoreInstance.profile.preferences.defaultWorkspaceId
			) {
				setDefaultWorkspace(
					userProfileStoreInstance.profile.preferences.defaultWorkspaceId
				);
				console.log(
					userProfileStoreInstance.profile.preferences.defaultWorkspaceId
				);
			}
		}
		form.setFieldValue("defaultWorkspace", defaultWorkspace);
	}, [workspaceList]);

	const { data: dashboardListData, run: fetchDashboardList } = useRequest(
		() => getDashboardLists(userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				// message.success("Dashboard data fetched successfully");
				console.log(data);
			},
			onError: (error) => {
				console.log(error);
				message.error(`error:${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (dashboardListData) {
			const data = dashboardListData.map(
				(item: { title: string; _id: string }) => ({
					title: item.title,
					_id: item._id,
				})
			);
			setDashboardList(data);
		}
	}, [dashboardListData]);

	useEffect(() => {
		fetchDashboardList();
	}, []);

	console.log("Dashboardlist", Dashboardlist);

	useEffect(() => {
		if (defaultDashboard && Dashboardlist.length > 0) {
			const selectedDashboard = Dashboardlist.find(
				(dashboard) => dashboard._id === defaultDashboard
			);
			if (selectedDashboard) {
				form.setFieldsValue({
					defaultDashboard: selectedDashboard.title,
				});
			}
		}
	}, [defaultDashboard, Dashboardlist, form]);

	return (
		<>
			<PreferenceNav />
			<Layout style={{ background: "white", height: "100%" }}>
				<div style={{ width: "80vw" }}>
					<Form
						layout={`vertical`}
						form={form}
						onFinish={saveUserPreferences}
						//initialValues={{ layout: formLayout }}
						//onValuesChange={onFormLayoutChange}
						style={{ maxWidth: 600 }}
					>
						<Flex style={{ marginLeft: "3rem", flexDirection: "column" }}>
							<Collapse
								bordered={false}
								defaultActiveKey={["1"]}
								expandIcon={({ isActive }) => (
									<CaretRightOutlined rotate={isActive ? 90 : 0} />
								)}
								//style={{ background: token.colorBgContainer }}
								items={[
									{
										key: "1",
										label: "Default workspace",
										children: (
											<Form.Item
												name="defaultWorkspace"
												// label="Select"
												hasFeedback
												// rules={[
												// 	{ required: true, message: "Please select your country!" },
												// ]}
											>
												<Select
													onSelect={onChangeWorkspace}
													style={{ width: "15vw" }}
													value={defaultWorkspace}
												>
													{workspaceList.map((workspace: any) => (
														<Select.Option
															key={workspace._id}
															value={workspace._id}
														>
															{workspace.name}
														</Select.Option>
													))}
													{/* <Select.Option value="sample">Sample</Select.Option> */}
												</Select>
											</Form.Item>
										),
									},
								]}
							/>

							<Collapse
								bordered={false}
								defaultActiveKey={["1"]}
								expandIcon={({ isActive }) => (
									<CaretRightOutlined rotate={isActive ? 90 : 0} />
								)}
								items={[
									{
										key: "1",
										label: "Default Dashboard",
										children: (
											<Form.Item name="defaultDashboard">
												<Select
													onChange={onChangeDashboard}
													style={{ width: "15vw" }}
													value={defaultDashboard}
												>
													{Dashboardlist.map((dashboard) => (
														<Select.Option
															key={dashboard._id}
															value={dashboard._id}
														>
															{dashboard.title}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										),
									},
								]}
							/>

							{/* <Collapse
						items={[
							{
								key: "2",
								label: "This is default size panel header",
								children: <></>,
							},
						]}
					/> */}

							<Form.Item>
								<Button
									type="primary"
									onClick={saveUserPreferences}
									style={{ marginLeft: "1rem" }}
								>
									Submit
								</Button>
							</Form.Item>
						</Flex>
					</Form>
				</div>
			</Layout>
		</>
	);
};

export default UserProfilePreference;
