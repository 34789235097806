import { create } from "zustand";
import { persist } from "zustand/middleware";
interface Bookmark {
	id: string;
	title: string;
	url: string;
}

export type UserProfileStoreType = {
	bookmarks(bookmarks: any): unknown;
	profile: any | null;
	setProfile: (profile: any | null) => void;
	idToken: string | null;
	setIdToken: (token: string | null) => void;
	profileId: string | null;
	setProfileId: (id: string | null) => void;
	permissions: Record<string, string>;
	setPermissions: (data: Record<string, string>) => void;
	setBookmarks: (data: Bookmark[]) => void;
	menuMode: any | null;
	setMenuMode: (menuMode: any | null) => void;
	clearStore: () => void;
};

const userProfileStore = create(
	persist(
		(set) => ({
			profile: {},
			setProfile: (profile: any | null) => set({ profile: profile }),
			idToken: null,
			setIdToken: (token: string | null) => set({ idToken: token }),
			profileId: null,
			setProfileId: (id: string | null) => set({ profileId: id }),
			permissions: {},
			setPermissions: (data: any) => set({ permissions: data }),
			setBookmarks: (data: Bookmark[]) => set({ bookmarks: data }),
			menuMode: "horizontal",
			setMenuMode: (mode: any) => set({ menuMode: mode }),
			clearStore: () =>
				set({
					profile: {},
					idToken: null,
					profileId: null,
					permissions: {},
					menuMode: "horizontal",
				}),
		}),
		{
			name: "userProfileStore",
		}
	)
);

export default userProfileStore;
