import { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link, useParams, useLocation } from "react-router-dom";
import useWorkspaceStore from "../../stores/WorkspaceState";

function DDDMenu() {
	const [selectedMenuItem, setSelectedMenuItem] = useState("Core");
	const { id } = useParams<{ id: string }>();
	const location = useLocation();

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	useEffect(() => {
		const section = location.pathname.split("/").pop();
		const sectionToMenuItem = {
			core: "Core",
			advanced: "Advanced Patterns",
			strategic: "Strategic Design",
		};

		setSelectedMenuItem(
			sectionToMenuItem[section as keyof typeof sectionToMenuItem] || "Core"
		);
	}, [location.pathname]);

	useEffect(() => {
		const menuItemToSection: Record<string, string> = {
			Core: "core",
			"Advanced Patterns": "advanced",
			"Strategic Design": "strategic",
		};

		if (
			location.pathname.includes(
				`/space/${currentWorkspace._id}/planner/de/ddd/${menuItemToSection[selectedMenuItem]}/${id}`
			)
		) {
			return;
		}

		window.history.replaceState(
			null,
			"",
			`/space/${currentWorkspace._id}/planner/de/ddd/${menuItemToSection[selectedMenuItem]}/${id}`
		);
	}, [selectedMenuItem, id, location.pathname]);

	const handleMenuClick = (e: any) => {
		setSelectedMenuItem(e.key);
	};

	return (
		<div style={{ paddingTop: "10vh", overflow: "auto" }}>
			<Menu
				className="leftmenu"
				defaultSelectedKeys={[selectedMenuItem]}
				mode="inline"
				onClick={handleMenuClick}
				style={{ overflow: "auto", height: "400px" }}
			>
				<div style={{}}>
					<Menu.Item key="Core" className="ux-persona-leftmenu-item">
						<Link
							to={`/space/${currentWorkspace._id}/planner/de/ddd/core/${id}`}
						>
							Core
						</Link>
					</Menu.Item>
					<Menu.Item
						key="Advanced Patterns"
						className="ux-persona-leftmenu-item"
					>
						<Link
							to={`/space/${currentWorkspace._id}/planner/de/ddd/advanced/${id}`}
						>
							Advanced Patterns
						</Link>
					</Menu.Item>
					<Menu.Item
						key="Strategic Design"
						className="ux-persona-leftmenu-item "
					>
						<Link
							to={`/space/${currentWorkspace._id}/planner/de/ddd/strategic/${id}`}
						>
							Strategic Design
						</Link>
					</Menu.Item>
				</div>
			</Menu>
		</div>
	);
}

export default DDDMenu;
