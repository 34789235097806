import React from "react";
import { Avatar, Card, Flex, Space, Tag, Tooltip } from "antd";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import "./CardComponent.css";
import {
	BuildFilled,
	DashOutlined,
	ExclamationOutlined,
	EyeFilled,
	FallOutlined,
	HarmonyOSOutlined,
	RiseOutlined,
} from "@ant-design/icons";
import { planerViewStore } from "../../stores/PlannerStore";
import { useViewsStores } from "../../stores/BacklogStore";
import { motion } from "framer-motion";

const DraggableCard: React.FC<{ task: any }> = ({ task }) => {
	console.log("task", task);
	const { setOpen, setSelectedItemDetails } = planerViewStore();
	const { setDrawerTab } = useViewsStores();
	const {
		setNodeRef,
		attributes,
		listeners,
		transform,
		transition,
		isDragging,
	} = useSortable({
		id: task._id,
		data: {
			type: "Task",
			task,
		},
	});

	const style = {
		transition,
		transform: CSS.Transform.toString(transform),
	};

	if (isDragging) {
		return (
			<div ref={setNodeRef} style={style} className="style-classs">
				Hello
			</div>
		);
	}

	const assigneeNameWords = task?.assignee?.name?.split(" ") || [];
	const firstName = assigneeNameWords[0] || "";
	const lastName =
		assigneeNameWords.length > 1
			? assigneeNameWords[assigneeNameWords.length - 1]
			: "";

	const avatarText = `${firstName.charAt(0)}${lastName.charAt(
		0
	)}`.toUpperCase();

	const getRandomPastelColor = () => {
		const hue = 219;
		const pastelColor = `hsl(${hue}, 80%, 80%)`;
		return pastelColor;
	};

	const pastelColor = getRandomPastelColor();
	// Define function to get tag color based on priority

	const getStatusTagColor = (status: string) => {
		switch (status) {
			case "READY":
				return "blue";
			case "COMPLETED":
				return "green";
			case "PENDING":
				return "red";
			case "IN_PROGRESS":
				return "orange";
			default:
				return "blue";
		}
	};

	const getTypeTagTextColor = (priority: string) => {
		console.log("Priority:", priority);
		switch (priority) {
			case "FEATURE":
				return "#FFB6C1"; // Pastel Pink
			case "USER STORY":
				return "#FFA07A"; // Pastel Salmon
			case "Epic":
				return "#FF7F50"; // Pastel Coral
			case "Bug":
				return "#FF6347"; // Pastel Tomato
			default:
				return "#87CEEB"; // Default Pastel Sky Blue
		}
	};

	const handleEyeIconClick = (newData: any[]) => {
		setOpen(true);
		setDrawerTab("details");
		console.log(open);

		setSelectedItemDetails(newData);
	};

	const truncateDescription = (text: string) => {
		const lines = text?.split("\n");
		if (lines?.length > 2) {
			return lines?.slice(0, 2).join("\n") + "...";
		}

		return text;
	};

	const getPriorityIcon = (priority: string) => {
		switch (priority) {
			case "URGENT":
			case "Urgent":
				return <RiseOutlined style={{ color: "orange" }} />;
			case "IMPORTANT":
			case "Important":
				return <ExclamationOutlined style={{ color: "red" }} />;
			case "Medium":
				return <DashOutlined style={{ color: "golden" }} />;
			case "LOW":
			case "Low":
				return <FallOutlined style={{ color: "green" }} />;
			default:
				return <HarmonyOSOutlined />;
		}
	};

	const getTagcolor = (priority?: string) => {
		if (!priority) return "blue";
		switch (priority.toLowerCase()) {
			case "urgent":
				return "orange";
			case "important":
				return "red";
			case "medium":
				return "gold";
			case "low":
				return "green";
			default:
				return "blue";
		}
	};

	return (
		<motion.div
			layout
			whileHover={{ scale: 1.05 }}
			whileTap={{ scale: 0.95 }}
			drag
			dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
			initial={{ scale: 0.8, opacity: 0 }}
			animate={{ scale: 1, opacity: 1 }}
			transition={{
				duration: 0.5,
				delay: 0.1,
			}}
		>
			<Card
				ref={setNodeRef}
				style={style}
				className="style-class"
				key={task._id}
			>
				<Flex {...attributes} {...listeners} align="start">
					<Space
						direction="vertical"
						style={{
							width: "100%",
							// marginLeft: "0.5rem",
							textAlign: "left",
						}}
					>
						<Flex justify="space-between" style={{ marginTop: "1vh" }}>
							<Flex gap={"0.5rem"}>
								<BuildFilled
									style={{
										fontSize: "1.3rem",
										color: getTypeTagTextColor(task.type),
									}}
								/>
								<div
									style={{
										// fontWeight: "bold",
										marginTop: "3px",
									}}
								>
									{task.WorkitemId} {task.title}
								</div>
							</Flex>
							<Flex>
								<EyeFilled
									onClick={() => handleEyeIconClick(task)}
									style={{
										fontSize: "1.1rem",
										marginRight: "1vw",
										color: "#0004bf",
										marginTop: "2px",
									}}
								/>
							</Flex>
						</Flex>

						<Flex
							style={{
								height: "3rem",
								overflowY: "hidden",
								minHeight: "1.8rem",
							}}
						>
							<div
								dangerouslySetInnerHTML={{
									__html: truncateDescription(task.description),
								}}
								style={{ lineHeight: "1.8rem" }}
								className="CardDiscription"
							/>
						</Flex>
					</Space>
				</Flex>
				<Flex
					justify="space-between"
					style={{
						marginTop: "2rem",
						// marginLeft: "0.5vw",
						alignItems: "center",
						marginBottom: "1vh",
					}}
				>
					<Tooltip
						title={task.assignee ? `${task.assignee.name}` : ""}
						placement="top"
					>
						<Avatar
							size="small"
							style={{
								backgroundColor: pastelColor,
							}}
						>
							{" "}
							{avatarText}
						</Avatar>
					</Tooltip>
					<Flex>
						<Tag
							color={getStatusTagColor(task.status)}
							style={{
								color: "black",
								fontSize: "0.8rem",
								width: "fit-content",
								fontWeight: "500",
								textAlign: "center",
								border: "none",
								padding: "5px 10px",
							}}
						>
							{task.status}
						</Tag>

						{/* <Tag
							icon={<AlertFilled />}
							color={getPriorityTagColor(task.priority)}
							style={{
								color: getPriorityTagTextColor(task.priority),
								fontSize: "0.8rem",
								width: "fit-content",
								fontWeight: "500",
								textAlign: "center",
								border: "none",
								padding: "5px 20px",
							}}
						>
							{task.priority}
						</Tag> */}
						<Tooltip title={task.priority ? task.priority : "Null"}>
							<Tag
								color={getTagcolor(task.priority)}
								style={{
									textAlign: "center",
									padding: "3px 8px",
								}}
							>
								{getPriorityIcon(task.priority)}
							</Tag>
						</Tooltip>
					</Flex>
				</Flex>
			</Card>
		</motion.div>
	);
};

export default DraggableCard;
