// import { ArrowLeftOutlined } from '@ant-design/icons';
// import { Breadcrumb, Button, ColorPicker, Flex, Form, Input, Modal, message } from 'antd';
// import Title from 'antd/es/typography/Title';
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import userProfileStore, { UserProfileStoreType } from '../stores/UserProfileStore';

// function ConnectionManagementNav({ openTagModal, setOpenTagModal }: { openTagModal: boolean, setOpenTagModal: React.Dispatch<React.SetStateAction<boolean>> }): any {
//     const navigate = useNavigate();
//     const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
//     const [tagName, setTagName] = useState("");
// 	const [category, setCategory] = useState("");
// 	const [description, setDescription] = useState("");
// 	const [color, setColor] = useState("");

// 	const handleAddTag = async () => {
// 		const name = tagName;
// 		console.log("Tag name:", name);

// 		try {
// 			const response = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
// 				method: "POST",
// 				headers: {
// 					"Content-Type": "application/json",
// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
// 				},
// 				body: JSON.stringify({
// 					name: tagName,
// 					category,
// 					description,
// 					color: color,
// 				}),
// 			});
// 			if (response.ok) {
// 				const responseData = await response.json();
// 				console.log("Response from the backend:", responseData);
// 				message.success("Tag added successfully");
// 				setOpenTagModal(false);
// 			} else {
// 				const errorData = await response.json();
// 				message.error(`Error adding tag. Please try again.${errorData}`);
// 			}
// 		} catch (error: any) {
// 			message.error(`Error adding tag. Please try again: ${error.message}`);
// 		}
// 	};

//   return (
//         <Flex
//             justify="space-between"
//             align="center"
//             style={{
//                 padding: "20px 50px 20px 50px",
//                 margin: "4px 0 0px",
//                 backgroundColor: "rgb(245, 248, 254)",
//             }}
//         >
//             <Flex align="center" gap={16}>
//                 <Button
//                     shape="circle"
//                     icon={<ArrowLeftOutlined />}
//                     size="large"
//                     onClick={() => navigate("/")}
//                     style={{ color: "#0444BF", borderColor: "#0444BF" }}
//                 />

//                 <Flex vertical>
//                     <Breadcrumb>
//                         <Breadcrumb.Item>Settings</Breadcrumb.Item>
//                     </Breadcrumb>
//                     <Title
//                         level={5}
//                         style={{
//                             margin: 0,
//                             textAlign: "left",
//                             fontFamily: "Poppins",
//                         }}
//                     >
//                         Tag Mangement
//                     </Title>
//                 </Flex>
//             </Flex>
//             <>
//             <Button type="primary" onClick={() => setOpenTagModal(true)}>
// 						Add Tag
// 					</Button>
// 					<Modal
// 						title="Add Tag"
// 						visible={openTagModal}
// 						onCancel={() => setOpenTagModal(false)}
// 						onOk={handleAddTag}
// 					>
// 						<Form layout="horizontal">
// 							<Flex justify="space-between" align="center">
// 								<Form.Item
// 									label="Tag"
// 									name="tagName"
// 									rules={[
// 										{
// 											required: true,
// 											message: "Please enter tag name",
// 										},
// 									]}
// 									style={{ width: "100%" }}
// 									labelCol={{ span: 6 }}
// 									wrapperCol={{ span: 18 }}
// 								>
// 									<Input
// 										placeholder="Enter tag name"
// 										value={tagName}
// 										onChange={(event) => setTagName(event.target.value)}
// 									/>
// 								</Form.Item>
// 							</Flex>

// 							<Flex justify="space-between" align="center">
// 								<Form.Item
// 									label="Category"
// 									name="category"
// 									rules={[
// 										{
// 											required: true,
// 											message: "Please enter tag category",
// 										},
// 									]}
// 									style={{ width: "100%" }}
// 									labelCol={{ span: 6 }}
// 									wrapperCol={{ span: 18 }}
// 								>
// 									<Input
// 										placeholder="Enter tag category"
// 										value={category}
// 										onChange={(event) => setCategory(event.target.value)}
// 									/>
// 								</Form.Item>
// 							</Flex>

// 							<Flex justify="space-between" align="center">
// 								<Form.Item
// 									label="Description"
// 									name="description"
// 									rules={[
// 										{
// 											required: true,
// 											message: "Please enter tag description",
// 										},
// 									]}
// 									style={{ width: "100%" }}
// 									labelCol={{ span: 6 }}
// 									wrapperCol={{ span: 18 }}
// 								>
// 									<Input.TextArea
// 										placeholder="Enter tag description"
// 										value={description}
// 										onChange={(event) => setDescription(event.target.value)}
// 									/>
// 								</Form.Item>
// 							</Flex>

// 							<Flex justify="space-between" align="center">
// 								<Form.Item
// 									label="Color"
// 									name="color"
// 									getValueFromEvent={(color) => {
// 										setColor("#" + color.toHex());
// 										return "#" + color.toHex();
// 									}}
// 									rules={[
// 										{
// 											required: false,
// 											message: "Please select tag color",
// 										},
// 									]}
// 									style={{ width: "90%" }}
// 									labelCol={{ span: 6 }}
// 									wrapperCol={{ span: 18 }}
// 								>
// 									<ColorPicker
// 										defaultValue="#1677ff"
// 										showText={(color) => (
// 											<span>Color: ({color.toHexString()})</span>
// 										)}
// 									/>
// 								</Form.Item>
// 							</Flex>
// 						</Form>
// 					</Modal>
// 				</>
//         </Flex>
//   );
// }

// export default ConnectionManagementNav;

// import React, { useState } from "react";
// import {
// 	Button,
// 	Modal,
// 	Form,
// 	Input,
// 	message,
// 	Flex,
// 	Breadcrumb,
// 	Select,
// } from "antd";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../stores/UserProfileStore";
// import useWorkspaceStore from "../stores/WorkspaceState";
// import { ArrowLeftOutlined } from "@ant-design/icons";
// import Title from "antd/es/typography/Title";
// import { useNavigate } from "react-router-dom";
// import GithubConnectionDetails from "./connections/GithubConnectionDetails";
// import JiraConnectionDetails from "./connections/JiraConnectionDetails";

// function ConnectionManagementNav({
// 	openConnectionModal,
// 	setOpenConnectionModal,
// }: {
// 	openConnectionModal: boolean;
// 	setOpenConnectionModal: React.Dispatch<React.SetStateAction<boolean>>;
// }): any {
// 	const [connectionType, setConnectionType] = useState("");
// 	//const [connectionValue, setConnectionValue] = useState("");
// 	const [connectionName, setConnectionName] = useState("");

// 	const [connectionData, setConnectionData] = useState<any[]>([]);
// 	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
// 	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
// 		currentWorkspace: state.currentWorkspace,
// 	}));
// 	const navigate = useNavigate();

// 	const handleAddConnection = () => {
// 		console.log("in connection add");
// 		console.log("currentWorkspace._id is :", currentWorkspace._id);
// 		const APIUrl = `${process.env.REACT_APP_API_URL}/connection`;
// 		const data = {
// 			type: connectionType,
// 			name: connectionName,
// 			workspaceId: currentWorkspace._id,
// 			data: connectionData,
// 			//lastnumber: 0,
// 		};
// 		fetch(APIUrl, {
// 			method: "POST",
// 			body: JSON.stringify(data),
// 			headers: {
// 				"Content-Type": "application/json; charset=UTF-8",
// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
// 			},
// 		})
// 			.then((response) => response.json())
// 			.then((data) => {
// 				console.log("Success:", data);
// 				message.success("Connection added successfully!");
// 				setOpenConnectionModal(false);
// 			})
// 			.catch((error) => {
// 				console.error("Error:", error);
// 				message.error("Error adding Connection");
// 			});
// 	};
// 	const formItemLayout = {
// 		labelCol: {
// 			xs: { span: 24 },
// 			sm: { span: 10 }, // Adjusted label width for "Type"
// 		},
// 		wrapperCol: {
// 			xs: { span: 24 },
// 			sm: { span: 14 }, // Adjusted wrapper width for "Type"
// 		},
// 	};

// 	return (
// 		<Flex
// 			justify="space-between"
// 			align="center"
// 			style={{
// 				padding: "20px 50px 20px 50px",
// 				margin: "4px 0 0px",
// 				backgroundColor: "rgb(245, 248, 254)",
// 			}}
// 		>
// 			<Flex align="center" gap={16}>
// 				<Button
// 					shape="circle"
// 					icon={<ArrowLeftOutlined />}
// 					size="large"
// 					onClick={() => navigate("/")}
// 					style={{ color: "#0444BF", borderColor: "#0444BF" }}
// 				/>

// 				<Flex vertical>
// 					<Breadcrumb>
// 						<Breadcrumb.Item>Workspace Settings</Breadcrumb.Item>
// 					</Breadcrumb>
// 					<Title
// 						level={5}
// 						style={{
// 							margin: 0,
// 							textAlign: "left",
// 							fontFamily: "Poppins",
// 						}}
// 					>
// 						Connection Mangement
// 					</Title>
// 				</Flex>
// 			</Flex>

// 			<>
// 				<Button type="primary" onClick={() => setOpenConnectionModal(true)}>
// 					Add Connection
// 				</Button>

// 				{/* Connection Modal */}
// 				<Modal
// 					title="Add Connection"
// 					visible={openConnectionModal}
// 					onCancel={() => setOpenConnectionModal(false)}
// 					onOk={handleAddConnection}
// 				>
// 					<Form layout="horizontal">
// 						<Form.Item
// 							label="Type"
// 							required
// 							style={{ width: "60%" }}
// 							{...formItemLayout}
// 						>

// 							{/* <Input
// 								value={connectionType}
// 								onChange={(e) => setConnectionType(e.target.value)}
// 							/> */}

// 							<Select
// 								defaultValue=""
// 								style={{ width: "100%", marginLeft: "20px" }}
// 								onChange={(value) => setConnectionType(value)}
// 								options={[
// 									{ value: "Jira", label: "Jira" },
// 									{ value: "Github", label: "Github" },
// 								]}
// 							/>
// 						</Form.Item>
// 					</Form>
// 					<Form layout="horizontal">
// 						<Form.Item
// 							label="Name"
// 							required
// 							style={{ width: "100%" }}
// 							labelCol={{ span: 6 }}
// 							wrapperCol={{ span: 16 }}
// 						>
// 							<Input
// 								style={{ marginLeft: "20px" }}
// 								value={connectionName}
// 								onChange={(e) => setConnectionName(e.target.value)}
// 							/>
// 						</Form.Item>
// 						{connectionType === "Github" && (
// 							<GithubConnectionDetails
// 								connectionData={connectionData}
// 								setConnectionData={setConnectionData}
// 							/>
// 						)}
// 						{connectionType === "Jira" && (
// 							<JiraConnectionDetails
// 								connectionData={connectionData}
// 								setConnectionData={setConnectionData}
// 							/>
// 						)}
// 					</Form>
// 				</Modal>
// 			</>
// 		</Flex>
// 	);
// }

// export default ConnectionManagementNav;

// import React, { useState } from "react";
// import {
// 	Button,
// 	Modal,
// 	Form,
// 	Input,
// 	message,
// 	Flex,
// 	Breadcrumb,
// 	Select,
// } from "antd";
// import userProfileStore, {
// 	UserProfileStoreType,
// } from "../stores/UserProfileStore";
// import useWorkspaceStore from "../stores/WorkspaceState";
// import { ArrowLeftOutlined } from "@ant-design/icons";
// import Title from "antd/es/typography/Title";
// import { useNavigate } from "react-router-dom";
// import GithubConnectionDetails from "./connections/GithubConnectionDetails";
// import JiraConnectionDetails from "./connections/JiraConnectionDetails";

// function ConnectionManagementNav({
// 	openConnectionModal,
// 	setOpenConnectionModal,
// }: {
// 	openConnectionModal: boolean;
// 	setOpenConnectionModal: React.Dispatch<React.SetStateAction<boolean>>;
// }): any {
// 	const [connectionType, setConnectionType] = useState("");
// 	//const [connectionValue, setConnectionValue] = useState("");
// 	const [connectionName, setConnectionName] = useState("");

// 	const [connectionData, setConnectionData] = useState<any[]>([]);
// 	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
// 	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
// 		currentWorkspace: state.currentWorkspace,
// 	}));
// 	const navigate = useNavigate();

// 	const handleAddConnection = () => {
// 		console.log("in connection add");
// 		console.log("currentWorkspace._id is :", currentWorkspace._id);
// 		const APIUrl = `${process.env.REACT_APP_API_URL}/connection`;
// 		const data = {
// 			type: connectionType,
// 			name: connectionName,
// 			workspaceId: currentWorkspace._id,
// 			data: connectionData,
// 			//lastnumber: 0,
// 		};
// 		fetch(APIUrl, {
// 			method: "POST",
// 			body: JSON.stringify(data),
// 			headers: {
// 				"Content-Type": "application/json; charset=UTF-8",
// 				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
// 			},
// 		})
// 			.then((response) => response.json())
// 			.then((data) => {
// 				console.log("Success:", data);
// 				message.success("Connection added successfully!");
// 				setOpenConnectionModal(false);
// 			})
// 			.catch((error) => {
// 				console.error("Error:", error);
// 				message.error("Error adding Connection");
// 			});
// 	};
// 	const formItemLayout = {
// 		labelCol: {
// 			xs: { span: 24 },
// 			sm: { span: 10 }, // Adjusted label width for "Type"
// 		},
// 		wrapperCol: {
// 			xs: { span: 24 },
// 			sm: { span: 14 }, // Adjusted wrapper width for "Type"
// 		},
// 	};

// 	return (
// 		<Flex
// 			justify="space-between"
// 			align="center"
// 			style={{
// 				padding: "20px 50px 20px 50px",
// 				margin: "4px 0 0px",
// 				backgroundColor: "rgb(245, 248, 254)",
// 			}}
// 		>
// 			<Flex align="center" gap={16}>
// 				<Button
// 					shape="circle"
// 					icon={<ArrowLeftOutlined />}
// 					size="large"
// 					onClick={() => navigate("/")}
// 					style={{ color: "#0444BF", borderColor: "#0444BF" }}
// 				/>

// 				<Flex vertical>
// 					<Breadcrumb>
// 						<Breadcrumb.Item>Workspace Settings</Breadcrumb.Item>
// 					</Breadcrumb>
// 					<Title
// 						level={5}
// 						style={{
// 							margin: 0,
// 							textAlign: "left",
// 							fontFamily: "Poppins",
// 						}}
// 					>
// 						Connection Mangement
// 					</Title>
// 				</Flex>
// 			</Flex>

// 			<>
// 				<Button type="primary" onClick={() => setOpenConnectionModal(true)}>
// 					Add Connection
// 				</Button>

// 				{/* Connection Modal */}
// 				<Modal
// 					title="Add Connection"
// 					visible={openConnectionModal}
// 					onCancel={() => setOpenConnectionModal(false)}
// 					onOk={handleAddConnection}
// 				>
// 					<Form layout="horizontal">
// 						<Form.Item
// 							label="Type"
// 							required
// 							style={{ width: "60%" }}
// 							{...formItemLayout}
// 						>

// 							{/* <Input
// 								value={connectionType}
// 								onChange={(e) => setConnectionType(e.target.value)}
// 							/> */}

// 							<Select
// 								defaultValue=""
// 								style={{ width: "100%", marginLeft: "20px" }}
// 								onChange={(value) => setConnectionType(value)}
// 								options={[
// 									{ value: "Jira", label: "Jira" },
// 									{ value: "Github", label: "Github" },
// 								]}
// 							/>
// 						</Form.Item>
// 					</Form>
// 					<Form layout="horizontal">
// 						<Form.Item
// 							label="Name"
// 							required
// 							style={{ width: "100%" }}
// 							labelCol={{ span: 6 }}
// 							wrapperCol={{ span: 16 }}
// 						>
// 							<Input
// 								style={{ marginLeft: "20px" }}
// 								value={connectionName}
// 								onChange={(e) => setConnectionName(e.target.value)}
// 							/>
// 						</Form.Item>
// 						{connectionType === "Github" && (
// 							<GithubConnectionDetails
// 								connectionData={connectionData}
// 								setConnectionData={setConnectionData}
// 							/>
// 						)}
// 						{connectionType === "Jira" && (
// 							<JiraConnectionDetails
// 								connectionData={connectionData}
// 								setConnectionData={setConnectionData}
// 							/>
// 						)}
// 					</Form>
// 				</Modal>
// 			</>
// 		</Flex>
// 	);
// }

// export default ConnectionManagementNav;

import React, { useState } from "react";
import {
	//Button,
	Modal,
	Form,
	Input,
	message,
	Flex,
	//Breadcrumb,
	Select,
	Divider,
	Space,
	Button,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
//import { ArrowLeftOutlined } from "@ant-design/icons";
//import Title from "antd/es/typography/Title";
//import { useNavigate } from "react-router-dom";
import GithubConnectionDetails from "./connections/GithubConnectionDetails";
import JiraConnectionDetails from "./connections/JiraConnectionDetails";
import BreadCrumb from "../pages/Editors/BreadCrumb";
import breadcrumbConfig from "../util/BreadCrumbsUtil";
import { motion } from "framer-motion";
import { EditOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import { createConnection } from "../api/ConnectionAPI";

function ConnectionManagementNav({
	openConnectionModal,
	setOpenConnectionModal,
}: {
	openConnectionModal: boolean;
	setOpenConnectionModal: React.Dispatch<React.SetStateAction<boolean>>;
}): any {
	const [connectionType, setConnectionType] = useState("");
	const [connectionName, setConnectionName] = useState("");
	const [connectionData, setConnectionData] = useState<any[]>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	//const navigate = useNavigate();

	const { run: createConections } = useRequest(
		(data) => createConnection(data, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "Connection data");
				message.success("Connection added successfully!");
				setOpenConnectionModal(false);
			},
			onError: (error) => {
				message.error(`Failed in adding Connections : ${error.message}`);
				console.error("Error in adding connections:", error);
			},
		}
	);
	const handleAddConnection = () => {
		const data = {
			type: connectionType,
			name: connectionName,
			workspaceId: currentWorkspace._id,
			data: connectionData,
		};

		createConections(data);
	};

	const [isHovered, setIsHovered] = useState(false);
	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	return (
		// <Flex
		// 	justify="space-between"
		// 	align="center"
		// 	style={{
		// 		padding: "20px 50px 20px 50px",
		// 		margin: "4px 0 0px",
		// 		backgroundColor: "rgb(245, 248, 254)",
		// 	}}
		// >
		// 	<Flex align="center" gap={16}>
		<div>
			<Divider className="ant-divider-horizontal" />
			<div className="ant-div-container">
				<Flex className="flex-container">
					<Flex style={{ alignItems: "center", marginLeft: "2vw" }}>
						<Space>
							<Flex className="breadcrumb">
								{/* <Button
					shape="circle"
					icon={<ArrowLeftOutlined />}
					size="large"
					onClick={() => navigate("/")}
					style={{ color: "#0444BF", borderColor: "#0444BF" }}
				/>

				<Flex vertical>
					<Breadcrumb>
						<Breadcrumb.Item>Workspace Settings</Breadcrumb.Item>
					</Breadcrumb>
					<Title
						level={5}
						style={{
							margin: 0,
							textAlign: "left",
							fontFamily: "Poppins",
						}}
					>
						Connection Management
					</Title>
				</Flex> */}
								<BreadCrumb config={breadcrumbConfig.connections} />
							</Flex>
						</Space>
					</Flex>
					<>
						{/* <Button type="primary" onClick={() => setOpenConnectionModal(true)}>
					Add Connection
				</Button> */}
						<motion.div
							initial="initial"
							animate={isHovered ? "hover" : "initial"}
							variants={buttonVariants}
							transition={{ duration: 0.3 }}
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
							className="add-work-item-button"
							onClick={() => setOpenConnectionModal(true)}
						>
							<EditOutlined />
							{isHovered && (
								<span style={{ marginLeft: "8px" }}>Add Connection</span>
							)}
						</motion.div>

						<Modal
							title="Add Connection"
							open={openConnectionModal}
							onCancel={() => setOpenConnectionModal(false)}
							// onOk={handleAddConnection}
							footer={[
								<Button
									key="cancel"
									onClick={() => setOpenConnectionModal(false)}
								>
									Cancel
								</Button>,
								<Button key="save" type="primary" onClick={handleAddConnection}>
									Save
								</Button>,
							]}
							width={500}
						>
							<Form
								layout="vertical"
								labelCol={{
									style: {
										textAlign: "right",
										margin: 0,
									},
								}}
							>
								<Form.Item
									label="Type"
									required
									style={{ width: "100%", marginBottom: 10, fontSize: "16px" }}
								>
									<Select
										placeholder="Select Type"
										//defaultValue=""
										style={{ width: "100%", fontSize: "16px" }}
										onChange={(value) => setConnectionType(value)}
										options={[
											{ value: "Jira", label: "Jira" },
											{ value: "Github", label: "Github" },
										]}
									/>
								</Form.Item>

								<Form.Item
									label="Name"
									required
									style={{ width: "100%", marginBottom: 10 }}
								>
									<Input
										style={{ width: "100%", fontSize: "14px", height: "32px" }}
										placeholder="Name"
										value={connectionName}
										onChange={(e) => setConnectionName(e.target.value)}
									/>
								</Form.Item>

								{connectionType === "Github" && (
									<GithubConnectionDetails
										connectionData={connectionData}
										setConnectionData={setConnectionData}
									/>
								)}
								{connectionType === "Jira" && (
									<JiraConnectionDetails
										connectionData={connectionData}
										setConnectionData={setConnectionData}
									/>
								)}
							</Form>
						</Modal>
					</>
				</Flex>
			</div>
		</div>
	);
}

export default ConnectionManagementNav;
