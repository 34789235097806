import { Route, Routes } from "react-router-dom";
import "./App.css";
// import LandingPage from "./component/LandingPage";
import AddRolesModal from "./pages/Settings/AddRole";
import UserManagement from "./pages/Settings/UserManagement";
import TagManagement from "./pages/Settings/TagManagement";
import SettingsLayout from "./pages/Settings/SettingsLayout";
import ProfileLayout from "./layout/ProfileLayout";
import RoleManagement from "./pages/Settings/RoleManagment";
import EditRolesModal from "./pages/Settings/EditRole";
import { MsalProvider } from "@azure/msal-react";
import EditUser from "./pages/Settings/EditUser";
import FeatureList from "./component/FeatureList";
import AppLayout from "./layout/AppLayout";
import FeedbackForm from "./pages/FeedBackForm";
import DataSchema from "./pages/Design-artifacts/DataSchema";
import UxProcess from "./pages/Design-artifacts/UxProcess";
import FeatureSpecification from "./component/FeatureSpec";
//import ProductInfo from "./component/ProductInfo";
import LoaderPage from "./pages/LoaderScreen";
import ApiDesign from "./pages/Design-artifacts/ApiDesign";
// import InformationArchitecture from "./component/InformationArchitecture";
import DesignSpecCore from "./pages/Design-artifacts/DesignSpecCore";
import StrategicDesign from "./pages/Design-artifacts/StrategicDesign";
import AdvancedPatterns from "./pages/Design-artifacts/AdvancedPatterns";
import ModuleCheck from "./component/ModuleCheck";
import DDDLayout from "./layout/DDDLayout";

import NotFound from "./pages/NotFound";
import WorkspaceLayout from "./layout/WorkspaceLayout";
import AddWorkspace from "./pages/AddWorkspace";
import UserProfileDetails from "./component/UserProfileDetails";
import FeatureRoles from "./pages/FeatureRoles";
import WatingModel from "./component/WatingModel";
import ConformationModel from "./component/ConformationModel";
import ArchiveWorkspace from "./pages/Settings/ArchiveWorkspace";
import Backlog from "./pages/Planner/Backlog";
import Planerlayout from "./layout/PlanerLayout";
import Release from "./pages/Planner/ReleasePlan/Release";
import MyTasks from "./pages/Planner/MyTasks";
//import TeamMember from "./pages/Planner/TeamMember";
import AddTag from "./pages/Settings/AddTag";
import KanoPrioritization from "./pages/Planner/FeaturePrioritization/KanoPrioritization";
import WorkitemRefinement from "./component/WorkItemRefinement";
import EditTagModal from "./pages/Settings/EditTag";
import TaskBoarding from "./pages/Planner/TaskBoard/TaskBoarding";
import HomePage from "./component/HomePage";
import EditMemberModal from "./pages/Planner/EditMember";
import CapacityPlanning from "./pages/Planner/CapacityPlanning";
import Feature from "./pages/Planner/FeaturePlan/Feature";
import Sprint from "./pages/Planner/SprintPlan/sprint";
import ExpandedModalForEdit from "./pages/Planner/ExpandedModalForEdit";
import AuthenticateUser from "./component/AuthenticateUser";
import FullDialogLayout from "./layout/FullDialogLayout";
import WorkspaceList from "./component/WorkspaceList";
import Rituals from "./pages/Planner/Rituals/Rituals";
import ReleasePlanView from "./pages/Planner/ReleasePlan/ReleasePlanView";
import FeaturePlanView from "./pages/Planner/FeaturePlan/FeaturePlanView";
import SprintPlanView from "./pages/Planner/SprintPlan/SprintPlanView";
import UserProfilePreference from "./pages/Settings/UserProfilePreference";
import RitualView from "./pages/Planner/Rituals/RitualView";
import CreateWorkpaceForm from "./component/CreateWorkspaceForm";
import Roadmap from "./pages/Roadmap";
// import Plans from "./pages/Planner/Plans";
import PlanView from "./pages/Planner/PlanView";
import PlanWorkitemView from "./pages/Planner/PlanWorkitemView";
import WorkspaceSettingsLayout from "./layout/WorkspaceSettingsLayout";
import WFFullDialogLayout from "./layout/WFFullDialogLayout";
import FeatureListEdit from "./component/FeatureListEdit";
import AddCapacityPlan from "./pages/Planner/AddCapacityPlan";
import NewWorkspace from "./component/NewUserWorkSpace";
import WorkItemElaboration from "./component/WorkItemElaboration";
import UserStoryEdit from "./component/UserStoryEdit";
import CreateFeatureFromThread from "./component/CreateFeatureFromThread";
import TaskDetailsPage from "./pages/Planner/ViewTask";
import ElaborateFeatureFromThread from "./component/ElaborateFeatureFromThread";
import ViewBacklog from "./pages/Planner/ViewBacklog";
// import AIRunPrepView from "./component/AIRunPrepView";
import AIRunPrepViewDummy from "./component/AIRunPrepViewDummy";
import ApiDesignV2 from "./pages/Design-artifacts/ApiDesignV2";
import AccountManagement from "./pages/Settings/AccountMangement";
import FlowPage from "./component/InformationArchitectureXFlow";
import PrefixManagement from "./pages/PrefixManagement";
import Flow from "./pages/X-flow examples/flow";

// import ContextMappingIndex from "./pages/CoreSubDomain/ContexMappingIndex";
// import SupportingChart from "./pages/SupportingSubDomain/index";
//import SupportPage from "./pages/CoreSubDomain/index";
import Connections from "./component/Connections";
// import EditConnection from "./component/EditConnection";
import EditConnectionModal from "./component/EditConnection";
// import SupportPage from "./pages/CoreSubDomain/index";
import PlanTypesManagement from "./pages/PlanTypesMangement";
import GridLayout from "./component/Reports/GridLayout";
import NewDashboard from "./component/Dashboard";
import PreChatProcess from "./component/chat/PreChatProcess";
import IdeaEnhansmentV2 from "./pages/IdeaEnhansmentV2";
import WhiteBoardDashboard from "./pages/X-flow examples/flow/whiteBoardDashboard";
import ChatStream from "./component/chat/ChatStream";

import ChatHistory from "./pages/ChatHistory";
import PostChatProcess from "./component/chat/PostChatProcess";
import BlockNoteEditors from "./pages/Editors/BlockNote";
import ChatUI from "./pages/ChatUI";
import PropertiesManagement from "./pages/PropertiesManagement";
import PlansV2Gantt from "./pages/Scheduler/PlansV2Gantt";
import NotesDashboard from "./pages/X-flow examples/flow/notesDashboard";
import NotesView from "./pages/X-flow examples/flow/notesView";
import ViewFilter from "./pages/ViewFilter/ViewFilter";
import ViewPage from "./pages/ViewFilter/ViewPage";

import TaskRunNav from "./component/TaskRunNav";
import TeamMemberV2 from "./pages/Planner/TeamMemberV2";
// import InformationArchitecturev2, {  } from "./component/informationArchitecturev2";
// import WorkspaceList from "./component/WorkspaceList";

function App({ instance }: any) {
	return (
		<MsalProvider instance={instance}>
			{/* <Link to="/idea"><Button  type="primary">Primary Button</Button></Link> */}

			<Routes>
				<Route path="/chatstream" element={<ChatStream />} />
				<Route path="/load" element={<LoaderPage />} />
				<Route path="/" element={<AuthenticateUser />}>
					<Route path="/load" element={<LoaderPage />} />
					<Route path="*" element={<NotFound />} />
				</Route>
				<Route path="/wfitemfd" element={<WFFullDialogLayout />}>
					<Route path="refinement/:wfitemId" element={<WorkitemRefinement />} />
					<Route
						path="elaboration/:wfitemId"
						element={<WorkItemElaboration />}
					/>
					<Route path="refinement/fr/:wfitemId" element={<FeatureListEdit />} />
					<Route path="elaboration/fr/:wfitemId" element={<UserStoryEdit />} />
				</Route>
				<Route path="/fd" element={<FullDialogLayout />}>
					<Route path="airun/:stageParam" element={<AIRunPrepViewDummy />} />
					<Route path="workspace/new" element={<NewWorkspace />} />
					<Route path="workspace/list" element={<WorkspaceList />} />
					{/* <Route path="chat/:goalParam" element={<IdeaEnhansment />} /> */}
					<Route path="chatv2/:goalParam" element={<IdeaEnhansmentV2 />} />
					<Route path="chatv3/:goalParam" element={<ChatUI />} />
					<Route
						path="chatv3/resume/:goalParam/:conversationThreadId"
						element={<ChatUI />}
					/>
					<Route
						path="chatv3/history/:goalParam/:conversationThreadId"
						element={<ChatHistory />}
					/>

					<Route
						path="preprocess/chat/:goalParam"
						element={<PreChatProcess />}
					/>
					<Route
						path="postprocess/chat/:goalParam"
						element={<PostChatProcess />}
					/>

					<Route
						path="workspace/create/:threadId"
						element={<CreateWorkpaceForm />}
					/>
					<Route
						path="workspace/create-feature/:threadId"
						element={<CreateFeatureFromThread />}
					/>
					<Route
						path="workspace/elaborate-feature/:threadId"
						element={<ElaborateFeatureFromThread />}
					/>
				</Route>
				<Route
					path="/fd/workspace/settings"
					element={<WorkspaceSettingsLayout />}
				>
					<Route index element={<TagManagement />} />
					<Route path="tag" element={<TagManagement />} />
					<Route path="connections" element={<Connections />} />
					<Route
						path="connections/edit/:id"
						element={<EditConnectionModal />}
					/>
					<Route path="taskRuns" element={<TaskRunNav />} />
					<Route path="tag/addTag" element={<AddTag />} />
					<Route path="tag/edit/:id" element={<EditTagModal />} />
					<Route path="workitem" element={<PrefixManagement />} />
					<Route path="plan" element={<PlanTypesManagement />} />
					<Route path="properties" element={<PropertiesManagement />} />
				</Route>
				<Route path="/settings" element={<SettingsLayout />}>
					<Route path="/settings/role" element={<RoleManagement />} />
					<Route path="/settings/user" element={<UserManagement />} />
					<Route path="/settings/account" element={<AccountManagement />} />
					{/* <Route path="/settings/tag" element={<TagManagement />} /> */}
					<Route path="/settings/role/addRole" element={<AddRolesModal />} />
					<Route path="/settings/workspace" element={<ArchiveWorkspace />} />
					<Route path="/settings/role/edit/:id" element={<EditRolesModal />} />
					<Route path="/settings/user/edit/:id" element={<EditUser />} />
					{/* <Route path="/settings/tag/edit/:id" element={<EditTagModal />} /> */}
					{/* <Route path="/settings/tag/addTag" element={<AddTag />} /> */}
					{/* <Route path="/settings/profile" element={<UserProfileDetails />} /> */}
				</Route>
				<Route path="feed" element={<FeedbackForm />} />
				<Route path="/space/:workspaceId" element={<AppLayout />}>
					<Route index element={<HomePage />}></Route>
					<Route path="reports" element={<GridLayout />} />
					<Route path="dashboard/:id" element={<NewDashboard />} />
					<Route path="roadmap" element={<Roadmap />} />
					<Route path="prioritization" element={<KanoPrioritization />} />
					<Route path="app" element={<WatingModel />} />
					<Route path="refinement" element={<WorkitemRefinement />} />
					<Route path="apps" element={<ConformationModel />} />

					{/* <Route path="/test/:id" element={<ProductList />} /> */}
					<Route path="describe" element={<AddWorkspace />} />
					{/* <Route path="/featurelist/:id" element={<FeatureList />} /> */}

					{/* <Route
						path="/featurelistSpec/:id"
						element={<FeatureSpecification />}
					/> */}
					<Route path="data/:id" element={<DataSchema />} />
					<Route path="uxprocess/:id" element={<UxProcess />} />
					<Route path="apiDesign/:id" element={<ApiDesign />} />
					<Route path="moduleCheck" element={<ModuleCheck />} />

					<Route path="ddd" element={<DDDLayout />}>
						<Route path="ddd/core" element={<DesignSpecCore />} />
						<Route path="ddd/strategic" element={<StrategicDesign />} />
						<Route path="ddd/advanced" element={<AdvancedPatterns />} />
					</Route>

					<Route path="planner" element={<Planerlayout />}>
						<Route path="flow/:id" element={<Flow />} />
						<Route path="editor/:id" element={<BlockNoteEditors />} />
						<Route path="whiteboard" element={<WhiteBoardDashboard />} />
						<Route path="views" element={<ViewFilter />} />
						<Route path="filterview" element={<ViewPage />} />
						<Route path="notes" element={<NotesDashboard />} />
						<Route path="notes/:id" element={<NotesView />} />
						<Route path="backlogs" element={<Backlog />} />
						<Route path="workitemView/:planId" element={<PlanWorkitemView />} />
						<Route path="releaseplan" element={<Release />} />
						<Route path="releaseplans" element={<Release />} />
						<Route path="releaseplanner" element={<ReleasePlanView />} />
						<Route path="featureplans" element={<Feature />} />
						<Route path="featureplanner" element={<FeaturePlanView />} />
						<Route path="planner" element={<PlanView />} />
						<Route path="sprintplans" element={<Sprint />} />
						<Route path="sprintplanner" element={<SprintPlanView />} />
						{/* <Route path="plans" element={<Plans />} /> */}
						<Route path="plans" element={<PlansV2Gantt />} />
						<Route path="mytasks" element={<MyTasks />} />
						<Route path="viewtask/:taskId" element={<TaskDetailsPage />} />
						<Route path="view/:taskId" element={<ViewBacklog />} />
						<Route path="taskboard/:planId" element={<TaskBoarding />} />
						<Route path="edit" element={<ExpandedModalForEdit />} />
						<Route path="rituals" element={<Rituals />} />
						{/* <Route path="teammember" element={<TeamMember />} /> */}
						<Route path="teammember" element={<TeamMemberV2 />} />
						<Route path="capacityplan/:planId" element={<CapacityPlanning />} />
						<Route
							path="capacityplan/createplan/:planId"
							element={<AddCapacityPlan />}
						/>
						<Route path="teammember/edit/:id" element={<EditMemberModal />} />
						<Route path="ritual/:itemId" element={<RitualView />} />

						<Route path="de/uxprocess/:id" element={<UxProcess />} />
						<Route path="de/info/:id" element={<FlowPage />} />
						<Route path="de/ddd/" element={<DDDLayout />}>
							<Route path="core/:id" element={<DesignSpecCore />} />
							<Route path="strategic/:id" element={<StrategicDesign />} />
							<Route path="advanced/:id" element={<AdvancedPatterns />} />
						</Route>
						<Route path="de/apiDesign/:id" element={<ApiDesignV2 />} />
						<Route path="de/data/:id" element={<DataSchema />} />
					</Route>
				</Route>
				<Route path="profile" element={<ProfileLayout />}>
					<Route path="details" element={<UserProfileDetails />} />
					<Route path="perferences" element={<UserProfilePreference />} />
				</Route>
				<Route path="workspace/" element={<WorkspaceLayout />}>
					<Route path="fe/featurelist/:id" element={<FeatureList />} />
					<Route path="fe/featurerole/:id" element={<FeatureRoles />} />
					<Route path="fe/featurespec/:id" element={<FeatureSpecification />} />
					<Route path="de/uxprocess/:id" element={<UxProcess />} />
					<Route path="de/info/:id" element={<FlowPage />} />
					<Route path="de/ddd/" element={<DDDLayout />}>
						<Route path="core/:id" element={<DesignSpecCore />} />
						<Route path="strategic/:id" element={<StrategicDesign />} />
						<Route path="advanced/:id" element={<AdvancedPatterns />} />
					</Route>
					<Route path="de/apiDesign/:id" element={<ApiDesign />} />
					<Route path="de/data/:id" element={<DataSchema />} />
				</Route>
				{/* <Route path="/" element={<AppLayout />}>
					<Route path="*" element={<NotFound />} />
				</Route> */}
			</Routes>
		</MsalProvider>
	);
}

export default App;
