/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Flex, Image, Layout } from "antd";

import { Outlet } from "react-router-dom";
import AppNavbar from "./AppNavbar";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
import AppHeader from "../layout/AppHeader";
import { Content } from "antd/es/layout/layout";
import WorkspaceSettingsMenu from "./WorkspaceSettingsMenu";
export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

const { Sider } = Layout;

function WorkspaceSettingsLayout() {
	const [abilities, setAbilities] = useState(ability);
	return (
		<AbilityContext.Provider value={abilities}>
			<Can I="SETTINGS_CAN_VIEW" a="SETTINGS_CAN_VIEW" passThrough>
				{(allowed) =>
					allowed ? (
						//<div>
						<Layout style={{ minHeight: "100vh", backgroundColor: "#FAFAFF" }}>
							{/* <Header
					style={{
						padding: 0,
						marginLeft: 0,
						position: "fixed",
						width: "100%",
						zIndex: 1,
					}}
				>
					<NavigationBar />
				</Header> */}

							{/*<AppNavbar
									breadcrumbs={["Settings"]}
									menuType="settings"
									//leftTitle="Role Management"
									//middleTitle="ijnjijnuj"
									reviewEnabled={false}
									onYesClick={() => {}}
								/>*/}
							<WorkspaceSettingsMenu />

							{/*<Layout
									style={{
										width: "92vw",
										backgroundColor: "#FAFAFF",
										paddingLeft: "36px",
									}}
								>
									<Sider style={{ backgroundColor: "#FAFAFF" }} width={"18vw"}>
										<WorkspaceSettingsMenu />
									</Sider>

									<Outlet />
								</Layout>
							</Layout>
						</div>
					) : (
						<Flex style={{ marginTop: "15vh" }} justify="center">
							<Image
								height={"50vh"}
								width={"60vw"}
								src={
									"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_security_access.svg"
								}
								preview={false}
							/>
						</Flex>
					)
				}  */}

							<Layout
								style={{
									backgroundColor: "#FAFAFF",
									// /paddingTop: "36px",
								}}
							>
								<Content
									style={{
										height: "100vh",
										overflow: "hidden",
										paddingTop: ".2rem",
										background: "#F5F8FE",
									}}
								>
									<div style={{ paddingTop: "0px" }}>
										<AppHeader />
									</div>

									{/* <AppNavbar
										breadcrumbs={["Settings"]}
										menuType="settings"
										//leftTitle="Role Management"
										//middleTitle="ijnjijnuj"
										reviewEnabled={false}
										onYesClick={() => {}}
									/> */}

									<Outlet />
								</Content>
								<Flex
									className="footer"
									justify="center"
									style={{
										width: "100%",
										position: "absolute",
										bottom: "0",
										margin: "1rem 0 0px 0",
										background: "#F5F8FE",
									}}
								>
									<p
										style={{
											marginLeft: "-28vw",
											fontSize: 12,
											fontWeight: 500,
											fontFamily: "Poppins",
										}}
									>
										© 2024 Zentience. All rights reserved.
									</p>
								</Flex>
							</Layout>
						</Layout>
					) : (
						<Flex style={{ marginTop: "15vh" }} justify="center">
							<Image
								height={"50vh"}
								width={"60vw"}
								src={
									"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_security_access.svg"
								}
								preview={false}
							/>
						</Flex>
					)
				}
			</Can>
		</AbilityContext.Provider>
	);
}

export default WorkspaceSettingsLayout;
