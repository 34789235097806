import React, { useState } from "react";
import "./PlanerHeader.css";
import {
	//DownOutlined,
	SearchOutlined,
	EditOutlined,
	CheckCircleOutlined,
	WarningOutlined,
	SwapOutlined,
	//ArrowLeftOutlined,
} from "@ant-design/icons";
import { Flex, Space, Input, Divider } from "antd";
import { TaskStore } from "../../stores/TasksStore";
// import { planerViewStore } from "../../stores/PlannerStore";
import { useLocation } from "react-router-dom";
import Constants from "../../util/ConstantsUtil";
//import MotionButton from "../../component/MotionComponent/MotionButton";
import BreadCrumb from "../Editors/BreadCrumb";
import { motion } from "framer-motion";
import UniversalComponent from "./Filter_button/UniversalFilter";
import Display from "./Display_button/Display";
import usePageStore from "../../stores/PageStore";
// import Display from "./Display_button/Display";

// const { Option } = Select;

const modeConfig = {
	filters: {
		status: {
			label: "Status",
			options: [
				{
					key: Constants.STATUS + "_" + Constants.IN_PROGRESS,
					label: Constants.IN_PROGRESS,
				},
				{ key: Constants.STATUS + "_" + Constants.OPEN, label: Constants.OPEN },
				{
					key: Constants.STATUS + "_" + Constants.COMPLETED,
					label: Constants.COMPLETED,
				},
			],
			icon: <CheckCircleOutlined />,
		},
		priority: {
			label: "Priority",
			options: [
				{
					key: Constants.PRIORITY + "_" + Constants.URGENT,
					label: Constants.URGENT,
				},
				{
					key: Constants.PRIORITY + "_" + Constants.IMPORTANT,
					label: Constants.IMPORTANT,
				},
				{
					key: Constants.PRIORITY + "_" + Constants.MEDIUM,
					label: Constants.MEDIUM,
				},
				{ key: Constants.PRIORITY + "_" + Constants.LOW, label: Constants.LOW },
			],
			icon: <WarningOutlined />,
		},
		type: {
			label: "Type",
			options: [
				{ key: "Type_MyTasks", label: "My Tasks" },
				{ key: "Type_AllTasks", label: "All Task" },
				{ key: "Type_ProcessTasks", label: "Process Tasks" },
			],
			icon: <SwapOutlined />,
		},
	},
};

function MyTaskNav() {
	const url = useLocation().pathname;
	console.log(url);

	const { breadcrumbConfig } = usePageStore((state: any) => ({
		breadcrumbConfig: state.breadcrumbConfig,
	}));
	// const { setRadioButtonSelected } = planerViewStore();
	const {
		setListView,
		setCardView,
		input,
		setInput,
		// setfilter,
		TaskFilterValue,
		setTaskFilterValue,
		mytaskworkitemmodel,
		setTaskSelectedFilter,
		setmytaskworkitemmodel,
		ViewFilter,
		setSelectedGroupBy,
		isCardView,
		setRowBy,
		RowBy,
		selectedGroupBy,
		CardOrderning,
		setDocsView,
		setCardOrderning,
		setSortBy,
		ListSelectedGroupBy,
		setListSelectedGroupBy,
	} = TaskStore();

	// const treeData = [
	// 	{
	// 		value: Constants.PRIORITY, // change to CAPS
	// 		title: Constants.PRIORITY,
	// 		children: [
	// 			{
	// 				value: Constants.PRIORITY + "_" + Constants.URGENT,
	// 				title: Constants.URGENT,
	// 			},
	// 			{
	// 				value: Constants.PRIORITY + "_" + Constants.IMPORTANT,
	// 				title: Constants.IMPORTANT,
	// 			},
	// 			{
	// 				value: Constants.PRIORITY + "_" + Constants.MEDIUM,
	// 				title: Constants.MEDIUM,
	// 			},
	// 			{
	// 				value: Constants.PRIORITY + "_" + Constants.LOW,
	// 				title: Constants.LOW,
	// 			},
	// 		],
	// 	},
	// 	{
	// 		value: Constants.STATUS, //change to CAPS
	// 		title: Constants.STATUS,
	// 		children: [
	// 			{
	// 				value: Constants.STATUS + "_" + Constants.IN_PROGRESS,
	// 				title: Constants.IN_PROGRESS,
	// 			},
	// 			{
	// 				value: Constants.STATUS + "_" + Constants.OPEN,
	// 				title: Constants.OPEN,
	// 			},
	// 			{
	// 				value: Constants.STATUS + "_" + Constants.COMPLETED,
	// 				title: Constants.COMPLETED,
	// 			},
	// 		],
	// 	},
	// ];

	// const [value, setValue] = useState<any[]>([]);

	// const onChange = (newValue: any[]) => {
	// 	const filteredValues = newValue.filter((val: any) => {
	// 		return !treeData.some((item) => item.value === val);
	// 	});
	// 	setValue(filteredValues);
	// 	setfilter(filteredValues);
	// };

	// const handleRadioChange = (value: string) => {
	// 	setValue([]);
	// 	setfilter([]);
	// 	setRadioButtonSelected(value);
	// };

	// const handleChange = (event: any) => {
	// 	setInput(event.target.value); // Update input value
	// };

	const handleAddWorkItemClick = () => {
		setmytaskworkitemmodel(!mytaskworkitemmodel);
	};

	const [isSearchExpanded, setIsSearchExpanded] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const handleSearchChange = (event: any) => {
		setInput(event.target.value);
		if (event.target.value === "") {
			setIsSearchExpanded(false);
		}
	};

	const handleSearchIconClick = () => {
		setIsSearchExpanded(true);
	};

	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	return (
		<div>
			<Divider className="ant-divider-horizontal" />
			<div className="ant-div-container">
				<Flex className="flex-container">
					<Flex className="breadcrumb" style={{ marginLeft: "2vw" }}>
						<Space>
							<BreadCrumb config={breadcrumbConfig} />
							<UniversalComponent
								config={modeConfig}
								filterValue={TaskFilterValue}
								setFilterValue={setTaskFilterValue}
								setSelectedFilter={setTaskSelectedFilter}
								ViewFilter={ViewFilter}
							/>
						</Space>
						{/* <Space>
							 <Tooltip placement="bottom" title="Back">
								<Button
									shape="circle"
									icon={<ArrowLeftOutlined />}
									size="large"
								/>
							</Tooltip>
							<Flex vertical>
								<Breadcrumb>
									<Breadcrumb.Item>
										<h3
											color="#87d068"
											style={{ fontSize: "1.1rem", fontFamily: "Poppins" }}
										>
											My Tasks
										</h3>
									</Breadcrumb.Item>
								</Breadcrumb>
							</Flex>
						</Space> */}
					</Flex>
					<Flex style={{ alignItems: "center", marginLeft: "3vw" }}>
						<Space></Space>
					</Flex>
					<Flex justify="end" gap={"small"} style={{ alignItems: "center" }}>
						{/* <Select
							onChange={handleRadioChange}
							defaultValue={`MyTasks`}
							size="small"
							style={{ width: "10vw", fontFamily: "Poppins", height: "auto" }}
						>
							<Option value={`MyTasks`} defaultChecked>
								My Tasks
							</Option>
							<Option value={`AllTasks`}>All Tasks</Option>
							<Option value={`ProcessTasks`}>Process Tasks</Option>
						</Select> */}

						{/* <Input
							suffix={<SearchOutlined />}
							size="middle"
							style={{
								height: "5vh",
								width: "14vw",
								fontSize: "0.9rem",
								fontFamily: "Poppins",
							}}
							placeholder="Search"
							onChange={handleChange}
							value={input}
							allowClear
						/> */}
						{isSearchExpanded ? (
							<Input
								suffix={<SearchOutlined />}
								size="small"
								className="search-input"
								placeholder="Search"
								onChange={handleSearchChange}
								value={input}
								allowClear
								autoFocus
								onBlur={() => {
									if (input === "") setIsSearchExpanded(false);
								}}
							/>
						) : (
							<SearchOutlined
								className="search-icon"
								onClick={handleSearchIconClick}
								// onMouseEnter={() => setIsSearchExpanded(true)}
							/>
						)}

						{/* <Flex>
							<TreeSelect
								showSearch
								size="small"
								style={{
									width: "10vw",
									// height: value.length > 1 ? `${value.length * 30}px` : "5vh",
									height: "auto",
									fontFamily: "Poppins",
								}}
								value={value}
								dropdownStyle={{
									maxHeight: 400,
									overflow: "auto",
									borderRadius: "6px",
								}}
								placeholder={
									<span style={{ color: "black", fontFamily: "Poppins" }}>
										Filter By
									</span>
								}
								allowClear
								multiple
								treeDefaultExpandAll={false}
								onChange={onChange}
								treeData={treeData}
							/>
						</Flex> */}

						{/* <Flex gap={10}>
							<Tooltip placement="bottom" title="List View">
								<Button
									shape="circle"
									icon={<UnorderedListOutlined />}
									size="small"
									onClick={setListView}
									style={{ color: "#0444BF", borderColor: "#0444BF" }}
								>
								</Button>
							</Tooltip>
							<Tooltip placement="bottom" title="Card View">
								<Button
									shape="circle"
									icon={<AppstoreFilled />}
									size="small"
									onClick={setCardView}
									style={{ color: "#0444BF", borderColor: "#0444BF" }}
								>
								</Button>
							</Tooltip>
						</Flex> */}
						{/* <Button
							size="middle"
							shape="round"
							type="primary"
							onClick={handleAddWorkItemClick}
						>
							Add Task
						</Button> */}
						<Display
							setListView={setListView}
							setCardView={setCardView}
							setSelectedGroupBy={setSelectedGroupBy}
							isCardView={isCardView}
							setRowBy={setRowBy}
							RowBy={RowBy}
							ListSelectedGroupBy={ListSelectedGroupBy}
							setListSelectedGroupBy={setListSelectedGroupBy}
							selectedGroupBy={selectedGroupBy}
							CardOrderning={CardOrderning}
							setDocsView={setDocsView}
							setCardOrderning={setCardOrderning}
							setSortBy={setSortBy}
							DocView={false}
						/>
						<motion.div
							initial="initial"
							animate={isHovered ? "hover" : "initial"}
							variants={buttonVariants}
							transition={{ duration: 0.3 }}
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
							className="add-work-item-button"
							onClick={handleAddWorkItemClick}
						>
							<EditOutlined />
							{isHovered && <span style={{ marginLeft: "8px" }}>Add Task</span>}
						</motion.div>
						{/* <MotionButton text="Add Task" onClick={handleAddWorkItemClick} /> */}
					</Flex>
				</Flex>

				{/* <MyTaskCreationModel
					visible={mytaskworkitemmodel}
					onCancel={handleModalCancel}
				/> */}
			</div>
		</div>
	);
}
export default MyTaskNav;
