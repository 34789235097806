import axios, { AxiosResponse } from "axios";
import { PropertiesConfig } from "../models/PropertiesConfig";

async function getWorkitemsTypeData(
	id: any,
	token: any,
	slug: string
): Promise<PropertiesConfig[]> {
	try {
		const response: AxiosResponse<PropertiesConfig[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/propertiesconfig/slug/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					slug: slug,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}

export { getWorkitemsTypeData };
