/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Menu, message } from "antd";
import React, { useEffect } from "react";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { useRequest } from "ahooks";
import { addPage, getNotesbyId } from "../../../api/Notes";

function RightPannel({
	note_id,
	selectedPage,
	setselectedPage,
	noteData,
	setnoteData,
	HandleNewPage,
}: {
	note_id: string;
	selectedPage: any;
	setselectedPage: any;
	noteData: any;
	setnoteData: any;
	HandleNewPage: any;
}) {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	//   const [noteData, setNoteData] = useState<any>({});

	const { run: fetchNoteById } = useRequest(
		(id) => getNotesbyId(id, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				console.log(data, "note data");
			},

			onError: (error) => {
				message.error(`Error: ${error.message}`);
			},
		}
	);

	useEffect(() => {
		fetchNoteById(note_id);
	}, [note_id]);

	const { run: runAddNewPage } = useRequest(
		(body, id) => addPage(body, id, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				console.log(data, "data");

				fetchNoteById(note_id);
				setnoteData(data);
				setselectedPage(data.pages[data.pages.length - 1]);
				message.success("Page added successfully");
			},
			onError: (error) => {
				console.log(error, "error");
				message.error(`Error: ${error.message}`);
			},
		}
	);

	const AddnewPage = async () => {
		const newPage = {
			pageTitle: " ",
			pageContent: [],
			createdBy: userProfileStoreInstance.profile._id,
		};

		runAddNewPage(newPage, note_id);
	};

	const newlycreatedData = (data: any) => {
		return data.slice().reverse();
	};

	return (
		<>
			<div style={{ textAlign: "right", margin: "1rem 0.5rem 1rem 0rem" }}>
				<Button
					type="primary"
					onClick={() => {
						AddnewPage();
						HandleNewPage();
					}}
				>
					Add Page
				</Button>
			</div>

			<Menu
				selectedKeys={[selectedPage?._id]}
				style={{ height: "70vh", overflow: "scroll", width: "17vw" }}
			>
				{noteData.pages?.length > 0 &&
					newlycreatedData(noteData.pages).map((page: any) => {
						return (
							<Menu.Item
								style={{
									textAlign: "left",
									margin: "0",
									minHeight: "1rem",
									height: "2.4rem",
									display: "flex",
									alignItems: "center",
								}}
								onClick={() => {
									setselectedPage(page);
								}}
								key={page._id}
							>
								{page.pageTitle}
							</Menu.Item>
						);
					})}
			</Menu>
		</>
	);
}

export default RightPannel;
