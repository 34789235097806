/* eslint-disable @typescript-eslint/no-unused-vars */
//import { ArrowLeftOutlined } from "@ant-design/icons";
import {
	//Breadcrumb,
	Button,
	Col,
	Divider,
	Flex,
	Form,
	Input,
	Modal,
	Row,
	Space,
	Table,
} from "antd";
//import Title from "antd/es/typography/Title";
import React, { useState } from "react";
//import { useNavigate } from "react-router-dom";
import useWorkspaceStore from "../stores/WorkspaceState";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import BreadCrumb from "./Editors/BreadCrumb";
import breadcrumbConfig from "../util/BreadCrumbsUtil";
import { motion } from "framer-motion";
import { EditOutlined } from "@ant-design/icons";

function PropertiesTypeManagementNav({
	openPlanTypeModal,
	setopenPlanTypeModal,
}: {
	openPlanTypeModal: boolean;
	setopenPlanTypeModal: React.Dispatch<React.SetStateAction<boolean>>;
}): any {
	//const navigate = useNavigate();
	// const [prefixType, setPrefixType] = useState("");
	// const [prefixValue, setPrefixValue] = useState("");
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm();
	const [valueform] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const handleSave = async () => {
		try {
			const values = await form.validateFields();
			const reqbody = {
				...values,
				workspaceid: currentWorkspace._id,
			};
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/propertiesconfig`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(reqbody),
				}
			);
			if (response.ok) {
				valueform.resetFields();
				form.resetFields();
				// fetchPlanTypeData();
				// console.log("Successfully updated");
				setopenPlanTypeModal(false);
			} else {
				console.error(`Failed to update prefix. Status: ${response.status}`);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	// const handleAddPlanType = () => {
	// 	const APIUrl = `${process.env.REACT_APP_API_URL}/planconfig`;
	// 	const data = {
	// 		type: prefixType,
	// 		prefix: prefixValue,
	// 		workspaceid: currentWorkspace._id,
	// 		lastnumber: 0,
	// 	};
	// 	fetch(APIUrl, {
	// 		method: "POST",
	// 		body: JSON.stringify(data),
	// 		headers: {
	// 			"Content-Type": "application/json; charset=UTF-8",
	// 			Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 		},
	// 	})
	// 		.then((response) => response.json())
	// 		.then((data) => {
	// 			console.log("Success:", data);
	// 			// message.success("Prefix added successfully!");
	// 			// setOpenPrefixModal(false);
	// 			setopenPlanTypeModal(false);
	// 		})
	// 		.catch((error) => {
	// 			console.error("Error:", error);
	// 			// message.error("Error adding prefix");
	// 		});
	// };

	const valuecolumns = [
		{
			title: "Slug",
			dataIndex: "slug",
			key: "slug",
			render: (record: any) => <div>{record}</div>,
		},
		{
			title: "Value",
			dataIndex: "value",
			key: "value",
			render: (record: any) => <div>{record}</div>,
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			render: (record: any) => <div>{record}</div>,
		},
	];

	const handleAdd = () => {
		valueform.resetFields();
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const handleOk = () => {
		valueform
			.validateFields()
			.then((values) => {
				const newValues = [...(form.getFieldValue("values") || []), values];
				form.setFieldsValue({ values: newValues });
				setVisible(false);
			})
			.catch(() => {});
	};

	const [isHovered, setIsHovered] = useState(false);
	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	return (
		// <Flex
		// 	justify="space-between"
		// 	align="center"
		// 	style={{
		// 		padding: "20px 50px 20px 50px",
		// 		margin: "4px 0 0px",
		// 		backgroundColor: "rgb(245, 248, 254)",
		// 	}}
		// >
		// 	<Flex align="center" gap={16}>
		<div>
			<Divider className="ant-divider-horizontal" />
			<div className="ant-div-container">
				<Flex className="flex-container">
					<Flex style={{ alignItems: "center", marginLeft: "2vw" }}>
						<Space>
							<Flex className="breadcrumb">
								<BreadCrumb config={breadcrumbConfig.propertiesmanagement} />
							</Flex>
						</Space>
					</Flex>
					<>
						{/* <Button type="primary" onClick={() => setopenPlanTypeModal(true)}>
					Add Type
				</Button> */}
						<motion.div
							initial="initial"
							animate={isHovered ? "hover" : "initial"}
							variants={buttonVariants}
							transition={{ duration: 0.3 }}
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
							className="add-work-item-button"
							onClick={() => setopenPlanTypeModal(true)}
						>
							<EditOutlined />
							{isHovered && <span style={{ marginLeft: "8px" }}>Add Type</span>}
						</motion.div>

						{/* PlanType Modal */}
						{/* <Modal
					title="Add Type"
					visible={openPlanTypeModal}
					onCancel={() => setopenPlanTypeModal(false)}
					onOk={handleAddPlanType}
				>
					<Form layout="horizontal">
						<Form.Item
							label="Type"
							required
							style={{ width: "100%" }}
							labelCol={{ span: 6 }}
							wrapperCol={{ span: 18 }}
						>
							<Input
								value={prefixType}
								onChange={(e) => setPrefixType(e.target.value)}
							/>
						</Form.Item>
						<Form.Item
							label="Prefix"
							required
							style={{ width: "100%" }}
							labelCol={{ span: 6 }}
							wrapperCol={{ span: 18 }}
						>
							<Input
								value={prefixValue}
								onChange={(e) => setPrefixValue(e.target.value)}
							/>
						</Form.Item>
					</Form>
				</Modal> */}
						<Modal
							width={"45vw"}
							title="Edit Type"
							visible={openPlanTypeModal}
							onCancel={() => setopenPlanTypeModal(false)}
							footer={[
								<Button
									key="cancel"
									onClick={() => setopenPlanTypeModal(false)}
								>
									Cancel
								</Button>,
								<Button key="save" type="primary" onClick={handleSave}>
									Save
								</Button>,
							]}
						>
							<Form
								form={form}
								layout="vertical"
								labelCol={{
									style: {
										textAlign: "right",
										margin: 0,
									},
								}}
							>
								<Row gutter={16}>
									{" "}
									{/* Adjust gutter value as needed */}
									<Col span={12}>
										<Form.Item
											label="Name"
											name="name"
											rules={[
												{ required: true, message: "Please input the name" },
											]}
											style={{ marginBottom: 10 }}
										>
											<Input
												style={{
													width: "100%",
													fontSize: "14px",
													height: "32px",
												}}
												placeholder="Name"
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label="Type"
											name="type"
											rules={[
												{ required: true, message: "Please input the type!" },
											]}
											style={{ marginBottom: 10 }}
										>
											<Input
												style={{
													width: "100%",
													fontSize: "14px",
													height: "32px",
												}}
												placeholder="Type"
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label="Slug"
											name="slug"
											rules={[
												{ required: true, message: "Please input the slug!" },
											]}
											style={{ marginBottom: 10 }}
										>
											<Input
												style={{
													width: "100%",
													fontSize: "14px",
													height: "32px",
												}}
												placeholder="Slug"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Form.Item
									label="Values"
									name="values"
									rules={[
										{ required: true, message: "Please input the values!" },
									]}
									style={{ marginBottom: 10 }}
								>
									<Table
										dataSource={
											form
												.getFieldValue("values")
												?.map((value: any, index: number) => ({
													key: index,
													slug: value.slug,
													value: value.value,
													description: value.description,
												})) || []
										}
										columns={valuecolumns}
										pagination={false}
										style={{ marginBottom: 10 }}
									/>
									<Button type="dashed" onClick={handleAdd}>
										Add Value
									</Button>
								</Form.Item>
							</Form>
						</Modal>
						<Modal
							title="Add Value"
							visible={visible}
							// onOk={handleOk}
							onCancel={handleCancel}
							footer={[
								<Button key="cancel" onClick={handleCancel}>
									Cancel
								</Button>,
								<Button key="save" type="primary" onClick={handleOk}>
									Save
								</Button>,
							]}
							destroyOnClose
						>
							<Form
								form={valueform}
								layout="vertical"
								onFinish={handleOk}
								labelCol={{
									style: {
										textAlign: "right",
										margin: 0,
									},
								}}
							>
								<Form.Item
									label="Slug"
									name="slug"
									rules={[
										{ required: true, message: "Please input the slug!" },
									]}
									style={{ marginBottom: 10 }}
								>
									<Input
										style={{ width: "100%", fontSize: "14px", height: "32px" }}
										placeholder="Slug"
									/>
								</Form.Item>
								<Form.Item
									label="Value"
									name="value"
									rules={[
										{ required: true, message: "Please input the value!" },
									]}
									style={{ marginBottom: 10 }}
								>
									<Input
										style={{ width: "100%", fontSize: "14px", height: "32px" }}
										placeholder="Value"
									/>
								</Form.Item>
								<Form.Item
									label="Description"
									name="description"
									style={{ marginBottom: 10 }}
								>
									<Input
										style={{ width: "100%", fontSize: "14px", height: "32px" }}
										placeholder="Description"
									/>
								</Form.Item>
							</Form>
						</Modal>
					</>
				</Flex>
			</div>
		</div>
	);
}

export default PropertiesTypeManagementNav;
