import axios, { AxiosResponse } from "axios";
import { Discussion } from "../models/Discussion";

async function getDiscussions(
	ParentId: any,
	type: any,
	token: any
): Promise<Discussion[]> {
	try {
		const response: AxiosResponse<Discussion[]> = await axios.get(
			`${process.env.REACT_APP_API_URL}/discussions/${ParentId}/${type}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching discussions:", error);
		throw error;
	}
}

async function getduscussionById(id: any, token: any): Promise<Discussion> {
	try {
		const response: AxiosResponse<Discussion> = await axios.get(
			`${process.env.REACT_APP_API_URL}/discussions/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching discussions:", error);
		throw error;
	}
}

async function updateDiscussion(
	id: any,
	discussion: any,
	token: any
): Promise<Discussion> {
	try {
		const response: AxiosResponse<Discussion> = await axios.put(
			`${process.env.REACT_APP_API_URL}/discussions/${id}`,
			discussion,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error updating discussion:", error);
		throw error;
	}
}

async function createDiscussion(
	discussion: any,
	token: any
): Promise<Discussion> {
	try {
		const response: AxiosResponse<Discussion> = await axios.post(
			`${process.env.REACT_APP_API_URL}/discussions`,
			discussion,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error creating discussion:", error);
		throw error;
	}
}

export {
	getDiscussions,
	getduscussionById,
	updateDiscussion,
	createDiscussion,
};
