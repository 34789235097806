import React, { useState, useEffect } from "react";
import { List, Typography, Flex, Divider, Pagination } from "antd";
import "./RoleManagement.css";
import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";
import BreadCrumb from "../Editors/BreadCrumb";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";
import AddUserModal from "./AddUser";
import { motion } from "framer-motion";
import { EditOutlined } from "@ant-design/icons";
import "./UserManagement.css";

const { Text } = Typography;

const UserManagement: React.FC = () => {
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const [responseData, setResponseData] = useState<any[]>([]);
	const navigate = useNavigate();
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [isModalVisible, setisModalVisible] = useState(false);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(20);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		const fetchRolesData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/user-profiles`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (response.ok) {
					const data = await response.json();
					setResponseData(data);
					console.log(data);
				} else if (response.status === 401) {
					setUnauthorizedModel(true);
				} else {
					console.error("Error fetching roles data");
				}
			} catch (error) {
				console.error("Error fetching roles data:", error);
			}
		};

		fetchRolesData();
	}, [isModalVisible]);

	useEffect(() => {
		console.log("Selected User ID:", selectedUserId);
	}, [selectedUserId]);

	const handleAddRoleClick = () => {
		setisModalVisible(true);
	};

	const handleViewPermissionsClick = (userId: any) => {
		setSelectedUserId(userId);
		navigate(`/settings/user/edit/${userId}`);
	};

	const [isHovered, setIsHovered] = useState(false);
	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	const renderListItem = (item: any) => (
		<List.Item
			key={item._id}
			style={{ padding: "5px 0" }}
			actions={[
				<a
					key="edit"
					style={{
						color: "#3478F6",
						fontSize: "12px",
						fontFamily: "Open Sans",
					}}
					onClick={() => handleViewPermissionsClick(item._id)}
				>
					Edit Permissions
				</a>,
			]}
		>
			<List.Item.Meta
				title={
					<Flex justify="space-between">
						<Text
							style={{
								fontSize: "12px",
								fontWeight: "400",
								marginLeft: "1vw",
							}}
						>
							{item.name}
						</Text>

						<Text
							style={{
								fontSize: "12px",
								fontWeight: "400",
							}}
						>
							{item.email}
						</Text>
					</Flex>
				}
			/>
		</List.Item>
	);

	// Get the current page data
	const currentPageData = responseData.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	return (
		<>
			<Divider className="ant-divider-horizontal" />
			<Flex
				justify="space-between"
				align="center"
				style={{
					margin: "0 1vw",
					marginTop: "-0.5vh",
				}}
			>
				<Flex style={{ marginLeft: "1vw" }} className="breadcrumb">
					<BreadCrumb config={breadcrumbConfig.usermanagement} />
				</Flex>

				<motion.div
					initial="initial"
					animate={isHovered ? "hover" : "initial"}
					variants={buttonVariants}
					transition={{ duration: 0.3 }}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
					className="add-work-item-button"
					onClick={handleAddRoleClick}
				>
					<EditOutlined />
					{isHovered && <span style={{ marginLeft: "8px" }}>Add User</span>}
				</motion.div>
			</Flex>
			{/* <Layout
				style={{
					height: "80vh",
					background: "white",
					margin: "0 1vw",
					padding: "0 1.5rem ",
					marginTop: "-0.5vh",
				}}
			> */}

			<div
				className="list-view-container"
				style={{
					display: "flex",
					flexDirection: "column",
					height: "76vh",
					marginLeft: "1vw",
				}}
			>
				<div style={{ flex: "1 1 auto" }}>
					<List
						dataSource={currentPageData}
						renderItem={renderListItem}
						className="list-view"
						size="small"
					/>
				</div>
				<div
					style={{
						flex: "0 0 20px",
						textAlign: "end",
						marginTop: "3px",
						marginBottom: "10px",
					}}
				>
					<Pagination
						current={currentPage}
						total={responseData.length}
						pageSize={pageSize}
						onChange={handlePageChange}
						showSizeChanger={false}
					/>
				</div>
				<AddUserModal
					isVisible={isModalVisible}
					setIsVisible={setisModalVisible}
				/>
			</div>
			{/* </Layout> */}
		</>
	);
};

export default UserManagement;
