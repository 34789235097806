import React, { useState, useEffect } from "react";
import {
	//Layout,
	Form,
	//Card,
	Table,
	Space,
	Typography,
	Tooltip,
	Input,
	Modal,
	Button,
	message,
	Popconfirm,
	Row,
	Col,
	List,
	Pagination,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import "./PrefixManagement.css";
import useWorkspaceStore from "../stores/WorkspaceState";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PropertiesTypeManagementNav from "./PropertiesManagementNav";

// const { Content } = Layout;
// const { Text } = Typography;

const PropertiesManagement: React.FC = () => {
	const [responseData, setResponseData] = useState<any[]>([]);
	const [form] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [editingRecord, setEditingRecord] = useState<any | null>(null);
	const [openPlanTypeModal, setopenPlanTypeModal] = useState(false);
	const [deleteDisabled] = useState(false);
	const [visible, setVisible] = useState(false);
	const [valueform] = Form.useForm();
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(15);

	const fetchPlanTypeData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/propertiesconfig/${currentWorkspace?._id}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const data = await response.json();
				if (Array.isArray(data)) {
					const filteredData = data.filter(
						(record) => record.isDelete !== true
					);
					const newdata = filteredData.map((record: any) => {
						return {
							...record,
							values: record.values.map((value: any, index: number) => {
								return {
									...value,
									key: index,
								};
							}),
						};
					});
					setResponseData(newdata);
				} else {
					console.error("Fetched data is not an array");
				}
			} else {
				console.error("Error fetching prefixes data");
			}
		} catch (error) {
			console.error("Error fetching prefixes data:", error);
		}
	};

	useEffect(() => {
		fetchPlanTypeData();
	}, [userProfileStoreInstance.idToken, currentWorkspace, openPlanTypeModal]);

	const openEditModal = (record: any) => {
		// console.log("Record:onedit", record);
		setEditingRecord(record);
		form.setFieldsValue(record);
		setIsModalVisible(true);
	};

	const handleSave = async () => {
		try {
			const values = await form.validateFields();
			// console.log("Form values:", values);

			const updatedRecord = { ...editingRecord, ...values };
			// console.log("updatedRecord:", updatedRecord);

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/propertiesconfig/${updatedRecord._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(values),
				}
			);
			if (response.ok) {
				setResponseData((prev) => {
					return prev.map((record) =>
						record._id === updatedRecord._id
							? { ...record, prefix: updatedRecord.prefix }
							: record
					);
				});
				valueform.resetFields();
				fetchPlanTypeData();
				// console.log("Successfully updated");
				setIsModalVisible(false);
			} else {
				console.error(`Failed to update prefix. Status: ${response.status}`);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleDelete = async (record: any) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/propertiesconfig/${record._id}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				message.success("Plan deleted successfully");
				// console.log("Successfully deleted");
				fetchPlanTypeData();
			} else {
				console.error(`Failed to delete. Status: ${response.status}`);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	// const columns = [
	// 	{
	// 		title: <div style={{ fontFamily: "Poppins" }}>Name</div>,
	// 		dataIndex: "name",
	// 		key: "name",
	// 		render: (text: any) => (
	// 			<Text
	// 				style={{
	// 					fontSize: "14px",
	// 					fontWeight: "500",
	// 					fontFamily: "Open Sans",
	// 				}}
	// 			>
	// 				{text}
	// 			</Text>
	// 		),
	// 	},
	// 	{
	// 		title: <div style={{ fontFamily: "Poppins" }}>Type</div>,
	// 		dataIndex: "type",
	// 		key: "type",
	// 		render: (text: any) => (
	// 			<Text
	// 				style={{
	// 					fontSize: "14px",
	// 					fontWeight: "500",
	// 					fontFamily: "Open Sans",
	// 				}}
	// 			>
	// 				{text}
	// 			</Text>
	// 		),
	// 	},
	// 	{
	// 		title: "Edit",
	// 		dataIndex: "",
	// 		key: "x",
	// 		render: (_: any, record: any) => (
	// 			<Space size={"large"}>
	// 				<Tooltip title="Edit">
	// 					<Typography.Link
	// 						onClick={() => openEditModal(record)}
	// 						style={{ fontFamily: "Open Sans" }}
	// 					>
	// 						<EditOutlined style={{ color: "#0444bf" }} />
	// 					</Typography.Link>
	// 				</Tooltip>
	// 				<Popconfirm
	// 					title="Sure to Delete?"
	// 					onConfirm={() => handleDelete(record)}
	// 					disabled={deleteDisabled}
	// 				>
	// 					<a
	// 						style={{
	// 							fontSize: "16px",
	// 							cursor: deleteDisabled ? "not-allowed" : "pointer",
	// 						}}
	// 					>
	// 						<DeleteOutlined style={{ color: "#ff4d4f" }} />
	// 					</a>
	// 				</Popconfirm>
	// 			</Space>
	// 		),
	// 	},
	// ];

	const valuecolumns = [
		{
			title: "Slug",
			dataIndex: "slug",
			key: "slug",
			render: (record: any) => <div>{record}</div>,
		},
		{
			title: "Value",
			dataIndex: "value",
			key: "value",
			render: (record: any) => <div>{record}</div>,
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			render: (record: any) => <div>{record}</div>,
		},
		{
			title: "Action",
			key: "action",
			render: (record: any) => (
				<Popconfirm
					title="Sure to Delete?"
					onConfirm={() => handleRemove(record)}
					disabled={deleteDisabled}
				>
					<a
						style={{
							fontSize: "16px",
							cursor: deleteDisabled ? "not-allowed" : "pointer",
						}}
					>
						<DeleteOutlined style={{ color: "#ff4d4f" }} />
					</a>
				</Popconfirm>
			),
		},
	];

	const showModal = () => {
		valueform.resetFields();
		setVisible(true);
	};

	const handleOk = () => {
		valueform
			.validateFields()
			.then((values) => {
				const newValues = [...(form.getFieldValue("values") || []), values];
				form.setFieldsValue({ values: newValues });
				setVisible(false);
			})
			.catch(() => {});
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const handleAdd = () => {
		showModal();
	};

	const handleRemove = (data: any) => {
		const newValues = form
			.getFieldValue("values")
			.filter((value: any) => value.key !== data.key);
		form.setFieldsValue({ values: newValues }); // Update form field with new values
		// Also, update the state immediately to reflect the change
		form.validateFields().then((values) => {
			setEditingRecord((prevRecord: any) => ({
				...prevRecord,
				values: values.values,
			}));
		});
	};

	const renderActions = (item: any) => (
		<Space size={"large"}>
			<Tooltip title="Edit">
				<Typography.Link
					onClick={() => openEditModal(item)}
					style={{ fontFamily: "Open Sans" }}
				>
					<EditOutlined style={{ color: "#0444bf" }} />
				</Typography.Link>
			</Tooltip>
			<Popconfirm
				title="Sure to Delete?"
				onConfirm={() => handleDelete(item)}
				disabled={deleteDisabled}
			>
				<a
					style={{
						fontSize: "16px",
						cursor: deleteDisabled ? "not-allowed" : "pointer",
					}}
				>
					<DeleteOutlined style={{ color: "#ff4d4f" }} />
				</a>
			</Popconfirm>
		</Space>
	);

	const renderListItem = (item: any) => (
		<List.Item key={item._id} style={{ padding: "0px 0" }}>
			<div className="list-item" style={{ marginLeft: "0.5vw" }}>
				<div className="list-item-description-left">
					<span
						className="work-item-title"
						style={{ width: 150, textAlign: "left" }}
					>
						{item?.name}
					</span>
					<br />
				</div>
				<div className="list-item-description-right">
					<span
						className="work-item-title"
						style={{ width: 100, textAlign: "left" }}
					>
						{item?.type}
					</span>
					<div>{renderActions(item)}</div>
				</div>
			</div>
		</List.Item>
	);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const currentPageData = responseData.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	return (
		<>
			<PropertiesTypeManagementNav
				openPlanTypeModal={openPlanTypeModal}
				setopenPlanTypeModal={setopenPlanTypeModal}
			/>
			{/* <Layout style={{ height: "100vh" }}>
				<Content className="prefix-content-container">
					<Card
						data-testid="card"
						style={{
							backgroundColor: "white",
							borderRadius: "16px",
							//padding: "24px",
							paddingLeft: "24px",
							paddingRight: "24px",
							border: "none",
							boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
							height: "100%",
							overflow: "scroll",
						}}
					>
						<div>
							<Table
								data-testid="table"
								scroll={{ y: 240 }}
								columns={columns}
								pagination={{
									pageSize: 6,
									pageSizeOptions: ["10", "20", "30"],
									showTotal: (total, range) =>
										`${range[0]}-${range[1]} of ${total} items`,
								}}
								dataSource={responseData.map((record) => ({
									key: record._id,
									type: record.type,
									name: record.name,
									values: record.values,
								}))}
							/>
						</div>
					</Card> */}

			<div
				className="list-view-container"
				style={{
					display: "flex",
					flexDirection: "column",
					height: "76vh",
					padding: "0vw 1vw",
				}}
			>
				<div style={{ flex: "1 1 auto" }}>
					<List
						itemLayout="vertical"
						dataSource={currentPageData}
						renderItem={renderListItem}
						className="list-view"
						size="small"
					/>
				</div>
				<div
					style={{
						flex: "0 0 20px",
						textAlign: "end",
						marginTop: "3px",
						marginBottom: "10px",
					}}
				>
					<Pagination
						current={currentPage}
						total={responseData.length}
						pageSize={pageSize}
						onChange={handlePageChange}
						showSizeChanger={false}
					/>
				</div>

				<Modal
					width={500}
					title="Edit Type"
					visible={isModalVisible}
					onCancel={() => setIsModalVisible(false)}
					footer={[
						<Button key="cancel" onClick={() => setIsModalVisible(false)}>
							Cancel
						</Button>,
						<Button key="save" type="primary" onClick={handleSave}>
							Save
						</Button>,
					]}
				>
					<Form form={form} layout="vertical">
						<Row gutter={16}>
							{" "}
							{/* Adjust gutter value as needed */}
							<Col span={12}>
								<Form.Item
									label="Name"
									name="name"
									rules={[{ required: true, message: "Please input the name" }]}
									style={{ marginBottom: 10 }}
								>
									<Input
										style={{ width: "100%", fontSize: "14px", height: "32px" }}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label="Type"
									name="type"
									rules={[
										{ required: true, message: "Please input the type!" },
									]}
									style={{ marginBottom: 10 }}
								>
									<Input
										style={{ width: "100%", fontSize: "14px", height: "32px" }}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Form.Item label="Values" name="values">
							<Table
								dataSource={
									form
										.getFieldValue("values")
										?.map((value: any, index: number) => ({
											key: index,
											slug: value.slug,
											value: value.value,
											description: value.description,
										})) || []
								}
								columns={valuecolumns}
								pagination={false}
								style={{ marginBottom: 10 }}
							/>
							<Button type="dashed" onClick={handleAdd}>
								Add Value
							</Button>
						</Form.Item>
					</Form>
				</Modal>
				<Modal
					title="Add Value"
					visible={visible}
					onOk={handleOk}
					onCancel={handleCancel}
					destroyOnClose
					width={500}
				>
					<Form form={valueform} layout="vertical" onFinish={handleOk}>
						<Form.Item
							label="Slug"
							name="slug"
							rules={[{ required: true, message: "Please input the slug!" }]}
							style={{ marginBottom: 10 }}
						>
							<Input
								style={{ width: "100%", fontSize: "14px", height: "32px" }}
								placeholder="Slug"
							/>
						</Form.Item>
						<Form.Item
							label="Value"
							name="value"
							rules={[{ required: true, message: "Please input the value!" }]}
							style={{ marginBottom: 10 }}
						>
							<Input
								style={{ width: "100%", fontSize: "14px", height: "32px" }}
								placeholder="Value"
							/>
						</Form.Item>
						<Form.Item
							label="Description"
							name="description"
							style={{ marginBottom: 10 }}
						>
							<Input
								style={{ width: "100%", fontSize: "14px", height: "32px" }}
								placeholder="Description"
							/>
						</Form.Item>
					</Form>
				</Modal>
				{/* </Content>
			</Layout> */}
			</div>
		</>
	);
};

export default PropertiesManagement;
