/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import {
	Modal,
	Form,
	Input as AntInput,
	Button,
	DatePicker,
	Radio,
	Flex,
	Checkbox,
	Table,
	Tag,
	Select,
	Tabs,
	message,
} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { useParams } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import useWorkspaceStore from "../../stores/WorkspaceState";
import { planerViewStore } from "../../stores/PlannerStore";
import {
	create,
	createnewTeamMember,
	findByPlan,
} from "../../api/capaicityPlaningAPI";
import { idText } from "typescript";
import { useRequest } from "ahooks";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const AddCapacityPlan: React.FC<any> = ({ planID }) => {
	const { openCapacityPlanner, setOpenCapacityPlanner } = planerViewStore();
	const { Option } = Select;
	const [activeTab, setActiveTab] = useState<string>("1");
	const [form] = Form.useForm();
	const [Memberform] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const workspaceId = useParams().workspaceId;
	const [teamMembers, setTeamMembers] = useState<any>([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [uniquemember, setuniquemember] = useState<any>([]);
	const [members, setMembers] = useState<any>([]);
	const planId = useParams().planId;
	// const [newlymember,setnewlymember]=useState

	const handleSelectChange = (selected: any) => {
		// If "Select All" is selected, select all options
		console.log("selected", selected);
		if (selected.includes("all")) {
			setSelectedOptions(
				uniquemember.map((option: any) => {
					return option.name, option._id;
				})
			);
		} else {
			setSelectedOptions(selected);
		}
	};

	const handleSelectChangemember = (selected: any) => {
		if (selected.includes("all")) {
			setSelectedOptions(
				teamMembers.map((option: any) => {
					return option.name, option._id;
				})
			);
		} else {
			setSelectedOptions(selected);
		}
	};

	const { data: capicityPlanData, run: fetchCapacityPlan } = useRequest(
		(id) => findByPlan(idText, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (response) => {
				console.log("response", response);
				// message.success("capacity plan added successfully")
			},
			onError: (error) => {
				console.log("error", error);
				message.error(error.message);
			},
		}
	);

	useEffect(() => {
		if (capicityPlanData) {
			setMembers(capicityPlanData);
		}
	}, [capicityPlanData]);

	useEffect(() => {
		const newdata = teamMembers.filter(
			(item: any) =>
				!members[0]?.teamMembers.some(
					(test: any) => test.userProfile._id === item._id
				)
		);
		console.log("members", members);
		// setMembers(uniquemembers);
		console.log("unique members", newdata);
		setuniquemember(newdata);
	}, [teamMembers, members, openCapacityPlanner]);

	// console.log("unique members", uniquemembers);
	console.log("team members", teamMembers);

	const AllTeammembers = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workspace-permissions/workspace?work-space-id=${workspaceId}`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				const newdata = data.map((item: any) => item.userProfileId);
				setTeamMembers(newdata);
				fetchCapacityPlan(planId);
				console.log(data);
			} else if (response.status === 401) {
				// setUnauthorizedModel(true);
			} else {
				console.error("Error fetching team data");
			}
		} catch (error) {
			console.error("Error fetching team data:", error);
		}
	};

	useEffect(() => {
		if (workspaceId && userProfileStoreInstance?.idToken) {
			AllTeammembers();
		}
		// fetchData();
	}, [openCapacityPlanner, workspaceId, userProfileStoreInstance]);

	const formItemLayout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 14 },
	};

	const { run: createCapicity } = useRequest(
		(body) => create(body, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (response) => {
				console.log("response", response);
				form.resetFields();
				console.log("response", response);
				// if (response.data) {
				// 	message.error(response.data);
				// } else {
				// 	message.success("Plan created successfully");
				// }
				form.resetFields();

				// fetchUserDetails();
				setOpenCapacityPlanner(false);
				// message.success("capacity plan added successfully")
			},
			onError: (error) => {
				console.log("error", error);
				message.error(error.message);
			},
		}
	);

	const finish = async (values: any) => {
		const newteammemberdata = selectedOptions.map((userProfile) => ({
			userProfile,
			leaveDates: "",
			hoursPerDay: "8",
		}));

		createCapicity({
			planID: planID,
			workspaceID: workspaceId,
			teamMembers: newteammemberdata,
		});
		// const response = await fetch(
		// 	`${process.env.REACT_APP_API_URL}/capacity-plan`,
		// 	{
		// 		method: "POST",
		// 		headers: {
		// 			"Content-Type": "application/json",
		// 			Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
		// 		},
		// 		body: JSON.stringify({
		// 			planID: planID,
		// 			workspaceID: workspaceId,
		// 			teamMembers: newteammemberdata,
		// 		}),
		// 	}
		// );

		// if (response.ok) {
		// 	console.log("Roles updated successfully");
		// 	const data: any = await response.json();
		// 	form.resetFields();
		// 	console.log(data);
		// 	if (data.data) {
		// 		message.error(data.data);
		// 	} else {
		// 		message.success("Plan created successfully");
		// 	}
		// 	form.resetFields();

		// 	// fetchUserDetails();
		// 	setOpenCapacityPlanner(false);
		// } else {
		// 	console.error("Error updating roles");
		// }
	};

	const { data: memberData, run: UpdateMemberData } = useRequest(
		(body, id) =>
			createnewTeamMember(body, id, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (response) => {
				Memberform.resetFields();
				setOpenCapacityPlanner(false);
				fetchCapacityPlan(planId);
				console.log("response", response);
				// message.success("capacity plan added successfully")
			},
			onError: (error) => {
				console.log("error", error);
				message.error(error.message);
			},
		}
	);

	const finishMemberform = async (values: any) => {
		console.log("values", values);
		const newteammemberdata = selectedOptions.map((userProfile: any) => ({
			userProfile,
			leaveDates: "",
			hoursPerDay: "8",
		}));
		UpdateMemberData({ newteammemberdata }, planId);

		// const response = await fetch(
		// 	`${process.env.REACT_APP_API_URL}/capacity-plan`,
		// 	{
		// 		method: "POST",
		// 		headers: {
		// 			"Content-Type": "application/json",
		// 			Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
		// 		},
		// 		body: JSON.stringify({
		// 			planID: planID,
		// 			workspaceID: workspaceId,
		// 			teamMembers: newteammemberdata,
		// 		}),
		// 	}
		// );

		// if (response.ok) {
		// 	console.log("Roles updated successfully");
		// 	// fetchUserDetails();
		// 	setOpenCapacityPlanner(false);
		// } else {
		// 	console.error("Error updating roles");
		// }
	};

	const handleTabChange = (key: string) => {
		setActiveTab(key);
	};

	return (
		<>
			<Modal
				width={"45vw"}
				open={openCapacityPlanner}
				onCancel={() => setOpenCapacityPlanner(false)}
				onOk={() => {
					Memberform.submit();
				}}
			>
				<Tabs activeKey={"2"} onChange={handleTabChange}>
					{/* <TabPane tab="Create New Plan" key="1">
						<Form
							{...formItemLayout}
							style={{ height: "10vh" }}
							form={form}
							onFinish={finish}
						>
							<Form.Item
								label="Team Members"
								name="Team member"
								style={{ margin: "5vh 0  5vh 0" }}
								rules={[{ required: true, message: "Please select a type" }]}
							>
								<Select
									mode="multiple"
									value={selectedOptions}
									onChange={handleSelectChange}
									allowClear
									placeholder="Please select Columns"
								>
									<Option key="all" value="all">
										Select All
									</Option>
									{teamMembers.map((option: any) => (
										<Option key={option._id} value={option._id}>
											{option.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Form>
					</TabPane> */}
					<TabPane tab="Add Member" key="2">
						<Form
							{...formItemLayout}
							style={{ height: "10vh" }}
							form={Memberform}
							onFinish={finishMemberform}
						>
							<Form.Item
								label="Add Members"
								name="Addmember"
								style={{ margin: "5vh 0  5vh 0" }}
								rules={[{ required: true, message: "Please select a type" }]}
							>
								<Select
									mode="multiple"
									value={uniquemember}
									onChange={handleSelectChange}
									allowClear
									placeholder="Please select Columns"
								>
									{uniquemember.length > 1 && (
										<Option key="all" value="all">
											Select All
										</Option>
									)}

									{uniquemember?.map((option: any) => (
										<Option key={option._id} value={option._id}>
											{option.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Form>
					</TabPane>
				</Tabs>
			</Modal>
		</>
	);
};

export default AddCapacityPlan;
