import { Button, Form, Input, Modal, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import { Createdashboard } from "../api/DashboardApi";
import { useRequest } from "ahooks";

const DashboardModel = ({ visible, onCancel }: any) => {
	const [linkTitle, setLinkTitle] = useState("");
	const [linkDescription, setLinkDescription] = useState("");
	const [dashboardId, setDashboardId] = useState("");
	const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { profile } = userProfileStore((state: any) => ({
		profile: state.profile,
	}));
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const { run: createDashbordModel } = useRequest(
		(body) => Createdashboard(body, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				setDashboardId(data._id);
				console.log("dashboardId:", dashboardId);
				navigate(`/space/${currentWorkspace._id}/dashboard/${data._id}`);
				message.success("Dashboard created successfully");
			},
			onError: (error) => {
				console.error("Error:", error);
				message.error(` Error in creating dashboard:${error.message}`);
			},
		}
	);
	const handleclick = () => {
		const data = {
			title: linkTitle,
			description: linkDescription,
			widgets: [],
			ownerId: profile._id,
		};

		createDashbordModel(data);
	};

	return (
		<Modal
			title="New Dashboard"
			visible={visible}
			onCancel={onCancel}
			footer={[
				<Button key="back" onClick={onCancel}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={handleclick}>
					Save
				</Button>,
			]}
		>
			<Form
				layout="vertical"
				// labelCol={{ span: 8 }}
				// wrapperCol={{ span: 16 }}
			>
				<Form.Item
					label="Title"
					required
					rules={[{ required: true, message: "Please input the title!" }]}
				>
					<Input
						value={linkTitle}
						onChange={(e) => setLinkTitle(e.target.value)}
					/>
				</Form.Item>

				<Form.Item
					label="Description"
					required
					rules={[{ required: true, message: "Please input the description!" }]}
				>
					<Input
						value={linkDescription}
						onChange={(e) => setLinkDescription(e.target.value)}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default DashboardModel;
