import React, { useEffect, useState } from "react";
import {
	Flex,
	Layout,
	Typography,
	Image,
	Card,
	Menu,
	Popover,
	Button,
	Input,
	FloatButton,
	Spin,
	//Space,
	//Breadcrumb,
	Result,
} from "antd";
import { useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import usePlatformStore from "../../stores/platformStore";
import { getNavigateUrlForWorkspaces } from "../../util/NavigateAssistUtil";
import Constants from "../../util/ConstantsUtil";
import "./UxProcess.css";
import { CloseOutlined, EditOutlined, SmileOutlined } from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import EditUxProcess from "./EditUxProcess";
import AddUxProcess from "./AddUxProcess";
import BreadCrumb from "../Editors/BreadCrumb";
import { useRequest } from "ahooks";
import { getProductArtifacts } from "../../api/UserPersona";
import usePageStore from "../../stores/PageStore";

const { Content } = Layout;
const { Title, Text } = Typography;

function formatString(inputString: any) {
	const stringWithoutUnderscores = inputString.replace(/_/g, " ");
	return (
		stringWithoutUnderscores.charAt(0).toUpperCase() +
		stringWithoutUnderscores.slice(1)
	);
}

const UxProcess: React.FC = () => {
	const [isPopoverVisible, setPopoverVisibility] = useState(false);
	const [commentPosted, setCommentPosted] = useState(false);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [isdata, setData] = useState<any>([]);
	const [selectedPersona, setSelectedPersona] = useState<number>(0);
	const { id } = useParams<{ id: string }>();
	const { id: routeId } = useParams<{ id: string }>();
	const [editUxProcessVisible, setEditUxProcessVisible] = useState(false);
	const [addUxProcessVisible, setAddUxProcessVisible] = useState(false);
	// const [comments, setComments] = useState<any[]>([]);
	const [newCommentContent, setNewCommentContent] = useState<string>("");
	//const [openCommentBox, setOpenCommentBox] = useState(false);
	const [contextMenuPosition, setContextMenuPosition] = useState<{
		x: number;
		y: number;
	} | null>(null);
	const [selectedPath, setSelectedPath] = useState<string | null>(null);
	//const [isCommentButtonClicked, setCommentButtonClicked] = useState(false);
	// const [isloading, setLoading] = useState(true);

	const handleEditUxProcessCancel = () => {
		setEditUxProcessVisible(false);
	};

	const handleEditUxProcessClick = () => {
		setEditUxProcessVisible(true);
	};

	const handleAddUxProcessCancel = () => {
		setAddUxProcessVisible(false);
	};

	const handelAddUxProcessClick = () => {
		setAddUxProcessVisible(true);
	};

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
		breadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
		breadcrumbConfig: state.breadcrumbConfig,
	}));

	const { currentWorkspace, isReviewEnabled, setCurrentActivity } =
		useWorkspaceStore((state: any) => ({
			currentWorkspace: state.currentWorkspace,
			isReviewEnabled: state.isReviewEnabled,
			setCurrentActivity: state.setCurrentActivity,
		}));
	const [contentClassName, setContentClassName] = useState(
		"UserPersonaLayoutContent"
	);

	// const fetchData = async () => {
	// 	try {
	// 		const response = await fetch(
	// 			`${process.env.REACT_APP_API_URL}/project-artifact/de-ux-spec-personas/${id}`,
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 				},
	// 			}
	// 		);
	// 		const result = await response.json();
	// 		if (response.status === 401) {
	// 			setUnauthorizedModel(true);
	// 		}
	// 		setData(result);
	// 		setCurrentActivity(result[0]);
	// 		setLoading(false);
	// 	} catch (error) {
	// 		console.error("Error fetching data:", error);
	// 	}
	// };

	// console.log(data);
	// use Request code

	const type = "de-ux-spec-personas";
	const {
		data: userPersonaData,
		error,
		loading,
		run: fetchRun,
	} = useRequest(
		() => getProductArtifacts(id, type, userProfileStoreInstance.idToken),
		{
			manual: true,
			onError: () => {
				setUnauthorizedModel(true);
				console.log("Error fetching data ....");
			},
		}
	);
	console.log(userPersonaData);

	const handlePersonaClick = (personaIndex: number) => {
		setSelectedPersona(personaIndex);
	};

	const {
		setBackNavigateUrl,
		setNextNavigateUrl,
		setAppBarTitle,
		setCurrentModule,
		setCurrentPage,
		setUnauthorizedModel,
	} = usePlatformStore((state: any) => ({
		setBackNavigateUrl: state.setBackNavigateUrl,
		setNextNavigateUrl: state.setNextNavigateUrl,
		setAppBarTitle: state.setAppBarTitle,
		setCurrentModule: state.setCurrentModule,
		setCurrentPage: state.setCurrentPage,
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));

	useEffect(() => {
		const navigationUrls = getNavigateUrlForWorkspaces(
			`${id}`,
			currentWorkspace.modules,
			Constants.DESIGN_ENGINEERING,
			Constants.DE_USER_PERSONA
		);
		setNextNavigateUrl(navigationUrls.nextUrl);
		setBackNavigateUrl(navigationUrls.prevUrl);
		setAppBarTitle(Constants.DE_USER_PERSONA);
		setCurrentModule(Constants.DESIGN_ENGINEERING);
		setCurrentPage(Constants.DE_USER_PERSONA);
	}, [currentWorkspace]);

	useEffect(() => {
		if (userPersonaData) {
			setData(userPersonaData);
			setCurrentActivity(userPersonaData[0]);
		}
	}, [userPersonaData]);

	useEffect(() => {
		if (userProfileStoreInstance.idToken) {
			fetchRun();
		}
	}, [
		userProfileStoreInstance.idToken,
		editUxProcessVisible,
		addUxProcessVisible,
	]);

	const personaMenu = isdata[0]?.data;
	console.log(personaMenu, "personamenu");

	useEffect(() => {
		if (personaMenu && personaMenu.length > 0) {
			const foundIndex = personaMenu.findIndex(
				(persona: any) => formatString(persona.id) === routeId
			);
			if (foundIndex !== -1) {
				setSelectedPersona(foundIndex);
			}
		}
	}, [personaMenu, routeId]);

	useEffect(() => {
		setContentClassName(
			isReviewEnabled
				? "UserPersonaLayoutContentHover"
				: "UserPersonaLayoutContent"
		);
	}, [isReviewEnabled]);

	useEffect(() => {
		setItemId(routeId);
		setPageSlug("user-persona");
		setPageTitle("User Persona");
		setPageDescription("User Persona");
		setIsList(false);
		setItemType(`${type}`);
		setItemDetails(isdata);
		setBreadcrumbConfig({
			page: `/space/${currentWorkspace._id}/planner/de/uxprocess/${routeId}`,
			config: [
				{
					label: "User Persona",
					url: `/space/${currentWorkspace._id}/planner/de/uxprocess/${currentWorkspace._id}`,
				},
			],
		});
	}, [routeId]);

	const handleRightClick = (e: React.MouseEvent) => {
		e.preventDefault();

		if (isReviewEnabled) {
			setPopoverVisibility(true);
			// setContentClassName(isCommentButtonClicked ? "FeatureSpecificationLayoutContentHover" : "FeatureSpecificationLayoutContent");
			const clickedElement = e.target as HTMLElement;
			const clickedPath = clickedElement.dataset.path || "";
			console.log(clickedPath); // Use an empty string as a fallback
			setContextMenuPosition({ x: e.clientX, y: e.clientY });
			console.log(clickedPath, "clickedpaths");
			setSelectedPath(clickedPath);
		} else {
			setContentClassName("FeatureSpecificationLayoutContent");
		}
	};

	// const addComment = (content: string, path: any) => {
	//  const newComment: any = {
	//      content,
	//      path,
	//  };
	//  setComments([...comments, newComment]);
	// };

	const postComment = () => {
		const newComment = {
			comment: newCommentContent,
			path: selectedPath,
		};

		const newData = [...isdata];
		const userReview = newData[0]?.userReview || [];
		userReview.push(newComment);
		newData[0].userReview = userReview;
		setCommentPosted(true);

		setPopoverVisibility(false);

		setNewCommentContent("");
		setContextMenuPosition(null);

		fetch(
			`${process.env.REACT_APP_API_URL}/project-artifact/de-ux-spec/${id}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify(newData[0]),
			}
		)
			.then((response) => response.json())
			.then((res) => {
				console.log("dev", res);
			})
			.catch((error) => {
				console.log(error);
			});

		setCommentPosted(true);
	};

	console.log(commentPosted);

	return (
		<Layout style={{ background: "#f5f8fe" }}>
			{loading ? ( // Show loading indicator while loading
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "100vh",
					}}
				>
					<Spin size="large" />
					<img
						style={{ marginTop: "10px", width: "150px" }}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
						alt="Loading"
					/>
					<Spin size="large" />
				</div>
			) : error ? (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "100vh",
					}}
				>
					<Result
						status="error"
						title="Something went wrong"
						subTitle="Please try again later."
					/>
				</div>
			) : (
				<>
					<Flex style={{ width: "100%", marginTop: "3vh" }}>
						{" "}
						<Flex
							justify="space-between"
							style={{ alignItems: "center", width: "100%" }}
						>
							{/* <Space>
                               <Flex vertical>
                                   <Breadcrumb>
                                       <Breadcrumb.Item>
                                           <h2
                                               color="#87d068"
                                               style={{ fontSize: "1.1rem", marginLeft: "3vw" }}
                                           >
                                               User Persona
                                           </h2>
                                       </Breadcrumb.Item>
                                   </Breadcrumb>
                               </Flex>
                           </Space> */}

							<div style={{ fontSize: "1.1rem", marginLeft: "3vw" }}>
								<BreadCrumb config={breadcrumbConfig} />
							</div>
							<Button type="primary" onClick={handelAddUxProcessClick}>
								Add User Persona
							</Button>
						</Flex>
					</Flex>
					<Layout>
						{isdata.length === 0 ? (
							<Result
								icon={<SmileOutlined style={{ color: "#0444BF" }} />}
								title="There are No User Persona Generated!"
								style={{ marginTop: "10vh", marginBottom: "20vh" }}
							/>
						) : (
							<>
								<Content style={{ backgroundColor: "#f5f8fe" }}>
									{/* <Flex justify="center" align="center"> */}

									{/* <Flex gap="middle" justify="center" vertical className="user-persona"> */}

									<Card
										className="content-dimensions"
										style={{
											width: "99%",
											overflowX: "auto",
											height: "75vh",
											overflowY: "auto",
										}}
									>
										{personaMenu &&
											personaMenu.length > 0 &&
											selectedPersona !== null && (
												<Flex gap="middle" vertical>
													<Flex justify="center" align="center">
														<Title level={3}>
															Persona {selectedPersona + 1}:{" "}
															{formatString(personaMenu[selectedPersona].id)}
														</Title>
													</Flex>
													<Flex justify="space-between" key={selectedPersona}>
														<Flex
															className="personaGridPosition"
															vertical
															align="center"
														>
															<Flex
																onContextMenu={handleRightClick}
																className={contentClassName}
																data-path={`/de/${selectedPersona}`}
																vertical
																style={{ paddingLeft: "10px" }}
															>
																<h2 className="subTitlePersona">👋Biography</h2>
																<Text className="textUserPersona">
																	{
																		personaMenu[selectedPersona].user_persona
																			.biography
																	}
																</Text>
															</Flex>
															<Flex
																onContextMenu={handleRightClick}
																className={contentClassName}
																data-path={`/de/${selectedPersona}`}
																vertical
															>
																<h2 className="subTitlePersona">
																	💪Motivation
																</h2>
																<Text className="textUserPersona">
																	{
																		personaMenu[selectedPersona].user_persona
																			.motivations
																	}
																</Text>
															</Flex>
														</Flex>
														<Flex
															vertical
															align="center"
															className="personaGridPosition"
														>
															<Flex vertical>
																<Image
																	style={{
																		borderRadius: "20px",
																		height: "18rem",
																		boxShadow:
																			" 3px 10px 10px 3px rgba(0, 0, 0, 0.15)",
																		marginBottom: "1rem",
																	}}
																	width="95%"
																	src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
																/>
															</Flex>
															<Flex
																onContextMenu={handleRightClick}
																className={contentClassName}
																data-path={`/de/${selectedPersona}`}
																vertical
															>
																<h2 className="subTitlePersona">
																	👤Demographics
																</h2>
																<Text className="listPersona">
																	Age:{" "}
																	{
																		personaMenu[selectedPersona].user_persona
																			.age
																	}
																</Text>
																<Text className="listPersona">
																	Name:{" "}
																	{
																		personaMenu[selectedPersona].user_persona
																			.name
																	}
																</Text>
																<Text className="listPersona">
																	Occupation:{" "}
																	{
																		personaMenu[selectedPersona].user_persona
																			.occupation
																	}
																</Text>
															</Flex>
														</Flex>
														<Flex
															vertical
															className="personaGridPosition"
															align="center"
														>
															<Flex
																onContextMenu={handleRightClick}
																className={contentClassName}
																data-path={`/de/${selectedPersona}`}
																vertical
															>
																<h2 className="subTitlePersona">💠Goals</h2>
																<Text className="textUserPersona">
																	Plan and organize trips to new and exciting
																	destinations. Find affordable travel deals and
																	accommodations. Manage her travel itinerary
																	and budget. Share her travel experiences with
																	friends and family
																</Text>
															</Flex>
															<Flex
																onContextMenu={handleRightClick}
																className={contentClassName}
																data-path={`/de/${selectedPersona}`}
																vertical
															>
																<h2 className="subTitlePersona">
																	💢Pain Points
																</h2>

																<Text className="textUserPersona">
																	{
																		personaMenu[selectedPersona].user_persona
																			.pain_points
																	}
																</Text>
															</Flex>
														</Flex>
													</Flex>
													{contextMenuPosition && (
														<Popover
															title={
																<Flex justify="space-between">
																	<Typography.Title level={5}>
																		{" "}
																		Add a Comment{" "}
																	</Typography.Title>
																	<Button
																		icon={<CloseOutlined />}
																		size="small"
																		shape="circle"
																		onClick={() => setContextMenuPosition(null)}
																	></Button>
																</Flex>
															}
															content={
																<div>
																	<Input.TextArea
																		style={{
																			height: "15vh",
																			marginBottom: "1vh",
																		}}
																		placeholder="Type your comment here..."
																		onChange={(e) =>
																			setNewCommentContent(e.target.value)
																		}
																		value={newCommentContent}
																	/>
																	<Button type="primary" onClick={postComment}>
																		Post Comment
																	</Button>
																</div>
															}
															trigger="click"
															//open={openCommentBox}
															open={isPopoverVisible}
															onOpenChange={(visible) =>
																visible || setContextMenuPosition(null)
															}
														>
															<div
																className="NewCommentSectio"
																style={{
																	position: "fixed",
																	top: contextMenuPosition.y + "px",
																	left: contextMenuPosition.x + "px",
																}}
															>
																{/* Placeholder for comment button */}
																<Button type="link"></Button>
															</div>
														</Popover>
													)}
												</Flex>
											)}
										{/* </Card> */}
									</Card>
									{/* </Flex> */}
									{/* </Flex> */}
								</Content>
								<Sider
									width={280}
									style={{
										backgroundColor: "#f5f8fe",
										overflow: "auto",
										height: "505px",
									}}
								>
									<div className="MenuItem-wrap">
										<Menu
											defaultSelectedKeys={[`${selectedPersona}`]}
											mode="inline"
											className="leftmenu"
											onClick={(e) => handlePersonaClick(parseInt(e.key))}
										>
											{personaMenu &&
												personaMenu.length > 0 &&
												personaMenu.map((persona: any, index: number) => (
													<Menu.Item
														className="ux-persona-leftmenu-item"
														key={index}
													>
														{formatString(persona.id)}
													</Menu.Item>
												))}
										</Menu>
									</div>
								</Sider>
								<FloatButton
									icon={<EditOutlined />}
									type="primary"
									style={{ right: 24, bottom: 100 }}
									onClick={handleEditUxProcessClick}
								/>
								<EditUxProcess
									personNo={selectedPersona}
									persona={userPersonaData}
									visible={editUxProcessVisible}
									onCancel={handleEditUxProcessCancel}
									data={personaMenu && personaMenu[selectedPersona]}
								></EditUxProcess>
								<AddUxProcess
									visible={addUxProcessVisible}
									onCancel={handleAddUxProcessCancel}
									data={userPersonaData}
								></AddUxProcess>
							</>
						)}
					</Layout>
				</>
			)}
		</Layout>
	);
};

export default UxProcess;
