import { Block, BlockNoteEditor, PartialBlock } from "@blocknote/core";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { getRitualSession, updateRitualSession } from "../../../api/RitualApi";
import { createActivelog } from "../../../api/ActiveLogAPI";
import { Alert, Card, Spin, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { useDebounceFn, useRequest } from "ahooks";
import { useParams } from "react-router-dom";

const RitualNotesV2 = () => {
	const { itemId } = useParams();
	const [data, setData] = useState<any>();
	const [notes, setNotes] = useState<any>([]);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [initialContent, setInitialContent] = useState<
		PartialBlock[] | undefined | "loading"
	>("loading");
	const [alertMessage, setAlertMessage] = useState<string | null>(null);
	const [initialNotes, setInitialNotes] = useState<any>([]);
	const {
		run: fetchData,
		loading,
		error,
	} = useRequest(
		() => {
			const token = userProfileStoreInstance.idToken;
			return getRitualSession(itemId ?? "", token ?? "");
		},
		{
			manual: true,
			onSuccess: (result) => {
				setInitialNotes(result.notes);
				setData(result);
			},
			onError: (err) => {
				message.error("Error fetching item: " + err.message);
			},
		}
	);
	const { run: postActivelogs } = useRequest(
		(body) => createActivelog(body, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (response) => {
				message.success("Activlog updated successfully");
				console.log(response);
			},
			onError: (error) => {
				console.log(error);
				message.error(`Active Log Error: ${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (itemId && userProfileStoreInstance?.idToken) {
			fetchData();
		}
	}, [itemId, fetchData, itemId, userProfileStoreInstance]);

	const getChanges = (initialNotes: any, currentNotes: any) => {
		if (JSON.stringify(initialNotes) !== JSON.stringify(currentNotes)) {
			return {
				field: "notes",
				oldValue: initialNotes,
				newValue: currentNotes,
			};
		}
		return null;
	};

	const { run: sendRequest } = useRequest(
		async () => {
			const updatedData = { ...data, notes: notes };
			const changes = getChanges(initialNotes, notes);
			if (changes) {
				postActivelogs({
					type: data.type,
					parentId: data._id,
					content: [
						{
							[changes.field]: {
								oldValue: changes.oldValue,
								newValue: changes.newValue,
							},
						},
					],
				});
			}
			await updateRitualSession(
				itemId ?? "",
				updatedData,
				userProfileStoreInstance.idToken ?? ""
			);
		},
		{
			manual: true,
			onSuccess: () => {
				fetchData();

				setAlertMessage("Notes added successfully");

				setTimeout(() => {
					setAlertMessage(null);
				}, 3000);
			},
			onError: (err) => {
				console.error("Error updating Notes:", err);
			},
		}
	);

	async function saveToStorage(jsonBlocks: Block[]) {
		localStorage.setItem("editorContent", JSON.stringify(jsonBlocks));
		console.log("Data saved to localStorage:", jsonBlocks);
		setNotes(jsonBlocks);
	}

	const account = process.env.REACT_APP_FILE_UPLOAD_STORAGE_ACCOUNT;
	const sasToken = process.env.REACT_APP_FILE_UPLOAD_STORAGE_SAS_TOKEN;
	const containerName = process.env
		.REACT_APP_FILE_UPLOAD_STORAGE_CONTAINER as string;

	if (!account || !sasToken || !containerName) {
		throw new Error("Missing Azure Blob Storage configuration.");
	}

	async function uploadFile(file: File) {
		const blobServiceClient = new BlobServiceClient(
			`https://${account}.blob.core.windows.net/?${sasToken}`
		);
		const containerClient = blobServiceClient.getContainerClient(containerName);

		const blobName = `${new Date().getTime()}-${file.name}`;
		const blobClient = containerClient.getBlockBlobClient(blobName);
		await blobClient.uploadData(file, {
			blobHTTPHeaders: { blobContentType: file.type },
		});

		const fileUrl = `https://${account}.blob.core.windows.net/${containerName}/${blobName}`;

		return fileUrl;
	}

	useEffect(() => {
		const loadContent = async () => {
			const baseContent: PartialBlock[] = [];

			if (data?.notes?.length > 0) {
				const mappedContent = data.notes.map((parent: any) => {
					return {
						type: parent.type || "paragraph",
						props: parent.props || {},
						content:
							parent.content && Array.isArray(parent.content)
								? parent.content.map((item: any) => {
										if (typeof item === "string") {
											return item;
										} else if ("text" in item) {
											return item.text;
										} else {
											return "";
										}
									})
								: [],
					} as PartialBlock;
				});

				setInitialContent([...baseContent, ...mappedContent]);
			} else {
				setInitialContent([
					...baseContent,
					{ type: "paragraph", content: [""] },
				]);
			}
		};
		if (data) {
			loadContent();
		}
	}, [data]);

	const editor = useMemo(() => {
		if (initialContent === "loading") {
			return undefined;
		}
		return BlockNoteEditor.create({ initialContent, uploadFile });
	}, [initialContent]);

	const { run: debouncedSend } = useDebounceFn(sendRequest, { wait: 1000 });

	if (initialContent === "loading" || !editor) {
		return <div>Loading content...</div>;
	}

	return (
		<>
			{!loading && !error && (
				<div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
					<Card
						style={{
							height: "60vh",
							width: "100%",
							overflowY: "auto",
							display: "flex",
							flexDirection: "column",
							marginTop: "0vh",
							marginRight: "1vw",
							//border: "none",
							boxShadow: "none",
						}}
					>
						<BlockNoteView
							editor={editor}
							theme="light"
							onChange={() => {
								saveToStorage(editor.document);
								debouncedSend();
							}}
						/>
						<div>
							{alertMessage && (
								<Alert
									message={alertMessage}
									type="success"
									showIcon
									closable
									onClose={() => setAlertMessage(null)}
								/>
							)}
							{loading && <Spin />}
							{error && (
								<Alert
									message="Error"
									description={error}
									type="error"
									closable
									onClose={() => setAlertMessage(null)}
								/>
							)}
						</div>
					</Card>
				</div>
			)}
		</>
	);
};

export default RitualNotesV2;
