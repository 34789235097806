import React from "react";
import { Flex } from "antd";
import BreadCrumb from "../../Editors/BreadCrumb";
import usePageStore from "../../../stores/PageStore";

interface NavProps {
	title: string;
}
const RitualViewNav: React.FC<NavProps> = ({ title }) => {
	const { breadcrumbConfig } = usePageStore((state: any) => ({
		breadcrumbConfig: state.breadcrumbConfig,
	}));
	return (
		<div>
			{/* <Divider className="ant-divider-horizontal" /> */}
			<div style={{ backgroundColor: "#f5f8fe" }} className="ant-div-container">
				<Flex className="flex-container">
					<Flex className="breadcrumb" style={{ marginLeft: "2vw" }}>
						<BreadCrumb config={breadcrumbConfig} workItemId={title} />
					</Flex>
				</Flex>
			</div>
		</div>
	);
};
export default RitualViewNav;
