import React, { useState, useEffect } from "react";
import { Layout, List, Modal, message, Button, Typography } from "antd";
import { FolderOpenTwoTone } from "@ant-design/icons";
import "./RoleManagement.css";
import Lottie from "lottie-react";
import ConformationWorkspace from "../../assets/ConformationWorksapce.json";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import usePlatformStore from "../../stores/platformStore";
import ArchiveWorkspaceNav from "./ArchiveWorkspaceNav";

const { Paragraph, Title } = Typography;

const ArchiveWorkspace: React.FC = () => {
	const [responseData, setResponseData] = useState<any[]>([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [workspaceToDelete, setWorkspaceToDelete] = useState<any | null>(null);
	const [name, setName] = useState<string>("");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const ServerLink = process.env.REACT_APP_API_URL;
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const fetchWorkspacesData = async () => {
		try {
			const response = await fetch(
				`${ServerLink}/workspace/archived/workspace`,
				{
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			const data = await response.json();
			if (!response.ok) {
				throw new Error(
					`HTTP error! Status: ${response.status}: ${response.statusText}`
				);
				return;
			}
			if (response.status === 401) {
				setUnauthorizedModel(true);
			} else {
				setUnauthorizedModel(false);
				setResponseData(data);
			}
		} catch (error) {
			message.error(`Error: ${error}`);
		}
	};

	useEffect(() => {
		fetchWorkspacesData();
	}, []);

	const showDeleteConfirmation = (record: any, name: string) => {
		setName(name);
		setWorkspaceToDelete(record);
		setIsModalVisible(true);
	};

	const handleOk = async () => {
		try {
			const response = await fetch(
				`${ServerLink}/workspace/${workspaceToDelete}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const existingWorkspaceData = await response.json();

			const updatedWorkspaceData = {
				...existingWorkspaceData,
				status: "ACTIVE",
			};

			// Perform the PUT request to update the workspace
			const updateResponse = await fetch(
				`${ServerLink}/workspace/${workspaceToDelete}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(updatedWorkspaceData),
				}
			);

			if (!updateResponse.ok) {
				throw new Error(
					`Status: ${updateResponse.status} - ${updateResponse.statusText}`
				);
			}

			fetchWorkspacesData();
		} catch (error: any) {
			message.error(
				`An error occurred in updating the workspace: ${error.message}`
			);
		}

		// Close the modal after handling the update
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setWorkspaceToDelete(null);
		setIsModalVisible(false);
	};

	// const renderListItem = (item: any) => (
	//     <List.Item
	//         key={item._id}
	//         actions={[
	//             <FolderOpenTwoTone
	//                 style={{ fontSize: "14px", fontFamily: "Open Sans" }}
	//                 onClick={() => showDeleteConfirmation(item._id, item.name)}
	//             />
	//         ]}
	//     >
	//         <List.Item.Meta
	//             title={<div style={{ fontFamily: "Poppins" }}>{item.name}</div>}
	//             description={
	//                 <span style={{ fontSize: "14px", fontFamily: "Open Sans" }}>
	//                     {item.status}
	//                 </span>
	//             }
	//         />
	//     </List.Item>
	// );

	return (
		<>
			<ArchiveWorkspaceNav />
			<Layout
				style={{
					height: "80vh",
					background: "white",
					margin: "0 1vw",
					padding: "0 1.5rem ",
					marginTop: "-0.5vh",
				}}
			>
				<List
					dataSource={responseData}
					renderItem={(item) => (
						<List.Item
							key={item._id} // Ensure key prop is correctly applied here
							actions={[
								<FolderOpenTwoTone
									key="edit"
									style={{ fontSize: "14px", fontFamily: "Open Sans" }}
									onClick={() => showDeleteConfirmation(item._id, item.name)}
								/>,
							]}
						>
							<List.Item.Meta
								style={{ padding: "5px 0" }}
								title={
									<div
										style={{ display: "flex", justifyContent: "space-between" }}
									>
										<div
											style={{
												fontFamily: "Poppins",
												fontSize: "12px",
												fontWeight: 400,
											}}
										>
											{item.name}
										</div>
										<div>
											{" "}
											<span style={{ fontSize: "12px", fontWeight: 400 }}>
												{item.status}
											</span>
										</div>
									</div>
								}
							/>
						</List.Item>
					)}
				/>
				<Modal
					open={isModalVisible}
					width={"45%"}
					onOk={handleOk}
					onCancel={handleCancel}
					footer={null}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<Lottie
							animationData={ConformationWorkspace}
							loop={true}
							autoplay={true}
							style={{ width: "40%", height: "auto" }}
						/>
						<Title level={4} style={{ textAlign: "center" }}>
							Are you certain you wish to restore this workspace: {name}?
						</Title>
						<Paragraph style={{ textAlign: "center" }}>
							{`Curious about the narratives woven into workspace restoration? Each click unveils a digital time capsule, breathing life into shared triumphs and igniting the flames of future innovation.`}
						</Paragraph>

						<div
							style={{
								display: "flex",
								gap: "10px",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Button
								size="small"
								style={{ borderRadius: "6px", fontSize: "14px" }}
								type="primary"
								onClick={handleOk}
							>
								Restore
							</Button>
							<Button
								size="small"
								style={{ borderRadius: "6px", fontSize: "14px" }}
								onClick={handleCancel}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Modal>
			</Layout>
		</>
	);
};

export default ArchiveWorkspace;
