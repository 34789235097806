import React, { useState, useEffect } from "react";
import {
	//Layout,
	Form,
	//Card,
	//Table,
	Space,
	Typography,
	Tooltip,
	Input,
	Modal,
	Button,
	message,
	Popconfirm,
	List,
	Tag,
	Pagination,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PrefixManagementNav from "./PrefixManagementNav";

// const { Content } = Layout;
// const { Text } = Typography;

const PrefixManagement: React.FC = () => {
	const [responseData, setResponseData] = useState<any[]>([]);
	const [form] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [editingRecord, setEditingRecord] = useState<any | null>(null);
	const [openPrefixModal, setOpenPrefixModal] = useState(false);
	const [deleteDisabled] = useState(false);
	const [inputWarning, setInputWarning] = useState("");
	const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(15);

	const fetchPrefixesData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitemIdConfig/${currentWorkspace?._id}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const data = await response.json();
				if (Array.isArray(data)) {
					const filteredData = data.filter(
						(record) => record.isDelete !== true
					);
					setResponseData(filteredData);
				} else {
					console.error("Fetched data is not an array");
				}
			} else {
				console.error("Error fetching prefixes data");
			}
		} catch (error) {
			console.error("Error fetching prefixes data:", error);
		}
	};

	useEffect(() => {
		fetchPrefixesData();
	}, [userProfileStoreInstance.idToken, currentWorkspace, openPrefixModal]);

	const openEditModal = (record: any) => {
		setEditingRecord(record);
		form.setFieldsValue(record);
		setIsModalVisible(true);
	};

	const handleSave = async () => {
		try {
			const values = await form.validateFields();
			console.log("Form values:", values);

			const updatedRecord = { ...editingRecord, ...values };
			console.log("updatedRecord:", updatedRecord);

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitemIdConfig/${updatedRecord.key}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						prefix: updatedRecord.prefix,
					}),
				}
			);
			if (response.ok) {
				setResponseData((prev) => {
					return prev.map((record) =>
						record._id === updatedRecord._id
							? { ...record, prefix: updatedRecord.prefix }
							: record
					);
				});
				fetchPrefixesData();
				console.log("Successfully updated");
				setIsModalVisible(false);
			} else {
				console.error(`Failed to update prefix. Status: ${response.status}`);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleDelete = async (record: any) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitemIdConfig/${record._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						isDelete: true,
					}),
				}
			);
			if (response.ok) {
				setResponseData((prev) =>
					prev.map((item) =>
						item._id === record._id ? { ...item, isDelete: true } : item
					)
				);
				message.success("Prefix deleted successfully");
				fetchPrefixesData();
			} else {
				console.error(`Failed to delete record. Status: ${response.status}`);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	// const columns = [
	// 	{
	// 		title: <div style={{ fontFamily: "Poppins" }}>Type</div>,
	// 		dataIndex: "type",
	// 		key: "type",
	// 		render: (text: any) => (
	// 			<Text
	// 				style={{
	// 					fontSize: "14px",
	// 					fontWeight: "500",
	// 					fontFamily: "Open Sans",
	// 				}}
	// 			>
	// 				{text}
	// 			</Text>
	// 		),
	// 	},
	// 	{
	// 		title: "Prefix",
	// 		dataIndex: "prefix",
	// 		key: "prefix",
	// 		render: (text: any) => <span>{text}</span>,
	// 	},
	// 	{
	// 		title: "Edit",
	// 		dataIndex: "",
	// 		key: "x",
	// 		render: (_: any, record: any) => (
	// 			<Space size={"large"}>
	// 				<Tooltip title="Edit">
	// 					<Typography.Link
	// 						onClick={() => openEditModal(record)}
	// 						style={{ fontFamily: "Open Sans" }}
	// 					>
	// 						<EditOutlined style={{ color: "#0444bf" }} />
	// 					</Typography.Link>
	// 				</Tooltip>
	// 				<Popconfirm
	// 					title="Sure to Delete?"
	// 					onConfirm={() => handleDelete(record)}
	// 					disabled={deleteDisabled}
	// 				>
	// 					<a
	// 						style={{
	// 							fontSize: "16px",
	// 							cursor: deleteDisabled ? "not-allowed" : "pointer",
	// 						}}
	// 					>
	// 						<DeleteOutlined style={{ color: "#ff4d4f" }} />
	// 					</a>
	// 				</Popconfirm>
	// 			</Space>
	// 		),
	// 	},
	// ];

	const handlePrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.toUpperCase();
		if (value.length > 3) {
			setInputWarning("Only up to 3 uppercase letters are allowed");
			setSaveButtonDisabled(true);
		} else if (!/^[A-Z]*$/.test(value)) {
			setInputWarning("Only capital letters are allowed");
			setSaveButtonDisabled(true);
		} else if (value.length === 0) {
			setInputWarning("Prefix cannot be empty");
			setSaveButtonDisabled(true);
			// } else if (value.length < 3) {
			// 	setInputWarning("Prefix must be exactly 3 characters long");
			// 	setSaveButtonDisabled(true);
		} else {
			setInputWarning("");
			setSaveButtonDisabled(false);
		}
		form.setFieldsValue({
			prefix: value,
		});
	};

	const renderActions = (item: any) => (
		<Space size={"large"}>
			<Tooltip title="Edit">
				<Typography.Link onClick={() => openEditModal(item)}>
					<EditOutlined style={{ color: "#0444bf" }} />
				</Typography.Link>
			</Tooltip>
			<Popconfirm
				title="Sure to Delete?"
				onConfirm={() => handleDelete(item)}
				disabled={deleteDisabled}
			>
				<a
					style={{
						fontSize: "16px",
						cursor: deleteDisabled ? "not-allowed" : "pointer",
					}}
				>
					<DeleteOutlined style={{ color: "#ff4d4f" }} />
				</a>
			</Popconfirm>
		</Space>
	);

	const renderListItem = (item: any) => (
		<List.Item key={item._id} style={{ padding: "0px 0" }}>
			<div className="list-item" style={{ marginLeft: "0.5vw" }}>
				<div className="list-item-description-left">
					<span
						className="work-item-title"
						style={{ width: 150, textAlign: "left" }}
					>
						{item?.type}
					</span>
					<br />
				</div>
				<div className="list-item-description-right">
					<Tag color="blue" className="tag-icon" style={{ marginRight: "1vw" }}>
						{item?.prefix}
					</Tag>
					<div style={{ marginRight: "1vw" }}>{renderActions(item)}</div>
				</div>
			</div>
		</List.Item>
	);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const currentPageData = responseData.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	return (
		<>
			<PrefixManagementNav
				openPrefixModal={openPrefixModal}
				setOpenPrefixModal={setOpenPrefixModal}
			/>
			{/* <Layout style={{ height: "100vh" }}>
				<Content className="prefix-content-container">
					<Card
						data-testid="card"
						style={{
							backgroundColor: "white",
							borderRadius: "16px",
							paddingLeft: "24px",
							paddingRight: "24px",
							border: "none",
							boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
							height: "100%",
							overflow: "scroll",
						}}
					>
						<div>
							<Table
								data-testid="table"
								scroll={{ y: 240 }}
								columns={columns}
								pagination={{
									pageSize: 6,
									pageSizeOptions: ["10", "20", "30"],
									showTotal: (total, range) =>
										`${range[0]}-${range[1]} of ${total} items`,
								}}
								dataSource={responseData.map((record) => ({
									key: record._id,
									type: record.type,
									prefix: record.prefix,
								}))}
							/>
						</div>
					</Card> */}
			<div
				className="list-view-container"
				style={{
					display: "flex",
					flexDirection: "column",
					height: "76vh",
					marginLeft: "1vw",
				}}
			>
				<div style={{ flex: "1 1 auto" }}>
					<List
						itemLayout="vertical"
						dataSource={currentPageData}
						renderItem={renderListItem}
						className="list-view"
						size="small"
					/>
				</div>
				<div
					style={{
						flex: "0 0 20px",
						textAlign: "end",
						marginTop: "3px",
						marginBottom: "10px",
					}}
				>
					<Pagination
						current={currentPage}
						total={responseData.length}
						pageSize={pageSize}
						onChange={handlePageChange}
						showSizeChanger={false}
					/>
				</div>

				<Modal
					title="Edit Prefix"
					visible={isModalVisible}
					onCancel={() => setIsModalVisible(false)}
					footer={[
						<Button key="cancel" onClick={() => setIsModalVisible(false)}>
							Cancel
						</Button>,
						<Button
							key="save"
							type="primary"
							onClick={handleSave}
							disabled={saveButtonDisabled}
						>
							Save
						</Button>,
					]}
					width={500}
				>
					<Form
						form={form}
						layout="vertical"
						labelCol={{
							style: {
								textAlign: "right",
								margin: 0,
							},
						}}
					>
						<Form.Item
							label="Type"
							name="type"
							rules={[{ required: true, message: "Please input the type!" }]}
							style={{ width: "100%", marginBottom: 10 }}
							// labelCol={{ span: 6 }}
							// wrapperCol={{ span: 18 }}
						>
							<Input
								readOnly
								style={{ width: "100%", fontSize: "14px", height: "32px" }}
							/>
						</Form.Item>
						<Form.Item
							label="Prefix"
							name="prefix"
							rules={[
								{ required: true, message: "Please input the prefix!" },
								{
									validator: (_, value) => {
										if (!value) {
											return Promise.reject("Please input the prefix!");
										} else if (!/^[A-Z]*$/.test(value)) {
											return Promise.reject(
												"Only capital letters and it must be 3 characters long"
											);
										} else if (value.length > 3) {
											return Promise.reject(
												"Only capital letters and it must be 3 characters long"
											);
										}
										return Promise.resolve();
									},
								},
							]}
							style={{ width: "100%", marginBottom: 10 }}
							// labelCol={{ span: 6 }}
							// wrapperCol={{ span: 18 }}
							validateStatus={inputWarning ? "error" : ""}
							help={inputWarning}
						>
							<Input
								style={{ width: "100%", fontSize: "14px", height: "32px" }}
								maxLength={3}
								onChange={handlePrefixChange}
							/>
						</Form.Item>
					</Form>
				</Modal>
				{/* </Content>
			</Layout> */}
			</div>
		</>
	);
};

export default PrefixManagement;
