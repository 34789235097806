import { Divider, Flex } from "antd";
import React from "react";
import breadcrumbConfig from "../../util/BreadCrumbsUtil";
import BreadCrumb from "../Editors/BreadCrumb";

function PreferenceNav(): any {
	return (
		<>
			<Divider className="ant-divider-horizontal" />
			<Flex
				justify="space-between"
				align="center"
				style={{
					margin: "0 1vw",
					marginTop: "-0.5vh",
				}}
			>
				<Flex style={{ marginLeft: "1vw" }} className="breadcrumb">
					<BreadCrumb config={breadcrumbConfig.preferencemanagement} />
				</Flex>
			</Flex>
		</>
	);
}

export default PreferenceNav;
