import React, { useState, useEffect } from "react";
import { Flex, Layout, theme, Spin } from "antd";
import { Outlet } from "react-router-dom";
import DDDMenu from "../pages/Design-artifacts/DDDMenu";
import BreadCrumb from "../pages/Editors/BreadCrumb";
import usePageStore from "../stores/PageStore";
import useWorkspaceStore from "../stores/WorkspaceState";
const { Sider, Content } = Layout;

function DDDLayout() {
	const {
		token: { colorPrimaryBg },
	} = theme.useToken();

	const [loading, setLoading] = useState(true);

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoading(false); // 1.5 sec
		}, 1500);
		return () => clearTimeout(timeout);
	}, []);

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
		breadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
		breadcrumbConfig: state.breadcrumbConfig,
	}));

	useEffect(() => {
		setPageSlug("design-artifacts");
		setPageTitle("Design Artifacts");
		setPageDescription("");
		setItemType("design-artifacts");
		setItemId("");
		setIsList(true);
		setItemDetails({});
		setBreadcrumbConfig({
			page: `/space/${currentWorkspace._id}/planner/de/ddd`,
			config: [
				{
					label: "Architecture",
					url: `/space/${currentWorkspace._id}/planner/de/ddd`,
				},
			],
		});
	}, []);

	return (
		<div>
			{loading ? (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "100vh",
					}}
				>
					<Spin size="large" />
					<img
						style={{ marginTop: "10px", width: "150px" }}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
						alt="Loading"
					/>
					<Spin size="large" />
				</div>
			) : (
				<>
					<Layout style={{ background: "#f5f8fe" }}>
						<Flex
							justify="start"
							style={{
								alignItems: "start",
								flexDirection: "column",
								width: "80%",
							}}
						>
							{/* <h2
								style={{
									color: "black",
									fontSize: "1.1rem",
									marginLeft: "50px",
									marginTop: "5vh",
									marginBottom: "1vh",
								}}
							>
								Architecture
							</h2> */}
							<div
								style={{
									fontSize: "1.1rem",
									marginLeft: "50px",
									marginTop: "5vh",
									marginBottom: "1vh",
								}}
							>
								<BreadCrumb config={breadcrumbConfig} />
							</div>
							{/* <Content style={{ backgroundColor: "rgb(250, 250, 255) " }}> */}
							<Content style={{ backgroundColor: "#f5f8fe" }}>
								<Outlet />
							</Content>
						</Flex>
						<Sider width={280} style={{ backgroundColor: colorPrimaryBg }}>
							<DDDMenu />
						</Sider>
					</Layout>
				</>
			)}
		</div>
	);
}

export default DDDLayout;
