import {
	LeftOutlined,
	RightOutlined,
	//FormatPainterFilled,
	HddFilled,
	LayoutFilled,
	FundFilled,
	DashboardFilled,
	CalendarFilled,
	UserOutlined,
	IdcardFilled,
	InteractionFilled,
	ApiFilled,
	DatabaseFilled,
	GoldFilled,
	ToolFilled,
	BookOutlined,
	EditOutlined,
	InsertRowLeftOutlined,
	InsertRowAboveOutlined,
	FilterOutlined,
	// EditOutlined,
} from "@ant-design/icons";
import {
	//Avatar,
	ConfigProvider,
	Flex,
	Menu,
	Switch,
	Tooltip,
	//Typography,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { useNavigate } from "react-router-dom";
import useWorkspaceStore from "../../stores/WorkspaceState";
import { planerViewStore } from "../../stores/PlannerStore";
import { useEffect, useState } from "react";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { motion, useAnimation } from "framer-motion";
import { Can } from "../../layout/AppLayout";
import userProfileStore from "../../stores/UserProfileStore";
import "./PlanerMenu.css";

const { SubMenu } = Menu;

function PlanerMenuV4() {
	interface Bookmark {
		id: string;
		url: string;
		title: string;
	}
	const { menuMode, setMenuMode } = userProfileStore((state: any) => ({
		menuMode: state.menuMode,
		setMenuMode: state.setMenuMode,
	}));
	const { bookmarks } = userProfileStore((state: any) => ({
		bookmarks: state.bookmarks,
	}));
	const { setRadioButtonSelected } = planerViewStore();
	const [collapsed, setCollapsed] = useState(false);

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};
	const navigate = useNavigate();
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const { currentWorkspacePermissions } = useWorkspaceStore((state: any) => ({
		currentWorkspacePermissions: state.workspacePermissions,
	}));

	const [selectedMenuKey, setSelectedMenuKey] = useState(
		localStorage.getItem("selectedMenuKey") || "10"
	);
	const [workspaceRole, setworkspaceRole] = useState<any>({});
	const handleWorkspacePermissions = (permissions: any) => {
		const rolename = permissions.roles?.name;
		console.log(permissions);
		// const rolename: any = "GUEST";
		let roleObj = {};
		if (rolename === "OWNER") {
			roleObj = {
				"10": true,
				"20": true,
				"30": true,
				"40": true,
				"50": true,
				"60": true,
				"70": true,
			};
			console.log("OWNER found");
		}
		if (rolename === "SCRUMMASTER") {
			roleObj = {
				"10": true,
				"20": true,
				"30": false,
				"40": true,
				"50": true,
				"60": false,
				"70": false,
			};
		}
		if (rolename === "ARCHITECT") {
			roleObj = {
				"10": true,
				"20": true,
				"30": false,
				"40": true,
				"50": true,
				"60": false,
				"70": false,
			};
		}
		if (rolename === "DEVELOPER") {
			roleObj = {
				"10": true,
				"20": true,
				"30": false,
				"40": true,
				"50": true,
				"60": false,
				"70": false,
			};
		}
		if (rolename === "GUEST") {
			roleObj = {
				"10": true,
				"20": true,
				"30": false,
				"40": false,
				"50": false,
				"60": false,
				"70": false,
			};
			console.log("Guest found");
		}
		setworkspaceRole(roleObj);
		console.log("WORKSPACEROLE", rolename);
		return roleObj;
	};

	const handleMenuClick = (info: any) => {
		if (info && info.key) {
			setSelectedMenuKey(info.key);
			localStorage.setItem("selectedMenuKey", info.key.toString());
		}
	};

	useEffect(() => {
		setSelectedMenuKey(localStorage.getItem("selectedMenuKey") || "10");
		handleWorkspacePermissions(currentWorkspacePermissions);
	}, [currentWorkspacePermissions]);

	useEffect(() => {
		const interval = setInterval(() => {
			const pathname = window.location.pathname;
			switch (pathname) {
				case `/space/${currentWorkspace?._id}`:
					setSelectedMenuKey("10");
					break;
				case `/space/${currentWorkspace?._id}/planner/mytasks`:
					setSelectedMenuKey("20");
					break;
				case `/space/${currentWorkspace?._id}/planner/backlogs`:
					setSelectedMenuKey("30");
					break;
				case `/space/${currentWorkspace?._id}/planner/plans`:
					setSelectedMenuKey("40");
					break;

				case `/space/${currentWorkspace?._id}/planner/de/userflows/${currentWorkspace?._id}`:
					setSelectedMenuKey("51");
					break;
				case `/space/${currentWorkspace?._id}/planner/de/uxprocess/${currentWorkspace?._id}`:
					setSelectedMenuKey("52");
					break;
				case `/space/${currentWorkspace?._id}/planner/de/info/${currentWorkspace?._id}`:
					setSelectedMenuKey("53");
					break;
				case `/space/${currentWorkspace?._id}/planner/de/processflow/${currentWorkspace?._id}`:
					setSelectedMenuKey("54");
					break;
				case `/space/${currentWorkspace?._id}/planner/de/ddd/core/${currentWorkspace?._id}`:
					setSelectedMenuKey("55");
					break;
				case `/space/${currentWorkspace?._id}/planner/de/apiDesign/${currentWorkspace?._id}`:
					setSelectedMenuKey("56");
					break;
				case `/space/${currentWorkspace?._id}/planner/de/data/${currentWorkspace?._id}`:
					setSelectedMenuKey("57");
					break;
				case `/space/${currentWorkspace?._id}/planner/rituals`:
					setSelectedMenuKey("60");
					break;
				case `/space/${currentWorkspace?._id}/planner/teammember`:
					setSelectedMenuKey("70");
					break;
				default:
					break;
			}
		}, 5); //5 milisecond

		return () => clearInterval(interval);
	}, []);

	const screens = useBreakpoint();

	useEffect(() => {
		if (screens.lg) {
			setCollapsed(false);
		} else {
			setCollapsed(true);
		}
	}, [screens]);

	const renderArrowButton = () => {
		// if (!screens.xs && menuMode === 'inline') {
		if (!screens.xs) {
			return (
				<div
					style={{
						position: "absolute",
						bottom: "20px",
						// left: "15px",
						right: "15px",
						cursor: "pointer",
						backgroundColor: "white",
						color: "#0444BF",
						borderRadius: "5px",
						width: "30px",
						height: "30px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
					}}
					onClick={toggleCollapsed}
				>
					<motion.div
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						animate={{ rotate: collapsed ? -180 : 180 }}
					>
						{collapsed ? (
							<RightOutlined style={{ transformOrigin: "center" }} />
						) : (
							<LeftOutlined style={{ transformOrigin: "center" }} />
						)}
					</motion.div>
				</div>
			);
		}
		return null;
	};

	const controls = useAnimation();
	useEffect(() => {
		controls.start({ width: collapsed ? 80 : 250 });
	}, [collapsed, controls]);

	//console.log(selectedMenuKey, "selectedMenuKey");

	const handleModeChange = () => {
		setMenuMode("horizontal");
		console.log(menuMode, "menumode");
	};

	return (
		<>
			{menuMode === "inline" && (
				<Sider
					// width={menuMode === 'inline' ? 250 : '100vw'}
					width={250}
					style={{
						background: "#0444BF",
						// borderRadius: "0px 20px 20px 0px",
						borderRadius: "20px 0px 0px 20px",
						paddingTop: ".5vh",
						zIndex: "2",
						//height: "100vh",
						// height: menuMode === 'inline' ? '100vh' : 'auto',
						height: "100vh",
					}}
					collapsed={collapsed}
					breakpoint="lg"
				>
					{/* <Flex
						onClick={() => navigate(`/space/${currentWorkspace?._id}`)}
						className="logo"
					>
						<Avatar
							shape="square"
							style={{
								backgroundColor: "#0444BF",
								margin: "22px 0px 15px 3px",
								width: "auto",
								height: "3vh",
							}}
							size={{ xs: 24, sm: 32, md: 32, lg: 32, xl: 32, xxl: 32 }}
							src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi-white-logo.svg"
						/>
						{!collapsed && (
							<Typography.Title
								style={{
									marginTop: "23px",
									marginLeft: "10px",
									color: "white",
									fontSize: "26px",
									fontFamily: "Poppins",
								}}
							>
								Alchemi
							</Typography.Title>
						)}
					</Flex> */}
					<ConfigProvider
						theme={{
							components: {
								Menu: {
									colorBgContainer: "#0444BF",
									itemBg: "#0444BF",
									subMenuItemBg: "#0444BF",
									popupBg: "#0444BF",

									colorText: "#ffffff",
								},
							},
						}}
					>
						<div style={{ paddingTop: "2rem" }}></div>
						<Menu
							style={{
								//paddingTop: "3rem",
								marginLeft: "12px",
								textAlign: "left",
								fontFamily: "Poppins",
								overflowY: "auto",
								height: "85vh",
								width: collapsed ? 68 : 238,
								borderRadius: "6px",
								// height: menuMode === 'inline' ? '70vh' : 'auto',
							}}
							mode="inline"
							// mode={menuMode}
							defaultSelectedKeys={[selectedMenuKey]}
							selectedKeys={[selectedMenuKey]}
							onClick={handleMenuClick}
						>
							{/* {workspaceRole && workspaceRole["10"] ? ( */}
							<Menu.ItemGroup
								title={<span className="ant-menu-light">My Space</span>}
							>
								<Can I="view" a="home" passThrough>
									{(allowed) =>
										allowed ? (
											<Menu.Item
												key="10"
												hidden={!workspaceRole["10"]}
												icon={
													<DashboardFilled
														style={{
															color:
																selectedMenuKey === "10"
																	? "#0444bf"
																	: "inherit",
															marginLeft: collapsed ? "-5px" : "12px",
															marginRight: collapsed ? "18px" : "auto",
														}}
													/>
												}
												onClick={() =>
													navigate(`/space/${currentWorkspace?._id}`)
												}
												style={{
													background:
														selectedMenuKey === "10" ? "white" : "transparent",
													color:
														selectedMenuKey === "10" ? "#0444bf" : "inherit",
													height: 35,
													borderRadius: "6px",
													width: collapsed ? 48 : 218,
													lineHeight: "35px",
												}}
											>
												Home
											</Menu.Item>
										) : (
											<></>
										)
									}
								</Can>
								{/* ) : (
							<></>
						)} */}
								{/* {workspaceRole && workspaceRole["20"] ? ( */}
								<Can I="view" a="my_task" passThrough>
									{(allowed) =>
										allowed ? (
											<Menu.Item
												key="20"
												icon={
													<FundFilled
														style={{
															color:
																selectedMenuKey === "20"
																	? "#0444bf"
																	: "inherit",
															marginLeft: collapsed ? "-5px" : "12px",
															marginRight: collapsed ? "18px" : "auto",
														}}
													/>
												}
												onClick={() => {
													navigate(
														`/space/${currentWorkspace._id}/planner/mytasks`
													);
													setRadioButtonSelected("MyTasks");
												}}
												style={{
													background:
														selectedMenuKey === "20" ? "white" : "transparent",
													color:
														selectedMenuKey === "20" ? "#0444bf" : "inherit",
													height: 35,
													borderRadius: "6px",
													width: collapsed ? 48 : 218,
													lineHeight: "35px",
												}}
											>
												My Tasks
											</Menu.Item>
										) : (
											<></>
										)
									}
								</Can>
								<Menu.Item
									key="View"
									icon={
										<Tooltip title="View">
											<FilterOutlined
												style={{
													color:
														selectedMenuKey === "view" ? "#0444bf" : "inherit",
													marginLeft: collapsed ? -6 : -12,
													marginRight: collapsed ? 0 : 0,
												}}
											/>
										</Tooltip>
									}
									onClick={() =>
										navigate(`/space/${currentWorkspace._id}/planner/views`)
									}
									style={{
										background:
											selectedMenuKey === "View" ? "white" : "transparent",
										color: selectedMenuKey === "View" ? "#0444bf" : "inherit",
										height: 35,
										width: collapsed ? 48 : 218,
										lineHeight: "35px",
										borderRadius: "6px",
									}}
								>
									My Views
								</Menu.Item>
								<SubMenu
									key="Bookmarks"
									className="custom-submenu"
									icon={
										<BookOutlined
											style={{
												color:
													selectedMenuKey === "Bookmarks"
														? "#0444bf"
														: "inherit",
												marginLeft: collapsed ? -1 : "-12px",
												marginRight: collapsed ? -60 : 0,
											}}
										/>
									}
									title={
										<span
											style={{
												color:
													selectedMenuKey === "Bookmarks"
														? "#0444bf"
														: "inherit",
											}}
										>
											Bookmarks
										</span>
									}
									style={{
										background:
											selectedMenuKey === "Bookmarks" ? "white" : "transparent",
										color:
											selectedMenuKey === "Bookmarks" ? "#0444bf" : "white",
										display: "table",
										width: collapsed ? 28 : 218,
										height: 35,
										lineHeight: "35px",
										borderRadius: "6px",
									}}
								>
									{/* Bookmark map function */}
									{bookmarks &&
										bookmarks.length > 0 &&
										bookmarks.map((bookmark: Bookmark) => (
											<Menu.Item
												// key={bookmark.url as string | number | bigint | null | undefined}
												key={bookmark.title}
												icon={
													<Tooltip title="Bookmark">
														<BookOutlined
															style={{
																color:
																	selectedMenuKey === "bookmarks"
																		? "#0444bf"
																		: "inherit",
															}}
														/>
													</Tooltip>
												}
												onClick={() => bookmark.url && navigate(bookmark.url)}
												style={{
													background:
														selectedMenuKey === "Bookmarks"
															? "white"
															: "transparent",
													color:
														selectedMenuKey === "Bookmarks"
															? "#0444bf"
															: "inherit",
													borderRadius: "6px",
													height: 35,
													lineHeight: "35px",
												}}
											>
												{bookmark.title}
											</Menu.Item>
										))}
								</SubMenu>
							</Menu.ItemGroup>
							{/* ) : (
						 	<></>
						 )} */}
							<Menu.ItemGroup
								title={<span className="ant-menu-light">Planner</span>}
							>
								<Can I="view" a="backlog" passThrough>
									{(allowed) =>
										allowed ? (
											<Menu.Item
												key="30"
												hidden={workspaceRole["30"]}
												icon={
													<LayoutFilled
														style={{
															color:
																selectedMenuKey === "30"
																	? "#0444bf"
																	: "inherit",
															marginLeft: collapsed ? "-5px" : "12px",
															marginRight: collapsed ? "18px" : "auto",
														}}
													/>
												}
												onClick={() => {
													navigate(
														`/space/${currentWorkspace._id}/planner/backlogs`
													);
													setRadioButtonSelected("AllItems");
												}}
												style={{
													background:
														selectedMenuKey === "30" ? "white" : "transparent",
													color:
														selectedMenuKey === "30" ? "#0444bf" : "inherit",
													height: 35,
													borderRadius: "6px",
													width: collapsed ? 48 : 218,
													lineHeight: "35px",
												}}
											>
												Backlogs
											</Menu.Item>
										) : (
											<></>
										)
									}
								</Can>
								<Can I="view" a="rituals" passThrough>
									{(allowed) =>
										allowed ? (
											<Menu.Item
												key="60"
												hidden={workspaceRole["60"]}
												icon={
													<CalendarFilled
														style={{
															color:
																selectedMenuKey === "60"
																	? "#0444bf"
																	: "inherit",
															marginLeft: collapsed ? "-5px" : "12px",
															marginRight: collapsed ? "18px" : "auto",
														}}
													/>
												}
												onClick={() =>
													navigate(
														`/space/${currentWorkspace._id}/planner/rituals`
													)
												}
												style={{
													background:
														selectedMenuKey === "60" ? "white" : "transparent",
													color:
														selectedMenuKey === "60" ? "#0444bf" : "inherit",
													height: 35,
													borderRadius: "6px",
													width: collapsed ? 48 : 218,
													lineHeight: "35px",
												}}
											>
												Rituals
											</Menu.Item>
										) : (
											<></>
										)
									}
								</Can>
								<Can I="view" a="plan" passThrough>
									{(allowed) =>
										allowed ? (
											<Menu.Item
												key="40"
												hidden={workspaceRole["40"]}
												icon={
													<HddFilled
														style={{
															color:
																selectedMenuKey === "40"
																	? "#0444bf"
																	: "inherit",
															marginLeft: collapsed ? "-5px" : "12px",
															marginRight: collapsed ? "18px" : "auto",
														}}
													/>
												}
												onClick={() =>
													navigate(
														`/space/${currentWorkspace._id}/planner/plans`
													)
												}
												style={{
													background:
														selectedMenuKey === "40" ? "white" : "transparent",
													color:
														selectedMenuKey === "40" ? "#0444bf" : "inherit",
													height: 35,
													borderRadius: "6px",
													width: collapsed ? 48 : 218,
													lineHeight: "35px",
												}}
											>
												Plans
											</Menu.Item>
										) : (
											<></>
										)
									}
								</Can>
								<Can I="view" a="team_member" passThrough>
									{(allowed) =>
										allowed ? (
											<Menu.Item
												key="70"
												hidden={workspaceRole["70"]}
												icon={
													<UserOutlined
														style={{
															color:
																selectedMenuKey === "70"
																	? "#0444bf"
																	: "inherit",
															marginLeft: collapsed ? "-5px" : "12px",
															marginRight: collapsed ? "18px" : "auto",
														}}
													/>
												}
												onClick={() =>
													navigate(
														`/space/${currentWorkspace._id}/planner/teammember`
													)
												}
												style={{
													background:
														selectedMenuKey === "70" ? "white" : "transparent",
													color: selectedMenuKey === "70" ? "#0444bf" : "white",
													height: 35,
													borderRadius: "6px",
													width: collapsed ? 48 : 218,
													lineHeight: "35px",
												}}
											>
												Team Members
											</Menu.Item>
										) : (
											<></>
										)
									}
								</Can>
							</Menu.ItemGroup>
							<Can I="view" a="design_artifacts" passThrough>
								{(allowed) =>
									allowed ? (
										<Menu.ItemGroup
											title={
												<span className="ant-menu-light">Design Artifacts</span>
											}
										>
											<Menu.Item
												key="52"
												icon={
													<IdcardFilled
														style={{
															color:
																selectedMenuKey === "52"
																	? "#0444bf"
																	: "inherit",
															marginLeft: collapsed ? "-5px" : "-12px",
															marginRight: collapsed ? "18px" : "auto",
														}}
													/>
												}
												onClick={() => {
													navigate(
														`planner/de/uxprocess/${currentWorkspace._id}`
													);
													setSelectedMenuKey("52");
												}}
												style={{
													background:
														selectedMenuKey === "52" ? "white" : "transparent",
													color:
														selectedMenuKey === "52" ? "#0444bf" : "inherit",
													borderRadius: "6px",
													height: 35,
													lineHeight: "35px",
													width: collapsed ? 48 : 218,
												}}
											>
												User Personas
											</Menu.Item>
											<Menu.Item
												key="53"
												icon={
													<InteractionFilled
														style={{
															color:
																selectedMenuKey === "53"
																	? "#0444bf"
																	: "inherit",
															marginLeft: collapsed ? "-5px" : "-12px",
															marginRight: collapsed ? "18px" : "auto",
														}}
													/>
												}
												onClick={() => {
													navigate(`planner/de/info/${currentWorkspace._id}`);
													setSelectedMenuKey("53");
												}}
												style={{
													background:
														selectedMenuKey === "53" ? "white" : "transparent",
													color:
														selectedMenuKey === "53" ? "#0444bf" : "inherit",
													borderRadius: "6px",
													height: 35,
													lineHeight: "35px",
													width: collapsed ? 48 : 218,
												}}
											>
												Sitemap
											</Menu.Item>
											<Menu.Item
												key="55"
												icon={
													<GoldFilled
														style={{
															color:
																selectedMenuKey === "55"
																	? "#0444bf"
																	: "inherit",
															marginLeft: collapsed ? "-5px" : "-12px",
															marginRight: collapsed ? "18px" : "auto",
														}}
													/>
												}
												onClick={() => {
													navigate(
														`planner/de/ddd/core/${currentWorkspace._id}`
													);
													setSelectedMenuKey("55");
												}}
												style={{
													background:
														selectedMenuKey === "55" ? "white" : "transparent",
													color:
														selectedMenuKey === "55" ? "#0444bf" : "inherit",
													borderRadius: "6px",
													height: 35,
													lineHeight: "35px",
													width: collapsed ? 48 : 218,
												}}
											>
												Architecture
											</Menu.Item>
											<Menu.Item
												key="56"
												icon={
													<ApiFilled
														style={{
															color:
																selectedMenuKey === "56"
																	? "#0444bf"
																	: "inherit",
															marginLeft: collapsed ? "-5px" : "-12px",
															marginRight: collapsed ? "18px" : "auto",
														}}
													/>
												}
												onClick={() => {
													navigate(
														`planner/de/apiDesign/${currentWorkspace._id}`
													);
													setSelectedMenuKey("56");
												}}
												style={{
													background:
														selectedMenuKey === "56" ? "white" : "transparent",
													color:
														selectedMenuKey === "56" ? "#0444bf" : "inherit",
													borderRadius: "6px",
													height: 35,
													lineHeight: "35px",
													width: collapsed ? 48 : 218,
												}}
											>
												API Definitions
											</Menu.Item>
											<Menu.Item
												key="57"
												icon={
													<DatabaseFilled
														style={{
															color:
																selectedMenuKey === "57"
																	? "#0444bf"
																	: "inherit",
															marginLeft: collapsed ? "-5px" : "-12px",
															marginRight: collapsed ? "18px" : "auto",
														}}
													/>
												}
												onClick={() => {
													navigate(`planner/de/data/${currentWorkspace._id}`);
													setSelectedMenuKey("57");
												}}
												style={{
													background:
														selectedMenuKey === "57" ? "white" : "transparent",
													color:
														selectedMenuKey === "57" ? "#0444bf" : "inherit",
													borderRadius: "6px",
													height: 35,
													lineHeight: "35px",
													width: collapsed ? 48 : 218,
												}}
											>
												Database Schema
											</Menu.Item>
										</Menu.ItemGroup>
									) : (
										// <SubMenu
										// 	key="sub2"
										// 	className="custom-submenu"
										// 	icon={
										// 		<FormatPainterFilled
										// 			style={{
										// 				color:
										// 					selectedMenuKey === "52" ||
										// 					selectedMenuKey === "53" ||
										// 					selectedMenuKey === "55" ||
										// 					selectedMenuKey === "56" ||
										// 					selectedMenuKey === "57"
										// 						? "#0444bf"
										// 						: "inherit",
										// 				marginLeft: collapsed ? 1 : "12px",
										// 				marginRight: collapsed ? -90 : 0,
										// 			}}
										// 		/>
										// 	}
										// 	title={
										// 		<span
										// 			style={{
										// 				color:
										// 					selectedMenuKey === "52" ||
										// 					selectedMenuKey === "53" ||
										// 					selectedMenuKey === "55" ||
										// 					selectedMenuKey === "56" ||
										// 					selectedMenuKey === "57"
										// 						? "#0444bf"
										// 						: "inherit",
										// 			}}
										// 		>
										// 			Design Artifacts
										// 		</span>
										// 	}
										// 	style={{
										// 		background:
										// 			selectedMenuKey === "52" ||
										// 			selectedMenuKey === "53" ||
										// 			selectedMenuKey === "55" ||
										// 			selectedMenuKey === "56" ||
										// 			selectedMenuKey === "57"
										// 				? "white"
										// 				: "transparent",
										// 		borderRadius: "6px",
										// 		width: collapsed ? 28 : 218,
										// 		display: "table",
										// 	}}
										// >
										// 	<Menu.Item
										// 		key="52"
										// 		icon={
										// 			<IdcardFilled
										// 				style={{
										// 					color:
										// 						selectedMenuKey === "52"
										// 							? "#0444bf"
										// 							: "inherit",
										// 					marginLeft: 0,
										// 				}}
										// 			/>
										// 		}
										// 		onClick={() => {
										// 			navigate(
										// 				`planner/de/uxprocess/${currentWorkspace._id}`
										// 			);
										// 			setSelectedMenuKey("52");
										// 		}}
										// 		style={{
										// 			background:
										// 				selectedMenuKey === "52" ? "white" : "transparent",
										// 			color:
										// 				selectedMenuKey === "52" ? "#0444bf" : "inherit",
										// 			borderRadius: "6px",
										// 			height: 35,
										// 			lineHeight: "35px",
										// 		}}
										// 	>
										// 		User personas
										// 	</Menu.Item>
										// 	<Menu.Item
										// 		key="53"
										// 		icon={
										// 			<InteractionFilled
										// 				style={{
										// 					color:
										// 						selectedMenuKey === "53"
										// 							? "#0444bf"
										// 							: "inherit",
										// 					marginLeft: 0,
										// 				}}
										// 			/>
										// 		}
										// 		onClick={() => {
										// 			navigate(`planner/de/info/${currentWorkspace._id}`);
										// 			setSelectedMenuKey("53");
										// 		}}
										// 		style={{
										// 			background:
										// 				selectedMenuKey === "53" ? "white" : "transparent",
										// 			color:
										// 				selectedMenuKey === "53" ? "#0444bf" : "inherit",
										// 			borderRadius: "6px",
										// 			height: 35,
										// 			lineHeight: "35px",
										// 		}}
										// 	>
										// 		Sitemap
										// 	</Menu.Item>
										// 	<Menu.Item
										// 		key="55"
										// 		icon={
										// 			<GoldFilled
										// 				style={{
										// 					color:
										// 						selectedMenuKey === "55"
										// 							? "#0444bf"
										// 							: "inherit",
										// 					marginLeft: 0,
										// 				}}
										// 			/>
										// 		}
										// 		onClick={() => {
										// 			navigate(
										// 				`planner/de/ddd/core/${currentWorkspace._id}`
										// 			);
										// 			setSelectedMenuKey("55");
										// 		}}
										// 		style={{
										// 			background:
										// 				selectedMenuKey === "55" ? "white" : "transparent",
										// 			color:
										// 				selectedMenuKey === "55" ? "#0444bf" : "inherit",
										// 			borderRadius: "6px",
										// 			height: 35,
										// 			lineHeight: "35px",
										// 		}}
										// 	>
										// 		Architecture
										// 	</Menu.Item>
										// 	<Menu.Item
										// 		key="56"
										// 		icon={
										// 			<ApiFilled
										// 				style={{
										// 					color:
										// 						selectedMenuKey === "56"
										// 							? "#0444bf"
										// 							: "inherit",
										// 					marginLeft: 0,
										// 				}}
										// 			/>
										// 		}
										// 		onClick={() => {
										// 			navigate(
										// 				`planner/de/apiDesign/${currentWorkspace._id}`
										// 			);
										// 			setSelectedMenuKey("56");
										// 		}}
										// 		style={{
										// 			background:
										// 				selectedMenuKey === "56" ? "white" : "transparent",
										// 			color:
										// 				selectedMenuKey === "56" ? "#0444bf" : "inherit",
										// 			borderRadius: "6px",
										// 			height: 35,
										// 			lineHeight: "35px",
										// 		}}
										// 	>
										// 		API definitions
										// 	</Menu.Item>
										// 	<Menu.Item
										// 		key="57"
										// 		icon={
										// 			<DatabaseFilled
										// 				style={{
										// 					color:
										// 						selectedMenuKey === "57"
										// 							? "#0444bf"
										// 							: "inherit",
										// 					marginLeft: 0,
										// 				}}
										// 			/>
										// 		}
										// 		onClick={() => {
										// 			navigate(`planner/de/data/${currentWorkspace._id}`);
										// 			setSelectedMenuKey("57");
										// 		}}
										// 		style={{
										// 			background:
										// 				selectedMenuKey === "57" ? "white" : "transparent",
										// 			color:
										// 				selectedMenuKey === "57" ? "#0444bf" : "inherit",
										// 			borderRadius: "6px",
										// 			height: 35,
										// 			lineHeight: "35px",
										// 		}}
										// 	>
										// 		Database schema
										// 	</Menu.Item>
										// </SubMenu>
										<></>
									)
								}
							</Can>
							<Menu.ItemGroup
								title={<span className="ant-menu-light">Tools</span>}
							>
								<Menu.Item
									key="tool1"
									icon={
										// <Tooltip title="Bookmarks">
										<ToolFilled
											style={{
												color:
													selectedMenuKey === "tool1" ? "#0444bf" : "white",
												marginLeft: collapsed ? -5 : -12,
											}}
										/>
										// </Tooltip>
									}
									onClick={() =>
										navigate(
											`/space/${currentWorkspace._id}/planner/whiteboard`
										)
									}
									style={{
										background:
											selectedMenuKey === "tool1" ? "white" : "transparent",
										color: selectedMenuKey === "tool1" ? "#0444bf" : "white",
										borderRadius: "6px",
										height: 35,
										lineHeight: "35px",
										width: collapsed ? 48 : 218,
									}}
								>
									Whiteboard
								</Menu.Item>
								<Menu.Item
									key="tool2"
									icon={
										//<Tooltip title="Bookmarks">
										<EditOutlined
											style={{
												color:
													selectedMenuKey === "tool2" ? "#0444bf" : "white",
												marginLeft: collapsed ? -5 : -12,
											}}
										/>
										//</Tooltip>
									}
									onClick={() =>
										navigate(`/space/${currentWorkspace._id}/planner/notes`)
									}
									style={{
										background:
											selectedMenuKey === "tool2" ? "white" : "transparent",
										color: selectedMenuKey === "tool2" ? "#0444bf" : "white",
										borderRadius: "6px",
										height: 35,
										lineHeight: "35px",
										width: collapsed ? 48 : 218,
									}}
								>
									Notes
								</Menu.Item>
								{/* <SubMenu
									key="Tools"
									className="custom-submenu"
									icon={
										<ToolFilled
											style={{
												color:
													selectedMenuKey === "tool1" ||
													selectedMenuKey === "tool2"
														? "#0444bf"
														: "inherit",
												marginLeft: collapsed ? 0 : -12,
												marginRight: collapsed ? -18 : 0,
											}}
										/>
									}
									title={
										<span
											style={{
												color:
													selectedMenuKey === "tool1" ||
													selectedMenuKey === "tool2"
														? "#0444bf"
														: "inherit",
											}}
										>
											Tools
										</span>
									}
									style={{
										background:
											selectedMenuKey === "tool1" || selectedMenuKey === "tool2"
												? "white"
												: "transparent",
										color:
											selectedMenuKey === "tool1" || selectedMenuKey === "tool2"
												? "#0444bf"
												: "white",
										height: "35px",
										lineHeight: "35px",
										display: "table",
										borderRadius: "6px",
										width: collapsed ? 42 : 218,
									}}
								>
									<Menu.Item
										key="tool1"
										icon={
											<Tooltip title="Bookmarks">
												<ToolFilled
													style={{
														color:
															selectedMenuKey === "tool1" ? "#0444bf" : "white",
														marginLeft: collapsed ? 0 : -30,
													}}
												/>
											</Tooltip>
										}
										onClick={() =>
											navigate(
												`/space/${currentWorkspace._id}/planner/whiteboard`
											)
										}
										style={{
											background:
												selectedMenuKey === "tool1" ? "white" : "transparent",
											color: selectedMenuKey === "tool1" ? "#0444bf" : "white",
											borderRadius: "6px",
											height: 35,
											lineHeight: "35px",
										}}
									>
										WhiteBoard
									</Menu.Item>
									<Menu.Item
										key="tool2"
										icon={
											<Tooltip title="Bookmarks">
												<EditOutlined
													style={{
														color:
															selectedMenuKey === "tool2" ? "#0444bf" : "white",
														marginLeft: collapsed ? 0 : -30,
													}}
												/>
											</Tooltip>
										}
										onClick={() =>
											navigate(`/space/${currentWorkspace._id}/planner/notes`)
										}
										style={{
											background:
												selectedMenuKey === "tool2" ? "white" : "transparent",
											color: selectedMenuKey === "tool2" ? "#0444bf" : "white",
											borderRadius: "6px",
											height: 35,
											lineHeight: "35px",
										}}
									>
										Notes
									</Menu.Item>
								</SubMenu> */}
							</Menu.ItemGroup>
						</Menu>
					</ConfigProvider>
					<Flex
						style={{
							position: "absolute",
							bottom: "20px",
							// left: "150px",
							right: "150px",
							cursor: "pointer",
						}}
					>
						{!collapsed && (
							<Tooltip
								title={
									menuMode === "inline"
										? "Switch Menu to Horizontal"
										: "Switch Menu to Vertical"
								}
							>
								<Flex>
									<InsertRowLeftOutlined
										style={{ color: "#fff", marginRight: "2px" }}
									/>{" "}
									{"    "}
									<Switch
										size="small"
										onClick={handleModeChange}
										checked={menuMode === "horizontal"}
									/>{" "}
									{"    "}
									<InsertRowAboveOutlined
										style={{ color: "#fff", marginLeft: "2px" }}
									/>
								</Flex>
							</Tooltip>
						)}
					</Flex>
					{renderArrowButton()}
				</Sider>
			)}
		</>
	);
}

export default PlanerMenuV4;
