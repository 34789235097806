/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	CheckCircleOutlined,
	//ArrowLeftOutlined,
	FilterFilled,
	SwapOutlined,
	ToolOutlined,
	WarningOutlined,
} from "@ant-design/icons";
import "./TaskBoarding.css";

import {
	Avatar,
	Button,
	Flex,
	Input,
	Layout,
	List,
	Space,
	Steps,
	Table,
	Tooltip,
	//Typography,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { useState, useEffect } from "react";
import TaskCreationModel from "./TaskCreationModel";
import Paragraph from "antd/es/typography/Paragraph";
import useWorkspaceStore from "../../../stores/WorkspaceState";
import { useNavigate, useParams } from "react-router-dom";
import { usePlanStore } from "../../../stores/PlanStore";
import breadcrumbConfig from "../../../util/BreadCrumbsUtil";
import BreadCrumb from "../../Editors/BreadCrumb";
import Constants from "../../../util/ConstantsUtil";
import UniversalComponent from "../Filter_button/UniversalFilter";

const modeConfig = {
	filters: {
		status: {
			label: "Status",
			options: [
				{
					key: Constants.STATUS + "_" + Constants.IN_PROGRESS,
					label: Constants.IN_PROGRESS,
				},
				{ key: Constants.STATUS + "_" + Constants.OPEN, label: Constants.OPEN },
				{
					key: Constants.STATUS + "_" + Constants.COMPLETED,
					label: Constants.COMPLETED,
				},
			],
			icon: <CheckCircleOutlined />,
		},
		priority: {
			label: "Priority",
			options: [
				{
					key: Constants.PRIORITY + "_" + Constants.URGENT,
					label: Constants.URGENT,
				},
				{
					key: Constants.PRIORITY + "_" + Constants.IMPORTANT,
					label: Constants.IMPORTANT,
				},
				{
					key: Constants.PRIORITY + "_" + Constants.MEDIUM,
					label: Constants.MEDIUM,
				},
				{ key: Constants.PRIORITY + "_" + Constants.LOW, label: Constants.LOW },
			],
			icon: <WarningOutlined />,
		},
		type: {
			label: "Type",
			options: [
				{ key: "Type_AllItems", label: "All Items" },
				{ key: "Type_FEATURE", label: "Features" },
				{ key: "Type_USER_STORY", label: "User Stories" },
				{ key: "Type_BUG", label: "Bugs" },
				{ key: "Type_EPIC", label: "Epic" },
			],
			icon: <SwapOutlined />,
		},
	},
};

interface TodoItem {
	title: string;
	description: string;
	_id: string;
	type: string;
	// Add more properties if needed
}

const { Header, Content } = Layout;
//const { Title } = Typography;
const { Search } = Input;

function TaskBoarding() {
	const navigate = useNavigate();
	const [data, setData] = useState<any>();
	const [openTaskModel, setOpenTaskModel] = useState(false);
	const [selectedItem, setSelectedItem] = useState<TodoItem | null>(null);
	const [allTask, setAllTask] = useState<any>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const workspaceId = useParams().workspaceId;
	const PlanId = useParams().planId;
	const handleSearch = (value: string) => {
		const newvalue = value.toLowerCase();
		setSearchQuery(newvalue);
	};

	const { newPlan } = usePlanStore();
	const { PlanFilterValue, setPlanFilterValue, setPlanSelectedFilter } =
		usePlanStore();

	const handelOpenTaskModel = () => {
		setOpenTaskModel(true);
	};

	const handelCloseTaskModel = () => {
		setOpenTaskModel(false);
	};

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const fetchTodos = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/plan-workitems/${PlanId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			const result = await response.json();
			console.log("API Response:", result);
			if (response.ok) {
				if (selectedItem === null) {
					setSelectedItem(result[0]);
				}

				setData(result);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	const FilterSearchValudata = (data: any) => {
		const newdata = data?.filter(
			(test: any) =>
				test.title?.toLowerCase().includes(searchQuery) ||
				test.description?.toLowerCase().includes(searchQuery) ||
				test?.WorkitemId?.toLowerCase().includes(searchQuery)
			// 	&&
			// test?.status
			// 	?.toLowerCase()
			// 	.includes(filterstatus)
		);
		return newdata;
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "WorkitemId",
			key: "WorkitemId",
		},
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			render: (text: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<a
						style={{ color: "black", fontWeight: "bold" }}
						dangerouslySetInnerHTML={{ __html: text }}
					></a>
				</div>
			),
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			render: (text: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<a
						style={{ color: "black", fontWeight: "bold" }}
						dangerouslySetInnerHTML={{ __html: text }}
					></a>
				</div>
			),
		},
	];

	const selectedWorkitem = (item: any) => {
		console.log(item, "selectedItem");
		setSelectedItem(item);
	};

	const fetchPlansData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/plan-workitems/${newPlan.planId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			const result = await response.json();
			console.log("API Response:", result);
			if (response.ok) {
				if (selectedItem === null) {
					setSelectedItem(result[0]);
				}

				setData(result);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	useEffect(() => {
		if (newPlan.planId && newPlan.active === true) {
			fetchPlansData();
		} else {
			fetchTodos();
		}
	}, [openTaskModel, newPlan]);

	const getAllTasks = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${selectedItem?._id}/TASK`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			const result = await response.json();
			if (response.ok) {
				console.log("setalltask", result);
				setAllTask(result);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};
	function formatText(inputText: string) {
		if (!inputText) {
			return inputText; // Return the input text if it's undefined or null
		}

		const words = inputText.split("_");

		if (words[0]) {
			words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
		}

		const formattedText = words.join(" ");

		return formattedText;
	}

	useEffect(() => {
		if (selectedItem !== null) {
			getAllTasks();
		}
	}, [selectedItem, openTaskModel]);

	const truncateDescription = (description: string, wordCount: number) => {
		const words = description?.split(" ");
		if (words?.length > wordCount) {
			return words?.slice(0, wordCount)?.join(" ") + "...";
		}
		return description;
	};

	console.log("newplan", newPlan);

	const handleNextStep = () => {
		navigate(`/space/${workspaceId}/planner/capacityplan/${newPlan.planId}`);
	};

	const onChangestep = (value: number) => {
		if (value === 0) {
			navigate(
				`/space/${workspaceId}/planner/planner?planId=${newPlan.planId}`
			);
		}
	};

	return (
		<>
			<Layout style={{ padding: "1rem", backgroundColor: "#F5F8FE" }}>
				<Header
					style={{
						background: "#F5F8FE",
						marginTop: newPlan.active ? "5.2vh" : "0",
						marginBottom: "1rem",
						width: "100vw",
					}}
				>
					<Flex align="center" justify="space-between">
						{newPlan.active ? (
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "80vw",
									marginLeft: "-2rem",
								}}
							>
								<div style={{ width: "65vw" }}>
									<Steps
										current={1}
										onChange={onChangestep}
										items={[
											{
												title: "",
												// description,
											},
											{
												title: "Add Tasks To Features",
												// description,
												// subTitle: "Left 00:00:08",
											},
											{
												title: "",
												// description,
											},
											{
												title: "",
												// description,
											},
										]}
									/>
								</div>
								<div
									style={{ marginRight: "2rem", position: "fixed", right: "0" }}
								>
									<Button
										size="middle"
										shape="round"
										type="primary"
										onClick={handleNextStep}
									>
										Next Step
									</Button>
								</div>
							</div>
						) : (
							<Flex gap={15} align="center">
								{/* <Button
									shape="circle"
									icon={<ArrowLeftOutlined />}
									onClick={() => navigate(-1)}
								/>
								<Title style={{ height: "5vh" }} level={3}>
									Task boarding
								</Title> */}
								<BreadCrumb config={breadcrumbConfig.task} />
							</Flex>
						)}
					</Flex>
				</Header>
				<Content style={{ backgroundColor: "#F5F8FE" }}>
					<Flex>
						<Flex vertical className="kano" gap={20}>
							<Flex justify="space-between" align="center">
								<Input
									placeholder="input search text"
									allowClear
									style={{ width: 300 }}
									onChange={(e) => handleSearch(e.target.value)}
								/>
								<UniversalComponent
									config={modeConfig}
									filterValue={PlanFilterValue}
									setFilterValue={setPlanFilterValue}
									setSelectedFilter={setPlanSelectedFilter}
								/>
							</Flex>
							{/* <Flex> */}
							<List
								split={false}
								style={{ overflowY: "auto", height: "68vh", width: "100%" }}
								dataSource={FilterSearchValudata(data)}
								renderItem={(item: any) => (
									<>
										<List.Item onClick={() => selectedWorkitem(item)}>
											<Flex style={{ alignItems: "center" }}>
												<Avatar>
													<ToolOutlined />
												</Avatar>
												<Space direction="vertical" align="start">
													<Space
														align="start"
														style={{ fontWeight: "bold", marginLeft: "1vw" }}
													>
														{formatText(item.title)}
													</Space>
												</Space>
												<Tooltip
													title={`${item.assignee?.name}`}
													placement="top"
												></Tooltip>
											</Flex>
										</List.Item>
										<div
											style={{
												width: "100%",
												textAlign: "left",
												marginLeft: "2.8vw",
												color: "black",
											}}
											dangerouslySetInnerHTML={{
												__html: formatText(
													truncateDescription(item.description, 10)
												), // Adjust the word count as needed (e.g., 20)
											}}
										/>
									</>
								)}
							/>
							{/* </Flex> */}
						</Flex>
						<Flex
							justify="center"
							style={{ width: "72%" }}
							className="taskBoard"
						>
							<Flex
								vertical
								className="taskBoardContent"
								style={{
									width: "90%",

									backgroundColor: "white",
								}}
							>
								<Flex gap={20} style={{ width: "100%" }} justify="end">
									<Button disabled>Add dependency</Button>
									<Button onClick={handelOpenTaskModel}>Add Task</Button>
								</Flex>
								<Flex>
									{selectedItem && (
										<Flex vertical style={{ margin: "3rem" }}>
											<Paragraph style={{ textAlign: "left" }}>
												<span className="selectedworkitemTitle">
													{formatText(selectedItem.title)}
												</span>
												:
												<span
													dangerouslySetInnerHTML={{
														__html: formatText(selectedItem.description),
													}}
												/>
											</Paragraph>

											<Table
												columns={columns}
												style={{ width: "45vw" }}
												dataSource={allTask}
												pagination={{ pageSize: 4 }}
												scroll={{ y: 140 }}
											/>
											{/* <Flex>
												<List
													style={{ marginLeft: "6vw" }}
													dataSource={allTask}
													renderItem={(task: any) => (
														<List.Item key={task._id}>
															<Flex vertical>
																<Flex className="selectedworkitemTitle">
																	{formatText(task.title)}
																</Flex>
																<div
																	style={{
																		textAlign: "left",
																		marginLeft: "1.5vw",
																		color: "black",
																	}}
																	dangerouslySetInnerHTML={{
																		__html: formatText(task.description),
																	}}
																/>
															</Flex>
														</List.Item>
													)}
												/>
											</Flex> */}
										</Flex>
									)}
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Content>
				<TaskCreationModel
					id={selectedItem?._id || ""}
					Parenttype={selectedItem?.type || ""}
					visible={openTaskModel}
					onCancel={handelCloseTaskModel}
				/>
			</Layout>
		</>
	);
}

export default TaskBoarding;
