/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { EyeOutlined, SmileOutlined } from "@ant-design/icons";
import {
	Button,
	Collapse,
	Flex,
	Layout,
	Result,
	Table,
	Tag,
	Spin,
	Image,
	List,
	Popover,
	Pagination,
	Tooltip,
} from "antd";

import RitualNav from "./RitualsNav";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import { Content } from "antd/es/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import { planerViewStore } from "../../../stores/PlannerStore";
import RitualCeration from "./RitualsCreation";
import useWorkspaceStore from "../../../stores/WorkspaceState";
import moment from "moment";
import { usePlanStore } from "../../../stores/PlanStore";
import RitualDropdownMenu from "./RitualContexMenu";
import { Can } from "../../../layout/AppLayout";
import {
	getRitualSessions,
	getRitualsBYFilterValue,
} from "../../../api/RitualApi";
import { useRequest } from "ahooks";
import { RitualModel } from "../../../models/RitualModel";
import { useRitualStore } from "./RitualStore";
import usePageStore from "../../../stores/PageStore";

function Rituals() {
	const navigate = useNavigate();

	const [data, setData] = useState<any[]>([]);
	const [data3, setdata3] = useState<any[]>([]);
	const { input, filter, FetchPlan, sortBy } = planerViewStore();
	const [Nodata, setNodata] = useState(false);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { selectedGroupBy } = usePlanStore();
	const [menuVisible, setMenuVisible] = React.useState(false);
	const [position, setPosition] = React.useState({
		x: 0,
		y: 0,
	});
	const [selectRec, setSelectRec] = useState<any>();
	const [loading, setLoading] = useState(true);

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
		breadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
		breadcrumbConfig: state.breadcrumbConfig,
	}));

	useEffect(() => {
		setPageTitle("Rituals");
		setPageSlug("rituals");
		setPageDescription("Rituals");
		setItemType("rituals");
		setIsList(true);
		setItemDetails({});
		setBreadcrumbConfig({
			page: `/space/${workspaceId}/planner/rituals`,
			config: [
				{
					label: "Rituals",
					url: `/space/${workspaceId}/planner/rituals`,
				},
			],
		});
	}, []);
	const handleIconClick = (item: any) => {
		navigate(`/space/${workspaceId}/planner/ritual/${item._id}`);
	};

	const [modalVisible, setModalVisible] = useState(false);
	const workspaceId = useParams().workspaceId;

	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(20);
	const { RitualsFilterValue, RitualsSelectedFilter } = useRitualStore();

	// setSelectedGroupBy("title");

	const setGroupKey = (selectedGroupBy: string) => {
		switch (selectedGroupBy) {
			case "Type":
				return "type";
			case "Priority":
				return "priority";
			case "Status":
				return "status";
			case "release":
				return "release";
			default:
				return "title";
		}
	};

	console.log(selectedGroupBy, "selectedGroupBy");
	const {
		data: RitualData,
		error: RitualError,
		loading: RitualLoading,
		run: fetchTodosRun,
	} = useRequest(
		(RitualsFilterValue, id) =>
			getRitualsBYFilterValue(
				RitualsFilterValue,
				id,
				userProfileStoreInstance.idToken ?? ""
			),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "result onSuccess");
				console.log(RitualData);
			},
			onError: () => {
				console.error("Error fetching todos ....");
				console.log(RitualError);
			},
		}
	);

	useEffect(() => {
		if (RitualData) {
			setData(RitualData);
			setNodata(RitualData.length === 0);
			setdata3(RitualData);
		}
	}, [RitualData]);

	useEffect(() => {
		if (workspaceId) {
			fetchTodosRun(RitualsFilterValue, workspaceId);
		}
	}, [FetchPlan, sortBy, RitualsSelectedFilter, workspaceId]);

	useEffect(() => {}, []);

	const filteredDataSource = (data: any) => {
		const filteredData = data.filter((item: any) => {
			let matchesFilter = true;
			const inputLower = input?.replace(/\s/g, "")?.toLowerCase();
			const typeFilters = filter
				.filter((filterItem) => filterItem.startsWith("Type"))
				.map((filterItem) => {
					const typeSplit = filterItem.split("_");
					const type = typeSplit.shift();
					const rest = typeSplit.join("_");
					return rest.toLowerCase();
				});
			const statusFilters = filter
				.filter((filterItem) => filterItem.startsWith("Status"))
				.map((filterItem) => filterItem.split("_")[1].toLowerCase());
			const priorityFilters = filter
				.filter((filterItem) => filterItem.startsWith("Priority"))
				.map((filterItem) => filterItem.split("_")[1].toLowerCase());

			matchesFilter =
				matchesFilter &&
				(item.title?.replace(/\s/g, "")?.toLowerCase().includes(inputLower) ||
					item.description
						?.replace(/\s/g, "")
						.toLowerCase()
						.includes(inputLower));

			if (typeFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					typeFilters.some(
						(filterValue) => item?.type?.toLowerCase() === filterValue
					);
			}

			if (statusFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					statusFilters.some(
						(filterValue) => item?.status?.toLowerCase() === filterValue
					);
			}

			if (priorityFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					priorityFilters.some(
						(filterValue) => item?.priority?.toLowerCase() === filterValue
					);
			}

			return matchesFilter;
		});

		const sortedAndFilteredData = [...filteredData].sort((a, b) => {
			if (sortBy === "Created date (Asc)") {
				return (
					new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
				);
			} else if (sortBy === "Created date (Desc)") {
				return (
					new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				);
			} else {
				return 0;
			}
		});
		console.log(sortedAndFilteredData, "sortfilterdata");

		return sortedAndFilteredData;
	};

	const handleAddWorkItemClick = () => {
		setModalVisible(true);
	};

	const handleModalCancel = () => {
		setModalVisible(false);
	};

	useEffect(() => {
		const test = filteredDataSource(data3);
		setData(test);
	}, [input, filter, selectedGroupBy, sortBy]);

	// useEffect(() => {
	// 	fetchTodos();
	// }, [FetchPlan, sortBy,RitualsSelectedFilter]);

	console.log(sortBy, "sortby");

	const groupKey = setGroupKey(selectedGroupBy);

	function removePTags(text: string) {
		return text?.replace(/<p>/g, "").replace(/<\/p>/g, "");
	}

	const groupedTasks = groupBy(filteredDataSource(data), groupKey);
	console.log(groupedTasks, "group", groupKey);
	if (RitualError) {
		return (
			<Result
				status="error"
				title="Error"
				subTitle="There was an error fetching the rituals."
			/>
		);
	}

	// const columns = [
	// 	{
	// 		title: "Action",
	// 		key: "action",
	// 		render: (item: any) => (
	// 			<EyeOutlined
	// 				onClick={() => handleIconClick(item)}
	// 				style={{ fontSize: "1rem", fontFamily: "Open Sans" }}
	// 			/>
	// 		),
	// 	},
	// 	{
	// 		title: "Title",
	// 		dataIndex: "title",
	// 		key: "title",
	// 		render: (text: any) => (
	// 			<a
	// 				style={{
	// 					color: "black",
	// 					fontWeight: "bold",
	// 					fontFamily: "Open Sans",
	// 				}}
	// 			>
	// 				{text}
	// 			</a>
	// 		),
	// 	},
	// 	// {
	// 	// 	title: "Description",
	// 	// 	dataIndex: "description",
	// 	// 	key: "description",
	// 	// 	render: (text: any) => (
	// 	// 		<div style={{ display: "flex", alignItems: "center" }}>
	// 	// 			<a
	// 	// 				style={{ color: "black", fontWeight: "bold" }}
	// 	// 				// dangerouslySetInnerHTML={{ __html: text }}
	// 	// 			>
	// 	// 				{removePTags(text)}
	// 	// 			</a>
	// 	// 		</div>
	// 	// 	),
	// 	// },
	// 	{
	// 		title: "Time Duration",
	// 		dataIndex: "timeDuration",
	// 		key: "timeDuration",
	// 		// render: (priority: any) => (
	// 		// 	<Tag color="blue" style={{ color: "black", fontFamily: "Open Sans" }}>
	// 		// 		{priority}
	// 		// 	</Tag>
	// 		// ),
	// 		render: (text: any, record: any) => {
	// 			const startDate = moment(record.startDate);
	// 			const endDate = moment(record.endDate);
	// 			const duration = moment.duration(endDate.diff(startDate));
	// 			const days = duration.days();
	// 			const hours = duration.hours();
	// 			const minutes = duration.minutes();
	// 			return (
	// 				<Tag color="blue" style={{ color: "black", fontFamily: "Open Sans" }}>
	// 					{days > 0 ? `${days} days ` : ""}
	// 					{hours > 0 ? `${hours} hours ` : ""}
	// 					{minutes > 0 ? `${minutes} minutes ` : ""}
	// 				</Tag>
	// 			);
	// 		},
	// 	},

	// 	{
	// 		title: "Start Date",
	// 		dataIndex: "startDate",
	// 		key: "startDate",
	// 		render: (startDate: any) => (
	// 			<span style={{ fontFamily: "Open Sans" }}>
	// 				{startDate && moment(startDate).format("DD/MM/YYYY")}
	// 			</span>
	// 		),
	// 	},
	// 	{
	// 		title: "End Date",
	// 		dataIndex: "endDate",
	// 		key: "endDate",
	// 		render: (enddata: any) => (
	// 			<span style={{ fontFamily: "Open Sans" }}>
	// 				{enddata && moment(enddata).format("DD/MM/YYYY")}
	// 			</span>
	// 		),
	// 	},
	// 	{
	// 		title: "Start Time",
	// 		dataIndex: "startDate",
	// 		key: "startTime",
	// 		render: (startTime: any) => (
	// 			<span style={{ fontFamily: "Open Sans" }}>
	// 				{startTime && moment.utc(startTime).format("HH:mm ")}
	// 			</span>
	// 		),
	// 	},
	// 	{
	// 		title: "End Time",
	// 		dataIndex: "endDate",
	// 		key: "endTime",
	// 		render: (endTime: any) => (
	// 			<span style={{ fontFamily: "Open Sans" }}>
	// 				{endTime && moment.utc(endTime).format("HH:mm ")}
	// 			</span>
	// 		),
	// 	},
	// ];

	const renderActions = (item: any) => (
		<div style={{ display: "flex", alignItems: "center" }}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginRight: "1vw",
				}}
			>
				<EyeOutlined
					onClick={() => handleIconClick(item)}
					style={{
						fontSize: "1rem",
						marginLeft: "1vw",
					}}
				/>
			</div>
		</div>
	);

	const calculateDuration = (startDate: string, endDate: string) => {
		const start = moment(startDate);
		const end = moment(endDate);
		const duration = moment.duration(end.diff(start));
		const days = duration.days();
		const hours = duration.hours();
		const minutes = duration.minutes();
		let durationString = "";
		if (days > 0) {
			durationString += `${days} days `;
		}
		if (hours > 0) {
			durationString += `${hours} hours `;
		}
		if (minutes > 0) {
			durationString += `${minutes} minutes `;
		}
		return durationString;
	};

	const renderListItem = (item: any) => (
		<List.Item key={item._id} style={{ padding: "0px 0" }}>
			<div className="list-item">
				<div className="list-item-description-left ">
					<div className="list-item-action">{renderActions(item)}</div>
					<span
						className="work-item-title"
						style={{ width: 100, marginLeft: "-5px", textAlign: "left" }}
					>
						{item.title}
					</span>
					<Tag color="blue" className="tag-icon">
						{calculateDuration(item.startDate, item.endDate)}
					</Tag>
				</div>
				<div className="list-item-description-right ">
					<Tooltip
						title={`Start on ${moment(item.startDate).format("MMM DD")} ${moment.utc(item.startDate).format("HH:mm a")}`}
						overlayStyle={{ borderRadius: "6px", height: 35 }}
					>
						<span
							className="work-item-title"
							style={{ cursor: "pointer", width: 60, textAlign: "left" }}
						>
							{moment(item.startDate).format("MMM DD")}
						</span>
					</Tooltip>
					<Tooltip
						title={`End on ${moment(item.endDate).format("MMM DD")} ${moment.utc(item.endDate).format("HH:mm a")}`}
					>
						<span
							className="work-item-title"
							style={{ cursor: "pointer", width: 60, textAlign: "left" }}
						>
							{moment(item.endDate).format("MMM DD")}
						</span>
					</Tooltip>
				</div>
			</div>
		</List.Item>
	);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const currentPageData = data.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	return (
		<Can I="view" a="rituals" passThrough>
			{(allowed) =>
				allowed ? (
					<Layout style={{ background: "#f5f8fe" }}>
						{RitualLoading ? (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
									minHeight: "100vh",
								}}
							>
								<Spin size="large" />
								<img
									style={{ marginTop: "10px", width: "150px" }}
									src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
									alt="Loading"
								/>
								<Spin size="large" />
							</div>
						) : (
							<>
								<RitualNav />
								<Layout style={{ background: "#f5f8fe" }}>
									{data.length > 0 ? (
										// <Content>
										// 	<div
										// 		style={{
										// 			position: "relative",
										// 		}}
										// 	>
										// 		<Flex
										// 			justify="center"
										// 			style={{
										// 				backgroundColor: "white",
										// 				margin: "1rem",
										// 				padding: "1rem",
										// 				overflow: "scroll",
										// 				// overflow: open ? "hidden" : "scroll",
										// 				// height: "73vh",
										// 				height: "68vh",
										// 				maxWidth: "100vw",
										// 				display: "block",
										// 				position: "relative",
										// 			}}
										// 			className="WorkspaceDiv"
										// 		>
										// 			{selectedGroupBy === "None" ? (
										// 				<>
										// 					<RitualDropdownMenu
										// 						open={menuVisible}
										// 						pos={position}
										// 						selectedRec={selectRec}
										// 					/>
										// 					<Table
										// 						columns={columns}
										// 						onRow={(record, rowIndex) => {
										// 							return {
										// 								onContextMenu: (event) => {
										// 									event.preventDefault();
										// 									console.log(
										// 										"Right clicked on row ",
										// 										record
										// 									);
										// 									setSelectRec(record);
										// 									console.log(rowIndex);
										// 									//event.preventDefault();
										// 									if (!menuVisible) {
										// 										document.addEventListener(
										// 											`click`,
										// 											function onClickOutside() {
										// 												setMenuVisible(false);
										// 												document.removeEventListener(
										// 													`click`,
										// 													onClickOutside
										// 												);
										// 											}
										// 										);
										// 									}
										// 									setMenuVisible(true);
										// 									setPosition({
										// 										x: event.clientX,
										// 										y: event.clientY,
										// 									});
										// 								},
										// 							};
										// 						}}
										// 						dataSource={filteredDataSource(data)}
										// 					></Table>
										// 				</>
										// 			) : (
										// 				<>
										// 					<Collapse ghost>
										// 						{Object.entries(groupedTasks)
										// 							.filter(
										// 								([groupKey]) => groupKey !== "undefined"
										// 							)
										// 							.map(([groupKey, groupData]) => (
										// 								<Collapse.Panel
										// 									key={groupKey}
										// 									header={
										// 										<div style={{ textAlign: "left" }}>
										// 											{groupKey}
										// 										</div>
										// 									}
										// 								>
										// 									<RitualDropdownMenu
										// 										open={menuVisible}
										// 										pos={position}
										// 										selectedRec={selectRec}
										// 									/>
										// 									<Table
										// 										columns={columns}
										// 										onRow={(record, rowIndex) => {
										// 											return {
										// 												onContextMenu: (event) => {
										// 													event.preventDefault();
										// 													console.log(
										// 														"Right clicked on row ",
										// 														record
										// 													);
										// 													setSelectRec(record);
										// 													console.log(rowIndex);
										// 													//event.preventDefault();
										// 													if (!menuVisible) {
										// 														document.addEventListener(
										// 															`click`,
										// 															function onClickOutside() {
										// 																setMenuVisible(false);
										// 																document.removeEventListener(
										// 																	`click`,
										// 																	onClickOutside
										// 																);
										// 															}
										// 														);
										// 													}
										// 													setMenuVisible(true);
										// 													setPosition({
										// 														x: event.clientX,
										// 														y: event.clientY,
										// 													});
										// 												},
										// 											};
										// 										}}
										// 										pagination={false}
										// 										dataSource={(groupData as any[]).map(
										// 											(item) => ({
										// 												...item,
										// 												key: item._id,
										// 											})
										// 										)}
										// 									/>
										// 								</Collapse.Panel>
										// 							))}
										// 					</Collapse>
										// 				</>
										// 			)}
										// 		</Flex>
										// 	</div>
										// </Content>
										<div
											className="list-view-container"
											style={{
												display: "flex",
												flexDirection: "column",
												height: "76vh",
												marginLeft: "1vw",
												padding: "0vw",
											}}
										>
											<div style={{ flex: "1 1 auto" }}>
												<List
													itemLayout="vertical"
													dataSource={currentPageData}
													renderItem={renderListItem}
													className="list-view"
													size="small"
												/>
											</div>
											<div
												style={{
													flex: "0 0 20px",
													textAlign: "end",
													marginTop: "3px",
													marginBottom: "10px",
												}}
											>
												<Pagination
													current={currentPage}
													total={data.length}
													pageSize={pageSize}
													onChange={handlePageChange}
													showSizeChanger={false}
												/>
											</div>
										</div>
									) : (
										Nodata ?? (
											<Result
												icon={<SmileOutlined style={{ color: "#0444BF" }} />}
												title="There are no existing rituals right now, Create one!"
												extra={
													<Button
														type="primary"
														onClick={handleAddWorkItemClick}
													>
														Next
													</Button>
												}
												style={{ marginTop: "10vh", marginBottom: "35vh" }}
											/>
										)
									)}
									<RitualCeration
										visible={modalVisible}
										onCancel={handleModalCancel}
									/>
								</Layout>
							</>
						)}
					</Layout>
				) : (
					<Flex style={{ marginTop: "15vh" }} justify="center">
						<Image
							height={"50vh"}
							width={"60vw"}
							src={
								"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_security_access.svg"
							}
							preview={false}
						/>
					</Flex>
				)
			}
		</Can>
	);
}

function groupBy(array: any[], key: string) {
	return array.reduce((result: any, currentItem: any) => {
		const groupKey = currentItem[key];
		if (groupKey !== undefined) {
			// Check if groupKey is defined
			if (!result[groupKey]) {
				result[groupKey] = [];
			}
			result[groupKey].push(currentItem);
		}
		return result;
	}, {});
}

export default Rituals;
