/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, act, CSSProperties } from "react";
import {
	Avatar,
	Button,
	Card,
	Divider,
	Dropdown,
	Empty,
	Layout,
	List,
	Menu,
	Table,
	Tag,
	Tooltip,
	message,
} from "antd";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { usePlanStore } from "../../stores/PlanStore";
import { ViewSwitcher } from "./ViewSwitcher";
import useWorkspaceStore from "../../stores/WorkspaceState";
import { planerViewStore } from "../../stores/PlannerStore";
import StatusFilterComponent from "./Filter";
import CreatePlanModel from "../Planner/CreatePlanModel";
import BacklogTable from "./WorkitemViewPlans";
import {
	BugOutlined,
	CheckCircleFilled,
	CheckCircleOutlined,
	CloseOutlined,
	DashOutlined,
	EditOutlined,
	ExclamationOutlined,
	FallOutlined,
	FileTextOutlined,
	HarmonyOSOutlined,
	HighlightOutlined,
	Loading3QuartersOutlined,
	RiseOutlined,
	UnorderedListOutlined,
	UsergroupAddOutlined,
} from "@ant-design/icons";
import "./PlansV2Gantt.css";
import { DndContext, useDraggable, useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import BreadCrumb from "../Editors/BreadCrumb";
import { motion } from "framer-motion";
import PlansContextMenu from "./PlansContextMenu";
import { useNavigate, useParams } from "react-router-dom";
import usePageStore from "../../stores/PageStore";

const PlansV2Gantt = () => {
	const navigate = useNavigate();
	const { Sider } = Layout;
	const {
		filterValScheduler,
		dateFilterVal,
		setSiderSchedulerOpen,
		isSiderSchedulerOpen,
	} = planerViewStore();
	const [view, setView] = useState(ViewMode.Month);
	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
		breadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
		breadcrumbConfig: state.breadcrumbConfig,
	}));

	useEffect(() => {
		setPageSlug("plans");
		setPageTitle("Plans");
		setPageDescription("");
		setItemType("");
		setItemId("");
		setIsList(true);
		setItemDetails({});
		setBreadcrumbConfig({
			page: `/space/${workspaceId}/planner/plans`,
			config: [
				{
					label: "Plans",
					url: `/space/${workspaceId}/planner/plans`,
				},
			],
		});
	}, []);
	const [tasks, setTasks] = useState<Task[]>([]);
	const [data, setData] = useState([]);
	const [planId, setPlanId] = useState("");
	const [selectedplanId, setselectedPlanId] = useState<string>("");
	const [workitemIds, setWorkitemIds] = useState([]);
	const [planData, setPlanData] = useState<any>([]);
	const [isChecked, setIsChecked] = useState(true);
	const [filteredBacklogData, setFilteredBacklogData] = useState([]);
	const [isAddTaskModalVisible, setAddTaskModalVisible] = useState(false);
	const [nodata, setNodata] = useState(false);
	const [empty, setEmpty] = useState(true);
	const [loading, setLoading] = useState(true);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { FetchPlan } = usePlanStore();
	const [modalVisible, setModalVisible] = useState(false);
	const [name, setName] = useState("");
	const [tasksDpl, setTasksDpl] = useState<any>();
	const [subMenuOpen, setSubMenuOpen] = useState(false);
	const [refreshTabe, setrefreshTable] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const { rightClickMenu } = planerViewStore();
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [isDragging, setIsDragging] = useState(false);

	const [position, setPosition] = React.useState({
		x: 0,
		y: 0,
	});

	const workspaceId = useParams().workspaceId;

	interface AssigneeData {
		name: string;
		preferences?: {
			profileColor?: string;
		};
	}

	interface ItemData {
		_id: string;
		WorkitemId: string;
		key: string;
		title: string;
		priority: string;
		type: string;
		status: string;
		assignee: AssigneeData;
	}

	const [selectRec, setSelectRec] = useState<ItemData | undefined>(undefined);

	type TaskListTableProps = {
		tasks: Task[];
		onExpanderClick: (task: Task) => void;
		handleAddTask: (task: Task) => void;
	};

	const TaskListTable: React.FC<TaskListTableProps> = ({
		tasks,
		onExpanderClick,
		handleAddTask,
	}) => {
		return (
			<div style={{ border: "1px solid #dfe1e5" }}>
				{tasks.map((item) => {
					return (
						<div
							key={item.id}
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								padding: "14px",
								borderBottom: "1px solid #dfe1e5",
							}}
						>
							<p style={{ color: "black" }}>{item.name}</p>
						</div>
					);
				})}
			</div>
		);
	};

	const [messageApi, contextHolder] = message.useMessage();
	const success = () => {
		messageApi.open({
			type: "success",
			content: "Backlog Added to Plan Sucessfully",
		});
	};

	const fetchTodos = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/${workspaceId}?type=All`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const todosData = await response.json();

				const planObject = todosData.find((plan: any) => plan._id === planId);

				// If the object is found, set the items field
				if (planObject) {
					setData(planObject.items);
				}

				const itemIds = planObject?.items?.map((item: any) => item._id);
				setWorkitemIds(itemIds);

				setPlanData(todosData);

				if (todosData.length === 0) {
					setNodata(true);
				} else {
					setNodata(false);
				}

				// Filter todosData based on filterValScheduler and dateFilterVal
				const filteredTodosData = todosData.filter((todo: any) => {
					const isTypeMatch =
						filterValScheduler.length === 0 ||
						filterValScheduler.includes(todo.type);

					const startDate = dateFilterVal[0] ? dateFilterVal[0].toDate() : null;
					const endDate = dateFilterVal[1] ? dateFilterVal[1].toDate() : null;
					const taskStartDate = new Date(todo.startdate);
					const taskEndDate = new Date(todo.enddate);

					const isStartDateMatch = startDate
						? taskStartDate >= startDate
						: true;
					const isEndDateMatch = endDate ? taskEndDate < endDate : true;

					const isDateMatch = isStartDateMatch && isEndDateMatch;

					return isTypeMatch && isDateMatch;
				});

				if (filteredTodosData.length === 0) {
					setTasks([]);
					setLoading(false);
					return;
				}

				const tasksData = filteredTodosData.map((todo: any) => {
					const completedItems = todo.items.filter(
						(item: any) => item.status === "COMPLETED"
					).length;
					const totalItems = todo.items.length;
					const progress =
						totalItems > 0
							? Math.round(((completedItems / totalItems) * 100) / 10) * 10
							: 0;

					return {
						start: new Date(todo.startdate),
						end: new Date(todo.enddate),
						name: todo.name,
						id: todo._id,
						type: todo.gantType,
						progress: progress,
						project: todo.project,
						dependencies: todo.dependencies || [],
						items: todo.items || [],
					};
				});
				setTasks(tasksData);
				// setTasksDpl(tasks);
				setLoading(false);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	useEffect(() => {
		if (workspaceId && userProfileStoreInstance.idToken !== null) {
			fetchTodos();
		}
	}, [
		userProfileStoreInstance,
		workspaceId,
		currentWorkspace,
		filterValScheduler,
		dateFilterVal,
		refreshTabe,
		rightClickMenu,
	]); // Add dateFilterVal to dependency array

	const handleAddTask = (newTask: any) => {
		setTasks([...tasks, newTask]);
		setAddTaskModalVisible(false);
	};

	const handleTaskChange = async (task: any) => {
		const updatedTask = tasks.map((t) => (t.id === task.id ? task : t));
		setTasks(updatedTask);

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/${task.id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({
						startdate: task.start,
						enddate: task.end,
					}),
				}
			);

			if (!response.ok) {
				console.error("Failed to update task dates");
			}
		} catch (error) {
			console.error("Error updating task dates:", error);
		}
	};

	const handleTaskDelete = (task: any) => {
		if (window.confirm(`Are you sure about ${task.name} ?`)) {
			setTasks(tasks.filter((t) => t.id !== task.id));
		}
	};

	const handleProgressChange = (task: any) => {
		setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
	};

	const handleDblClick = (task: any) => {
		alert("On Double Click event Id:" + task.id);
	};

	const handleSelect = (task: any) => {
		// fetchWorkitemsForPlans(task.items._id);
		console.log(task, "task");
		// setData(task.items);
		setPlanId(task.id);
		setselectedPlanId(planId);
		setrefreshTable(!refreshTabe);

		const itemIds = task.items.map((item: any) => item._id);
		setWorkitemIds(itemIds);

		setName(task.name);
		setEmpty(false);
	};

	const handleExpanderClick = (task: any) => {
		setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
	};

	let columnWidth = 60;
	if (view === ViewMode.Month) {
		columnWidth = 300;
	} else if (view === ViewMode.Week) {
		columnWidth = 250;
	} else if (view === ViewMode.Year) {
		columnWidth = 500;
	}

	const handleAddWorkItemClick = () => {
		setModalVisible(true);
	};

	const handleModalCancel = () => {
		setModalVisible(false);
	};

	const handleSiderToggle = () => {
		setSiderSchedulerOpen(true);
	};

	const onClose = () => {
		setSiderSchedulerOpen(false);
	};

	const [menuVisible, setMenuVisible] = useState(false);

	const fetchBacklogsForPlans = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${workspaceId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const data = await response.json();

				// Filter items whose planIds field is empty or does not exist
				const filteredData = data.filter(
					(item: any) => !item.planIds || item.planIds.length === 0
				);

				setFilteredBacklogData(filteredData);
			} else {
				console.error("Failed to fetch work items");
			}
		} catch (error) {
			console.error("Error fetching work items:", error);
		}
	};
	useEffect(() => {
		if (workspaceId && userProfileStoreInstance.idToken !== null) {
			fetchBacklogsForPlans();
		}
	}, [isSiderSchedulerOpen, userProfileStoreInstance.idToken, workspaceId]);

	const handleMenuClick = async (e: any, item: any) => {
		setSubMenuOpen(false);

		try {
			const resultdata = planData?.filter((data: any) => data._id === e.key);

			if (resultdata && resultdata.length > 0) {
				// Iterate over all matching plans
				const updatedPlans = resultdata.map((plan: any) => {
					// Convert items array to an array of item IDs
					plan.items = plan.items.map((item: any) => item._id);

					// Add new item ID to the items array
					plan.items.push(item._id);

					return plan;
				});

				for (const plan of updatedPlans) {
					const updateResponse = await fetch(
						`${process.env.REACT_APP_API_URL}/plan/${plan._id}`,
						{
							method: "PUT",
							headers: {
								"Content-Type": "application/json; charset=UTF-8",
								Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
							},
							body: JSON.stringify(plan),
						}
					);

					const updateResult = await updateResponse.json();

					if (updateResponse.ok) {
						messageApi.open({
							type: "success",
							content: "Backlog Added to Plan Sucessfully",
						});
					} else {
						console.error("Failed to update data:", updateResult);
					}
				}
			}
		} catch (error) {
			console.error("Error updating data:", error);
		}
		try {
			// Assuming there is an API endpoint for updating data
			const updatePLanWorkitem = await fetch(
				`${process.env.REACT_APP_API_URL}/workitems/${item._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify({ planIds: item._id }),
				}
			);

			const updateResults = await updatePLanWorkitem.json();

			if (updatePLanWorkitem.ok) {
				fetchBacklogsForPlans();
			} else {
				console.error("Failed to update data");
			}
		} catch (error) {
			console.error("Error updating data:", error);
		}
	};

	const handleSubMenuClick = () => {
		setSubMenuOpen(true);
	};

	const handleVisibleChange = (visible: any) => {
		if (visible) {
			setSubMenuOpen(true);
		}
	};

	const DraggableItem = ({ id, item }: any) => {
		const { attributes, listeners, setNodeRef, transform, isDragging } =
			useDraggable({ id });

		const style: React.CSSProperties = {
			transform: CSS.Transform.toString(transform),
			padding: "1rem",
			backgroundColor: "#edf4ff",
			borderRadius: "0.5rem",
			marginBottom: "0.5rem",
			cursor: "pointer",
			// pointerEvents: isDragging ? 'none' : 'auto', // Disable pointer events while dragging
			// width: '90%', // Specify a fixed width for the item,
			zIndex: isDragging ? 1000 : "auto", // Set a high z-index when dragging
			position: isDragging ? "fixed" : "relative", // Change position when dragging
		};
		return (
			<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
				{/* <Dropdown
					trigger={["contextMenu"]}
					onVisibleChange={handleVisibleChange}
					overlay={
						subMenuOpen ? (
							<Menu className="context-menu">
								<Menu.SubMenu
									style={{ borderRadius: "1px" }}
									key="sub2"
									className="sub-menu"
									title="Assign to Plan"
								>
									<Menu
										onClick={(e) => handleMenuClick(e, item)}
										className="dropdoownMenu"
										style={{ maxHeight: "400px", overflowY: "auto" }}
									>
										{planData.map((menuItem: any) =>
											menuItem ? (
												<Menu.Item
													style={{ width: "10vw" }}
													className="dropdoownMenu"
													key={menuItem._id}
												>
													{menuItem.name}
												</Menu.Item>
											) : null
										)}
									</Menu>
								</Menu.SubMenu>
							</Menu>
						) : (
							<></>
						) // Use an empty fragment when subMenuOpen is false
					}
				> */}
				<div>
					<div style={{ fontWeight: "bold" }}>{item.title}</div>
					<div>{item.description}</div>
				</div>
				{/* </Dropdown> */}
			</div>
		);
	};

	const DroppableTable = ({ children, isDraggingOver }: any) => {
		const { setNodeRef } = useDroppable({ id: "droppable-table" });
		const style: CSSProperties = {
			position: "relative",
			border: isDraggingOver ? "2px dashed #1890ff" : "none",
			transition: "filter 0.2s, border 0.2s",
		};

		const tableStyle: CSSProperties = {
			filter: isDraggingOver ? "blur(2px)" : "none",
		};

		const overlayStyle: CSSProperties = {
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			backgroundColor: "rgba(0, 0, 0, 0.5)",
			color: "white",
			padding: "8px",
			borderRadius: "4px",
			pointerEvents: "none",
			opacity: isDraggingOver ? 1 : 0,
			transition: "opacity 0.2s",
		};

		return (
			<div ref={setNodeRef} style={style}>
				<div style={tableStyle}>{children}</div>
				<div style={overlayStyle}>Drop here</div>
			</div>
		);
	};

	const getRandomPastelColor = () => {
		const hue = 219;
		return `hsl(${hue}, 80%, 80%)`;
	};

	const columns = [
		{
			title: <div style={{ fontFamily: "Poppins" }}>ID</div>,
			dataIndex: "WorkitemId",
			key: "WorkitemId",
			width: 50,
			render: (text: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<a
						style={{
							color: "black",
							fontWeight: "bold",
							fontFamily: "Open Sans",
						}}
					>
						{text}
					</a>
				</div>
			),
		},
		{
			title: <div style={{ fontFamily: "Poppins" }}>Title</div>,
			dataIndex: "title",
			key: "title",
			width: 300,
			render: (text: any) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<a
						style={{
							color: "black",
							fontWeight: "bold",
							fontFamily: "Open Sans",
						}}
					>
						{text}
					</a>
				</div>
			),
		},
		{
			title: <div style={{ fontFamily: "Poppins" }}>Priority</div>,
			dataIndex: "priority",
			key: "priority",
			width: 100,
			render: (priority: any) => (
				<Tag color="blue" style={{ color: "black", fontFamily: "Open Sans" }}>
					{priority?.charAt(0)}
				</Tag>
			),
		},
		{
			title: <div style={{ fontFamily: "Poppins" }}>Type</div>,
			dataIndex: "type",
			key: "type",
			width: 100,
			render: (type: any) => (
				<Tag color="yellow" style={{ color: "black", fontFamily: "Open Sans" }}>
					{type}
				</Tag>
			),
		},
		{
			title: <div style={{ fontFamily: "Poppins" }}>Status</div>,
			dataIndex: "status",
			key: "status",
			width: 130,
			render: (status: any) => <div>{status?.toUpperCase()}</div>,
		},
		{
			title: <div style={{ fontFamily: "Poppins" }}>Assign</div>,
			dataIndex: "assignee",
			key: "assignee",
			width: 100,
			render: (assigneeData: any) => {
				const assigneeNameWords = assigneeData?.name?.split(" ") || [];
				const firstName = assigneeNameWords[0] || "";
				const lastName =
					assigneeNameWords.length > 1
						? assigneeNameWords[assigneeNameWords.length - 1]
						: "";

				const avatarText =
					`${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();

				const pastelColor = assigneeData?.preferences?.profileColor
					? assigneeData.preferences.profileColor
					: getRandomPastelColor();

				return (
					<Tooltip title={`${assigneeData?.name}`} placement="top">
						<Avatar style={{ backgroundColor: pastelColor }}>
							{avatarText}
						</Avatar>
					</Tooltip>
				);
			},
		},
	];

	console.log(planId, "planId");

	const handleDragStart = () => {
		console.log("Drag started");
		setIsDragging(true);
	};

	const handleDragEnd = async (event: any) => {
		const { active, over } = event;
		setIsDragging(false);

		if (over && over.id === "droppable-table") {
			const draggedItem = filteredBacklogData.find(
				(item: any) => item._id === active.id
			);
			if (draggedItem) {
				// // Make the PUT request to update the data in the backend
				// const planId = currentWorkspace?.planId; // Assuming you have a planId
				// // if (planId) {
				//   const updatedPlan = {
				//     ...currentWorkspace.plan,
				//     items: [...currentWorkspace.plan.items, active.id], // Push the dragged item id to the items array
				//   };

				try {
					// const resultdata = planData?.filter((data: any) => data._id === active.id);

					// // if (resultdata && resultdata.length > 0) {
					// 	// Iterate over all matching plans
					// 	const updatedPlans = resultdata.map((plan: any) => {
					// 		// Convert items array to an array of item IDs
					// 		plan.items = plan.items.map((item: any) => item._id);

					// 		// Add new item ID to the items array
					// 		plan.items.push(active.id);

					// 		return plan;
					// 	});

					// for (const plan of updatedPlans) {
					const updateResponse = await fetch(
						`${process.env.REACT_APP_API_URL}/plan/${planId}`,
						{
							method: "PUT",
							headers: {
								"Content-Type": "application/json; charset=UTF-8",
								Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
							},
							body: JSON.stringify({ items: [...workitemIds, active.id] }),
						}
					);

					const updateResult = await updateResponse.json();

					if (updateResponse.ok) {
						messageApi.open({
							type: "success",
							content: "Backlog Added to Plan Sucessfully",
						});

						setrefreshTable(!refreshTabe);
					} else {
						console.error("Failed to update data:", updateResult);
					}
					// }
					// }
				} catch (error) {
					console.error("Error updating data:", error);
				}
				try {
					// Assuming there is an API endpoint for updating data
					const updatePLanWorkitem = await fetch(
						`${process.env.REACT_APP_API_URL}/workitems/${active.id}`,
						{
							method: "PUT",
							headers: {
								"Content-Type": "application/json; charset=UTF-8",
								Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
							},
							body: JSON.stringify({ planIds: active.id }),
						}
					);

					// const updateResults = await updatePLanWorkitem.json();

					if (updatePLanWorkitem.ok) {
						fetchBacklogsForPlans();
					} else {
						console.error("Failed to update data");
					}
				} catch (error) {
					console.error("Error updating data:", error);
				}
			}
		}
	};

	const buttonVariants = {
		initial: { width: "30px", height: "24px" },
		hover: { width: "150px", height: "24px" },
	};

	const renderAssigneeAvatar = (assigneeData: AssigneeData) => {
		const assigneeNameWords = assigneeData?.name?.split(" ") || [];
		const firstName = assigneeNameWords[0] || "";
		const lastName =
			assigneeNameWords.length > 1
				? assigneeNameWords[assigneeNameWords.length - 1]
				: "";

		const avatarText = `${firstName.charAt(0)}${lastName.charAt(
			0
		)}`.toUpperCase();

		const pastelColor = assigneeData?.preferences?.profileColor
			? assigneeData.preferences.profileColor
			: getRandomPastelColor();

		return (
			<Tooltip title={`${assigneeData?.name}`} placement="top">
				<Avatar
					style={{
						backgroundColor: pastelColor,
					}}
					size="small"
				>
					{avatarText}
				</Avatar>
			</Tooltip>
		);
	};

	const getTypeIcon = (type: string) => {
		switch (type) {
			case "FEATURE":
				return <HighlightOutlined />;
			case "TASK":
				return <CheckCircleOutlined />;
			case "BUG":
				return <BugOutlined />;
			case "USER STORY":
				return <FileTextOutlined />;
			default:
				return null;
		}
	};

	const getPriorityIcon = (priority: string) => {
		switch (priority) {
			case "URGENT":
				return <RiseOutlined style={{ color: "orange" }} />;
			case "Urgent":
				return <RiseOutlined style={{ color: "orange" }} />;
			case "IMPORTANT":
				return <ExclamationOutlined style={{ color: "red" }} />;
			case "Important":
				return <ExclamationOutlined style={{ color: "red" }} />;
			case "Medium":
				return <DashOutlined style={{ color: "golden" }} />;
			case "LOW":
				return <FallOutlined style={{ color: "green" }} />;
			case "Low":
				return <FallOutlined style={{ color: "green" }} />;
			default:
				return <HarmonyOSOutlined />;
		}
	};

	const getTagcolor = (priority?: string) => {
		if (!priority) return "blue";
		switch (priority.toLowerCase()) {
			case "urgent":
				return "orange";
			case "important":
				return "red";
			case "medium":
				return "gold";
			case "low":
				return "green";
			default:
				return "blue";
		}
	};

	const getStatusStyle = (status?: string) => {
		if (!status) return { color: "gray", icon: <DashOutlined /> };
		const formattedStatus = status.replace(/_/g, " ").toLowerCase();

		switch (formattedStatus) {
			case "completed":
				return { color: "green", icon: <CheckCircleFilled /> };
			case "in progress":
				return { color: "blue", icon: <Loading3QuartersOutlined /> };
			case "pending":
				return { color: "orange", icon: <ExclamationOutlined /> };
			case "open":
				return { color: "red", icon: <HarmonyOSOutlined /> };
			default:
				return { color: "gray", icon: <DashOutlined /> };
		}
	};

	const formatStatusText = (status?: string) => {
		if (!status) return "";
		return status.replace(/_/g, " ").toLowerCase();
	};

	const renderListItem = (item: ItemData) => (
		<List.Item
			onContextMenu={(event) => {
				event.preventDefault();
				setSelectRec(item);
				// console.log("selectRec", selectRec);
				if (!menuVisible) {
					document.addEventListener(`click`, function onClickOutside() {
						setMenuVisible(false);
						document.removeEventListener(`click`, onClickOutside);
					});
				}
				setMenuVisible(true);
				setPosition({ x: event.clientX, y: event.clientY });
			}}
			key={item._id}
			style={{ padding: "0px 0" }}
		>
			<div className="list-item">
				<div className="list-item-description-left ">
					{/* <div className="list-item-action">{renderActions(item)}</div> */}
					<a className="work-item-id">{item.WorkitemId}</a>
					<a className="work-item-title">{item.title}</a>
				</div>
				<div className="list-item-description-right ">
					<a
						className="status-tag"
						style={{ color: getStatusStyle(item.status).color }}
					>
						{formatStatusText(item.status)}
					</a>
					<Tooltip title={item.type}>
						<Tag color="blue" className="tag-icon">
							{getTypeIcon(item.type)}
						</Tag>
					</Tooltip>
					<Tooltip title={item.priority ? item.priority : "Null"}>
						<Tag color={getTagcolor(item.priority)} className="priority-tag">
							{getPriorityIcon(item.priority)}
						</Tag>
					</Tooltip>
					<div className="assignee-avatar">
						{renderAssigneeAvatar(item.assignee)}
					</div>
				</div>
			</div>
		</List.Item>
	);

	return (
		<DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
			<div style={{ position: "relative", zIndex: 0 }}>
				<Sider
					width={400}
					collapsible
					collapsed={!isSiderSchedulerOpen}
					onCollapse={onClose}
					collapsedWidth={0}
					trigger={null}
					reverseArrow
					style={{
						background: "#fff",
						position: "fixed",
						right: 0,
						height: "100vh",
						overflow: "auto",
						zIndex: "2",
					}}
				>
					<div style={{ padding: 20, textAlign: "left" }}>
						<h2>Backlogs</h2>
						<List
							style={{ width: "18vw" }}
							dataSource={filteredBacklogData}
							renderItem={(item: any) => (
								<DraggableItem key={item._id} id={item._id} item={item} />
							)}
						/>
						<Button
							type="text"
							icon={<CloseOutlined />}
							onClick={onClose}
							style={{ position: "absolute", top: 20, right: 20 }}
						/>
					</div>
				</Sider>
				<Divider className="ant-divider-horizontal" />
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "stretch",
						marginTop: "1.3vh",
					}}
				>
					<div
						className="ant-div-container"
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							margin: "0 2.2vw",
						}}
					>
						<div style={{ display: "flex", alignItems: "center" }}>
							<BreadCrumb config={breadcrumbConfig} />
							<StatusFilterComponent />
						</div>
						<div style={{ display: "flex", alignItems: "center" }}>
							<ViewSwitcher
								onViewModeChange={(viewMode) => setView(viewMode)}
								onViewListChange={setIsChecked}
								isChecked={isChecked}
							/>
							<motion.div
								initial="initial"
								animate={isHovered ? "hover" : "initial"}
								variants={buttonVariants}
								transition={{ duration: 0.3 }}
								onMouseEnter={() => setIsHovered(true)}
								onMouseLeave={() => setIsHovered(false)}
								style={{ marginLeft: "0.5vw" }}
								className="add-work-item-button"
								onClick={handleAddWorkItemClick}
							>
								<EditOutlined />
								{isHovered && (
									<span style={{ marginLeft: "8px" }}>Add Plan</span>
								)}
							</motion.div>
							<Tooltip placement="top" title="Add workitems">
								<UnorderedListOutlined
									style={{ marginLeft: "0.5vw" }}
									onClick={handleSiderToggle}
								/>
							</Tooltip>
						</div>
					</div>

					<Card
						style={{
							overflowY: "auto",
							maxHeight: "80vh",
							width: isSiderSchedulerOpen ? "78%" : "100%",
							transition: "width 0.3s ease-in-out",
							margin: "0 1vw",
							marginTop: "1vh",
							borderRadius: "6px",
						}}
					>
						{!loading ? (
							tasks.length > 0 ? (
								<div style={{ marginTop: "1vh", marginLeft: "1vw" }}>
									<Gantt
										tasks={tasks}
										viewMode={view}
										onDateChange={handleTaskChange}
										onDelete={handleTaskDelete}
										onProgressChange={handleProgressChange}
										onDoubleClick={handleDblClick}
										onSelect={handleSelect}
										onExpanderClick={handleExpanderClick}
										listCellWidth={isChecked ? "155px" : ""}
										columnWidth={columnWidth}
										TaskListHeader={() => (
											<div
												style={{
													height: 50,
													width: 270,
													fontFamily: "sans-serif",
													fontWeight: "bold",
													paddingLeft: 10,
													margin: 0,
													marginBottom: -1,
													display: "flex",
													alignItems: "center",
													border: "black 1px",
												}}
											>
												Plans
											</div>
										)}
										TaskListTable={(props) => (
											<TaskListTable {...props} handleAddTask={handleAddTask} />
										)}
									/>
								</div>
							) : (
								<Empty style={{ height: "40vh", marginTop: "13vh" }} />
							)
						) : (
							<div>Loading...</div>
						)}
						{nodata && <div>No Data Available</div>}
						<CreatePlanModel
							visible={modalVisible}
							onCancel={handleModalCancel}
						/>
						<div style={{ marginTop: "3vh" }}>
							{empty ? (
								<div style={{ marginTop: "3vh" }}></div>
							) : (
								<div style={{ marginTop: "3vh", marginLeft: "2vw" }}>
									<div
										style={{ display: "flex", justifyContent: "space-between" }}
									>
										<p
											style={{
												fontSize: "1.2rem",
												color: "#0444BF",
												textAlign: "left",
												fontWeight: "bold",
												marginBottom: "2vh",
											}}
										>
											{name}
										</p>
										<Tooltip
											title="Capacity planning"
											key={`Capacity planning`}
										>
											<Button
												type="default"
												style={{
													borderRadius: "6px",
													fontSize: "14px",
													marginRight: "1.3vw",
												}}
												onClick={() =>
													navigate(
														`/space/${workspaceId}/planner/capacityplan/${planId}`
													)
												}
												//type="text"
												size="small"
												key="edit"
												icon={
													<UsergroupAddOutlined
														style={{
															color: "#0444BF",
														}}
													/>
												}
											></Button>
										</Tooltip>
									</div>
									<DroppableTable isDraggingOver={isDragging}>
										{/* <Table columns={columns} dataSource={data} /> */}
										<PlansContextMenu
											planid={planId}
											open={menuVisible}
											pos={position}
											selectedRec={selectRec}
										/>
										<List
											itemLayout="vertical"
											dataSource={data}
											renderItem={renderListItem}
											className="list-view"
											size="small"
											pagination={{
												pageSize: 15,
											}}
										/>
									</DroppableTable>
								</div>
							)}
						</div>
						{contextHolder}
					</Card>
				</div>
			</div>
		</DndContext>
	);
};

export default PlansV2Gantt;
