import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal, Input, message, Spin, Alert, Flex } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
import useWorkspaceStore from "../../../stores/WorkspaceState";
import { useNavigate } from "react-router-dom";
import { createNote, getNotesbyWorkspaceId } from "../../../api/Notes";
// import { manual } from "@antv/x6/lib/registry/port-label-layout/side";
import { useRequest } from "ahooks";
import usePageStore from "../../../stores/PageStore";
import BreadCrumb from "../../Editors/BreadCrumb";

interface Box {
	bookTitle: string;
	workspaceId: string;
	pages: any[];
}

const NotesDashboard: React.FC = () => {
	const navigate = useNavigate();
	const [boxes, setBoxes] = useState<any[]>([]);

	const [error, setError] = useState<string>("");
	const [visible, setVisible] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>("");
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const {
		data: noteByWorkspaceData,
		loading: noteByWorkspaceLoading,
		error: noteByWorkspaceError,
		run: runNoteByWorkspace,
	} = useRequest(
		(workspaceId) =>
			getNotesbyWorkspaceId(workspaceId, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				// message.info("Welcome to Notes Dashboard");
				console.log(data, "data");
			},
			onError: (error) => {
				console.log(error, "error");
				setError(error.message);
				message.error(`Error: ${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (noteByWorkspaceData) {
			setBoxes(noteByWorkspaceData);
		}
	}, [noteByWorkspaceData]);

	useEffect(() => {
		if (currentWorkspace?._id) {
			runNoteByWorkspace(currentWorkspace._id);
		}
	}, [currentWorkspace]);

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
		breadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
		breadcrumbConfig: state.breadcrumbConfig,
	}));

	useEffect(() => {
		setPageSlug("notes");
		setPageTitle("Notes");
		setPageDescription("");
		setItemType("");
		setItemId("");
		setIsList(false);
		setItemDetails({});
		setBreadcrumbConfig({
			page: `/space/${currentWorkspace?._id}/notes`,
			config: [
				{
					label: "Notes",
					url: `/space/${currentWorkspace?._id}/planner/notes`,
				},
			],
		});
	}, []);
	const addBox = () => {
		setVisible(true);
	};

	const { run: addNewBox } = useRequest(
		(data) => createNote(data, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				message.success(`${data.bookTitle} Note created `);
				setBoxes([...boxes, data]);
			},
			onError: (error) => {
				message.error(`Error: ${error.message}`);
			},
		}
	);

	const handleOk = async () => {
		if (inputValue.trim() !== "") {
			const newBox: Box = {
				bookTitle: inputValue.trim(),
				workspaceId: currentWorkspace._id,
				pages: [
					{
						pageTitle: inputValue.trim(),
						pageContent: [],
						createdBy: userProfileStoreInstance.profile._id,
					},
				],
			};

			await addNewBox(newBox);
		}

		setVisible(false);
		setInputValue("");
	};

	const handleCancel = () => {
		setVisible(false);
		setInputValue("");
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const handleCardClick = (id: string) => {
		navigate(`/space/${currentWorkspace._id}/planner/notes/${id}`);
	};

	return (
		<div style={{ height: "80vh", overflowY: "scroll", padding: "10px" }}>
			<Flex style={{ margin: "1.5vw" }}>
				<BreadCrumb config={breadcrumbConfig} />
			</Flex>
			{noteByWorkspaceLoading ? (
				<Spin tip="Loading...">
					<div
						style={{
							height: "80vh",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					/>
				</Spin>
			) : noteByWorkspaceError ? (
				<Alert
					style={{ marginBottom: "10vh" }}
					message="Error"
					description={error}
					type="error"
					showIcon
				/>
			) : (
				<Row gutter={[16, 16]}>
					<Col span={6} style={{ marginBottom: "10px" }}>
						<Card
							hoverable
							onClick={addBox}
							style={{
								height: "18vh",
								backgroundColor: "#1890ff",
								margin: "0 10px",
								cursor: "pointer",
								textAlign: "center",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<PlusOutlined /> Add NoteBook
						</Card>
						<Modal
							title="Enter NoteBook Name"
							open={visible}
							onOk={handleOk}
							onCancel={handleCancel}
						>
							<Input value={inputValue} onChange={handleInputChange} />
						</Modal>
					</Col>
					{boxes.map((box, index) => (
						<Col span={6} key={index}>
							<Card
								onClick={() => handleCardClick(box._id)}
								style={{
									backgroundColor: "#1890ff",
									height: "18vh",
									color: "#fff",
									margin: "0 10px",
									cursor: "pointer",
									textAlign: "center",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
									transition: "background-color 0.3s ease",
								}}
							>
								{box.bookTitle}
							</Card>
						</Col>
					))}
				</Row>
			)}
		</div>
	);
};

export default NotesDashboard;
