import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	Button,
	Select,
	Modal,
	Layout,
	message,
	Row,
	Col,
} from "antd";
// import "./AddRole.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import { useRequest } from "ahooks";
import { getallRoles } from "../../api/RolesAPI";

interface AddUserModalProps {
	isVisible: boolean;
	setIsVisible: (visible: boolean) => void;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
	isVisible,
	setIsVisible,
}) => {
	interface Workspace {
		_id: number;
		name: string;
	}

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [form] = Form.useForm();
	const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
	const [responseData, setResponseData] = useState<any[]>([]);

	const ServerLink = process.env.REACT_APP_API_URL;

	const fetchData = async () => {
		try {
			const response = await fetch(`${ServerLink}/workspace`, {
				headers: {
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			});
			const json = await response.json();
			setWorkspaces(json);
			if (response.status === 401) {
				// Assuming setUnauthorizedModel is used elsewhere correctly
				// setUnauthorizedModel(true);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleCancelClick = () => {
		setIsVisible(false);
	};

	const handleSaveClick = async (values: any) => {
		try {
			const userProfileResponse = await fetch(`${ServerLink}/user-profiles`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify({
					name: values.name,
					email: values.email,
					isActive: true,
					adUserID: "dummyAzureUserID",
					preferences: "dummyPreferences",
					account_Id: userProfileStoreInstance.profile.account_Id,
					CreatedBy: "dummyCreatedByUserID",
					updatedBy: "dummyUpdatedByUserID",
				}),
			});

			if (!userProfileResponse.ok) {
				console.error(
					"Error saving user profile data:",
					userProfileResponse.statusText
				);
				return;
			}

			const userProfileData = await userProfileResponse.json();
			const userProfileId = userProfileData._id;

			const fetchRoleData = async () => {
				try {
					const response = await fetch(`${ServerLink}/roles/${values.roleId}`, {
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					});
					const data = await response.json();
					const permissionDetails = data.permissions.map(
						({ field, permissions }: any) => ({
							field,
							permissions,
						})
					);

					const workspacePermissionsResponse = await fetch(
						`${ServerLink}/workspace-permissions`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
							},
							body: JSON.stringify({
								workspaceId: values.workspaceId,
								userProfileId: userProfileId,
								permission: permissionDetails,
								roles: values.roleId,
								CreatedBy: "Kasinath",
								updatedBy: "Kasinath",
							}),
						}
					);

					if (workspacePermissionsResponse.ok) {
						setIsVisible(false);
					} else {
						console.error(
							"Error saving workspace permissions data:",
							workspacePermissionsResponse.statusText
						);
					}
				} catch (error) {
					console.error("Error in fetchRoleData try-catch block:", error);
				}
			};

			await fetchRoleData();
		} catch (error) {
			console.error("Error in handleSaveClick try-catch block:", error);
		}
	};

	const { data: roleData, run: fetchRolesData } = useRequest(
		() => getallRoles(userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess() {
				// message.success("Role fetched successfully");
			},
			onError() {
				message.error("Error fetching roles data");
			},
		}
	);

	useEffect(() => {
		if (roleData) {
			setResponseData(roleData);
		}
	}, [roleData]);

	useEffect(() => {
		fetchRolesData();
	}, []);

	return (
		<Modal
			title="Add User"
			visible={isVisible}
			onCancel={handleCancelClick}
			footer={null}
			width={500}
		>
			<Layout style={{ background: "white" }}>
				<Form
					form={form}
					initialValues={{ remember: true }}
					onFinish={handleSaveClick}
					layout="vertical"
					colon={false}
					labelCol={{
						style: {
							textAlign: "right",
							margin: 0,
						},
					}}
					// wrapperCol={{ style: { paddingLeft: "1vw" } }}
				>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item
								name="name"
								label="User Name"
								rules={[{ required: true, message: "Please enter user name" }]}
								style={{ marginBottom: 10 }}
							>
								<Input
									style={{ width: "100%", fontSize: "14px", height: "32px" }}
									placeholder="Name"
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item
								name="email"
								label="Email"
								rules={[
									{
										required: true,
										type: "email",
										message: "Please enter email",
									},
								]}
								style={{ marginBottom: 10 }}
							>
								<Input
									style={{ width: "100%", fontSize: "14px", height: "32px" }}
									placeholder="Email"
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item
								name="workspaceId"
								label="Select Workspace"
								rules={[
									{ required: true, message: "Please select a workspace" },
								]}
								style={{ marginBottom: 10 }}
							>
								<Select
									style={{ width: "100%", fontSize: "16px" }}
									placeholder="Select Workspaces"
								>
									{workspaces.map((workspace) => (
										<Select.Option key={workspace._id} value={workspace._id}>
											{workspace.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item
								name="roleId"
								label="Select Role"
								rules={[{ required: true, message: "Please select a role" }]}
								style={{ marginBottom: 15 }}
							>
								<Select
									placeholder="Select Roles"
									style={{ width: "100%", fontSize: "16px" }}
								>
									{responseData.map((role) => (
										<Select.Option key={role._id} value={role._id}>
											{role.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>

					<Form.Item style={{ marginBottom: 0 }}>
						<div style={{ textAlign: "right" }}>
							<Button
								style={{
									marginRight: 8,
									borderRadius: "6px",
									fontSize: "16px",
								}}
								onClick={handleCancelClick}
								size="small"
							>
								Cancel
							</Button>
							<Button
								size="small"
								style={{ borderRadius: "6px", fontSize: "16px" }}
								type="primary"
								htmlType="submit"
							>
								Save
							</Button>
						</div>
					</Form.Item>
				</Form>
			</Layout>
		</Modal>
	);
};

export default AddUserModal;
