/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import {
	Modal,
	Button,
	List,
	Avatar,
	message,
	Form,
	Select,
	Tabs,
	AutoComplete,
	Input,
} from "antd";
import { CloseCircleOutlined, UserOutlined } from "@ant-design/icons";
import "./ShareWorkspace.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import usePlatformStore from "../stores/platformStore";
import { useRequest } from "ahooks";
import { getallRoles } from "../api/RolesAPI";

const ShareWorkSpaceModal = ({ visible, onCancel, id }: any) => {
	// console.log("id", id);
	const { TabPane } = Tabs;
	const [form] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [SharedMembersData, setSharedMembersData] = useState<any[]>([]);
	// const [inputValue, setInputValue] = useState<string>("");
	const [individualWords, setIndividualWords] = useState<string[]>([]);
	const ServerLink = process.env.REACT_APP_API_URL;
	const [responseData, setResponseData] = useState<any[]>([]);
	const [responseDataRole, setResponseDataRole] = useState<any[]>([]);
	const [roleDataById, setRoleDataById] = useState<any>({});
	const [permissionData, setPermissionData] = useState<any[]>([]);
	const [activeTab, setActiveTab] = useState<string>("1");
	const [selecteduserid, setselecteduserid] = useState<string>("");
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const [existingUsers, setExistingUsers] = useState<string[]>([]);
	const [existingRoles, setExistingRoles] = useState<string[]>([]);

	const [defaultRoleId, setDefaultRoleId] = useState<string | null>(null);

	const { data: roleData, run: fetchRolesData } = useRequest(
		() => getallRoles(userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess() {
				// message.success("Role fetched successfully");
			},
			onError() {
				message.error("Error fetching roles data");
			},
		}
	);

	useEffect(() => {
		if (roleData) {
			setUnauthorizedModel(false);
			const uniqueData = roleData.filter(
				(value: any) => value.name.toLowerCase() !== "owner"
			);
			setResponseDataRole(uniqueData);
			const guestRole = uniqueData.find(
				(value: any) => value.name.toLowerCase() === "guest"
			);
			if (guestRole) {
				setDefaultRoleId(guestRole._id);
			}
		}
	}, [roleData]);

	useEffect(() => {
		if (userProfileStoreInstance.idToken) {
			fetchRolesData();
		}
	}, []);

	// console.log(roleDataById);

	const handleTabChange = (key: string) => {
		setActiveTab(key);
	};

	// useEffect(() => {
	const fetchUserData = async () => {
		if (userProfileStoreInstance.idToken) {
			try {
				const response = await fetch(`${ServerLink}/user-profiles`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				if (response.ok) {
					const data = await response.json();

					// Remove duplicates based on the 'name' property
					const uniqueData = data.filter(
						(value: any, index: any, self: any) =>
							index ===
							self.findIndex(
								(v: any) => v.name.toLowerCase() === value.name.toLowerCase()
							)
					);

					setResponseData(uniqueData);
				} else if (response.status === 401) {
					// Unauthorized response, set unauthorizedModel to true
					setUnauthorizedModel(true);
				} else {
					console.error("Error fetching user profiles data");
				}
			} catch (error) {
				console.error("Error fetching user profiles data:", error);
			}
		}
	};
	useEffect(() => {
		fetchUserData();
	}, []);

	const fetchSharedMembersData = async () => {
		try {
			if (id) {
				const response = await fetch(
					`${ServerLink}/workspace-permissions/workspace/?work-space-id=${id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				const data = await response.json();
				setSharedMembersData(data);

				// Extract existing users and roles
				const existingUsers = data.map(
					(item: any) => item.userProfileId?._id || ""
				);
				const existingRoles = data.map((item: any) => item.roles?._id || "");
				setExistingUsers(existingUsers);
				setExistingRoles(existingRoles);
			}
		} catch (error) {
			console.error("Error fetching members", error);
		}
	};

	const [inputValue, setInputValue] = useState<string>("");

	useEffect(() => {
		if (visible) {
			fetchUserData();
			fetchSharedMembersData();
		}
	}, [visible]);

	const handleInputChange = (value: string) => {
		setInputValue(value);
		setUserSelected(false); // Reset userSelected when the user types
	};

	const handleSelectUser = (value: string) => {
		// console.log("checkid", value);
		const name = value.split(",")[0].trim(); // Trim to remove leading/trailing whitespaces
		const email = value.split(",")[1].trim();
		// console.log("name", name, "email", email);
		// console.log("reponseData", responseData);

		const user = responseData.find((user) => user.email.trim() === email);

		// console.log("user", user);

		if (user) {
			setInputValue(value);
			setUserSelected(true);
			// console.log("User ID:", user._id);
			setselecteduserid(user._id);
		} else {
			// console.log("User not found in responseData");
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			await fetchSharedMembersData();
		};
		fetchData();
	}, [id]);

	// console.log(individualWords);

	const [userSelected, setUserSelected] = useState<boolean>(false);

	// const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
	//   const typedName = e.target.value;
	//   // You can add any additional logic here based on the typedName
	//   // For simplicity, let's consider the user is selected if the typedName is not empty
	//   setUserSelected(!!typedName);
	// };

	const handleOk = async (values: any) => {
		let permissionDetails;
		if (values.name && values.role) {
			const fetchRolesDataById = async () => {
				try {
					if (values.role) {
						const response = await fetch(`${ServerLink}/roles/${values.role}`, {
							headers: {
								Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
							},
						});
						const data = await response.json();
						setRoleDataById(data);
						// console.log("Role data fetched:", data);
						permissionDetails =
							data.permissions?.map(({ field, permissions }: any) => ({
								field,
								permissions,
							})) || [];
						setPermissionData(permissionDetails);
					}
				} catch (error) {
					console.error("Error fetching members", error);
				}
			};

			await fetchRolesDataById();

			const permission = permissionDetails;
			const roles = values.role;
			const CreatedBy = "Kasinath";
			const updatedBy = "Kasinath";
			// console.log("inputvalue", inputValue);
			const words = inputValue.split(",").map((word: any) => word.trim());
			setIndividualWords(words);
			setInputValue("");
			const inviteEndpoint = `${ServerLink}/workspace-permissions`;

			try {
				const userData = {
					workspaceId: id,
					userProfileId: selecteduserid,
					permission,
					roles,
					CreatedBy,
					updatedBy,
				};

				// console.log("userdata", userData);
				await fetch(inviteEndpoint, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
					body: JSON.stringify(userData),
				})
					.then((json) => json.json())
					.then((data) => {
						if (data) {
							form.resetFields();
						}
					})
					.catch((error) => console.error(error));

				//  else {
				// 	await fetch("/api/sendMail", {
				// 		method: "POST",
				// 		headers: {
				// 			"Content-Type": "application/json",
				// 			Authorization: `Bearer ${authStore.idToken}`,
				// 		},
				// 		body: JSON.stringify({ email: word }),
				// 	});
				// }

				// await Promise.all(promises);

				await fetchSharedMembersData();
				setUserSelected(false);
				message.success("Workspace shared to member");
			} catch (error) {
				console.error("Error sending invitations", error);
				message.error("Error sending invitations");
			}
		} else if (values.Inviteuser) {
			fetch(`${process.env.REACT_APP_API_URL}/invite-user`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
				body: JSON.stringify({
					email: values.Inviteuser,
					workspaceId: id,
					status: "PENDING",
					accountId: userProfileStoreInstance.profile.accountId[0],
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					if (data) {
						// console.log("Data", data);
						if (data.error) {
							message.error(data.error);
						} else {
							message.success("Invitation sent to Email");
							form.resetFields();
						}
					}
				})
				.catch((error: any) => {
					console.error("error", error);
					message.error(`Error: ${error.message.data}`);
				});
		}
	};

	// console.log("responseData", responseData);

	return (
		<Form layout="vertical" form={form} onFinish={handleOk}>
			<Modal
				className="modal-share-workspace"
				data-testId="modal-share-workspace"
				closeIcon={<CloseCircleOutlined style={{ fontSize: "24px" }} />}
				open={visible}
				okText="Send"
				onCancel={onCancel}
				width="50%"
				footer={[
					<Button
						className="button-copy-link"
						key="copy"
						onClick={onCancel}
						data-testId="copy-link"
					>
						Cancel
					</Button>,
					<Button
						data-testId="send-invite-btn-test"
						className="button-send-invite"
						key="send"
						type="primary"
						onClick={() => form.submit()}
					>
						{activeTab === "1" ? "Add To Team" : "Send Invite"}
					</Button>,
				]}
			>
				<div
					style={{ height: "68vh", display: "flex", flexDirection: "column" }}
				>
					<Tabs activeKey={activeTab} onChange={handleTabChange}>
						<TabPane tab="Add Member" key="1">
							<div>
								<h2>Shared Workspace</h2>
								<Form.Item name="name">
									<AutoComplete
										className="input-add-team-members"
										placeholder="Type UserName"
										value={inputValue}
										onChange={handleInputChange}
										onSelect={handleSelectUser}
										options={responseData
											.filter(
												(user) =>
													!existingUsers.includes(user._id) &&
													user.name
														.toLowerCase()
														.includes(inputValue.toLowerCase())
											)
											.map((user) => ({
												value: `${user.name} , ${user.email}`,
											}))}
										filterOption={(inputValue, option) =>
											option!.value
												.toUpperCase()
												.includes(inputValue.toUpperCase())
										}
									/>
								</Form.Item>

								{/* Check if the user has selected a user */}
								{userSelected && (
									<div>
										{/* <Form.Item name="user">
          <Select
            className="input-add-team-members"
            placeholder="Select User"
          >
            {responseData
             
              .map((responseData) => (
                <Select.Option
                  key={responseData._id}
                  value={responseData._id}
                >
                  {responseData.name} - {responseData.email}
                </Select.Option>
              ))}
          </Select>
        </Form.Item> */}

										{/* Show the role field once the user is selected */}
										<Form.Item name="role" initialValue={defaultRoleId}>
											<Select
												allowClear={false}
												className="input-add-team-members"
												placeholder="Select Role"
												defaultValue={defaultRoleId}
											>
												{responseDataRole
													// .filter((role) => !existingRoles.includes(role._id))
													.map((responseDataRole) => (
														<Select.Option
															key={responseDataRole._id}
															value={responseDataRole._id}
														>
															{responseDataRole.name}
														</Select.Option>
													))}
											</Select>
										</Form.Item>
									</div>
								)}

								<List
									style={{
										flexGrow: 1,
										overflowY: "auto",
										maxHeight: "40vh",
										marginTop: "16px",
									}}
									// className="list-view-members"
									dataSource={SharedMembersData}
									renderItem={(item: any) => (
										<List.Item data-testid="list-item">
											<List.Item.Meta
												avatar={<Avatar icon={<UserOutlined />} />}
												title={
													<div className="custom-title-style">
														{item.userProfileId && item.userProfileId.name
															? item.userProfileId.name
															: ""}
													</div>
												}
											/>
											<div>{item.roles.name}</div>
										</List.Item>
									)}
								/>
							</div>
						</TabPane>

						<TabPane tab="Invite user" key="2">
							<div>
								<h2>Invite User</h2>
								{/* <AutoComplete
          data-testId="input-test"
          options={responseData.map((user) => ({ value: user.name }))}
          placeholder="Email, comma separated"
          value={inputValue}
          onChange={handleInputChange}
          filterOption={filterOption}
        /> */}
								<Form.Item name="Inviteuser">
									<Input
										className="input-add-team-members"
										placeholder="write email..."
										style={{ fontSize: "0.9rem" }}
										type="email"
									></Input>
								</Form.Item>
								{/* <Form.Item name="role">
						<Select
							allowClear={false}
							className="input-add-team-members"
							placeholder="Select Role"
						>
							{responseDataRole.length > 0 &&
								responseDataRole.map((responseDataRole) => (
									<Select.Option
										key={responseDataRole._id}
										value={responseDataRole._id}
									>
										{responseDataRole.name}
									</Select.Option>
								))}
						</Select>
					</Form.Item> */}
								{/* <List
						className="list-view-members"
						dataSource={SharedMembersData}
						renderItem={(item: any) => (
							<List.Item data-testid="list-item">
								<List.Item.Meta
									avatar={<Avatar icon={<UserOutlined />} />}
									title={
										<div className="custom-title-style">
											{item.userProfileId.name}
										</div>
									}
								/>
								{/* <div>{item.roles.name}</div> */}
								{/* </List.Item>
						)}
					/> */}
							</div>
						</TabPane>
					</Tabs>
				</div>
			</Modal>
		</Form>
	);
};

export default ShareWorkSpaceModal;
