import React, { useEffect, useState } from "react";

import {
	Button,
	//Button,
	Card,
	Col,
	ConfigProvider,
	Dropdown,
	Flex,
	MenuProps,
	//Dropdown,
	//Flex,
	//List,
	//Menu,
	Row,
	Select,
	Spin,
	message,
} from "antd";
//import { WindowsOutlined } from "@ant-design/icons";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";

import { planerViewStore } from "../stores/PlannerStore";
import ListViewItem from "./HomePageTasksListView";
import useWorkspaceStore from "../stores/WorkspaceState";
import { useNavigate } from "react-router-dom";
import "./HomePage.css";
// import { motion } from "framer-motion";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import DashboardModel from "./DashboardModal";
import DynamicContent from "./Reports/DynamicContent";
import DashboardShareModal from "./DashboardShareModal";
import { useRequest } from "ahooks";
import { fetchWorkitemByType, getWorkItemSForMYTask } from "../api/WorkItemAPI";
import {
	HighlightOutlined,
	BugFilled,
	FileDoneOutlined,
	BuildFilled,
} from "@ant-design/icons";
import {
	getDashboardById,
	getDashboardLists,
	updatedbyid,
} from "../api/DashboardApi";
import usePageStore from "../stores/PageStore";
import BreadCrumb from "../pages/Editors/BreadCrumb";
//import { data } from '@antv/x6-common/lib/dom/data';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

interface DropdownOption {
	title: string;
	id: string;
}
interface ReportData {
	_id: string;
	widgets?: Widget[];
}

interface Widget {
	id: string;
	title: string;
	description: string;
	type: string;
	component: string;
	size: "small" | "medium" | "large";
	createdAt: string;
}
const HomePage = () => {
	const navigate = useNavigate();
	// const menu = (
	// 	<Menu>
	// 		<Menu.Item>
	// 			<a rel="noopener noreferrer" href="/fd/idea">
	// 				New Product Idea
	// 			</a>
	// 		</Menu.Item>
	// 		<Menu.Item>
	// 			<a rel="noopener noreferrer" href="/fd/idea">
	// 				New Feature
	// 			</a>
	// 		</Menu.Item>
	// 	</Menu>
	// );

	const [isLoading, setIsLoading] = useState(true);
	const { sortBy } = planerViewStore();
	const [data1, setData] = useState<any[]>([]);
	const [user, setUser] = useState<any[]>([]);
	const [todos, setTodos] = useState<any[]>([]);
	const [tasks, setTasks] = useState<any[]>([]);
	const [planData, setPlanData] = useState<any[]>([]);
	const [openModal, setOpenModal] = useState(false);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [workItemData, setWorkItemData] = useState<any[]>([]);
	const [workItemData1, setWorkItemData1] = useState<any[]>([]);
	const { input, filter, FetchPlan } = planerViewStore();
	const [selectedOption, setSelectedOption] = useState<string | null>(null);
	const [reportData, setReportData] = useState<ReportData>({
		_id: "",
		widgets: [],
	});
	const [isShareModalVisible, setIsShareModalVisible] = useState(false);

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
		breadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
		breadcrumbConfig: state.breadcrumbConfig,
	}));

	useEffect(() => {
		setPageSlug("home");
		setPageTitle("Home");
		setPageDescription("");
		setItemType("");
		setItemId("");
		setIsList(false);
		setItemDetails({});
		setBreadcrumbConfig({
			page: `/space/${currentWorkspace?._id}`,
			config: [
				{
					label: "Home",
					url: `/space/${currentWorkspace?._id}`,
				},
			],
		});
	}, []);

	const toggleShareModal = () => {
		setIsShareModalVisible(!isShareModalVisible);
	};

	const handleShareDashboard = () => {
		toggleShareModal();
	};

	const toggleModal = () => {
		setOpenModal(!openModal);
	};

	const handleNewDashboard = () => {
		toggleModal();
	};

	console.log("reportData", reportData);
	console.log(todos, "todos");
	console.log(user, "user");

	const { data: workitemUserData, run: fetchUserStory } = useRequest(
		(worspaceId, type) =>
			fetchWorkitemByType(worspaceId, type, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "User Data");
				// message.success(`user story fetched successfully): ${result.length}`);
			},
			onError: (error) => {
				message.error(`Failed to fetch user story : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (workitemUserData) {
			setUser(workitemUserData);
			const data = workitemUserData.map(({ type, WorkitemId }) => ({
				type,
				typeId: WorkitemId,
			}));
			setData(data);
		}
	}, [workitemUserData]);

	useEffect(() => {
		fetchUserStory(currentWorkspace?._id, "USER_STORY");
	}, [open, FetchPlan]);

	const { data: workitemBugData, run: fetchBugStory } = useRequest(
		(worspaceId, type) =>
			fetchWorkitemByType(worspaceId, type, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "Bug data");
				// message.success(`All Bugs fetched successfully): ${result.length}`);
			},
			onError: (error) => {
				message.error(`Failed to fetch Bugs : ${error}`);
			},
		}
	);

	useEffect(() => {
		if (workitemBugData) {
			setTodos(workitemBugData);
			const data = workitemBugData.map(({ type, WorkitemId }) => ({
				type,
				typeId: WorkitemId,
			}));
			setWorkItemData1(data);
		}
	}, [workitemBugData]);

	useEffect(() => {
		fetchBugStory(currentWorkspace?._id, "BUG");
	}, [open, FetchPlan]);

	const { data: workitemTaskData, run: fetchTaskStory } = useRequest(
		(worspaceId, type, assigned) =>
			getWorkItemSForMYTask(
				worspaceId,
				type,
				assigned,
				userProfileStoreInstance.idToken
			),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "Task data");
				// message.success(`All Tasks fetched successfully: ${result.length}`);
			},
			onError: (error) => {
				message.error(`Failed to fetch Tasks : ${error.message}`);
				// throw new Error(`Fetching tasks failed: ${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (workitemTaskData) {
			setTasks(workitemTaskData);
			const data = workitemTaskData.map(
				({ title, _id, type, WorkitemId, status }: any) => ({
					name: title,
					id: _id,
					type: type,
					typeId: WorkitemId,
					status: status,
				})
			);
			setWorkItemData(data);
		}
	}, [workitemTaskData]);

	useEffect(() => {
		fetchTaskStory(currentWorkspace?._id, "TASK", "MyTasks");
	}, [FetchPlan]);

	const sortedTasks = [...tasks].sort((a, b) => {
		if (sortBy === "Created date (Asc)") {
			return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
		} else if (sortBy === "Created date (Desc)") {
			return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
		} else {
			return 0;
		}
	});

	const filteredDataSource = (data: any[]) => {
		return data?.filter((item) => {
			let matchesFilter = true;
			const inputLower = input.replace(/\s/g, "").toLowerCase();
			const typeFilters = filter
				.filter((filterItem) => filterItem.startsWith("Type"))
				.map((filterItem) => {
					const typeSplit = filterItem.split("_");

					const rest = typeSplit.join("_");
					return rest.toLowerCase();
				});
			const statusFilters = filter
				.filter((filterItem) => filterItem.startsWith("Status"))
				.map((filterItem) => filterItem.split("_")[1].toLowerCase());
			const priorityFilters = filter
				.filter((filterItem) => filterItem.startsWith("Priority"))
				.map((filterItem) => filterItem.split("_")[1].toLowerCase());

			matchesFilter =
				matchesFilter &&
				(item.title.replace(/\s/g, "").toLowerCase().includes(inputLower) ||
					item.description
						.replace(/\s/g, "")
						.toLowerCase()
						.includes(inputLower));

			if (typeFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					typeFilters.some(
						(filterValue) => item?.type?.toLowerCase() === filterValue
					);
			}

			if (statusFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					statusFilters.some(
						(filterValue) => item?.status?.toLowerCase() === filterValue
					);
			}

			if (priorityFilters.length > 0) {
				matchesFilter =
					matchesFilter &&
					priorityFilters.some(
						(filterValue) => item?.priority?.toLowerCase() === filterValue
					);
			}

			return matchesFilter;
		});
	};
	//const [updates, setUpdates] = React.useState<any[]>([]);
	// const [displayedUpdates, setDisplayedUpdates] = useState<any[]>([]);
	// const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState(false);
	// const [itemsToShow, setItemsToShow] = useState<number>(6);

	// const loadMoreItems = () => {
	// 	setItemsToShow(itemsToShow + 4);

	// 	// Disable the button when the full list is rendered
	// 	if (itemsToShow + 4 >= updates.length) {
	// 		setIsLoadMoreDisabled(true);
	// 	}
	// };
	// useEffect(() => {
	// 	// Update the displayed updates based on the current items to show
	// 	setDisplayedUpdates(updates.slice(0, itemsToShow));
	// }, [updates, itemsToShow]);

	// const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
	// 	const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

	// 	// Load more items when the user scrolls to the bottom
	// 	if (scrollTop + clientHeight === scrollHeight) {
	// 		loadMoreItems();
	// 	}
	// };

	// const fetchUpdates = async () => {
	// 	try {
	// 		const response = await fetch(
	// 			`${process.env.REACT_APP_API_URL}/activelogs`,
	// 			{
	// 				method: "GET",
	// 				headers: {
	// 					"Content-Type": "application/json; charset=UTF-8",
	// 					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
	// 				},
	// 			}
	// 		);

	// 		if (response.ok) {
	// 			setUpdates([]);
	// 			const todosData = await response.json();

	// 			const filteredData = todosData
	// 				.filter((res: any) => res.content && res.content.length > 0)
	// 				.map((res: any) => {
	// 					const extractedData = res.content.flatMap((item: any) => {
	// 						if (typeof item === "object") {
	// 							const keys = Object.keys(item);
	// 							return keys.map((key) => ({
	// 								key,
	// 								data: item[key],
	// 							}));
	// 						}
	// 						return [];
	// 					});

	// 					return {
	// 						content: extractedData, // Assigning the extracted data back to the content property
	// 					};
	// 				});

	// 			const extractDataArray = (extractedData: any) => {
	// 				return extractedData.flatMap((data: any) => {
	// 					return data.content.map((item: any) => {
	// 						return {
	// 							key: item.key,
	// 							date: item.date,
	// 							createdBy: item.createdBy,
	// 							data: item.data,
	// 						};
	// 					});
	// 				});
	// 			};

	// 			const dataArray = extractDataArray(filteredData);
	// 			setUpdates(dataArray);
	// 		} else {
	// 			console.error("Failed to fetch todos");
	// 		}
	// 	} catch (error) {
	// 		console.error("Error fetching todos:", error);
	// 	}
	// };

	// useEffect(() => {
	// 	fetchUpdates();
	// }, []);

	// function extractValueAfterSlash(inputString: string) {
	// 	// Find the index of the slash
	// 	const slashIndex = inputString.indexOf("/");

	// 	// Extract the substring after the slash
	// 	if (slashIndex !== -1) {
	// 		// Check if slash is found
	// 		const valueAfterSlash = inputString.substring(slashIndex + 1);
	// 		return valueAfterSlash;
	// 	} else {
	// 		return inputString; // Return null if slash is not found
	// 	}
	// }

	useEffect(() => {
		const setLoadingFalse = () => setIsLoading(false);

		// API calls wrapped in Promise.all to wait for all of them to finish
		Promise.all([
			fetchUserStory(currentWorkspace?._id, "USER_STORY"),
			fetchBugStory(currentWorkspace?._id, "BUG()"),
			fetchTaskStory(currentWorkspace?._id, "TASK", "MyTasks"),
		])
			.then(() => {
				setLoadingFalse();
			})

			.catch((error) => {
				console.error("Error fetching data:", error);
				setLoadingFalse();
			});
	}, [currentWorkspace]);

	const { data: dashboardListData, run: fetchDashboardList } = useRequest(
		() => getDashboardLists(userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				// message.success("Dashboard list data fetched successfully");
				console.log(data);
			},
			onError: (error) => {
				console.log(error);
				message.error(`error:${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (dashboardListData) {
			const filteredData = dashboardListData?.filter(
				(item: { title: any; _id: any; isDelete?: boolean }) => !item.isDelete
			);
			setDropdownData(
				filteredData.map((item: { title: any; _id: any }) => ({
					title: item.title,
					id: item._id,
				}))
			);
		}
	}, [dashboardListData]);

	useEffect(() => {
		fetchDashboardList();
	}, []);

	const [dropdownData, setDropdownData] = useState<DropdownOption[]>([]);

	const [defaultDashboardId, setDefaultDashboardId] = useState<string | null>(
		null
	);
	useEffect(() => {
		const fetchUserProfile = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileStoreInstance.profile._id}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);

				if (response.ok) {
					const userData = await response.json();
					const defaultDashboardId = userData.preferences?.defaultDashboardId;
					setDefaultDashboardId(defaultDashboardId);
				} else {
					console.error("Failed to fetch user profile data");
				}
			} catch (error) {
				console.error("Error fetching user profile data:", error);
			}
		};

		fetchUserProfile();
	}, [userProfileStoreInstance.profile._id, userProfileStoreInstance.idToken]);

	useEffect(() => {
		if (defaultDashboardId && dropdownData.length > 0) {
			const defaultDashboardTitle = dropdownData.find(
				(option) => option.id === defaultDashboardId
			)?.title;
			setSelectedOption(defaultDashboardTitle || null);
			setIsLoading(false);
		} else {
			setSelectedOption(null);
			setIsLoading(false);
		}
	}, [defaultDashboardId, dropdownData]);

	const handleDashboardDropdownChange = (value: string) => {
		setSelectedOption(value);
	};

	const { data: dashBoarddata, run: fetchDashboard } = useRequest(
		(id) => getDashboardById(id, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				// message.success("Dashboard data fetched successfully");
				console.log(data);
			},
			onError: (error) => {
				console.log(error);
				message.error(`error:${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (dashBoarddata) {
			setReportData(dashBoarddata);
		}
	}, [dashBoarddata]);

	useEffect(() => {
		const selectedOptionId = dropdownData.find(
			(option) => option.title === selectedOption
		)?.id;
		fetchDashboard(selectedOptionId);
	}, [selectedOption, userProfileStoreInstance.idToken]);

	const { data: updatedData, run: updateDashboardbyId } = useRequest(
		(id, body) => updatedbyid(id, body, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				console.log(data);
				message.success("Dashboard deleated successfully");
			},
			onError: (error) => {
				console.log(error);
				message.error(`Error:${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (updatedData) {
			const selectedOptionId = dropdownData.find(
				(option) => option.title === selectedOption
			)?.id;

			setDropdownData((prevData) =>
				prevData.map((item) =>
					item.id === selectedOptionId ? { ...item, isDelete: true } : item
				)
			);
			fetchDashboardList();
		}
	}, [updatedData]);
	const handleDelete = async () => {
		if (selectedOption) {
			try {
				const selectedOptionId = dropdownData.find(
					(option) => option.title === selectedOption
				)?.id;
				updateDashboardbyId(selectedOptionId, {
					isDelete: true,
				});
			} catch (error) {
				console.error("Error deleting:", error);
			}
		}
	};

	console.log("reportData._id", reportData._id);

	const items: MenuProps["items"] = [
		{
			key: "1",
			label: (
				<a
					onClick={() =>
						navigate(
							`/space/${currentWorkspace._id}/dashboard/${reportData._id}`
						)
					}
				>
					Edit Dashboard
				</a>
			),
		},
		{
			key: "2",
			label: <a onClick={handleNewDashboard}>New Dashboard</a>,
		},
		{
			key: "3",
			label: <a onClick={handleShareDashboard}>Share Dashboard</a>,
		},
		{
			key: "4",
			label: <a onClick={handleDelete}>Delete Dashboard</a>,
		},
	];

	const includesAssigned = (widget: Widget) =>
		widget?.component?.toLowerCase().includes("assigned");

	const getColSpan = (size: "small" | "medium" | "large") => {
		switch (size) {
			case "small":
				return 8;
			case "medium":
				return 16;
			case "large":
				return 24;
			default:
				return 8;
		}
	};

	const fetchPlans = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/plan/${currentWorkspace?._id}?type=All`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				}
			);

			if (response.ok) {
				const todosData = await response.json();
				setPlanData(todosData);
			} else {
				console.error("Failed to fetch todos");
			}
		} catch (error) {
			console.error("Error fetching todos:", error);
		}
	};

	useEffect(() => {
		fetchPlans();
	}, [open, FetchPlan]);

	return (
		<div className="home-page-container" style={{ width: "100%" }}>
			{isLoading ? (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "100vh",
					}}
				>
					<Spin size="large" />
					<img
						style={{ marginTop: "10px", width: "150px" }}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
						alt="Loading"
					/>
					<Spin size="large" />
				</div>
			) : (
				<>
					<Flex className="padding_dash" justify="space-between" align="center">
						<BreadCrumb config={breadcrumbConfig} />
						<Flex
							className="padding_dash"
							justify="end"
							style={{ alignItems: "center" }}
						>
							<Select
								value={selectedOption}
								onChange={handleDashboardDropdownChange}
								style={{
									marginRight: "0.5rem",
									marginTop: "0.8vh",
									width: "180px",
									height: "25px",
								}}
								placeholder="Select Dashboard"
							>
								{dropdownData.map((option, index) => (
									<Select.Option key={index} value={option.title}>
										{option.title}
									</Select.Option>
								))}
							</Select>

							<ConfigProvider
								theme={{
									components: {
										Dropdown: {
											borderRadiusLG: 9,
										},
									},
								}}
							>
								<Dropdown menu={{ items }} placement="bottomRight" arrow>
									<Button
										size="small"
										style={{
											marginTop: "0.8vh",
											marginRight: 0,
											borderRadius: "6px",
										}}
									>
										<div>Action</div>
									</Button>
								</Dropdown>
							</ConfigProvider>
							<DashboardModel visible={openModal} onCancel={toggleModal} />
							<DashboardShareModal
								visible={isShareModalVisible}
								onCancel={toggleShareModal}
								selectedOptionId={reportData._id}
							/>
						</Flex>
					</Flex>
					<div className="content-container">
						{selectedOption ? (
							<Row gutter={20} justify="start" align="top" wrap={true}>
								{reportData.widgets &&
									reportData.widgets
										.filter((widget) => includesAssigned(widget))
										.map((widget) => (
											<Col key={widget.id} span={getColSpan(widget.size)}>
												<Card
													title={widget.title}
													bordered={false}
													style={{ marginBottom: "20px" }}
												>
													<DynamicContent componentName={widget.component} />
												</Card>
											</Col>
										))}
							</Row>
						) : (
							<div>
								<Row
									className="row"
									gutter={20}
									justify="start"
									align="top"
									wrap={true}
								>
									<Col
										xs={{ span: 24 }}
										sm={{ span: 12 }}
										md={{ span: 6 }}
										lg={{ span: 6 }}
									>
										<Card
											className="card-content"
											bordered={false}
											style={{
												padding: "30px 0",
												boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
											}}
										>
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													padding: "0 5px",
												}}
											>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														marginRight: "20px",
													}}
												>
													<HighlightOutlined
														style={{
															fontSize: "2rem",
															color: "#2389FF",
															marginRight: "6px",
														}}
													/>
													<p
														style={{
															fontSize: "35px",
															fontWeight: "bold",
															color: "black",
															margin: 0,
														}}
													>
														{
															data1.filter((item) => item.type === "USER_STORY")
																.length
														}
													</p>
												</div>
												<p
													style={{
														fontSize: "28px",
														margin: 0,
														fontWeight: "bold",
													}}
												>
													User Stories
												</p>
											</div>
										</Card>
									</Col>
									<Col
										xs={{ span: 24 }}
										sm={{ span: 12 }}
										md={{ span: 6 }}
										lg={{ span: 6 }}
									>
										<Card
											className="card-content"
											bordered={false}
											style={{
												padding: "30px 0",
												boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
											}}
										>
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													padding: "0 5px",
												}}
											>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														marginRight: "20px",
													}}
												>
													<BugFilled
														style={{
															fontSize: "2rem",
															color: "red",
															marginRight: "6px",
														}}
													/>
													<p
														style={{
															fontSize: "35px",
															fontWeight: "bold",
															color: "black",
															margin: 0,
														}}
													>
														{
															workItemData1.filter(
																(item) => item.type === "BUG"
															).length
														}
													</p>
												</div>
												<p
													style={{
														fontSize: "28px",
														margin: 0,
														fontWeight: "bold",
													}}
												>
													Bugs
												</p>
											</div>
										</Card>
									</Col>
									<Col
										xs={{ span: 24 }}
										sm={{ span: 12 }}
										md={{ span: 6 }}
										lg={{ span: 6 }}
									>
										<Card
											className="card-content"
											bordered={false}
											style={{
												padding: "30px 0",
												boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
											}}
										>
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													padding: "0 5px",
												}}
											>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														marginRight: "20px",
													}}
												>
													<FileDoneOutlined
														style={{
															fontSize: "2rem",
															color: "#2CF651",
															marginRight: "6px",
														}}
													/>
													<p
														style={{
															fontSize: "35px",
															fontWeight: "bold",
															color: "black",
															margin: 0,
														}}
													>
														{
															workItemData.filter(
																(item) => item.type === "TASK"
															).length
														}
													</p>
												</div>
												<p
													style={{
														fontSize: "28px",
														margin: 0,
														fontWeight: "bold",
													}}
												>
													Tasks
												</p>
											</div>
										</Card>
									</Col>
									<Col
										xs={{ span: 24 }}
										sm={{ span: 12 }}
										md={{ span: 6 }}
										lg={{ span: 6 }}
									>
										<Card
											className="card-content"
											bordered={false}
											style={{
												padding: "30px 0",
												boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
											}}
										>
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													padding: "0 5px",
												}}
											>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														marginRight: "20px",
													}}
												>
													<BuildFilled
														style={{ fontSize: "2rem", marginRight: "6px" }}
													/>
													<p
														style={{
															fontSize: "35px",
															fontWeight: "bold",
															color: "black",
															margin: 0,
														}}
													>
														{
															planData.filter((item) => item.type != null)
																.length
														}
													</p>
												</div>
												<p
													style={{
														fontSize: "28px",
														margin: 0,
														fontWeight: "bold",
													}}
												>
													Plans
												</p>
											</div>
										</Card>
									</Col>
								</Row>
							</div>
						)}
						{/* <Divider /> */}
						{selectedOption ? (
							<Row gutter={20} justify="start" align="top" wrap={true}>
								{reportData.widgets &&
									reportData.widgets
										.filter((widget) => !includesAssigned(widget))
										.map((widget) => (
											<Col key={widget.id} span={getColSpan(widget.size)}>
												<Card
													title={widget.title}
													bordered={false}
													style={{ marginBottom: "20px" }}
												>
													<DynamicContent componentName={widget.component} />
												</Card>
											</Col>
										))}
							</Row>
						) : (
							<div style={{ marginTop: "-2vh" }}>
								<Row gutter={20} justify="start" align="top" wrap={true}>
									<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
										<Card
											title={
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
														width: "100%",
													}}
												>
													<span
														style={{
															marginTop: "4px",
															marginRight: "15px",
															fontFamily: "Poppins",
															fontWeight: "bold",
															fontSize: "1.2rem",
														}}
													>
														Task by Assignee{" "}
													</span>
												</div>
											}
											bordered={false}
											style={{
												marginBottom: "20px",
												boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
											}}
										>
											<div
												style={{
													width: "100%",
													maxHeight: "40vh",
													overflowY: "auto",
												}}
											>
												<BarChart />
											</div>
										</Card>
									</Col>
									<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
										<Card
											title={
												<div
													style={{
														fontFamily: "Poppins",
														fontWeight: "bold",
														fontSize: "1.2rem",
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
													}}
												>
													Task by Status
												</div>
											}
											bordered={false}
											style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
										>
											<div
												style={{
													width: "100%",
													maxHeight: "100%",
													// overflowY: "scroll",
													// overflowX: "scroll",
													whiteSpace: "nowrap",
													// scrollbarWidth: "thin",
												}}
											>
												<PieChart />
											</div>
										</Card>
									</Col>
								</Row>
								<Row gutter={20} justify="start" align="top" wrap={true}>
									<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
										<Card
											title={
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
														width: "100%",
													}}
												>
													<span
														style={{
															marginTop: "4px",
															marginRight: "15px",
															fontFamily: "Poppins",
															fontWeight: "bold",
															fontSize: "1.2rem",
														}}
													>
														My Tasks{" "}
													</span>
													{/* <Button
											type="primary"
											style={{
												fontFamily: "Poppins",
												backgroundColor: "#0444BF",
											}}
											onClick={() => navigate("/space/planner/mytasks")}
										>
											All Tasks
										</Button> */}
													{/* <motion.button
													type="button"
													style={{
														fontFamily: "Poppins",
														fontSize: "14px",
														backgroundColor: "#0444BF",
														borderRadius: "20px",
														padding: "8px",
														color: "white",
														borderColor: "white",
														//width: "10%",
														minWidth: "7vw",
													}}
													onClick={() => navigate("/space/planner/mytasks")}
													whileTap={{ scale: 0.8, backgroundColor: "#032c81" }}
												>
													All Tasks
												</motion.button> */}
												</div>
											}
											bordered={false}
											style={{ marginBottom: "20px" }}
										>
											<ListViewItem
												Data={filteredDataSource(sortedTasks)}
											></ListViewItem>
										</Card>
									</Col>
								</Row>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default HomePage;
