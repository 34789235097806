import { create } from "zustand";
import { persist } from "zustand/middleware";

export type PageStoreType = {
	pageSlug: string;
	setPageSlug: (value: string) => void;
	pageTitle: string;
	setPageTitle: (value: string) => void;
	pageDescription: string;
	setPageDescription: (value: string) => void;
	itemType: string;
	setItemType: (value: string) => void;
	itemId: string;
	setItemId: (value: string) => void;
	isList: boolean;
	setIsList: (value: boolean) => void;
	itemDetails: any;
	setItemDetails: (value: any) => void;
	breadcrumbConfig: any;
	setBreadcrumbConfig: (value: any) => void;

	clearStore: () => void;
};

const usePageStore = create(
	persist(
		(set) => ({
			pageSlug: "",
			setPageSlug: (value: string) => set({ pageSlug: value }),
			pageTitle: "",
			setPageTitle: (value: string) => set({ pageTitle: value }),
			pageDescription: "",
			setPageDescription: (value: string) => set({ pageDescription: value }),
			itemType: "",
			setItemType: (value: string) => set({ itemType: value }),
			itemId: "",
			setItemId: (value: string) => set({ itemId: value }),
			isList: false,
			setIsList: (value: boolean) => set({ isList: value }),
			itemDetails: {},
			setItemDetails: (value: any) => set({ itemDetails: value }),
			breadcrumbConfig: {},
			setBreadcrumbConfig: (value: any) => set({ breadcrumbConfig: value }),

			clearStore: () =>
				set({
					pageSlug: "",
					pageTitle: "",
					pageDescription: "",
					itemType: "",
					itemId: "",
					isList: false,
					itemDetails: {},
					breadcrumbConfig: {},
				}),
		}),
		{
			name: "page-store",
		}
	)
);

export default usePageStore;
