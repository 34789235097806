/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Block, BlockNoteEditor, PartialBlock } from "@blocknote/core";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import {
	Avatar,
	Button,
	//Button,
	Card,
	Divider,
	Flex,
	Input,
	InputRef,
	message,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { planerViewStore } from "../../../stores/PlannerStore";
import userProfileStore, {
	UserProfileStoreType,
} from "../../../stores/UserProfileStore";
// import "./BlockNote.css";
// import ActivityTab from "../Planner/ActivityTab";
// import ChatInterface from "../Planner/DiscussionTab";
import RightPannel from "./RightPannel";
import { useDebounceFn, useRequest } from "ahooks";
// import "./BreadCrumb.css";
import { useParams } from "react-router-dom";
import { addContent, getNotesbyId } from "../../../api/Notes";
import usePageStore from "../../../stores/PageStore";
import useWorkspaceStore from "../../../stores/WorkspaceState";
import BreadCrumb from "../../Editors/BreadCrumb";

async function saveToStorage(jsonBlocks: Block[]) {
	localStorage.setItem("editorContent", JSON.stringify(jsonBlocks));
	console.log("Data saved to localStorage:", jsonBlocks);
}

export default function NotesView() {
	const note_id = useParams().id as string;
	const [selectedItemDetails, setselectedItemDetails] = useState<any>({});
	const [selectedPage, setselectedPage] = useState<any>({});
	// const { selectedItemDetails } = planerViewStore();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [initialContent, setInitialContent] = useState<
		PartialBlock[] | undefined | "loading"
	>("loading");
	const [selectedTitle, setSelectedTitle] = useState<any>("");
	const [noteData, setNoteData] = useState<any>([]);
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [Focus, setFocus] = useState(false);
	const ref = useRef<any>(null);
	const HandleNewPage = () => {
		if (ref.current) {
			ref.current.focus();
		}
	};
	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
		breadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
		breadcrumbConfig: state.breadcrumbConfig,
	}));

	const { run } = useDebounceFn(
		() => {
			SaveTitle(selectedTitle).catch((error) => {
				console.error(error);
			});
		},
		{
			wait: 1000,
		}
	);

	const SaveTitle = async (title: any) => {
		const reqbody = {
			pageTitle: title,
		};

		const requestOptions = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(reqbody),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/notes/${note_id}/pages/${selectedPage._id}/content`,
			requestOptions
		);

		if (!response.ok) {
			throw new Error("Failed to save data to server");
		} else {
			const data = await response.json();
			const id = selectedPage._id;
			const filterdata = data.pages.filter((data: any) => data._id == id);
			console.log("filtereddata", filterdata);
			setselectedPage(filterdata[0]);
			setselectedItemDetails(data);
		}
	};

	const account = process.env.REACT_APP_FILE_UPLOAD_STORAGE_ACCOUNT;
	const sasToken = process.env.REACT_APP_FILE_UPLOAD_STORAGE_SAS_TOKEN;
	const containerName = process.env
		.REACT_APP_FILE_UPLOAD_STORAGE_CONTAINER as string;

	if (!account || !sasToken || !containerName) {
		throw new Error("Missing Azure Blob Storage configuration.");
	}

	async function uploadFile(file: File) {
		const blobServiceClient = new BlobServiceClient(
			`https://${account}.blob.core.windows.net/?${sasToken}`
		);
		const containerClient = blobServiceClient.getContainerClient(containerName);

		// Generate a unique blob name and upload the file
		const blobName = `${new Date().getTime()}-${file.name}`;
		const blobClient = containerClient.getBlockBlobClient(blobName);
		await blobClient.uploadData(file, {
			blobHTTPHeaders: { blobContentType: file.type },
		});

		// Get the URL of the uploaded file
		const fileUrl = `https://${account}.blob.core.windows.net/${containerName}/${blobName}`;

		return fileUrl;
	}

	const { data: notesDataById, run: fetchNoteById } = useRequest(
		(id) => getNotesbyId(id, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				// message.success("File uploaded successfully");

				console.log(data, "feature data");
				setNoteData(data);
			},

			onError: (error) => {
				console.log(error);
				message.error(`Error: ${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (notesDataById) {
			setselectedPage(notesDataById.pages[0]);
			setselectedItemDetails(notesDataById);
		}
	}, [notesDataById]);

	useEffect(() => {
		fetchNoteById(note_id);
	}, [note_id]);

	useEffect(() => {
		setPageSlug(`${notesDataById?.bookTitle}`);
		setPageTitle(`${notesDataById?.bookTitle}`);
		setPageDescription("");
		setItemType("");
		setItemId("");
		setIsList(false);
		setItemDetails({});
		setBreadcrumbConfig({
			page: `/space/${currentWorkspace?._id}/notes`,
			config: [
				{
					label: "Notes",
					url: `/space/${currentWorkspace?._id}/planner/notes`,
				},
				{
					label: `${notesDataById?.bookTitle}`,
					url: `/space/${currentWorkspace?._id}/planner/notes/${note_id}`,
					dynamicId: true,
				},
			],
		});
	}, [notesDataById]);
	useEffect(() => {
		const loadContent = async () => {
			// try {
			setSelectedTitle(selectedPage?.pageTitle || "");
			const baseContent: PartialBlock[] = [
				// {
				// 	type: "heading",
				// 	content: [selectedPage?.pageTitle || ""],
				// 	props: { level: 1 },
				// },
				// {
				// 	type: "paragraph",
				// 	content: [selectedItemDetails.description || ""],
				// },
			];

			if (selectedPage && selectedPage?.pageContent?.length > 0) {
				const mappedContent = selectedPage.pageContent.map((parent: any) => {
					return {
						type: parent.type || "numberedListItem",
						props: parent.props || {},
						content:
							parent.content && Array.isArray(parent.content)
								? parent.content.map((item: any) => {
										if (typeof item === "string") {
											return item;
										} else if ("text" in item) {
											return item.text;
										} else {
											return "";
										}
									})
								: [],
					} as PartialBlock;
				});

				setInitialContent([...baseContent, ...mappedContent]);
			} else {
				setInitialContent([
					...baseContent,
					{ type: "paragraph", content: [""] },
				]);
			}
			// } catch (error) {
			// 	console.error(error);
			// 	setInitialContent([]);
			// }
		};
		loadContent();
	}, [selectedPage]);

	async function saveToServer(jsonBlocks: Block[]) {
		const blocksToSave = jsonBlocks;
		const reqbody = {
			newContent: blocksToSave,
		};
		// const reqbody = {...selectedItemDetails, selectedItemDetails.pages[0].pageContent: blocksToSave};
		const requestOptions = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(reqbody),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/notes/${note_id}/pages/${selectedPage._id}/content`,
			requestOptions
		);

		if (response.ok) {
			const data: any = await response.json();
			setselectedItemDetails(data);
			// console.log("dataaftercontentreq",data);
			// const selectedPageData = data.pages.find((page: any) => page._id === selectedPage._id);

			// console.log("selecedPageData",selectedPageData);
			// setselectedPage(selectedPageData);
		} else {
			throw new Error("Failed to save data to server");
		}

		console.log("Data saved to server:", jsonBlocks);
	}

	const editor = useMemo(() => {
		if (initialContent === "loading") {
			return undefined;
		}
		return BlockNoteEditor.create({ initialContent, uploadFile });
	}, [initialContent]);

	// const handleSaveToServer = () => {
	// 	if (editor) {
	// 		saveToServer(editor.document).catch((error) => {
	// 			console.error(error);
	// 		});
	// 	}
	// };

	const debouncedSave = useDebounceFn(
		() => {
			if (editor) {
				saveToServer(editor.document).catch((error) => {
					console.error(error);
				});
			}
		},
		{ wait: 1000 }
	);

	if (initialContent === "loading" || !editor) {
		return <div>Loading content...</div>;
	}

	const HandleItemTitleChange = (e: any) => {
		setSelectedTitle(e.target.value);
		run();
	};

	return (
		<>
			<div
				style={{
					marginLeft: "2.5rem",
					marginTop: "5px",
					marginBottom: "-20px",
				}}
			></div>

			<div
				style={{
					display: "flex",
					height: "100vh",
					marginTop: "5vh",
					backgroundColor: "white",
				}}
			>
				<div style={{ flex: 8, display: "flex", flexDirection: "column" }}>
					<Flex style={{ marginLeft: "2vw" }}>
						<BreadCrumb config={breadcrumbConfig} />
					</Flex>
					<Card
						style={{
							height: "70vh",
							overflowY: "auto",
							display: "flex",
							flexDirection: "column",
							marginTop: "1.5vh",
							border: "none",
							boxShadow: "none",
						}}
					>
						{/* <div style={{ fontSize: "2rem" }}>
							{selectedItemDetails?.bookTitle}
						</div> */}
						<Input
							ref={ref}
							style={{
								fontSize: "1.5rem",
								fontWeight: "400",
								marginLeft: "3vw",
								marginTop: "2vh",
							}}
							value={selectedTitle}
							bordered={false}
							onChange={HandleItemTitleChange}
						/>
						<BlockNoteView
							editor={editor}
							theme="light"
							onChange={() => {
								saveToStorage(editor.document);
								debouncedSave.run();
							}}
							style={{ minHeight: "80vh" }}
						/>
						{/* <Button
						style={{
							float: "right",
							marginTop: "31vh",
							marginBottom: "0.5rem",
						}}
						type="primary"
						onClick={handleSaveToServer}
					>
						Save
					</Button> */}
						<Divider />
						{/* <Flex justify="space-between" align="center">
							<h2 style={{ textAlign: "left", marginLeft: "3vw" }}>Activity</h2>
							
						</Flex> */}
						<div style={{ textAlign: "left", marginLeft: "3vw" }}>
							{/* <Activity subscriberlist={subscriberlist} setsubscriberlist={setsubscriberlist} /> */}
						</div>
					</Card>
				</div>
				<div style={{ flex: 2, backgroundColor: "white" }}>
					<RightPannel
						note_id={note_id}
						selectedPage={selectedPage}
						setselectedPage={setselectedPage}
						noteData={selectedItemDetails}
						setnoteData={setselectedItemDetails}
						HandleNewPage={HandleNewPage}
					/>
				</div>
			</div>
		</>
	);
}
