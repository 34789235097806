import { Key, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GridLayout from "react-grid-layout";

import {
	DeleteOutlined,
	PlusOutlined,
	SettingOutlined,
} from "@ant-design/icons";
import {
	Modal,
	Input,
	Select,
	Form,
	Button,
	Card,
	Tooltip,
	Dropdown,
	Menu,
	ConfigProvider,
	message,
} from "antd";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { v4 as uuidv4 } from "uuid";
import DynamicContent from "./Reports/DynamicContent";
import { remove } from "lodash";
import EditWidgetModal from "./DashboardEditWidgetModal";
import DashboardNav from "./DashboardNav";
import { useRequest } from "ahooks";
import {
	getDashboardById,
	updateWidgetById,
	updatedbyid,
} from "../api/DashboardApi";
import useWorkspaceStore from "../stores/WorkspaceState";
import usePageStore from "../stores/PageStore";

interface Widget {
	id: string;
	title: string;
	description: string;
	type: string;
	component: string;
	size?: string;
}

const NewDashboard = () => {
	const [layout, setLayout] = useState([
		{ i: "Add Widget", x: 0, y: 0, w: 4, h: 3 },
	]);
	const { Option } = Select;

	const [isLayoutDisabled, setIsLayoutDisabled] = useState(true);
	const [modalVisible, setModalVisible] = useState(false);
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { id } = useParams();
	const [formItem, setFormItem] = useState({
		title: "",
		description: "",
		type: "",
		component: "",
	});

	const [data, setData] = useState<any>(null);
	const [dashboardTitle, setDashboardTitle] = useState("");
	const [widgetsData, setWidgetsData] = useState<Widget[]>([]);
	const [editmodalVisible, setEditModalVisible] = useState(false);
	const [selectedWidgetId, setSelectedWidgetId] = useState("");

	const handleEditClick = (widgetId: SetStateAction<string>) => {
		setSelectedWidgetId(widgetId);
		setEditModalVisible(true);
	};

	const handleEditCancel = () => {
		setEditModalVisible(false);
		setSelectedWidgetId("");
	};

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const { data: dashBoarddata, run: fetchDashboard } = useRequest(
		(id) => getDashboardById(id, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				// message.success("Dashboard data fetched successfully");
				console.log(data);
			},
			onError: (error) => {
				console.log(error);
				message.error(`error:${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (dashBoarddata) {
			setData(dashBoarddata);
			setDashboardTitle(dashBoarddata.title);
			setWidgetsData(dashBoarddata.widgets);
			if (dashBoarddata.layoutConfig) setLayout(dashBoarddata.layoutConfig);
			console.log("widgetsData", widgetsData);
		}
	}, [dashBoarddata]);

	useEffect(() => {
		fetchDashboard(id);
	}, [id]);

	const {
		setPageSlug,
		setPageTitle,
		setPageDescription,
		setItemType,
		setItemId,
		setIsList,
		setItemDetails,
		setBreadcrumbConfig,
	} = usePageStore((state: any) => ({
		setPageSlug: state.setPageSlug,
		setPageTitle: state.setPageTitle,
		setPageDescription: state.setPageDescription,
		setItemType: state.setItemType,
		setItemId: state.setItemId,
		setIsList: state.setIsList,
		setItemDetails: state.setItemDetails,
		setBreadcrumbConfig: state.setBreadcrumbConfig,
	}));

	useEffect(() => {
		setPageSlug("dashboard");
		setPageTitle(`${dashBoarddata?.title}`);
		setPageDescription("");
		setItemType("");
		setItemId("");
		setIsList(false);
		setItemDetails({});
		setBreadcrumbConfig({
			page: `/space/${currentWorkspace?._id}/dashboard`,
			config: [
				{
					label: "Home",
					url: `/space/${currentWorkspace?._id}`,
				},
				{
					label: "Details",
					url: `/space/${currentWorkspace?._id}/dashboard/${id}`,
					dynamicId: true,
				},
			],
		});
	}, []);

	// const YPositionForNewWidget = () => {
	// 	let y = 0;
	// 	let h = 4;
	// 	layout.forEach((item) => {
	// 		if (item.y > y) {
	// 			y = item.y;
	// 			h = item.h;
	// 		}
	// 	});
	// 	return y + h;
	// };

	const addGrid = () => {
		const maxY = Math.max(...layout.map((item) => item.y));
		const newGrid = {
			i: "Add Widget",
			x: 0,
			y: maxY + 4,
			w: 4,
			h: 4,
		};
		setLayout([...layout, newGrid]);
	};

	const onLayoutChange = (
		newLayout: SetStateAction<
			{ i: string; x: number; y: number; w: number; h: number }[]
		>
	) => {
		console.log("newLayout", newLayout);
		setLayout(newLayout);
	};

	const { run: updateDashboardbyId } = useRequest(
		(id, body) => updatedbyid(id, body, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				console.log(data);
			},
			onError: (error) => {
				console.log(error);
				message.error(`error:${error.message}`);
			},
		}
	);

	const updateDashboard = (updatedWidgetsData: any, updatedLayoutData: any) => {
		remove(layout, (item) => item.i === "Add Widget");
		updateDashboardbyId(id, {
			title: data && data.title ? data.title : "",
			description: data && data.description ? data.description : "",
			widgets: updatedWidgetsData,
			layoutConfig: updatedLayoutData,
		});
	};

	const handleEditMode = () => {
		setIsLayoutDisabled(!isLayoutDisabled);
	};

	const handleSaveDashboard = () => {
		//update dashboard
		updateDashboard(widgetsData, layout);
		setIsLayoutDisabled(!isLayoutDisabled);
	};

	const { data: updatedData, run: updatehandelOk } = useRequest(
		(id, body) => updatedbyid(id, body, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				// message.success("Dashboard updated successfully for hadelOk");
				console.log(data);
			},
			onError: (error) => {
				console.log(error);
				message.error(`error:${error.message}`);
			},
		}
	);

	useEffect(() => {
		if (updatedData) {
			setModalVisible(false);
			setIsLayoutDisabled(false);

			setData(updatedData);

			fetchDashboard(id);
		}
	}, [updatedData]);

	const handleOk = async () => {
		try {
			const newWidgetId = uuidv4();
			const newWidgetLayout = {
				i: newWidgetId,
				x: layout.find((item) => item.i === "Add Widget")?.x || 0,
				y: layout.find((item) => item.i === "Add Widget")?.y || 0,
				w: 4,
				h: 10,
			};
			if (formItem.component.includes("Assigned")) {
				newWidgetLayout.h = 4; // height 4 For Assigned Component
			}

			updatehandelOk(id, {
				widgets: [
					...(widgetsData || []),
					{
						id: newWidgetId,
						title: formItem.title,
						description: formItem.description,
						type: formItem.type,
						component: formItem.component,
						size: "small",
						createdAt: new Date().toISOString(),
					},
				],
				layoutConfig: layout.map((item) =>
					item.i === "Add Widget" ? newWidgetLayout : item
				),
			});
		} catch (error) {
			console.error("Error adding item:", error);
		}
	};

	const handleDelete = async (widgetId: string) => {
		try {
			const updateWidgetsData = widgetsData.filter(
				(widget) => widget.id !== widgetId
			);
			setWidgetsData(updateWidgetsData);
			console.log("widgetsdata", widgetsData);
			const updatedLayout = layout.filter((item) => item.i !== widgetId);
			setLayout(updatedLayout);
			updateDashboard(updateWidgetsData, updatedLayout);
		} catch (error) {
			console.error("Error deleting widget:", error);
		}
	};

	const { run: updateSize } = useRequest(
		(id, widgetId, body) =>
			updateWidgetById(id, widgetId, body, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				console.log(data);
				message.success("Widget size updated ");
			},
			onError: (error) => {
				console.log(error);
				message.error(`Error in updating size:${error.message}`);
			},
		}
	);
	const handleResize = async (widgetId: string, size: string) => {
		const newSize = getSizeConfig(size);
		const newLayout = layout.map((item) => {
			if (item.i === widgetId) {
				return { ...item, ...newSize };
			}
			return item;
		});
		setLayout(newLayout);

		const widgetIndex = widgetsData.findIndex(
			(widget) => widget.id === widgetId
		);
		if (widgetIndex !== -1) {
			const updatedWidgetsData = [...widgetsData];
			updatedWidgetsData[widgetIndex].size = size;

			setWidgetsData(updatedWidgetsData);

			updateSize(id, widgetId, { size });
		} else {
			console.error("Widget not found in widgetsData array");
		}
	};

	const getSizeConfig = (size: string) => {
		switch (size) {
			case "small":
				return { w: 4, h: 10 };
			case "medium":
				return { w: 8, h: 10 };
			case "large":
				return { w: 12, h: 10 };
			default:
				return { w: 4, h: 10 };
		}
	};

	return (
		<div style={{ width: "100%" }}>
			<DashboardNav
				isLayoutDisabled={isLayoutDisabled}
				handleEditMode={handleEditMode}
				handleSaveDashboard={handleSaveDashboard}
				addGrid={addGrid}
				title={dashboardTitle}
			/>
			<div style={{ overflow: "scroll", height: "150vh" }}>
				<GridLayout
					className="react-grid-layout"
					layout={layout}
					cols={12}
					rowHeight={30}
					width={1200}
					isDraggable={!isLayoutDisabled}
					// isResizable={!isLayoutDisabled}
					isResizable={false}
					onLayoutChange={onLayoutChange}
					draggableCancel=".no-drag"
					style={{ overflowY: "scroll", height: "100%", marginLeft: "2vw" }}
				>
					{layout.map((layoutItem: { i: Key | null | undefined }) => {
						const widget = (data as any)?.widgets.find(
							(item: { id: string }) => item.id === layoutItem.i
						);
						return (
							<div key={layoutItem.i}>
								<Card
									title={
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												width: "100%",
											}}
										>
											<span
												style={{
													marginTop: "4px",
													marginRight: "15px",
													fontFamily: "Poppins",
													fontWeight: "bold",
													fontSize: "1.2rem",
													cursor: isLayoutDisabled ? "default" : "move",
												}}
											>
												{widget?.title}
											</span>
											{!isLayoutDisabled && widget && (
												<div
													style={{ position: "absolute", top: 12, right: 8 }}
												>
													<ConfigProvider
														theme={{
															components: {
																Dropdown: {
																	borderRadiusLG: 9,
																},
															},
														}}
													>
														<Dropdown
															overlay={
																<Menu>
																	<Menu.Item
																		className="no-drag"
																		onClick={() => handleEditClick(widget?.id)}
																		key="edit"
																	>
																		Edit Widget
																	</Menu.Item>
																	<Menu.SubMenu
																		key="resize"
																		title="Resize"
																		className="no-drag"
																		style={{ border: "none" }}
																	>
																		<Menu.Item
																			key="small"
																			className="no-drag"
																			onClick={() =>
																				handleResize(widget?.id, "small")
																			}
																		>
																			Small
																		</Menu.Item>
																		<Menu.Item
																			key="medium"
																			className="no-drag"
																			onClick={() =>
																				handleResize(widget?.id, "medium")
																			}
																		>
																			Medium
																		</Menu.Item>
																		<Menu.Item
																			key="large"
																			className="no-drag"
																			onClick={() =>
																				handleResize(widget?.id, "large")
																			}
																		>
																			Large
																		</Menu.Item>
																	</Menu.SubMenu>
																</Menu>
															}
															placement="bottomRight"
															//arrow
															className="no-border"
														>
															<span
																style={{ padding: "4px", cursor: "pointer" }}
															>
																<SettingOutlined
																	className="no-drag"
																	style={{ border: "none", color: "#0004bf" }}
																/>
															</span>
														</Dropdown>
													</ConfigProvider>
													<Tooltip placement="top" title="Delete">
														<Button
															type="text"
															icon={
																<DeleteOutlined
																	className="no-drag"
																	style={{ border: "none", color: "#0004bf" }}
																/>
															}
															onClick={() => handleDelete(widget?.id)}
														/>
													</Tooltip>
													<EditWidgetModal
														visible={editmodalVisible}
														onCancel={handleEditCancel}
														widgetId={selectedWidgetId}
														fetchData={() => fetchDashboard(id)}
													/>
												</div>
											)}
										</div>
									}
								>
									{widget && (
										<div>
											<DynamicContent componentName={widget.component} />
										</div>
									)}
									{!isLayoutDisabled && !widget && (
										<PlusOutlined
											className="no-drag"
											onClick={() => {
												console.log("plus clicked");
												setFormItem({
													title: "",
													description: "",
													type: "",
													component: "",
												});
												setModalVisible(true);
											}}
											// style={{ margin: "130px" }}
										/>
									)}
								</Card>
							</div>
						);
					})}
				</GridLayout>
			</div>
			<Modal
				title="Add Widget"
				visible={modalVisible}
				onOk={handleOk}
				onCancel={() => setModalVisible(false)}
			>
				<Form
					layout="horizontal"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
				>
					<Form.Item label="Title">
						<Input
							value={formItem.title}
							onChange={(e) =>
								setFormItem({ ...formItem, title: e.target.value })
							}
						/>
					</Form.Item>
					<Form.Item label="Description">
						<Input.TextArea
							value={formItem.description}
							onChange={(e) =>
								setFormItem({ ...formItem, description: e.target.value })
							}
						/>
					</Form.Item>
					<Form.Item label="Type">
						<Select
							value={formItem.type}
							onChange={(value) => setFormItem({ ...formItem, type: value })}
							style={{ width: "100%" }}
						>
							<Option value="text">Text</Option>
							<Option value="number">Number</Option>
							<Option value="date">Date</Option>
						</Select>
					</Form.Item>
					<Form.Item label="Component">
						<Select
							value={formItem.component}
							onChange={(value) =>
								setFormItem({ ...formItem, component: value })
							}
							style={{ width: "100%" }}
						>
							<Option value="PieChart">PieChart</Option>
							<Option value="BarChart">BarChart</Option>
							<Option value="AssignedTask">Assigned Task</Option>
							<Option value="AssignedBug">Assigned Bug</Option>
							<Option value="AssignedUserStory">Assigned User Story</Option>
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default NewDashboard;
