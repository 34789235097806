import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { BrowserRouter } from "react-router-dom";

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<BrowserRouter
		basename={
			process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL : ""
		}
	>
		<ConfigProvider
			theme={{
				token: {
					colorBgBase: "#ffffff",
					colorPrimaryBg: "#F5F8FE",
					colorBgContainer: "#ffffff",
					borderRadiusLG: 20,
					fontFamily: "'Poppins', sans-serif",
					colorPrimary: "#3478f6",
					colorInfo: "#3478f6",
					wireframe: false,
					colorSuccess: "#49a078",
					colorLink: "#c9d9f8",
					colorWarning: "#fcb07e",
					colorError: "#bd1a35",
					colorLinkActive: "#a8c0ed",
					fontSizeHeading2: 24,
					fontSizeHeading4: 18,
					fontSizeHeading5: 16,
					//borderRadius: 16,
					fontSize: 14,
					fontSizeHeading1: 32,
					fontSizeHeading3: 20,
				},

				components: {
					Layout: {
						siderBg: "#03045e",
					},

					Button: {
						borderColorDisabled: "rgb(214, 214, 214)",
						defaultBorderColor: "rgb(52, 120, 246)",
						defaultColor: "rgb(52, 120, 246)",
						textHoverBg: "rgba(255, 255, 255, 0.06)",
						colorText: "rgb(52, 120, 246)",
						defaultGhostColor: "rgba(214, 214, 214, 0.5)",
						colorLink: "rgb(147, 178, 241)",
						colorLinkActive: "rgb(111, 149, 223)",
						colorLinkHover: "rgb(169, 196, 252)",
						contentFontSize: 16,
						fontSize: 16,
						lineHeight: 35,
						borderRadius: 6,
						borderRadiusLG: 20,
						colorPrimaryBorder: "rgb(176, 212, 255)",
						colorPrimaryHover: "rgb(94, 156, 255)",
						colorPrimaryActive: "rgb(33, 88, 207)",
						colorPrimary: "rgb(4, 68, 191)",
						colorError: "rgb(189, 26, 53)",
						colorErrorActive: "rgb(150, 14, 41)",
						colorErrorBg: "rgb(252, 237, 237)",
						colorErrorBorderHover: "rgb(214, 99, 110)",
						colorErrorHover: "rgb(201, 60, 79)",
						colorErrorOutline: "rgb(217, 30, 30)",
						colorTextDisabled: "rgb(0, 0, 0)",
						colorTextLightSolid: "rgb(255, 255, 255)",
					},
					Typography: {
						colorText: "rgb(0, 0, 0)",
						colorTextDescription: "rgba(0, 0, 0, 0.6)",
						colorTextDisabled: "rgba(0, 0, 0, 0.25)",
						colorTextHeading: "rgb(0, 0, 0)",
						fontSize: 16,
						fontFamilyCode: "Poppins",
						colorErrorActive: "rgb(150, 14, 41)",
						colorErrorHover: "rgb(201, 60, 79)",
						colorError: "rgb(189, 26, 53)",
						colorLink: "rgb(201, 217, 248)",
						colorLinkActive: "rgb(168, 192, 237)",
						colorLinkHover: "rgb(240, 246, 255)",
						colorSuccess: "rgb(73, 160, 120)",
						colorWarning: "rgb(252, 176, 126)",
						fontSizeHeading1: 32,
					},
					Divider: {
						fontFamily: "Poppins",
						colorSplit: "rgba(0, 0, 0, 0.06)",
						colorText: "rgba(0, 0, 0, 0.8)",
						colorTextHeading: "rgba(0, 0, 0, 0.85)",
					},
					Space: {
						padding: 8,
					},
					Form: {
						labelColor: "rgba(0, 0, 0, 0.7)",
						labelRequiredMarkColor: "rgb(255, 77, 79)",
						colorBorder: "rgb(214, 214, 214)",
						colorError: "rgb(189, 26, 53)",
						colorPrimary: "rgb(52, 120, 246)",
						colorSuccess: "rgb(73, 160, 120)",
						colorText: "rgba(0, 0, 0, 0.8)",
						colorTextDescription: "rgba(0, 0, 0, 0.5)",
						colorTextHeading: "rgba(0, 0, 0, 0.9)",
						colorWarning: "rgb(252, 176, 126)",
						controlOutline: "rgb(5, 138, 255)",
						//labelHeight: 16,
						verticalLabelMargin: 8,
						fontSize: 16,
						fontFamily: "Poppins",
						lineType: "solid ",
					},
					Input: {
						colorBgContainer: "rgb(255, 255, 255)",
						colorBgContainerDisabled: "rgb(0, 0, 0)",
						colorBorder: "rgb(214, 214, 214)",
						colorError: "rgb(189, 26, 53)",
						colorIcon: "rgb(52, 120, 246)",
						colorIconHover: "rgb(88, 148, 245)",
						colorPrimary: "rgb(52, 120, 246)",
						colorPrimaryActive: "rgb(33, 88, 207)",
						colorPrimaryHover: "rgb(94, 156, 255)",
						colorText: "rgba(0, 0, 0, 0.8)",
						colorTextDescription: "rgba(0, 0, 0, 0.5)",
						colorTextDisabled: "rgba(0, 0, 0, 0.2)",
						colorTextPlaceholder: "rgba(0, 0, 0, 0.3)",
						fontSize: 16,
						fontSizeIcon: 16,
						borderRadius: 8,
						fontFamily: "Poppins",
						lineType: "solid ",
					},
					Select: {
						optionSelectedBg: "rgb(230, 244, 255)",
						selectorBg: "rgb(255, 255, 255)",
						optionSelectedColor: "rgba(0, 0, 0, 0.8)",
						colorBgContainer: "rgb(255, 255, 255)",
						colorPrimary: "rgb(52, 120, 246)",
						colorPrimaryHover: "rgb(94, 156, 255)",
						colorText: "rgba(0, 0, 0, 0.8)",
					},
					Switch: {
						colorPrimary: "rgb(52, 120, 246)",
						colorPrimaryBorder: "rgb(176, 212, 255)",
						colorPrimaryHover: "rgb(94, 156, 255)",
						handleBg: "rgb(255, 255, 255)",
					},
					Tabs: {
						// verticalItemPadding: "8px 114px 8px 24px",
						cardPadding: "16px 110px 16px 32px",
						cardGutter: 8,
						lineWidth: 0,
						cardHeight: 50,
					},
					Menu: {
						itemActiveBg: "rgb(230, 244, 255)",
						itemBg: "white",
						itemSelectedBg: "white",
						activeBarBorderWidth: 0,
						itemHeight: 60,
						colorBgBase: "#03045e",
					},
					Breadcrumb: {
						fontSize: 12,
					},
					Tag: {
						lineWidth: 2,
					},
				},
				//"algorithm": "compact"
			}}
		>
			<App instance={msalInstance} />
		</ConfigProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
