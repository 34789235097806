import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import { Flex, Image, Spin } from "antd";
import useWorkspaceStore from "../stores/WorkspaceState";

const CreateFeatureFromThread = () => {
	// Implement your component logic here
	const navigate = useNavigate();
	const { threadId } = useParams();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const createWorkItem = async (goalResult: any) => {
		// Implement your code here
		const workItem = {
			title: goalResult.title,
			description: goalResult.description,
			data: {
				benefits: goalResult.benefits,
				complexity_score: goalResult.complexity_score,
				complexity_score_reason: goalResult.complexity_score_reason,
			},
			priority: "MEDIUM",
			status: "OPEN",
			type: "FEATURE",
			workspaceId: currentWorkspace._id,
			assignee: userProfileStoreInstance.profile._id,
		};

		const result = await fetch(`${process.env.REACT_APP_API_URL}/workitems`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
			body: JSON.stringify(workItem),
		});
		console.log(result.status, "result");
		if (result.status !== 200 && result.status !== 201) {
			throw new Error("Network response was not ok");
		}
		const responseData = await result.json();
		console.log(responseData);
		navigate(`/space/${currentWorkspace._id}`);
	};

	const getConversationThread = async (threadId: string) => {
		// Implement your code here
		const result = await fetch(
			`${process.env.REACT_APP_API_URL}/ai-conversation-thread/${threadId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
				},
			}
		);
		if (result.status !== 200 && result.status !== 201) {
			throw new Error("Network response was not ok");
		}
		const responseData = await result.json();
		if (responseData && responseData.goalResult) {
			await createWorkItem(responseData.goalResult);
		}
	};

	useEffect(() => {
		if (threadId) getConversationThread(threadId);
	}, [threadId]);

	return (
		<Flex
			vertical
			style={{
				height: "100vh",
			}}
		>
			<Flex justify="center">
				{/* <Steps
				type="navigation"
				//current={currentStep}
				size="small"
				style={{ marginTop: "8vh", width: "60%", justifyContent: "center" }}
				percent={100}
				items={[
					{ title: "Understand the product idea in detail", icon:<BulbFilled/>},
					// { title: "Existing solution" },
					// { title: "Your solution" },
					// {
					// 	title: "USP",
					// },
				]}
			/> */}
				<>
					<Spin size="large" />
					<Image
						style={{ marginTop: "10px" }}
						width={150}
						preview={false}
						src="https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_logo.png"
					/>
					<Spin size="large" />
				</>
			</Flex>{" "}
			<Flex justify="center">
				<h3>Finishing the final steps...</h3>
			</Flex>
		</Flex>
	);
};

export default CreateFeatureFromThread;
