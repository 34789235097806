import React, { useState } from "react";
import { Tag, Pagination, List, Tooltip } from "antd";
import {
	CheckCircleFilled,
	DashOutlined,
	ExclamationOutlined,
	FallOutlined,
	HarmonyOSOutlined,
	Loading3QuartersOutlined,
	RiseOutlined,
} from "@ant-design/icons";

const HomePageTaskListViewItem: React.FC<any> = ({ Data }: { Data: any[] }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};
	const getStatusStyle = (status?: string) => {
		if (!status) return { color: "gray", icon: <DashOutlined /> };
		const formattedStatus = status.replace(/_/g, " ").toLowerCase();

		switch (formattedStatus) {
			case "completed":
				return { color: "green", icon: <CheckCircleFilled /> };
			case "in progress":
				return { color: "blue", icon: <Loading3QuartersOutlined /> };
			case "pending":
				return { color: "orange", icon: <ExclamationOutlined /> };
			case "open":
				return { color: "red", icon: <HarmonyOSOutlined /> };
			default:
				return { color: "gray", icon: <DashOutlined /> };
		}
	};
	const formatStatusText = (status?: string) => {
		if (!status) return "";
		return status.replace(/_/g, " ").toLowerCase();
	};
	const getTagcolor = (priority?: string) => {
		if (!priority) return "blue";
		switch (priority.toLowerCase()) {
			case "urgent":
				return "orange";
			case "important":
				return "red";
			case "medium":
				return "gold";
			case "low":
				return "green";
			default:
				return "blue";
		}
	};
	const getPriorityIcon = (priority: string) => {
		switch (priority) {
			case "URGENT":
				return <RiseOutlined style={{ color: "orange" }} />;
			case "Urgent":
				return <RiseOutlined style={{ color: "orange" }} />;
			case "IMPORTANT":
				return <ExclamationOutlined style={{ color: "red" }} />;
			case "Important":
				return <ExclamationOutlined style={{ color: "red" }} />;
			case "Medium":
				return <DashOutlined style={{ color: "golden" }} />;
			case "LOW":
				return <FallOutlined style={{ color: "green" }} />;
			case "Low":
				return <FallOutlined style={{ color: "green" }} />;
			default:
				return <HarmonyOSOutlined />;
		}
	};
	const renderListItem = (item: any) => (
		<List.Item key={item._id} style={{ padding: "0px 0" }}>
			<div className="list-item">
				<div className="list-item-description-left ">
					<span className="work-item-title">{item.title}</span>
				</div>
				<div className="list-item-description-right ">
					<span
						className="status-tag"
						style={{ color: getStatusStyle(item.status).color }}
					>
						{formatStatusText(item.status)}
					</span>
					<Tooltip title={item.priority ? item.priority : "Null"}>
						<Tag color={getTagcolor(item.priority)} className="priority-tag">
							{getPriorityIcon(item.priority)}
						</Tag>
					</Tooltip>
				</div>
			</div>
		</List.Item>
	);

	// const columns = [
	// 	{
	// 		title: <div style={{ fontFamily: "Poppins" }}>Title</div>,
	// 		dataIndex: "title",
	// 		key: "title",
	// 		render: (text: any) => (
	// 			<div style={{ display: "flex", alignItems: "center" }}>
	// 				<a
	// 					style={{
	// 						color: "black",
	// 						// test
	// 						fontWeight: "bold",
	// 						fontFamily: "Open Sans",
	// 					}}
	// 				>
	// 					{text}
	// 				</a>
	// 			</div>
	// 		),
	// 	},
	// 	{
	// 		title: <div style={{ fontFamily: "Poppins" }}>Status</div>,
	// 		dataIndex: "status",
	// 		key: "status",
	// 		render: (text: any) => (
	// 			<Tag color="yellow" style={{ color: "black", fontFamily: "Open Sans" }}>
	// 				{text.toUpperCase()}
	// 			</Tag>
	// 		),
	// 	},
	// 	{
	// 		title: <div style={{ fontFamily: "Poppins" }}>Priority</div>,
	// 		dataIndex: "priority",
	// 		key: "priority",
	// 		render: (priority: any) => (
	// 			<Tag color="blue" style={{ color: "black", fontFamily: "Open Sans" }}>
	// 				{priority}
	// 			</Tag>
	// 		),
	// 	},
	// ];

	const data = Data.map((item) => ({
		WorkitemId: item.WorkitemId,
		key: item._id,
		title: item.title,
		description: item.description,
		priority: item.priority,
		status: item.status,
	}));
	const pageSize = 3;

	const currentPageData = data.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	return (
		// <Table
		// 	columns={columns}
		// 	dataSource={data}
		// 	pagination={{
		// 		pageSize: pageSize,
		// 		showSizeChanger: false,
		// 	}}
		// 	scroll={{ y: 120 }}
		// />
		<>
			<div>
				<List
					style={{ paddingLeft: "1.8rem" }}
					itemLayout="vertical"
					dataSource={currentPageData}
					renderItem={renderListItem}
					className="list-view"
					size="small"
				/>
			</div>
			<div
				style={{
					flex: "0 0 20px",
					textAlign: "end",
					marginTop: "3px",
					marginBottom: "10px",
				}}
			>
				<Pagination
					current={currentPage}
					total={data.length}
					pageSize={pageSize}
					onChange={handlePageChange}
					showSizeChanger={false}
				/>
			</div>
		</>
	);
};

export default HomePageTaskListViewItem;
