import axios, { AxiosResponse } from "axios";
import { Workspace } from "../models/Workspace";

async function getWorkspacebyId(
	workspaceId: any,
	token: any
): Promise<Workspace> {
	try {
		const response: AxiosResponse<Workspace> = await axios.get(
			`${process.env.REACT_APP_API_URL}/workspace/${workspaceId}`,
			{
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching work items:", error);
		throw error;
	}
}

export { getWorkspacebyId };
