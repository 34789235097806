/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
// import { List, Space, Tag, Flex, Collapse, Table, Avatar, Tooltip } from "antd";
import { Tag, Collapse, Avatar, Tooltip, List, Pagination } from "antd";
// import { EllipsisOutlined, EyeOutlined } from "@ant-design/icons";
import {
	BugOutlined,
	CheckCircleFilled,
	CheckCircleOutlined,
	DashOutlined,
	ExclamationOutlined,
	EyeOutlined,
	FallOutlined,
	FileTextOutlined,
	HarmonyOSOutlined,
	HighlightOutlined,
	Loading3QuartersOutlined,
	MessageOutlined,
	RiseOutlined,
	WechatWorkOutlined,
} from "@ant-design/icons";
// import { useViewsStores } from "../../stores/BacklogStore";
import { planerViewStore } from "../../stores/PlannerStore";

import { useLocation, useNavigate } from "react-router-dom";
import "./ListView.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import BacklogDropdownMenu from "./BacklogContextMenu";
import { constant } from "lodash";

const MyTaskListViewItemV2: React.FC<any> = ({
	Data,
	selectedGroupBy,
	setDrawerTab,
}) => {
	// const { selectedGroupBy, setDrawerTab } = useViewsStores();
	const { setOpen, setSelectedItemDetails } = planerViewStore();
	const url = useLocation().pathname;
	//const [parentId, setParentId] = useState("");
	//const [todos, setTodos] = useState<any[]>([]);
	//const [expandedRowKey, setExpandedRowKey] = useState<string | number>("");
	const [selectRec, setSelectRec] = useState<any>();
	const [tags, setTags] = useState<any[]>([]);
	const navigate = useNavigate();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(20);

	const setGroupKey = (selectedGroupBy: string) => {
		switch (selectedGroupBy) {
			case "Type":
				return "type";
			case "Priority":
				return "priority";
			case "Status":
				return "status";
			case "release":
				return "release";
			default:
				if (url === `/space/${currentWorkspace._id}/planner/backlogs`) {
					return "None";
				} else if (url === `/space/${currentWorkspace._id}/planner/mytasks`) {
					return "status";
				} else {
					return "release";
				}
		}
	};

	const groupKey = setGroupKey(selectedGroupBy);

	const groupedTasks = groupBy(Data, groupKey);

	console.log(groupedTasks, "groupedTasks");

	const handleEyeIconClick = (item: any, Data: any[]) => {
		setOpen(true);
		setDrawerTab("details");
		console.log(open);

		const selectedItemData = Data.find((dataItem) => dataItem._id === item.key);

		setSelectedItemDetails(selectedItemData);
	};

	const handleChatIconClick = (item: any, Data: any[]) => {
		setOpen(true);
		setDrawerTab("discussion");
		console.log(open);

		const selectedItemData = Data.find((dataItem) => dataItem._id === item.key);

		setSelectedItemDetails(selectedItemData);
	};

	useEffect(() => {
		const fetchTagsData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/team-members?workspace-id=${currentWorkspace._id}`,
					{
						headers: {
							Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
						},
					}
				);
				if (response.ok) {
					const data = await response.json();
					setTags(data);
					console.log(data);
				} else {
					console.error("Error fetching tags data");
				}
			} catch (error) {
				console.error("Error fetching tags data:", error);
			}
		};

		fetchTagsData();
	}, []);

	{
		/*{function removePTags(text: string) {
		return text.replace(/<p>/g, "").replace(/<\/p>/g, "");
	}} */
	}

	console.log(tags, "tags");

	// const columns = [
	// 	{
	// 		title: <div style={{ fontFamily: "Poppins" }}>Action</div>,
	// 		key: "action",
	// 		render: (text: any, item: any) => (
	// 			<div style={{ display: "flex", alignItems: "center" }}>
	// 				<div
	// 					style={{
	// 						display: "flex",
	// 						alignItems: "center",
	// 						marginRight: "12px",
	// 					}}
	// 				>
	// 					<EyeOutlined
	// 						onClick={() => handleEyeIconClick(item, Data)}
	// 						style={{
	// 							fontSize: "1.1rem",
	// 							marginRight: "1vw",
	// 							//color: "#FCA311"
	// 						}}
	// 					/>
	// 				</div>
	// 				<div style={{ display: "flex", alignItems: "center" }}>
	// 					<MessageOutlined
	// 						onClick={() => handleChatIconClick(item, Data)}
	// 						style={{
	// 							fontSize: "1.1rem",
	// 							marginRight: "1vw",
	// 							//color: "#FCA311"
	// 						}}
	// 					/>
	// 				</div>
	// 			</div>
	// 		),
	// 	},
	// 	// {
	// 	// 	title: "",
	// 	// 	dataIndex: "",
	// 	// 	key: "WorkitemId",
	// 	// 	render: (text: any, item: any) => (
	// 	// 		<div style={{ display: "flex", alignItems: "center" }}>
	// 	// 			<EyeOutlined
	// 	// 				onClick={() => handleEyeIconClick(item, Data)}
	// 	// 				style={{ fontSize: "1.1rem", marginRight: "1vw" }}
	// 	// 			/>
	// 	// 			{/* <a style={{ color: "black", fontWeight: "bold" }}>{text}</a> */}
	// 	// 		</div>
	// 	// 	),
	// 	// },
	// 	// {
	// 	// 	title: "",
	// 	// 	dataIndex: "",
	// 	// 	key: "WorkitemId",
	// 	// 	render: (text: any, item: any) => (
	// 	// 		<div style={{ display: "flex", alignItems: "center" }}>
	// 	// 			<MessageOutlined
	// 	// 				onClick={() => handleChatIconClick(item, Data)}
	// 	// 				style={{ fontSize: "1.1rem", marginRight: "1vw" }}
	// 	// 			/>
	// 	// 			{/* <a style={{ color: "black", fontWeight: "bold" }}>{text}</a> */}
	// 	// 		</div>
	// 	// 	),
	// 	// },
	// 	{
	// 		//title: "ID",
	// 		title: <div style={{ fontFamily: "Poppins" }}>ID</div>,
	// 		dataIndex: "WorkitemId",
	// 		key: "WorkitemId",
	// 		render: (text: any) => (
	// 			<div style={{ display: "flex", alignItems: "center" }}>
	// 				{/* <EyeOutlined
	// 					onClick={() => handleIconClick(item, Data)}
	// 					style={{ fontSize: "1.1rem", marginRight: "1vw" }}
	// 				/> */}
	// 				<a
	// 					style={{
	// 						color: "black",
	// 						fontWeight: "bold",
	// 						fontFamily: "Open Sans",
	// 					}}
	// 				>
	// 					{text}
	// 				</a>
	// 			</div>
	// 		),
	// 	},
	// 	{
	// 		//title: "Title",
	// 		title: <div style={{ fontFamily: "Poppins" }}>Title</div>,
	// 		dataIndex: "title",
	// 		key: "title",
	// 		render: (text: any) => (
	// 			<div style={{ display: "flex", alignItems: "center" }}>
	// 				<a
	// 					style={{
	// 						color: "black",
	// 						fontWeight: "bold",
	// 						fontFamily: "Open Sans",
	// 					}}
	// 				>
	// 					{text}
	// 				</a>
	// 			</div>
	// 		),
	// 	},
	// 	// {
	// 	// 	title: "Description",
	// 	// 	dataIndex: "description",
	// 	// 	key: "description",
	// 	// 	render: (text: any) => (
	// 	// 		<div style={{ display: "flex", alignItems: "center" }}>
	// 	// 			<a
	// 	// 				style={{ color: "black", fontWeight: "bold" }}
	// 	// 				// dangerouslySetInnerHTML={{ __html: text }}
	// 	// 			>
	// 	// 				{removePTags(text)}
	// 	// 			</a>
	// 	// 		</div>
	// 	// 	),
	// 	// },
	// 	{
	// 		//title: "Priority",
	// 		title: <div style={{ fontFamily: "Poppins" }}>Priority</div>,
	// 		dataIndex: "priority",
	// 		key: "priority",
	// 		render: (priority: any) => (
	// 			<Tag color="blue" style={{ color: "black", fontFamily: "Open Sans" }}>
	// 				{priority}
	// 			</Tag>
	// 		),
	// 	},
	// 	{
	// 		//title: "Type",
	// 		title: <div style={{ fontFamily: "Poppins" }}>Type</div>,
	// 		dataIndex: "type",
	// 		key: "type",
	// 		render: (type: any) => (
	// 			<Tag color="yellow" style={{ color: "black", fontFamily: "Open Sans" }}>
	// 				{type}
	// 			</Tag>
	// 		),
	// 	},
	// 	{
	// 		//title: "Status",
	// 		title: <div style={{ fontFamily: "Poppins" }}>Status</div>,
	// 		dataIndex: "status",
	// 		key: "status",
	// 		render: (type: any) => <div>{type.toUpperCase()}</div>,
	// 	},
	// 	{
	// 		//title: "Assign",
	// 		title: <div style={{ fontFamily: "Poppins" }}>Assign</div>,
	// 		dataIndex: "assignee",
	// 		key: "assignee",
	// 		render: (assigneeData: any) => {
	// 			if (selectedGroupBy === "None") {
	// 				// const assigneeNameWords =
	// 				// 	assigneeData?.name || assigneeData?.split(" ") || [];
	// 				// const firstName = assigneeNameWords[0] || "";
	// 				// const lastName =
	// 				// 	assigneeNameWords.length > 1
	// 				// 		? assigneeNameWords[assigneeNameWords.length - 1]
	// 				// 		: "";

	// 				// const avatarText = `${firstName.charAt(0)}${lastName.charAt(
	// 				// 	0
	// 				// )}`.toUpperCase();
	// 				const assigneeNameWords = assigneeData?.name?.split(" ") || [];
	// 				const firstName = assigneeNameWords[0] || "";
	// 				const lastName =
	// 					assigneeNameWords.length > 1
	// 						? assigneeNameWords[assigneeNameWords.length - 1]
	// 						: "";

	// 				const avatarText = `${firstName.charAt(0)}${lastName.charAt(
	// 					0
	// 				)}`.toUpperCase();

	// 				const pastelColor = assigneeData?.preferences?.profileColor
	// 					? assigneeData.preferences.profileColor
	// 					: getRandomPastelColor();

	// 				return (
	// 					<Tooltip title={`${assigneeData?.name}`} placement="top">
	// 						<Avatar
	// 							style={{
	// 								backgroundColor: pastelColor,
	// 							}}
	// 						>
	// 							{avatarText}
	// 						</Avatar>
	// 					</Tooltip>
	// 				);
	// 			} else {
	// 				const assigneeNameWords = assigneeData?.name?.split(" ") || [];
	// 				const firstName = assigneeNameWords[0] || "";
	// 				const lastName =
	// 					assigneeNameWords.length > 1
	// 						? assigneeNameWords[assigneeNameWords.length - 1]
	// 						: "";

	// 				const avatarText = `${firstName.charAt(0)}${lastName.charAt(
	// 					0
	// 				)}`.toUpperCase();

	// 				const pastelColor = assigneeData?.preferences?.profileColor
	// 					? assigneeData.preferences.profileColor
	// 					: getRandomPastelColor();

	// 				return (
	// 					<Tooltip title={`${assigneeData.name}`} placement="top">
	// 						<Avatar
	// 							style={{
	// 								backgroundColor: pastelColor,
	// 							}}
	// 						>
	// 							{avatarText}
	// 						</Avatar>
	// 					</Tooltip>
	// 				);
	// 			}
	// 		},
	// 	},
	// 	// {
	// 	// 	//title: "Action",
	// 	// 	title: <div style={{ fontFamily: "Poppins" }}>Action</div>,
	// 	// 	key: "action",
	// 	// },
	// ];

	const data = Data.map((item: any) => ({
		_id: item._id,
		WorkitemId: item.WorkitemId,
		key: item._id,
		title: item.title,
		description: item.description,
		priority: item.priority,
		type: item.type,
		status: item.status,
		story: item.story,
		assignee: item?.assignee,
		assigneeId: item?.assignee?._id,
	}));

	const getRandomPastelColor = () => {
		// const hue = Math.floor(Math.random() * 360);
		const hue = 219;
		const pastelColor = `hsl(${hue}, 80%, 80%)`;
		return pastelColor;
	};
	const [menuVisible, setMenuVisible] = React.useState(false);
	const [position, setPosition] = React.useState({
		x: 0,
		y: 0,
	});

	const handleEditor = (item: any) => {
		const selectedItemData = currentPageData.find(
			(dataItem: any) => dataItem._id === item.key
		);

		setSelectedItemDetails(selectedItemData);

		navigate(`/space/${currentWorkspace._id}/planner/editor/${item?._id}`);
	};

	const renderActions = (item: any) => (
		<div style={{ display: "flex", alignItems: "center" }}>
			<WechatWorkOutlined
				onClick={() => handleEditor(item)}
				style={{
					fontSize: "1rem",
					marginLeft: "1vw",
				}}
			/>
		</div>
	);

	const renderAssigneeAvatar = (assigneeData: any) => {
		const assigneeNameWords = assigneeData?.name?.split(" ") || [];
		const firstName = assigneeNameWords[0] || "";
		const lastName =
			assigneeNameWords.length > 1
				? assigneeNameWords[assigneeNameWords.length - 1]
				: "";

		const avatarText = `${firstName.charAt(0)}${lastName.charAt(
			0
		)}`.toUpperCase();

		const pastelColor = assigneeData?.preferences?.profileColor
			? assigneeData.preferences.profileColor
			: getRandomPastelColor();

		return (
			<Tooltip title={`${assigneeData?.name}`} placement="top">
				<Avatar
					style={{
						backgroundColor: pastelColor,
					}}
					size="small"
				>
					{avatarText}
				</Avatar>
			</Tooltip>
		);
	};

	const handleListItemClick = (item: any) => {
		setSelectedItemDetails(item);
	};

	const getTypeIcon = (type: string) => {
		switch (type) {
			case "FEATURE":
				return <HighlightOutlined />;
			case "TASK":
				return <CheckCircleOutlined />;
			case "BUG":
				return <BugOutlined />;
			case "USER STORY":
				return <FileTextOutlined />;
			default:
				return null;
		}
	};

	const getPriorityIcon = (priority: string) => {
		switch (priority) {
			case "URGENT":
				return <RiseOutlined style={{ color: "orange" }} />;
			case "Urgent":
				return <RiseOutlined style={{ color: "orange" }} />;
			case "IMPORTANT":
				return <ExclamationOutlined style={{ color: "red" }} />;
			case "Important":
				return <ExclamationOutlined style={{ color: "red" }} />;
			case "Medium":
				return <DashOutlined style={{ color: "golden" }} />;
			case "LOW":
				return <FallOutlined style={{ color: "green" }} />;
			case "Low":
				return <FallOutlined style={{ color: "green" }} />;
			default:
				return <HarmonyOSOutlined />;
		}
	};

	const getTagcolor = (priority?: string) => {
		if (!priority) return "blue";
		switch (priority.toLowerCase()) {
			case "urgent":
				return "orange";
			case "important":
				return "red";
			case "medium":
				return "gold";
			case "low":
				return "green";
			default:
				return "blue";
		}
	};

	const getStatusStyle = (status?: string) => {
		if (!status) return { color: "gray", icon: <DashOutlined /> };
		const formattedStatus = status.replace(/_/g, " ").toLowerCase();

		switch (formattedStatus) {
			case "completed":
				return { color: "green", icon: <CheckCircleFilled /> };
			case "in progress":
				return { color: "blue", icon: <Loading3QuartersOutlined /> };
			case "pending":
				return { color: "orange", icon: <ExclamationOutlined /> };
			case "open":
				return { color: "red", icon: <HarmonyOSOutlined /> };
			default:
				return { color: "gray", icon: <DashOutlined /> };
		}
	};

	const formatStatusText = (status?: string) => {
		if (!status) return "";
		return status.replace(/_/g, " ").toLowerCase();
	};

	const renderListItem = (item: any) => (
		<List.Item
			key={item._id}
			onClick={() => handleListItemClick(item)}
			onContextMenu={(event) => {
				event.preventDefault();
				setSelectRec(item);
				if (!menuVisible) {
					document.addEventListener(`click`, function onClickOutside() {
						setMenuVisible(false);
						document.removeEventListener(`click`, onClickOutside);
					});
				}
				setMenuVisible(true);
				setPosition({ x: event.clientX, y: event.clientY });
			}}
			style={{ padding: "0px 0" }}
		>
			<div className="list-item">
				<div className="list-item-description-left ">
					<div className="list-item-action">{renderActions(item)}</div>
					<span className="work-item-id">{item.WorkitemId}</span>
					<span className="work-item-title">{item.title}</span>
				</div>
				<div className="list-item-description-right ">
					<span
						className="status-tag"
						style={{ color: getStatusStyle(item.status).color }}
					>
						{formatStatusText(item.status)}
					</span>
					<Tooltip title={item.type}>
						<Tag color="blue" className="tag-icon">
							{getTypeIcon(item.type)}
						</Tag>
					</Tooltip>
					<Tooltip title={item.priority ? item.priority : "Null"}>
						<Tag color={getTagcolor(item.priority)} className="priority-tag">
							{getPriorityIcon(item.priority)}
						</Tag>
					</Tooltip>
					<div className="assignee-avatar">
						{renderAssigneeAvatar(item.assignee)}
					</div>
				</div>
			</div>
		</List.Item>
	);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const currentPageData = data.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize
	);

	//////////////////////////////////////Case: No grouping is applied///////////////////////////////////////////////////

	if (selectedGroupBy === "None") {
		// 	return (
		// 		<>
		// 			<BacklogDropdownMenu
		// 				open={menuVisible}
		// 				pos={position}
		// 				selectedRec={selectRec}
		// 			/>
		// 			<Table
		// 				columns={columns}
		// 				onRow={(record, rowIndex) => {
		// 					return {
		// 						onContextMenu: (event) => {
		// 							event.preventDefault();
		// 							console.log("Right clicked on row ", record);
		// 							setSelectRec(record);
		// 							console.log(rowIndex);
		// 							//event.preventDefault();
		// 							if (!menuVisible) {
		// 								document.addEventListener(`click`, function onClickOutside() {
		// 									setMenuVisible(false);
		// 									document.removeEventListener(`click`, onClickOutside);
		// 								});
		// 							}
		// 							setMenuVisible(true);
		// 							setPosition({ x: event.clientX, y: event.clientY });
		// 						},
		// 					};
		// 				}}
		// 				//expandedRowRender={expandedRowRender}
		// 				dataSource={data}
		// 				// expandable={{
		// 				// 	expandedRowKeys: [expandedRowKey],
		// 				// 	onExpand: (expanded, record) => {
		// 				// 		setExpandedRowKey(expanded ? record.key : "");
		// 				// 		if (expanded) {
		// 				// 			handleCollapse(record);
		// 				// 		}
		// 				// 	},
		// 				// }}
		// 			>
		// 				{/* <Table.Column title="ID" dataIndex="WorkitemId" key="WorkitemId" />
		// 				<Table.Column title="Title" dataIndex="title" key="title" />
		// 				<Table.Column title="Description" dataIndex="description" key="description" />
		// 				<Table.Column title="Priority" dataIndex="priority" key="priority" />
		// 				<Table.Column title="Type" dataIndex="type" key="type" />
		// 				<Table.Column title="Status" dataIndex="status" key="status" />
		// 				<Table.Column title="Action" key="action" />   */}
		// 			</Table>
		// 		</>
		// 	);
		// }

		return (
			// <div className="list-view-container" style={{ marginLeft: "1vw" }}>
			<div
				className="list-view-container"
				style={{
					display: "flex",
					flexDirection: "column",
					height: "76vh",
					marginLeft: "1vw",
					padding: "0vw",
				}}
			>
				<BacklogDropdownMenu
					open={menuVisible}
					pos={position}
					selectedRec={selectRec}
				/>
				<div style={{ flex: "1 1 auto" }}>
					<List
						itemLayout="vertical"
						dataSource={currentPageData}
						renderItem={renderListItem}
						className="list-view"
						size="small"
					/>
				</div>
				<div
					style={{
						flex: "0 0 20px",
						textAlign: "end",
						marginTop: "3px",
						marginBottom: "10px",
					}}
				>
					<Pagination
						current={currentPage}
						total={data.length}
						pageSize={pageSize}
						onChange={handlePageChange}
						showSizeChanger={false}
					/>
				</div>
			</div>
		);
	}

	//////////////////////////////////////Case: Grouping is applied///////////////////////////////////////////////////
	return (
		<Collapse ghost>
			{Object.entries(groupedTasks)
				.filter(([groupKey]) => groupKey !== "undefined")
				.map(([groupKey, groupData]) => (
					<Collapse.Panel
						key={groupKey}
						header={<div style={{ textAlign: "left" }}>{groupKey}</div>}
					>
						<BacklogDropdownMenu
							open={menuVisible}
							pos={position}
							selectedRec={selectRec}
						/>
						{/* <Table
							columns={columns}
							onRow={(record, rowIndex) => {
								return {
									onContextMenu: (event) => {
										event.preventDefault();
										console.log("Right clicked on row ", record);
										setSelectRec(record);
										console.log(rowIndex);
										//event.preventDefault();
										if (!menuVisible) {
											document.addEventListener(
												`click`,
												function onClickOutside() {
													setMenuVisible(false);
													document.removeEventListener(`click`, onClickOutside);
												}
											);
										}
										setMenuVisible(true);
										setPosition({ x: event.clientX, y: event.clientY });
									},
								};
							}}
							pagination={false}
							//expandedRowRender={expandedRowRender}
							dataSource={(groupData as any[]).map((item) => ({
								...item,
								key: item._id,
							}))}
							// expandable={{
							// 	expandedRowKeys: [expandedRowKey],
							// 	onExpand: (expanded, record) => {
							// 		setExpandedRowKey(expanded ? record.key : "");
							// 		if (expanded) {
							// 			handleCollapse(record);
							// 		}
							// 	},
							// }}
						/> */}
						<div style={{ flex: "1 1 auto" }}>
							<List
								itemLayout="vertical"
								dataSource={(groupData as any[]).map((item) => ({
									...item,
									key: item._id,
								}))}
								renderItem={renderListItem}
								style={{
									maxHeight: "70vh",
									overflowY: "scroll",
									height: 35,
									lineHeight: "35px",
								}}
								size="small"
							/>
						</div>
						<div
							style={{
								flex: "0 0 20px",
								textAlign: "end",
								marginTop: "3px",
								marginBottom: "10px",
							}}
						>
							<Pagination
								current={currentPage}
								total={data.length}
								pageSize={pageSize}
								onChange={handlePageChange}
								showSizeChanger={false}
							/>
						</div>
					</Collapse.Panel>
				))}
		</Collapse>
	);

	function groupBy(array: any[], key: string) {
		return array.reduce((result: any, currentItem: any) => {
			const groupKey = currentItem[key];
			if (groupKey !== undefined) {
				// Check if groupKey is defined
				if (!result[groupKey]) {
					result[groupKey] = [];
				}
				result[groupKey].push(currentItem);
			}
			return result;
		}, {});
	}
};

export default MyTaskListViewItemV2;
