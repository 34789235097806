/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import {
	Modal,
	Button,
	List,
	Avatar,
	message,
	Form,
	Select,
	Tabs,
	AutoComplete,
	Input,
} from "antd";
import { CloseCircleOutlined, UserOutlined } from "@ant-design/icons";
import "./ShareWorkspace.css";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import usePlatformStore from "../stores/platformStore";
import { useRequest } from "ahooks";
import { updatedbyid } from "../api/DashboardApi";
import { getallRoles } from "../api/RolesAPI";

const DashboardShareModal = ({ visible, onCancel, selectedOptionId }: any) => {
	const { TabPane } = Tabs;
	const [form] = Form.useForm();
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const [SharedMembersData, setSharedMembersData] = useState<any[]>([]);
	const ServerLink = process.env.REACT_APP_API_URL;
	const [responseData, setResponseData] = useState<any[]>([]);
	const [responseDataRole, setResponseDataRole] = useState<any[]>([]);
	const [activeTab, setActiveTab] = useState<string>("1");
	const [selecteduserid, setselecteduserid] = useState<string>("");
	const { setUnauthorizedModel } = usePlatformStore((state: any) => ({
		setUnauthorizedModel: state.setUnauthorizedModel,
	}));
	const [existingUsers, setExistingUsers] = useState<string[]>([]);
	const [existingRoles, setExistingRoles] = useState<string[]>([]);

	const [defaultRoleId, setDefaultRoleId] = useState<string | null>(null);

	const { data: roleData, run: fetchRolesData } = useRequest(
		() => getallRoles(userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess() {
				// message.success("Role fetched successfully");
			},
			onError() {
				message.error("Error fetching roles data");
			},
		}
	);

	useEffect(() => {
		if (roleData) {
			setUnauthorizedModel(false);
			const uniqueData = roleData.filter(
				(value: any) => value.name.toLowerCase() !== "owner"
			);
			setResponseDataRole(uniqueData);
			const guestRole = uniqueData.find(
				(value: any) => value.name.toLowerCase() === "guest"
			);
			if (guestRole) {
				setDefaultRoleId(guestRole._id);
			}
		}
	}, [roleData]);
	useEffect(() => {
		if (userProfileStoreInstance.idToken) {
			fetchRolesData();
		}
	}, []);

	const handleTabChange = (key: string) => {
		setActiveTab(key);
	};
	const fetchUserData = async () => {
		if (userProfileStoreInstance.idToken) {
			try {
				const response = await fetch(`${ServerLink}/user-profiles`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					const uniqueData = data.filter(
						(value: any, index: any, self: any) =>
							index ===
							self.findIndex(
								(v: any) => v.name.toLowerCase() === value.name.toLowerCase()
							)
					);

					setResponseData(uniqueData);
				} else if (response.status === 401) {
					setUnauthorizedModel(true);
				} else {
					console.error("Error fetching user profiles data");
				}
			} catch (error) {
				console.error("Error fetching user profiles data:", error);
			}
		}
	};
	useEffect(() => {
		fetchUserData();
	}, []);

	const fetchSharedMembersData = async () => {
		try {
			if (selecteduserid) {
				const response = await fetch(`${ServerLink}/user-profiles`, {
					headers: {
						Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
					},
				});
				const data = await response.json();
				// Filter the data based on already selected userProfileId
				const filteredData = data.filter(
					(item: { userProfileId: { _id: string } }) =>
						item.userProfileId
							? item.userProfileId._id !== selecteduserid
							: true
				);
				setSharedMembersData(filteredData);
				const existingUsers = filteredData.map(
					(item: any) => item.userProfileId?._id || ""
				);
				const existingRoles = filteredData.map(
					(item: any) => item.roles?._id || ""
				);
				setExistingUsers(existingUsers);
				setExistingRoles(existingRoles);
			}
		} catch (error) {
			console.error("Error fetching members", error);
		}
	};

	const [inputValue, setInputValue] = useState<string>("");

	useEffect(() => {
		if (visible) {
			fetchUserData();
			fetchSharedMembersData();
		}
	}, [visible]);

	const handleInputChange = (value: string) => {
		setInputValue(value);
		setUserSelected(false);
	};

	const handleSelectUser = (value: string) => {
		const name = value.split(",")[0].trim();
		const email = value.split(",")[1].trim();
		const user = responseData.find((user) => user.email.trim() === email);

		if (user) {
			setInputValue(value);
			setUserSelected(true);
			setselecteduserid(user._id);
		} else {
			// console.log("User not found in responseData");
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			await fetchSharedMembersData();
		};
		fetchData();
	}, [selecteduserid]);

	const [userSelected, setUserSelected] = useState<boolean>(false);

	const { run: updateDashboardbyId } = useRequest(
		(id, body) => updatedbyid(id, body, userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (data) => {
				console.log(data);
				form.resetFields();
				message.success("Dashboard shared with the member");
			},
			onError: (error) => {
				console.log(error);
				message.error(`Error while sharing dashboard:${error.message}`);
			},
		}
	);

	const handleOk = async (values: any) => {
		try {
			if (!selecteduserid) {
				message.error("Please select a user to share the dashboard with.");
				return;
			}
			const isUserAlreadyShared = SharedMembersData.some(
				(item: any) => item.userProfileId?._id === selecteduserid
			);
			if (isUserAlreadyShared) {
				message.warning("User is already shared with this dashboard.");
				return;
			}

			updateDashboardbyId(selectedOptionId, {
				share: [selecteduserid],
			});
		} catch (error) {
			console.error("Error sharing Dashboard:", error);
			message.error("Error sharing Dashboard");
		}
	};

	return (
		<Form layout="vertical" form={form} onFinish={handleOk}>
			<Modal
				className="modal-share-Dashboard"
				data-testId="modal-share-Dashboard"
				closeIcon={<CloseCircleOutlined style={{ fontSize: "24px" }} />}
				open={visible}
				okText="Send"
				onCancel={onCancel}
				width="50%"
				footer={[
					<Button
						className="button-copy-link"
						key="copy"
						onClick={onCancel}
						data-testId="copy-link"
					>
						Cancel
					</Button>,
					<Button
						data-testId="send-invite-btn-test"
						className="button-send-invite"
						key="send"
						type="primary"
						onClick={() => form.submit()}
					>
						Share Dashboard
					</Button>,
				]}
			>
				<div
					style={{ height: "52vh", display: "flex", flexDirection: "column" }}
				>
					<div>
						<h2>Shared Dashboard</h2>
						<Form.Item name="name">
							<AutoComplete
								className="input-add-team-members"
								placeholder="Type UserName"
								value={inputValue}
								onChange={handleInputChange}
								onSelect={handleSelectUser}
								options={responseData
									.filter(
										(user) =>
											!existingUsers.includes(user._id) &&
											user.name.toLowerCase().includes(inputValue.toLowerCase())
									)
									.map((user) => ({
										value: `${user.name} , ${user.email}`,
									}))}
								filterOption={(inputValue, option) =>
									option!.value.toUpperCase().includes(inputValue.toUpperCase())
								}
							/>
						</Form.Item>
					</div>
				</div>
			</Modal>
		</Form>
	);
};

export default DashboardShareModal;
