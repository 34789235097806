import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Input, Tag, message } from "antd";
import { useNavigate } from "react-router-dom";
import userProfileStore, {
	UserProfileStoreType,
} from "../stores/UserProfileStore";
import useWorkspaceStore from "../stores/WorkspaceState";
import { useDebounceEffect, useRequest } from "ahooks";
import { motion, AnimatePresence } from "framer-motion";
import { CloseOutlined } from "@ant-design/icons";
import { getWorkItemsbyQuery } from "../api/WorkItemAPI";

type Props = {
	setOpenCommandDialog: React.Dispatch<React.SetStateAction<boolean>>;
	openCommandDialog: boolean;
};

const CommandSearchV2: React.FC<Props> = ({
	setOpenCommandDialog,
	openCommandDialog,
}) => {
	const navigate = useNavigate();
	const [workItemData, setWorkItemData] = useState<any[]>([]);
	const modalRef = useRef<HTMLDivElement>(null);

	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;
	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));

	const [searchInput, setSearchInput] = React.useState("");
	const [filteredOptions, setFilteredOptions] = React.useState<
		{
			label: string;
			type: string;
			data: { url: string };
			category: string;
			tags: string[];
		}[]
	>([]);

	const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>("");

	useDebounceEffect(
		() => {
			setSearchInput(debouncedSearchInput);
		},
		[debouncedSearchInput],
		{
			// wait: 1000,
			wait: 500,
		}
	);

	const { run: fetchTodosRequest } = useRequest(getWorkItemsbyQuery, {
		manual: true,
		onSuccess: (data) => {
			setWorkItemData(data);
		},
		onError: (error) => {
			message.error("Failed to fetch todos.");
			throw new Error(`Fetching todos failed: ${error.message}`);
		},
	});

	useEffect(() => {
		if (currentWorkspace?._id && userProfileStoreInstance.idToken) {
			fetchTodosRequest(
				currentWorkspace._id,
				searchInput,
				userProfileStoreInstance.idToken
			);
		}
	}, [searchInput, currentWorkspace, userProfileStoreInstance.idToken]);

	const commandOptions = [
		{
			label: "Go to settings",
			type: "navigate",
			data: {
				url: "/settings/role",
			},
			category: "settings",
			tags: ["settings", "role"],
		},
		{
			label: "Go to User management",
			type: "navigate",
			data: {
				url: "/settings/user",
			},
			category: "settings",
			tags: ["user", "settings"],
		},
	];

	React.useEffect(() => {
		if (filteredOptions.length === 0) setFilteredOptions(commandOptions);
	});

	React.useEffect(() => {
		if (searchInput === "" || searchInput === null) {
			setFilteredOptions(commandOptions);
			return;
		} else {
			setFilteredOptions(
				filteredOptions.filter((option) => {
					return option.tags.some((tag) => tag.includes(searchInput));
				})
			);
		}
	}, [searchInput]);

	const doCommand = (item: {
		label: string;
		type: string;
		data: { url: string };
		category: string;
		tags: string[];
		_id: string;
	}) => {
		if (item.type === "navigate") {
			navigate(`${item.data.url}`);
		}
		if (item.type === "TASK") {
			const url = `/space/${currentWorkspace._id}/planner/viewtask/${item._id}`;
			navigate(url);
		}
		if (item.type === "FEATURE") {
			const url = `/space/${currentWorkspace._id}/planner/view/${item._id}`;
			navigate(url);
		}
		if (item.type === "USER STORY") {
			const url = `/space/${currentWorkspace._id}/planner/view/${item._id}`;
			navigate(url);
		}
		if (item.type === "BUG") {
			const url = `/space/${currentWorkspace._id}/planner/view/${item._id}`;
			navigate(url);
		}
		setOpenCommandDialog(false);
		setSearchInput("");
		setDebouncedSearchInput("");
		setFilteredOptions(commandOptions);
	};

	const dropIn = {
		hidden: {
			y: "-100vh",
			opacity: 0,
		},
		visible: {
			y: "0",
			opacity: 1,
			transition: {
				duration: 0.1,
				type: "spring",
				damping: 25,
				stiffness: 500,
			},
		},
		exit: {
			y: "100vh",
			opacity: 0,
		},
	};

	const onClose = () => {
		setOpenCommandDialog(false);
		setSearchInput("");
		setDebouncedSearchInput("");
		setFilteredOptions(commandOptions);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				openCommandDialog &&
				modalRef.current &&
				!modalRef.current.contains(event.target as Node)
			) {
				onClose();
			}
		};
		const handleClickInside = (event: MouseEvent) => {
			event.stopPropagation();
		};

		if (openCommandDialog) {
			document.addEventListener("mousedown", handleClickOutside);
			modalRef.current?.addEventListener("mousedown", handleClickInside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			modalRef.current?.removeEventListener("mousedown", handleClickInside);
		};
	}, [openCommandDialog]);

	const getTagColor = (type: string): string => {
		switch (type) {
			case "TASK":
				return "blue";
			case "FEATURE":
				return "yellow";
			case "USER STORY":
				return "pink";
			case "BUG":
				return "red";
			case "task":
				return "blue";
			default:
				return "gray";
		}
	};

	const ModalWidth = (): string => {
		const screenWidth = window.innerWidth;
		const breakpointLg = 992;

		if (screenWidth < breakpointLg) {
			return "320px";
		}
		return "28vw";
	};

	return (
		<AnimatePresence>
			{openCommandDialog && (
				<>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 0.5 }}
						exit={{ opacity: 0 }}
						style={{
							position: "fixed",
							top: 0,
							left: 0,
							width: "100vw",
							height: "100vh",
							background: "rgba(0, 0, 0, 0.4)",
							backdropFilter: "blur(0px)",
							zIndex: 999,
						}}
						onClick={onClose}
					/>
					<motion.div
						variants={dropIn}
						initial="hidden"
						animate="visible"
						exit="exit"
						ref={modalRef}
						style={{
							position: "fixed",
							top: "15%",
							left: "40%",
							transform: "translate(-50%, -50%)",
							zIndex: 1000,
							background: "#fff",
							padding: "10px",
							borderRadius: "18px",
							width: ModalWidth(),
							boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
						}}
					>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<h2
								style={{
									marginTop: "-1.2vh",
									marginLeft: "10vw",
									marginBottom: "-1.2vh",
								}}
							>
								Search
							</h2>
							<Button
								type="text"
								onClick={onClose}
								style={{ color: "gray", fontSize: "18px" }}
							>
								<CloseOutlined />
							</Button>
						</div>
						<Input
							placeholder="Search"
							value={debouncedSearchInput}
							onChange={(e) => setDebouncedSearchInput(e.target.value)}
							allowClear
						/>
						<Card style={{ height: "50vh", border: "0px", overflowY: "auto" }}>
							<motion.ul
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ duration: 0.5, delay: 0.2 }}
								style={{ listStyle: "none", padding: 0 }}
							>
								{workItemData.map((item, index) => (
									<motion.li
										key={index}
										initial={{ opacity: 0, y: -20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.5, delay: index * 0.1 }}
										style={{
											cursor: "pointer",
											marginBottom: "10px",
											textAlign: "left",
										}}
										onClick={() => doCommand(item)}
									>
										<div>
											<h3
												style={{
													display: "flex",
													justifyContent: "space-between",
													margin: 5,
												}}
											>
												<span>{item.title}</span>
												<Tag
													color={getTagColor(item.type)}
													style={{ fontSize: ".8rem", marginRight: "-20px" }}
												>
													{item.type}
												</Tag>
											</h3>
											<p style={{ margin: 5 }}>{item.description}</p>
										</div>
									</motion.li>
								))}
							</motion.ul>
						</Card>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	);
};

export default CommandSearchV2;
