/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Flex, Image, Layout } from "antd";
import { Outlet } from "react-router-dom";
import AppNavbar from "./AppNavbar";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import ability from "../util/ability";
import AppHeader from "../layout/AppHeader";
import { Content } from "antd/es/layout/layout";
import ProfileMenu from "../pages/ProfileMenu";

export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);

const { Sider } = Layout;

function SettingsLayout() {
	const [abilities, setAbilities] = useState(ability);
	return (
		<AbilityContext.Provider value={abilities}>
			<Can I="SETTINGS_CAN_VIEW" a="SETTINGS_CAN_VIEW" passThrough>
				{(allowed) =>
					allowed ? (
						<Layout style={{ minHeight: "100vh", backgroundColor: "#FAFAFF" }}>
							<ProfileMenu />
							<Layout
								style={{
									backgroundColor: "#FAFAFF",
									justifyContent: "start",
								}}
							>
								<Content
									style={{
										height: "90vh",
										overflow: "hidden",
										paddingTop: ".2rem",
										background: "#F5F8FE",
									}}
								>
									<div>
										<AppHeader />
									</div>
									{/* <AppNavbar
										breadcrumbs={["Settings"]}
										menuType="settings"
										reviewEnabled={false}
										onYesClick={() => {}}
									/> */}
									<Outlet />
								</Content>
								<Flex
									className="footer"
									justify="center"
									style={{
										width: "100%",
										position: "absolute",
										bottom: "0",
										margin: "1rem 0 0px 0",
										background: "#F5F8FE",
									}}
								>
									<p
										style={{
											marginLeft: "-28vw",
											fontSize: 12,
											fontWeight: 500,
											fontFamily: "Poppins",
										}}
									>
										© 2024 Zentience. All rights reserved.
									</p>
								</Flex>
							</Layout>
						</Layout>
					) : (
						<Flex style={{ marginTop: "15vh" }} justify="center">
							<Image
								height={"50vh"}
								width={"60vw"}
								src={
									"https://alchemidevappfilestorage.blob.core.windows.net/assets/images/alchemi_security_access.svg"
								}
								preview={false}
							/>
						</Flex>
					)
				}
			</Can>
		</AbilityContext.Provider>
	);
}

export default SettingsLayout;
