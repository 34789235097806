import React, { useEffect, useState } from "react";

import {
	//UserOutlined,
	SearchOutlined,
	//ArrowLeftOutlined,
} from "@ant-design/icons";
import {
	Flex,
	//Button,
	//Space,
	Input,

	//Tooltip,
	//Breadcrumb,
	Divider,
} from "antd";
import BreadCrumb from "../Editors/BreadCrumb";
//import breadcrumbConfig from "../../util/BreadCrumbsUtil";
import { useViewsFilterStores } from "../../stores/ViewsFilter";
import usePageStore from "../../stores/PageStore";
//import usePageStore from "../../stores/PageStore";

// interface ViewFilterNavbarProps {
// 	viewData: any;
// }

// const ViewFilterNavbar: React.FC<ViewFilterNavbarProps> = ({ viewData }) => {
const ViewFilterNavbar = () => {
	const [isSearchExpanded, setIsSearchExpanded] = useState(false);
	const [input, setInput] = useState("");
	const [searchFilteredData, setSearchFilteredData] = useState(); // State to hold filtered data

	const handleSearchIconClick = () => {
		setIsSearchExpanded(true);
	};
	const { viewData, setViewData } = useViewsFilterStores((state: any) => ({
		viewData: state.viewData,
		setViewData: state.setViewData,
	}));

	const { breadcrumbConfig } = usePageStore((state: any) => ({
		breadcrumbConfig: state.breadcrumbConfig,
	}));
	const handleSearchChange = (event: any) => {
		const searchValue = event.target.value;
		setInput(searchValue);

		// Filter viewData based on input
		const filtered = viewData.filter((item: any) => {
			//console.log(item.name.toLowerCase());
			//console.log(searchValue.toLowerCase());

			return item.name.toLowerCase().includes(searchValue.toLowerCase());
		});
		console.log(filtered);
		setSearchFilteredData(filtered);
		setViewData(filtered);

		/// console.log(filteredData);

		if (searchValue === "") {
			setIsSearchExpanded(false);
			setSearchFilteredData(viewData); // Reset to original data if search input is cleared
		}
	};
	useEffect(() => {
		console.log(searchFilteredData);
	}, [searchFilteredData]);
	return (
		<div>
			<Divider className="ant-divider-horizontal" />

			<div className="ant-div-container">
				<Flex className="flex-container">
					<Flex className="breadcrumb" style={{ marginLeft: "2vw" }}>
						<BreadCrumb config={breadcrumbConfig} />
					</Flex>
					<Flex justify="end" gap={"small"} style={{ alignItems: "center" }}>
						{/* <Input
							suffix={<SearchOutlined />}
							size="middle"
							style={{
								height: "5vh",
								width: "14vw",
								fontSize: "0.9rem",
								fontFamily: "Poppins",
							}}
							placeholder="Search"
							onChange={handleChange}
							value={input}
						/> */}
						{isSearchExpanded ? (
							<Input
								suffix={<SearchOutlined />}
								size="small"
								className="search-input"
								placeholder="Search"
								onChange={handleSearchChange}
								value={input}
								allowClear
								autoFocus
								// onBlur={() => {
								// 	if (input === "") setIsSearchExpanded(false);
								// }}
							/>
						) : (
							<SearchOutlined
								className="search-icon"
								onClick={handleSearchIconClick}
								// onMouseEnter={() => setIsSearchExpanded(true)}
							/>
						)}
					</Flex>
				</Flex>
			</div>
		</div>
	);
};
export default ViewFilterNavbar;
