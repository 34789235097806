/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
	Menu,
	Dropdown,
	Button,
	Modal,
	Input,
	MenuProps,
	Space,
	message,
	Form,
	Select,
} from "antd";
import {
	DownOutlined,
	PlusOutlined,
	GithubOutlined,
	RightOutlined,
	SettingOutlined,
	PlusSquareOutlined,
} from "@ant-design/icons";
import "./ActionsDropdown.css"; // Import the CSS file
import { getConnection } from "../../api/ConnectionAPI";
import { useRequest } from "ahooks";
import userProfileStore, {
	UserProfileStoreType,
} from "../../stores/UserProfileStore";
import useWorkspaceStore from "../../stores/WorkspaceState";
import { planerViewStore } from "../../stores/PlannerStore";

const ActionDropdown = () => {
	const userProfileStoreInstance = userProfileStore() as UserProfileStoreType;

	const { selectedItemDetails } = planerViewStore();

	const { currentWorkspace } = useWorkspaceStore((state: any) => ({
		currentWorkspace: state.currentWorkspace,
	}));
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [integration, setIntegration] = useState("");
	const [ConnectionsData, setConnectionsData] = useState<any[]>([]);
	const [connectionName, setConnectionName] = useState("");

	const [taskRunName, setTaskRunName] = useState("");
	const [formData, setFormData] = useState({
		workItemId: "",
		workspaceId: "",
		connectionId: "",
		userProfileId: "",
	});

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		console.log("Form Data:", formData);
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	type MenuItem = Required<MenuProps>["items"][number];

	const items: MenuItem[] = [
		{
			key: "sub4",
			label: "Create Related",
			icon: <PlusSquareOutlined />,
			children: [
				{ key: "9", label: "GitHub Issues", icon: <GithubOutlined /> },
			],
		},
	];

	const handleMenuClick: MenuProps["onClick"] = (e) => {
		if (e.key === "9") {
			showModal();
		}
	};

	const menu = (
		<Menu
			items={items}
			style={{ borderRadius: "8px", marginTop: "1vh" }}
			onClick={handleMenuClick}
		/>
	);

	const { data: connectionsData, run: fetchConnections } = useRequest(
		() => getConnection(userProfileStoreInstance.idToken),
		{
			manual: true,
			onSuccess: (result) => {
				console.log(result, "Connections data");
				// message.info("Conection Setting");
			},
			onError: (error) => {
				message.error(`Failed to fetch Connections : ${error.message}`);
				console.error("Error fetching connections data:", error);
			},
		}
	);

	useEffect(() => {
		if (connectionsData) {
			console.log("integration", integration);
			if (integration === "Github") {
				const githubConnections = connectionsData.filter(
					(connection) => connection.type === "Github"
				);
				console.log("githubConnections", githubConnections);
				setConnectionsData(githubConnections);
			} else {
				const jiraConnections = connectionsData.filter(
					(connection) => connection.type === "Jira"
				);
				setConnectionsData(jiraConnections);
				console.log("jiraConnections", jiraConnections);
			}
		}
	}, [connectionsData, integration]);

	useEffect(() => {
		if (userProfileStoreInstance.idToken) {
			fetchConnections();
		}
	}, [userProfileStoreInstance.idToken]);

	const handleCreateTaskRun = () => {
		const APIUrl = `${process.env.REACT_APP_API_URL}/taskrun/export`;
		const data = {
			name: taskRunName,
			type: "Export",
			workspaceId: currentWorkspace._id,
			workItemId: selectedItemDetails._id,
			userProfileId: userProfileStoreInstance.profile._id,
			config: {
				integration: integration,
				connection: connectionName,
			},
			status: "open",
			createdBy: userProfileStoreInstance.profile._id,
			updatedBy: userProfileStoreInstance.profile._id,
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
		};

		console.log("data", data);

		fetch(APIUrl, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${userProfileStoreInstance.idToken}`,
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				console.log("Success:", data);
				setIsModalVisible(false);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	return (
		<div>
			<Dropdown overlay={menu}>
				<a onClick={(e) => e.preventDefault()}>
					<Button
						size="small"
						style={{ borderRadius: "6px", fontSize: "14px" }}
					>
						<Space>
							Actions
							<DownOutlined />
						</Space>
					</Button>
				</a>
			</Dropdown>
			<Modal
				title="Create GitHub Issue"
				visible={isModalVisible}
				onOk={handleCreateTaskRun}
				onCancel={handleCancel}
			>
				<Space style={{ width: "100%" }} direction="vertical" size="large">
					<Form layout="horizontal" style={{ width: "100%", padding: "2vh 0" }}>
						<Form.Item
							label="Name"
							required={false}
							labelCol={{
								style: {
									width: "150px",
									textAlign: "right",
									paddingRight: "20px",
								},
							}}
							wrapperCol={{ style: { paddingLeft: "1vw" } }}
						>
							<Input
								style={{ width: "100%" }}
								value={taskRunName}
								onChange={(e) => setTaskRunName(e.target.value)}
								placeholder="Enter name"
							/>
						</Form.Item>

						<Form.Item
							label="Integration"
							required={false}
							labelCol={{
								style: {
									width: "150px",
									textAlign: "right",
									paddingRight: "20px",
								},
							}}
							wrapperCol={{ style: { paddingLeft: "1vw" } }}
						>
							<Select
								placeholder="Select integration"
								style={{ width: "100%" }}
								onChange={(value) => setIntegration(value)}
								options={[
									{ value: "Jira", label: "Jira" },
									{ value: "Github", label: "Github" },
								]}
							/>
						</Form.Item>

						<Form.Item
							label="Connection"
							required={false}
							labelCol={{
								style: {
									width: "150px",
									textAlign: "right",
									paddingRight: "20px",
								},
							}}
							wrapperCol={{ style: { paddingLeft: "1vw" } }}
						>
							<Select
								placeholder="Select connection"
								style={{ width: "100%" }}
								onChange={(value) => setConnectionName(value)}
								options={ConnectionsData.map((connection) => ({
									value: connection._id,
									label: connection.name,
								}))}
							/>
						</Form.Item>
					</Form>
				</Space>
			</Modal>
		</div>
	);
};

export default ActionDropdown;
